import React from 'react'
import EventPostHorizontalCardComponent from './EventPostHorizontalCardComponent'
import NoDataAvailable from '../../../../pages/myEvent/component/NoDataAvailable' // Импортируем карточку

import { IEventInterface } from '../../../../models/events/event.interface'

interface Props {
  events: IEventInterface[]
}

export const EventCardList: React.FC<Props> = ({ events }) => {
  return events.length ? (
    <div className="flex w-full flex-wrap">
      {events.map((item) => (
        <div
          key={item.id}
          className="p-2 flex-1 xl:flex-none min-w-[240px] w-full md:w-1/2 2xl:w-1/3 3xl:w-1/4"
        >
          <EventPostHorizontalCardComponent event={item} isLoggedIn />
        </div>
      ))}
    </div>
  ) : (
    <NoDataAvailable
      imgSrc={'assets/placeholders/NoEventsFoundProfile.svg'}
      text="No event exists"
      textColor="text-[#122B46]"
    />
  )
}
