import React, { FC } from 'react'

import { TCustomSchedule } from '../../../../models/event-scheduling/event-scheduling.interface'

interface ScheduleItemProps {
  schedules: { [key: string]: TCustomSchedule[] }
  schedule: string
  scheduleBy?: 'class' | 'rider'
}

export const ScheduleByDayItem: FC<ScheduleItemProps> = ({ schedules, schedule, scheduleBy }) => {
  const sortedSchedules = schedules[schedule].sort((a, b) => {
    const getTimeValue = (item: TCustomSchedule) => {
      let hours = parseInt(item.startTimeHours, 10)

      const minutes = parseInt(item.startTimeMinutes, 10)

      const isPM = item.startTimeFormat === 'PM'

      if (isPM && hours !== 12) hours += 12

      if (!isPM && hours === 12) hours = 0

      return hours * 60 + minutes
    }

    return getTimeValue(a) - getTimeValue(b)
  })

  return (
    <div className="scheduleByDay border-[#D3DAEE] border rounded-lg px-6 py-3 mt-6">
      <div className="flex items-center my-4 mb-8 text-[20px] font-bold">
        <h3 className="text-SeabiscuitDark200ThemeColor mr-1">{schedule.split('_')[0]},</h3>
        <div className="text-SeabiscuitDark200ThemeColor opacity-30 font-semibold ml-1 ">
          {new Date(schedules[schedule][0].startDate).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
          })}{' '}
        </div>
      </div>
      {sortedSchedules.map((item, index: number) => {
        return (
          <React.Fragment key={index}>
            <div className="flex flex-wrap md:flex-nowrap w-full mb-2 mt-3 ">
              <div
                className={`text-SeabiscuitDark200ThemeColor ${scheduleBy === 'rider' ? 'w-[20px]' : 'w-[150px]'}  font-bold mr-2 text-[14px] rounded-lg`}
              >
                {scheduleBy === 'class' &&
                  `${item.startTimeHours}:${item.startTimeMinutes}${item.startTimeFormat}`}
              </div>
              <div className="w-full">
                <div className="flex flex-wrap">
                  <div className="text-SeabiscuitDark200ThemeColor flex justify-between rounded-lg font-medium capitalize ellipsis ">
                    {item.name}
                  </div>
                  <div className="mx-1">•</div>
                  <div className="text-SeabiscuitDark200ThemeColor rounded-lg capitalize ellipsis ">
                    {item.location}
                  </div>
                  {scheduleBy === 'class' && (
                    <>
                      <div className="mx-1">•</div>

                      <div className="text-SeabiscuitDark200ThemeColor rounded-lg capitalize ellipsis text-opacity-50">
                        {item.tag} Time
                      </div>
                    </>
                  )}
                </div>
                <div className="text-[14px] mb-2 mt-2 text-SeabiscuitDark200ThemeColor">
                  {item.note}
                </div>
              </div>
            </div>
            {index !== schedules[schedule].length - 1 && <hr className="opacity-40"></hr>}
          </React.Fragment>
        )
      })}
    </div>
  )
}
