import React, { FC } from 'react'

// Components
import MainModal from './common/MainModal'
import { IconBack } from '../icons/IconBack'
import { ScratchModalHeader } from './components/ScratchModalHeader'
import { ScratchWarning } from './components/ScratchWarning'

// Redux
import { useAppSelector } from '../../store/hooks'
import { selectRecipientR } from '../../store/exhibitor/exhibitorSlice'

// Models
import { IUserInterface } from '../../models/users/user.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'

// Constants
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'

// Services
import { httpService } from '../../services/httpService'

// Helpers
import { toastFunctionsObj } from '../../helpers/ToasterHelper'
import { getFloatPrice } from '../../helpers/price'
import { processScratchItems } from '../../helpers/scratch'

interface PropsConfirmScratchFullEntryModal {
  show: boolean
  handleModal: any
  dataToPassOn: {
    totalCost: number
    exhibitor: IUserInterface
    registrations: IRegistrationByDayInterface[]
    fees: IRegistrationFeesInterface[]
    tickets: IRegistrationTicketInterface[]
  }
}

export const ConfirmScratchFullEntryModal: FC<PropsConfirmScratchFullEntryModal> = ({
  show,
  handleModal,
  dataToPassOn,
}) => {
  const recipientR = useAppSelector(selectRecipientR)
  const { registrations, exhibitor, totalCost, fees, tickets } = dataToPassOn
  const [loading, setLoading] = React.useState(false)
  const handleBackClick = () => {
    handleModal(false, MODAL_CONSTS.CONFIRM_SCRATCH_FULL_ENTRY)
    handleModal(true, MODAL_CONSTS.SCRATCH_FULL_ENTRY)
  }

  const scratchHandler = async () => {
    setLoading(true)

    try {
      const { scratchedItems: scratchedRegistrations, scratchTotal: regTotal } =
        processScratchItems(registrations, 'registrationPrice')
      const { scratchedItems: scratchedFees, scratchTotal: feesTotal } = processScratchItems(
        fees,
        'feesPrice'
      )
      const { scratchedItems: scratchedTickets, scratchTotal: ticketsTotal } = processScratchItems(
        tickets,
        'ticketPrice'
      )

      await httpService({
        url: 'scratch_items',
        method: 'POST',
        data: {
          userDocId: exhibitor.id,
          recipientId: exhibitor.id,
          registrationDocId: recipientR.data?.registrationDocId,
          scratchedRegistrationByDayItems: scratchedRegistrations.map(({ id }) => ({
            docId: id,
          })),
          scratchedFeesItems: scratchedFees.map(({ id }) => ({ docId: id })),
          scratchedTicketItems: scratchedTickets.map(({ id }) => ({ docId: id })),
        },
      })

      const totalScratchAmount = regTotal + feesTotal + ticketsTotal
      handleModal(false, MODAL_CONSTS.CONFIRM_SCRATCH_FULL_ENTRY)
      handleModal(true, MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL, {
        ...recipientR.data,
        scratchTotal: totalScratchAmount,
      })
    } catch (error: any) {
      console.error(error, 'error')
      toastFunctionsObj.error({
        message: error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      customTitle={
        <div className={'flex gap-3'}>
          <button onClick={handleBackClick}>
            <IconBack />
          </button>
          <div className={`text-SeabiscuitDark200ThemeColor text-[25px] font-bold`}>
            Confirm Scratch
          </div>
        </div>
      }
      size={'lg'}
      show={show}
      onHide={() => handleModal(false, MODAL_CONSTS.CONFIRM_SCRATCH_FULL_ENTRY)}
      type={'CONFIRM_SCRATCH_FULL_ENTRY'}
      buttons={[
        {
          label: `Scratch ${getFloatPrice(`${totalCost}`)}`,
          loading: loading,
          disabled: loading,
          className: '!w-full h-[58px]',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: scratchHandler,
          textClass: '!text-white uppercase',
          onHoverClass: 'hover:!bg-SeabiscuitMainThemeColorDark',
        },
        {
          label: 'CANCEL',
          loading: loading,
          disabled: loading,
          className: '!w-full',
          bgClass: '!bg-SeabiscuitIconThemeColor/10 border-0 !mt-2 h-[58px]',
          onClick: () => handleModal(false, MODAL_CONSTS.CONFIRM_SCRATCH_FULL_ENTRY),
          textClass: '!text-[#8597b3] uppercase',
          onHoverClass: 'hover:!bg-SeabiscuitIconThemeColor/20',
        },
      ]}
    >
      {exhibitor && (
        <div className={'flex flex-col gap-[22px] pt-5 w-full text-SeabiscuitDark200ThemeColor'}>
          <ScratchModalHeader
            totalCost={totalCost}
            exhibitor={exhibitor}
            totalEntries={registrations.length}
          />
          <ScratchWarning />
          <ScratchWarning isInfo />
        </div>
      )}
    </MainModal>
  )
}
