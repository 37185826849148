import React from 'react'
import clsx from 'clsx'

import BillsHeader from './common/BillsHeader'

import { getFloatPrice } from '../../../helpers/price'
import { getRiderHorses } from '../../../helpers/riders'

import useHorse from '../../../pages/organizer/manage/common/table/HorsesManage/hooks/useHorse'

import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IBillsSliceInitialStateInterface as ibsisi } from '../../../store/bills/billsSlice.types'
import { IHorseData } from '../../../models/horse/horse.interface'

import { PAYMENT_STATUS, PAYMENT_TYPE } from '../../../types/payment'

type IBillDetailsRegistrationDataProps = {
  countDetails: ibsisi['selected']['countDetails']
  registrationsByDay: IRegistrationByDayInterface[]
  modalThemeColor: `#${string}`
  currentUser: string
  showRefund: boolean
  refundregistrationTotalState: number
}

type ITotalRowArgs = {
  modalThemeColor: `#${string}`
  item: string
  horses: string
  rider: string
  price: string | number
  showRefund?: boolean
  refundregistrationTotalState: number
}

type IDetailRow = {
  horses: IHorseData[]
  showRefund: boolean
  currOneDayRegistration: IRegistrationByDayInterface
}

const EventReviewPublishInput: React.FC<{
  className?: string
  data?: any
  price?: string | number
  icon?: string
  postIcon?: string
  style?: React.CSSProperties
}> = ({ className, data, price, icon, postIcon, style = {} }) => {
  return (
    <div className={`${className} text-SeabiscuitDark200ThemeColor`} style={style}>
      {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
      <div className="text-SeabiscuitDark200ThemeColor font-normal mr-1">{price}</div>
      <div>{data}</div>
      {postIcon ? <img className="w-5 h-5 absolute right-3" src={postIcon} alt="icon" /> : null}
    </div>
  )
}

const DetailRow = ({ horses, showRefund, currOneDayRegistration }: IDetailRow) => {
  const split =
    currOneDayRegistration.riderName && currOneDayRegistration.riderName.length > 10
      ? currOneDayRegistration.riderName.substring(0, 10)
      : null
  return (
    <div className="flex flex-col md:flex-row w-full mb-5 md:mb-0">
      <EventReviewPublishInput
        className={clsx(
          `rounded-lg min-h-11 bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 ml-0 w-full md:w-4/12 `
        )}
        data={
          <div className="flex flex-wrap items-center gap-1">
            <span>{currOneDayRegistration.registrationByDayName}</span>
            {currOneDayRegistration.isScratched ? (
              <span
                className={'bg-[#122B461A] text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'}
              >
                Scratched
              </span>
            ) : (
              ''
            )}
            {currOneDayRegistration.isQualify ? (
              <span
                className={
                  'border border-SeabiscuitDark200ThemeColor text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'
                }
              >
                Qualifying
              </span>
            ) : (
              ''
            )}
          </div>
        }
      />
      <EventReviewPublishInput
        className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12"
        data={horses[0] ? horses.map((item) => item.horseName).join(', ') : 'N/A'}
        icon={'/assets/og_icons/YearofHorse-1.svg'}
      />
      <EventReviewPublishInput
        className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12 capitalize"
        data={split ? `${split}...` : currOneDayRegistration.riderName}
        icon={'/assets/cp_icons/User-1.svg'}
      />
      <EventReviewPublishInput
        className="relative rounded-lg text-center bg-SeabiscuitGrayThemeColor flex items-center justify-center text-sm pl-7 p-3 m-1 w-full md:w-3/12"
        data={
          <span>
            {getFloatPrice(`${currOneDayRegistration.registrationPrice}`)}{' '}
            {currOneDayRegistration.isQualify && (
              <span>
                • {getFloatPrice(`${currOneDayRegistration.qualifyFee}`)}{' '}
                {currOneDayRegistration.qualifyFeeName || 'qual'}
              </span>
            )}
          </span>
        }
        icon={
          currOneDayRegistration.paymentType === PAYMENT_TYPE.CARD
            ? '/assets/img/dark/credit-card.svg'
            : currOneDayRegistration.paymentType === PAYMENT_TYPE.CHECK
              ? '/assets/img/dark/check.svg'
              : '/assets/img/dark/cash.svg'
        }
        postIcon={
          currOneDayRegistration.paymentStatus === PAYMENT_STATUS.PAID
            ? '/assets/cp_icons/Ok-3.svg'
            : '/assets/cp_icons/Cancel-2.svg'
        }
      />

      {showRefund && (
        <>
          {' '}
          <EventReviewPublishInput
            className="rounded-lg text-center bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 w-full md:w-3/12"
            data={`${currOneDayRegistration.amountScratched > 0 ? 'Scratched' : 'No'}`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-center bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 w-full md:w-3/12"
            data={getFloatPrice(`${currOneDayRegistration.amountRefunded}`)}
          />
        </>
      )}
    </div>
  )
}

const TotalRow = (args: ITotalRowArgs) => {
  const { item, horses, rider, price, showRefund, refundregistrationTotalState } = args

  return (
    <>
      {showRefund ? (
        <>
          <div className="flex flex-col md:flex-row md:items-start w-full">
            <EventReviewPublishInput
              className={
                'rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-[21%] font-semibold bg-SeabiscuitGreenLightThemeColor'
              }
              data={`Total`}
            />
            <EventReviewPublishInput
              className="rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-10/12 font-semibold flex md:justify-end bg-SeabiscuitGreenLightThemeColor"
              data={`${getFloatPrice(`${refundregistrationTotalState}`)} refunded`}
              price={`${getFloatPrice(`${price}`)} paid,`}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col md:flex-row md:items-start w-full">
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] text-sm p-3 mb-3 md:md-0 md:m-1 ml-0 w-full md:w-4/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`Total ${item} Registration`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] relative flex items-center justify-center text-sm p-3 mb-3 md:md-0 md:m-1 w-full md:w-3/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`${horses} horse`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] relative flex items-center justify-center text-sm p-3 mb-3 md:md-0 md:m-1 w-full md:w-3/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`${rider} rider`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-center text-[#122B46] text-sm p-3 md:m-1 w-full md:w-3/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={getFloatPrice(`${price}`)}
          />
        </div>
      )}
    </>
  )
}

const BillDetailsRegistrationData = ({
  registrationsByDay,
  showRefund,
  refundregistrationTotalState,
  modalThemeColor,
}: IBillDetailsRegistrationDataProps) => {
  const { allHorses } = useHorse(registrationsByDay[0]?.eventId ?? '')

  // Hooks and vars
  let registerCount = new Set()
  let price = 0
  let qualifyFee = 0

  const uniqueHorses: string[] = []

  registrationsByDay.forEach((registration) => {
    registration.horseIds &&
      registration.horseIds.forEach((horseId) => {
        if (!uniqueHorses.includes(horseId)) {
          uniqueHorses.push(horseId)
        }
      })
  })

  return (
    <>
      {registrationsByDay && registrationsByDay?.length ? (
        <div className="flex items-center">
          <BillsHeader title="Class" tooltipText="Registration" />
          <BillsHeader title="Horse" tooltipText="Registered horse" />
          <BillsHeader title="Rider" tooltipText="Registered rider" />
          <BillsHeader title="Price" tooltipText="Price" />
          {showRefund && (
            <>
              {' '}
              <BillsHeader title="Scratched" tooltipText="Scratched" />
              <BillsHeader title="Refunded" tooltipText="Refunded" />{' '}
            </>
          )}
        </div>
      ) : null}

      {registrationsByDay && registrationsByDay?.length ? (
        registrationsByDay?.map((currOneDayRegistration, index) => {
          registerCount.add(currOneDayRegistration.riderId)
          if (
            currOneDayRegistration.registrationPrice &&
            currOneDayRegistration.paymentStatus === PAYMENT_STATUS.PAID
          )
            price += currOneDayRegistration.registrationPrice

          if (currOneDayRegistration.isQualify)
            qualifyFee += Number(currOneDayRegistration.qualifyFee)

          return (
            <DetailRow
              key={index}
              horses={getRiderHorses(allHorses, currOneDayRegistration.horseIds ?? [])}
              showRefund={showRefund ?? false}
              currOneDayRegistration={currOneDayRegistration}
            />
          )
        })
      ) : (
        <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
          No fees added
        </span>
      )}

      {registrationsByDay?.length ? (
        <TotalRow
          modalThemeColor={modalThemeColor}
          item={`${registrationsByDay.length}`}
          horses={`${uniqueHorses.length}`}
          rider={`${registerCount.size}`}
          price={price + qualifyFee}
          showRefund={showRefund ?? false}
          refundregistrationTotalState={refundregistrationTotalState}
        />
      ) : null}
    </>
  )
}

export default BillDetailsRegistrationData
