import React, { useEffect, useState } from 'react'

// Libs
import { FiAlertTriangle } from 'react-icons/fi'
import { LuClipboardCheck, LuClipboardList, LuClipboardX } from 'react-icons/lu'

// Components
import MainModal from 'web/src/components/modals/common/MainModal'

// Helpers
import useToasterHelper from '../../helpers/ToasterHelper'

// Models
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'

// Constants
import { MODAL_CONSTS } from '../../const/modal-const'

type RescheduleRideTimesModalProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  dataToPassOn: {
    dayKey: string
    currentRegistrations: IRegistrationByDayInterface[]
    increment: string
    recalculateRideTimes: (dayKey: string, increment: string) => void
  }
}

const RescheduleRideTimesModal: React.FC<RescheduleRideTimesModalProps> = ({
  show,
  handleModal,
  dataToPassOn,
}) => {
  const { currentRegistrations, dayKey, increment, recalculateRideTimes } = dataToPassOn
  const toast = useToasterHelper()

  const [resolved, setResolved] = useState(false)

  const [stats, setStats] = useState({
    total: 0,
    outOfTurn: 0,
    conflicts: 0,
  })

  const getInvalidRiderStats = (riders: any[]) => {
    let outOfOrderCount = 0
    let duplicateCount = 0
    const rideTimeCounts = new Map<string, number>()

    // Ensure we're only processing actual riders (not breaks)
    const validRiders = riders.filter((rider) => !('isBreakDrag' in rider && rider.isBreakDrag))

    validRiders.forEach((rider, index) => {
      if (!rider.rideTime) return

      const [riderHours, riderMinutes, riderSeconds] = rider.rideTime.split(':').map(Number)
      const riderTotalSeconds = riderHours * 3600 + riderMinutes * 60 + riderSeconds
      const rideTimeKey = rider.rideTime

      rideTimeCounts.set(rideTimeKey, (rideTimeCounts.get(rideTimeKey) || 0) + 1)

      let outOfOrder = false

      if (index > 0) {
        const prevRider = validRiders[index - 1]
        if (prevRider.rideTime) {
          const [prevHours, prevMinutes, prevSeconds] = prevRider.rideTime.split(':').map(Number)
          const prevTotalSeconds = prevHours * 3600 + prevMinutes * 60 + prevSeconds

          if (riderTotalSeconds < prevTotalSeconds) {
            outOfOrder = true
          }
        }
      }

      if (index < validRiders.length - 1) {
        const nextRider = validRiders[index + 1]
        if (nextRider.rideTime) {
          const [nextHours, nextMinutes, nextSeconds] = nextRider.rideTime.split(':').map(Number)
          const nextTotalSeconds = nextHours * 3600 + nextMinutes * 60 + nextSeconds

          if (riderTotalSeconds > nextTotalSeconds) {
            outOfOrder = true
          }
        }
      }

      if (rideTimeCounts.get(rideTimeKey)! > 1) {
        duplicateCount++
      }

      if (outOfOrder) {
        outOfOrderCount++
      }
    })

    return {
      outOfOrder: outOfOrderCount,
      duplicates: duplicateCount,
    }
  }

  // compute the invalid riders when the modal opens set correct resolved local state
  useEffect(() => {
    if (show) {
      // Filter out break items using isBreakDrag
      const actualRegistrations = currentRegistrations.filter(
        (reg) => !('isBreakDrag' in reg && reg.isBreakDrag)
      )

      const calculatedStats = getInvalidRiderStats(actualRegistrations)
      setStats({
        total: actualRegistrations.length,
        outOfTurn: calculatedStats.outOfOrder,
        conflicts: calculatedStats.duplicates,
      })
      setResolved(false)
    }
  }, [show, currentRegistrations])

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.RESCHEDULE_RIDE_TIMES)
  }

  const onResolve = () => {
    setStats({ ...stats, outOfTurn: 0, conflicts: 0 })

    setResolved(true)
  }

  const onSave = async () => {
    try {
      recalculateRideTimes(dayKey, increment)
      toast.success({ message: 'Ride times updated!' })
      closeModal()
    } catch (err) {
      console.log('err!:', err)
      toast.error({ message: 'Something went wrong saving ride times' })
    }
  }

  const onUndo = () => {
    setResolved(false)
    setStats({ total: 0, outOfTurn: 0, conflicts: 0 })
    closeModal()
    toast.success({ message: 'Ride time auto-schedule canceled' })
  }

  const renderContent = () => {
    if (!resolved) {
      return (
        <div>
          <p className="font-medium mt-2">
            Click resolve below to reschedule the ride times for each rider.
          </p>
          <div className="flex flex-col mt-8 gap-6 ">
            <div className="flex items-center gap-3">
              <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-lg">
                <LuClipboardCheck className="text-gray-500 w-6 h-6" />
              </div>

              <div className="flex flex-col">
                <p className="text-gray-500 text-sm">Total</p>
                <span className="flex items-center gap-1">
                  <p className="font-medium text-gray-700">{stats.total}</p>
                  <p className="text-gray-600">rides</p>
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-lg">
                <LuClipboardList className="text-[#3852FB] w-6 h-6" />
              </div>

              <div className="flex flex-col">
                <p className="text-gray-500 text-sm">Out of turn</p>
                <span className="flex items-center gap-1 text-md text-gray-700">
                  <p className="font-medium">{stats.outOfTurn}</p>
                  <p className="">rides are out of turn</p>
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="bg-red-100 w-10 h-10 flex items-center justify-center rounded-lg">
                <LuClipboardX className="text-red-600 w-6 h-6" />
              </div>

              <div className="flex flex-col">
                <p className="text-gray-500 text-sm">Conflicts</p>
                <span className="flex items-center gap-1 text-md text-gray-700">
                  <p className="font-medium">{stats.conflicts}</p>
                  <p className="">rides are scheduled at the same time</p>
                </span>
              </div>
            </div>
          </div>

          <div className="bg-gray-100  rounded-lg p-4 flex items-start gap-3 shadow-sm mt-8 mb-8">
            <div className="flex items-center justify-center w-6 h-6">
              <span className="text-gray-700 ">
                <FiAlertTriangle className="h-6 w-6" />
              </span>
            </div>

            <div>
              <p className="font-medium text-gray-900">WARNING</p>
              <p className="text-gray-700 mt-1">
                This will override any manual times you have entered. All rides will be given new
                ride times.
              </p>
              <p className="text-gray-700 mt-3 font-medium">This cannot be undone.</p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <p className="font-medium mt-2">
            Scheduling conflicts successfully resolved, click save below to continue.
          </p>
          <div className="flex flex-col mt-8 gap-6 ">
            <div className="flex items-center gap-3">
              <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-lg">
                <LuClipboardCheck className="text-gray-500 w-6 h-6" />
              </div>

              <div className="flex flex-col">
                <p className="text-gray-500 text-sm">Total</p>
                <span className="flex items-center gap-1">
                  <p className="font-medium text-gray-700">{stats.total}</p>
                  <p className="text-gray-600">rides</p>
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-lg">
                <LuClipboardList className="text-[#3852FB] w-6 h-6" />
              </div>

              <div className="flex flex-col">
                <p className="text-gray-500 text-sm">Out of turn</p>
                <span className="flex items-center gap-1 text-md text-gray-700">
                  <p className="font-medium">{stats.outOfTurn}</p>
                  <p className="">rides are out of turn</p>
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="bg-red-100 w-10 h-10 flex items-center justify-center rounded-lg">
                <LuClipboardX className="text-red-600 w-6 h-6" />
              </div>

              <div className="flex flex-col">
                <p className="text-gray-500 text-sm">Conflicts</p>
                <span className="flex items-center gap-1 text-md text-gray-700">
                  <p className="font-medium">{stats.conflicts}</p>
                  <p className="">rides are scheduled at the same time</p>
                </span>
              </div>
            </div>
          </div>
          {/* Invisible Placeholder for maintaining height */}
          <div className="bg-gray-100  rounded-lg p-4 flex items-start gap-3 shadow-sm mt-8 mb-8 opacity-0 cursor-default">
            <div className="flex items-center justify-center w-6 h-6">
              <span className="text-gray-700 ">
                <FiAlertTriangle className="h-6 w-6" />
              </span>
            </div>

            <div>
              <p className="font-medium text-gray-900">WARNING</p>
              <p className="text-gray-700 mt-1">
                This will override any manual times you have entered. All rides will be given new
                ride times.
              </p>
              <p className="text-gray-700 mt-3 font-medium">This cannot be undone.</p>
            </div>
          </div>
        </div>
      )
    }
  }

  const buttons = !resolved
    ? [
        {
          label: 'Resolve',
          onClick: onResolve,
          textClass: 'text-white text-lg uppercase  ',
        },
        {
          label: 'Cancel',
          onClick: closeModal,
          bgClass: 'bg-gray-100',
          textClass: 'text-gray-600 text-lg uppercase',
          borderClass: 'border-transparent',
        },
      ]
    : [
        {
          label: 'Save',
          onClick: onSave,
          textClass: 'text-white text-lg uppercase',
        },
        {
          label: 'Undo',
          onClick: onUndo,
          bgClass: 'bg-gray-100',
          textClass: 'text-gray-600 text-lg uppercase',
          borderClass: 'border-transparent',
        },
      ]

  return (
    <MainModal
      title={!resolved ? 'Auto-schedule ride times' : 'Resolved'}
      show={show}
      type="RESCHEDULE_RIDE_TIMES"
      titleClassName="ml-2 font-semibold "
      onHide={closeModal}
      setHeightAsPerContent
      size="sm"
      buttons={buttons}
    >
      <div className="text-SeabiscuitDark200ThemeColor text-base  ">{renderContent()}</div>
    </MainModal>
  )
}

export default RescheduleRideTimesModal
