import { FC, useEffect, useState } from 'react'

// Third party
import { IonItem } from '@ionic/react'
import { motion } from 'framer-motion'

import CardsLoader from '../../../loader/CardsLoader'
import EventOrganizationHorizontalCardComponent from '../../cards/organization-horizontal-card/EventOrganizationHorizontalCardComponent'
import { getRegisteredRiders } from '../../../../helpers/riders'

import InfiniteScroll from 'react-infinite-scroll-component'
import { IEventInterface } from '../../../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'

const DUMMY_USER_IMAGE = 'assets/img/Empty user.png'

interface ShowsHorizontalCardStackComponentProps {
  events: IEventInterface[]
  onEventClicked: any
  hasMore?: boolean
  fetchMoreLoading?: boolean
  fetchMore?: (cursorId: string) => void
}

const EventOrganizationHorizontalCardStackComponent: FC<ShowsHorizontalCardStackComponentProps> = (
  props
) => {
  const fetchMore = () => {
    props.fetchMore?.(props?.events?.at(-1)?.id ?? (null as any))
  }

  const [allRegisteredRiders, setAllRegisteredRiders] = useState<IRegistrationByDayInterface[]>([])

  useEffect(() => {
    const getEventRiders = async () => {
      const eventIds = props?.events.map((event) => event.id ?? '') ?? []

      if (eventIds.length > 0) {
        const allRiders = await getRegisteredRiders(eventIds)

        setAllRegisteredRiders(allRiders)
      } else {
        setAllRegisteredRiders([])
      }
    }

    getEventRiders().then()
  }, [props?.events])

  return (
    <IonItem lines="none" color="#F6F7FB" className="mx-1">
      <div id="mainscrollDiv" className="transition-all overflow-auto no-scrollbar flex-1">
        <InfiniteScroll
          dataLength={props.events.length}
          next={fetchMore}
          hasMore={props?.hasMore ?? false}
          loader={<CardsLoader />}
          scrollableTarget="mainscrollDiv"
        >
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {props.events.map((event) => {
              const uniqueRiders: IRegistrationByDayInterface[] = []
              const ids: string[] = []

              const registeredRiders = allRegisteredRiders.filter(
                (rider) => rider.eventId === event.id
              )

              registeredRiders.forEach((rider) => {
                if (!ids.includes(rider?.riderId ?? '')) {
                  uniqueRiders.push(rider)
                  ids.push(rider?.riderId ?? '')
                }
              })

              let registeredUsersImages = uniqueRiders?.map(
                (item) => item.riderProfilePicture ?? ''
              )

              registeredUsersImages = [
                ...(registeredUsersImages ?? []),
                ...[...Array(3)].map(() => DUMMY_USER_IMAGE),
              ].slice(0, 3)

              return (
                <motion.div
                  key={event.id}
                  className="inline-block col-span-1 rounded-lg px-3 h-min-[430px] mb-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.1,
                  }}
                >
                  <div
                    key={event.id}
                    onClick={(_) => props.onEventClicked(event.id)}
                    className="inline-block rounded-lg shrink-0 pr-3 mb-6 w-full"
                  >
                    <EventOrganizationHorizontalCardComponent
                      event={event}
                      registeredUsersImages={registeredUsersImages}
                      registeredRiders={registeredRiders ?? []}
                    />
                  </div>
                </motion.div>
              )
            })}
          </div>
        </InfiniteScroll>
        {props.fetchMoreLoading ? <CardsLoader /> : null}
      </div>
    </IonItem>
  )
}

export default EventOrganizationHorizontalCardStackComponent
