import clsx from 'clsx'
import { Controller, UseFormReturn } from 'react-hook-form'
import AmountInput from '../../../../../components/common/inputs/AmountInput'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { FEES_CATEGORY_CONST } from '../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { IFeesTab } from '../../../../../models/event-registered-users/event-registered-users.interface'
import React, { FC, useState } from 'react'
import { capitalize } from '../../../../../helpers/helpers'
import FeesHeader from './FeesHeader'
import { IManageInfo } from '../../EventRegistrationTabs'
import { IOnChangeFees } from '../../hooks/useEventRegistrationTabs/useEventRegistrationTabs'
import { IRegistrationFeesInterface } from '../../../../../models/registration-fees/registrationFees.interface'
import { Tooltip } from '@mui/material'
import TooltipIcon from '../../../../../helpers/TooltipIcon'
import { getFloatPrice } from '../../../../../helpers/price'

// Types
type IFeesColumnComp = {
  feesItemsForm: UseFormReturn
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  showHeader?: boolean
  feesCategory: string
  feesTabData: IFeesTab[]
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  onChangeFees: ({
    setManageInfo,
    manageInfo,
    isManage,
  }: IOnChangeFees) => Promise<null | undefined>
}

// Constants

const widths = [
  'shrink-0 w-[170px]',
  'shrink-0 w-[100px]',
  'shrink-0 w-[150px]',
  'shrink-0 w-[130px]',
  'grow min-w-[230px]',
  'shrink-0 w-[130px] mr-3',
]

const FeesRow: FC<IFeesColumnComp> = ({
  handleModal,
  feesItemsForm,
  setManageInfo,
  isManage,
  onChangeFees,
  manageInfo,
  showHeader,
  feesTabData,
  feesCategory,
}) => {
  // Vars
  let classList: string = 'border border-solid border-SeabiscuitLightThemeColor !rounded-[8px]'
  let commonClasses = 'text-ellipsis overflow-hidden whitespace-nowrap h-[56px]'

  const [inputValue, setInputValue] = useState<number>(0)

  const openSeeMoreModal = (content: string) => {
    handleModal(true, MODAL_CONSTS.ADD_NOTE, { noteInputRef: content })
  }

  const filteredFees = feesTabData.filter((fees) => fees.feesCategory === feesCategory)
  return filteredFees.length > 0 ? (
    <div className="text-nr">
      {showHeader ? (
        <FeesHeader feesCategory={filteredFees[0].feesCategory} />
      ) : (
        <div className="capitalize font-semibold mt-16 lg:mt-0">
          {filteredFees[0].feesCategory === 'governance'
            ? 'mandatory'
            : filteredFees[0].feesCategory}
        </div>
      )}
      <section className={clsx('mb-5 md:mb-7 lg:mb-8', { 'mt-2': !showHeader })}>
        {filteredFees.map((feesRow, index) => (
          <div
            key={index}
            className={clsx(
              `border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-5 lg:p-0  flex flex-col lg:flex-row items-start w-full text-nr gap-[10px] ${index !== filteredFees.length - 1 ? 'mb-5' : ''} lg:mb-2`
            )}
          >
            <span
              className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[0])}
            >
              Item
              <Tooltip
                title={<h1 className="tooltip_title">Fees added by the event organizer</h1>}
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </span>
            <div
              className={clsx(
                'text-SeabiscuitDark200ThemeColor w-full lg:w-[170px] py-4 px-2 rounded-lg',
                classList,
                widths[0],
                commonClasses,
                inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
              )}
            >
              {capitalize(feesRow?.feesTitle, {
                capitalizeAll: false,
              })}
            </div>

            <span
              className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[1])}
            >
              Price
              <Tooltip
                title={<h1 className="tooltip_title">Price per fee set by event organizer</h1>}
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </span>
            <div
              className={clsx(
                'lg:text-center text-SeabiscuitDark200ThemeColor w-full lg:w-[100px] py-4 px-2 rounded-lg lg:!justify-center',
                classList,
                widths[1],
                commonClasses,
                inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
              )}
            >
              {feesRow?.feesPrice ? getFloatPrice(`${feesRow.feesPrice}`) : feesRow?.feesPrice}
            </div>

            <span
              className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[2])}
            >
              Duration
              <Tooltip
                title={<h1 className="tooltip_title">Frequency you will be charged the price</h1>}
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </span>
            <div
              className={clsx(
                'lg:text-center text-SeabiscuitDark200ThemeColor w-full lg:w-[150px] py-4 px-2 rounded-lg !justify-center capitalize',
                classList,
                widths[2],
                commonClasses,
                inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
              )}
            >
              {feesRow?.feesDuration ?? 'N/A'}
            </div>

            <span
              className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[3])}
            >
              Availability
              <Tooltip
                title={<h1 className="tooltip_title">Units of this item remaining</h1>}
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </span>
            <div
              className={clsx(
                'w-full lg:w-[130px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg',
                { 'opacity-50': feesRow?.avaliability === 0 },
                classList,
                widths[3],
                commonClasses,
                inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
              )}
            >
              {feesRow.feesCategory !== FEES_CATEGORY_CONST.GOVERNANCE &&
                `${feesRow?.avaliability} remaining`}
            </div>
            <span
              className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[4])}
            >
              Details
              <Tooltip
                title={<h1 className="tooltip_title">Click for more details on this fee</h1>}
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </span>
            <div
              className={clsx(
                'w-full text-SeabiscuitDark200ThemeColor p-4 rounded-lg lg:w-[unset] flex flex-nowrap',
                classList,
                widths[4],
                inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
              )}
            >
              {feesRow?.feesDescription ? (
                <>
                  <div className="w-full line-clamp-1">
                    {capitalize(feesRow?.feesDescription, {
                      capitalizeAll: false,
                    })}
                  </div>
                  <img
                    onClick={() => openSeeMoreModal(feesRow.feesDescription as string)}
                    src={'assets/og_icons/FullScreen-1.svg'}
                    alt="fullScreenIcon"
                    className="w-6 h-6 ml-2 cursor-pointer"
                  />
                </>
              ) : (
                'N/A'
              )}
            </div>
            <span
              className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[5])}
            >
              Enter Amount
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Select how many of these items you would like to purchase
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </span>
            <div className={clsx(widths[5], 'w-full lg:w-[130px] flex flex-col')}>
              <div
                className={clsx(
                  'w-full lg:w-[100%] text-SeabiscuitDark200ThemeColor rounded-lg flex items-center h-[56px]',
                  classList,
                  widths[5],
                  'border-b-transparent border-solid border !border-SeabiscuitLightThemeColor !mb-0',
                  inputValue && 'bg-[#f6f7fb] !border-[#f6f7fb]'
                )}
                style={{
                  overflow: 'unset',
                }}
              >
                {feesRow?.avaliability !== 0 ? (
                  <Controller
                    name={feesRow.feesItemId}
                    control={feesItemsForm.control}
                    render={({ field: { value, name, onChange } }) => {
                      return (
                        <AmountInput
                          name={name}
                          disable={feesRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE}
                          onChange={async (current: string) => {
                            let newValue: number
                            if (Number(current) > Number(feesRow?.avaliability)) {
                              onChange(feesRow?.avaliability)
                              newValue = feesRow?.avaliability
                            } else {
                              onChange(Number(current))
                              newValue = Number(current)
                            }
                            setInputValue(newValue)
                          }}
                          inputClassName={clsx(
                            'w-full rounded-lg searchableComponent focus:ring-0 lg:p-0 focus:ring-transparent lg:text-center border-none bg-[transparent]'
                          )}
                          placeholder="Enter units"
                          value={
                            feesRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE
                              ? (feesRow.registrationFees?.selectedUnitsCount ?? 0)
                              : value
                          }
                          onBlur={async () => {
                            await onChangeFees({
                              currentRow: {
                                ...feesRow,
                                registrationFees: {
                                  ...(feesRow.registrationFees as IRegistrationFeesInterface),
                                  selectedUnitsCount: inputValue,
                                },
                              },
                              setManageInfo: setManageInfo,
                              manageInfo: manageInfo,
                              isManage: isManage,
                            })
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  <div className="text-center w-[100%] opacity-50">N/A</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  ) : null
}

export default FeesRow
