import React, { ReactNode, useEffect, useRef, useState } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew'

import useToasterHelper from '../../../../helpers/ToasterHelper'

import helpers from '../../../../commonHelpers/helpers'

import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import { IUserDocument } from '../../../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../../../models/user-documents/user-documents.model'

import FirestoreService from '../../../../services/firestoreService'

import { MESSAGES_CONST } from '../../../../const/messages-const'
import { CONST } from '../../../../const/const'
import fakeDocumentList from '../../../../fakeData/fakeDocumentList'
import { IRiderTeamMemberInterface } from '../../../../models/rider-team-member/riderTeamMember.interface'
import { getConvertedData } from '../../../../models/interface.helper'
import { collection, documentId, getDocs, query, where } from 'firebase/firestore'
import { RiderTeamMemberModel } from '../../../../models/rider-team-member/riderTeamMember.model'
import { riderActions } from '../../../../store/rider/riderSlice'
import FirebaseApp from '../../../../services/firebaseApp'
import { EventDetailsModel } from '../../../../models/event-details/event-details.model'
import { createMailLog, generatePaperworkDocument } from '../../../../helpers/sign'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { UserModel } from '../../../../models/users/user.model'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const EVENT_DETAILS_CONST = COLLECTIONS.EVENT_DETAILS

const ExhibitorTeamPaperworkInput: React.FC<{
  className?: string
  data?: string | ReactNode
  icon?: string
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}
const DetailRow = ({
  document,
  eventId,
  cbSingSuccess,
  registrationByDay,
}: {
  document: IUserDocument
  eventId: string | null
  cbSingSuccess: () => void
  registrationByDay: IRegistrationByDayInterface
}) => {
  const dispatch = useAppDispatch()
  const toasterFunctions = useToasterHelper()

  const { resend_email } = useAppSelector((state) => state.events.exhibitor_team)
  const selectedEventR = useAppSelector(selectedEvent)
  const storeRider = useAppSelector((state) => state.rider)

  const [innerDoc, setInnerDoc] = useState<IUserDocument>(document)
  const [loading, setLoading] = useState(false)

  const resend_email_ref = useRef<any[]>(resend_email)

  useEffect(() => {
    resend_email_ref.current = resend_email
  }, [resend_email])

  function getDocumentType(): string {
    if (document.documentOriginalName?.includes('USEF Entry')) {
      return 'usefentry'
    }
    return document.documentOriginalName?.split(' ')[0].toLowerCase() || ''
  }

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  const onSign = async () => {
    setLoading(true)

    try {
      const competitorSnap = await FirestoreService.getItem(
        COLLECTIONS.USERS.NAME,
        document?.competitorId ?? ''
      )
      const competitor = UserModel.fromFirestoreDoc(competitorSnap).toObject()

      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(
        document?.registrationDocId ?? ''
      )

      let currRidersTeamMember = ridersTeamMembersInDb__.find(
        (m) => m.id === document.riderTeamMemberDocId
      )

      if (!selectedEventR) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error get selectedEventR')
      }

      if (!currRidersTeamMember) {
        currRidersTeamMember = {
          eventId: document.eventId || '',
          registrationDocId: document.registrationDocId,
          registrationByDayDocId: document.registrationByDayDocId,
          registrationByDayUuid: document.registrationByDayUuid,
          registrationByDayUniqId: document.registrationByDayUniqId,
          userId: document.registrationDocId,
          mailLog: [],
          teamMemberId: competitor?.id || '',
          teamMemberDob: competitor?.userDOB || '',
          riderId: competitor?.id || '',
          riderDob: competitor?.userDOB || '',
          riderName: document.signatoryName || '',
        }
      }
      if (!eventId) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error get eventId')
      }
      if (!competitor.id) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error get competitor id')
      }

      const paperwork = fakeDocumentList.find(
        (document) => document.document_type === getDocumentType()
      )

      if (!paperwork) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting paperwork')
      }

      const query_ = query(
        collection(FirebaseApp.firestore, EVENT_DETAILS_CONST.NAME),
        where(documentId(), '==', eventId)
      )

      const docEvent = await getDocs(query_)
      if (!docEvent.size) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting v01_event_details')
      }
      const eventData = EventDetailsModel.fromFirestoreDoc(docEvent.docs[0]).cloneDeep().toObject()

      if (!eventData) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting v01_event_details')
      }

      await generatePaperworkDocument({
        paperwork,
        currRidersTeamMember,
        registrationByDay,
        userData: competitor,
        eventId,
        EventDetails: eventData,
        dateOfSignature: new Date(),
      })

      const teamMembersWithMailLog = await createMailLog({
        ridersTeamMembers: [currRidersTeamMember],
        teamMemberId: currRidersTeamMember.teamMemberId ?? '',
        paperworkDocument: paperwork,
      })

      if (!teamMembersWithMailLog) throw new Error('Error get Rider Team Member')

      await FirestoreService.updateItem(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        currRidersTeamMember.id,
        new RiderTeamMemberModel(teamMembersWithMailLog[0]).toFirestore()
      )

      const docSnaps = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
        innerDoc?.id ?? ''
      )
      const userDocument = getConvertedData(UserDocumentModel.fromFirestoreDoc(docSnaps).toObject())

      toasterFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })
      setInnerDoc({
        ...userDocument,
      })

      const newStoreRiders = [...storeRider.documents].filter(
        (itm) => itm.id !== userDocument.id && itm.id !== userDocument.id
      )
      newStoreRiders.push(userDocument as any)

      const isSigned = !newStoreRiders.some((d) => d.status !== 'Signed')
      dispatch(riderActions.setIsAppPaperworkSign(isSigned))
      dispatch(riderActions.setDocuments(newStoreRiders))

      cbSingSuccess()
    } catch (error) {
      console.log(error, 'generatePaperworkDocument')
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row lg:items-start w-full">
      <div className="lg:hidden font-medium text-[12px]">Document</div>
      <ExhibitorTeamPaperworkInput
        className={`text-SeabiscuitDark200ThemeColor ${innerDoc.status !== 'Not Signed' ? 'text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor' : 'text-[#EB5757] bg-[#EB57571A]'} rounded-lg text-[14px] capitalize min-h-12 p-3 lg:m-1 lg:w-8/12`}
        data={
          <div className={'flex items-center gap-2 w-full justify-between'}>
            {innerDoc.documentOriginalName?.toLowerCase()}
            {innerDoc.status !== 'Not Signed' ? (
              <>
                <div
                  className="cursor-pointer py-0.5 px-3 border border-solid border-[#122B46] rounded-full  transition-all"
                  onClick={() => {
                    window.open(innerDoc.documentUrl, '_blank')
                  }}
                >
                  View
                </div>
              </>
            ) : (
              <button
                disabled={loading}
                className="cursor-pointer py-0.5 px-3 border border-solid border-[#EB5757] rounded-full hover:bg-[#EB5757] text-[#EB5757] hover:text-white transition-all"
                onClick={() => onSign()}
              >
                {loading ? <AutorenewIcon fontSize="small" className="animate-spin" /> : 'Sign'}
              </button>
            )}
          </div>
        }
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Role</div>
      <ExhibitorTeamPaperworkInput
        className={`text-SeabiscuitDark200ThemeColor ${innerDoc.status !== 'Not Signed' ? 'text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor' : 'text-[#EB5757] bg-[#EB57571A]'} rounded-lg text-[14px] capitalize min-h-12 p-3 lg:m-1 lg:w-2/12`}
        data={<div>{innerDoc.signatoryDefaultRole}</div>}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Status</div>
      <ExhibitorTeamPaperworkInput
        className={`flex items-center ${innerDoc.status !== 'Not Signed' ? 'text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor' : 'text-[#EB5757] bg-[#EB57571A]'} rounded-lg text-[14px] capitalize min-h-12 lg:m-1 lg:w-2/12`}
        data={
          innerDoc.status !== 'Not Signed' ? (
            <div className="flex w-full items-center relative p-3">Signed</div>
          ) : (
            <div className="flex w-full items-center relative px-3 py-1">Pending</div>
          )
        }
      />
    </div>
  )
}

const ExhibitorTeamPaperworkAccordion = ({
  docsList,
  title,
  eventId,
  cbSingSuccess,
  registrationByDay,
}: {
  docsList: IUserDocument[]
  title: String
  tooltip: String
  eventId: string | null
  cbSingSuccess: () => void
  registrationByDay: IRegistrationByDayInterface
}) => {
  return (
    <>
      <div className="hidden lg:flex items-center w-full">
        <ExhibitorTeamPaperworkInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-8/12"
          data={<span className="flex items-center">{title}</span>}
        />
        <ExhibitorTeamPaperworkInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-2/12"
          data={<span className="flex items-center">Role</span>}
        />
        <ExhibitorTeamPaperworkInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-2/12"
          data={<span className="flex items-center">Status</span>}
        />
      </div>

      {docsList.length
        ? docsList.map((document, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(document)}${index}`}
                document={document}
                eventId={eventId}
                cbSingSuccess={cbSingSuccess}
                registrationByDay={registrationByDay}
              />
            )
          })
        : ''}
    </>
  )
}

export default ExhibitorTeamPaperworkAccordion
