import React from 'react'

export const IconInfo: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#122B46"
        d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 1.5c4.703 0 8.5 3.797 8.5 8.5s-3.797 8.5-8.5 8.5A8.49 8.49 0 0 1 1.5 10c0-4.703 3.797-8.5 8.5-8.5M10 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-.012 3.49a.75.75 0 0 0-.738.76v5.5a.75.75 0 1 0 1.5 0v-5.5a.75.75 0 0 0-.762-.76"
      />
    </svg>
  )
}
