// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IonItem, IonList, IonPopover, IonContent } from '@ionic/react'
import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CONST } from '../../../../const/const'
import { setActiveFilters } from '../../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setUserMode } from '../../../../store/system/systemSlice'
import {
  clearUserStoredData,
  selectUserNameAc,
  selectUserProfileImageUrl,
  selectProfileData,
} from '../../../../store/user/userSlice'
import './TopNavigationRightMenuCommonComponent.css'
import FirebaseApp from '../../../../services/firebaseApp'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { useIntercom } from 'react-use-intercom'
import { ChannelList, InfiniteScroll, InfiniteScrollProps } from 'stream-chat-react'
import { ChannelSort, ChannelFilters, ChannelOptions } from 'stream-chat'
import ChatHOC from '../../../../pages/messages/components/ChatHOC'
import CustomChannelPreview from '../../../../pages/messages/components/CustomChannelPreview'
import { useHistory } from 'react-router-dom'
import { IconRulebook } from '../../../icons/IconRulebook'
import { IconMessagingWithStream } from '../../../icons/IconMessagingWithStream'
import { IonSpinner } from '@ionic/react'
import './style.css'
import '@knocklabs/react/dist/index.css'
import { IconNotification } from '../../../../pages/notifications/IconNotification'
import './TopNavigationRightMenuCommonComponent.css'
import { withKnockProvider } from '../../../hoc/withKnockProvider'
import { NotificationFeed, NotificationCell, useKnockClient } from '@knocklabs/react'
import { formatTimestamp } from '@knocklabs/react-core'
import clsx from 'clsx'
import { getUserFullName } from '../../../../helpers/helpers'

const Paginator = (props: InfiniteScrollProps) => <InfiniteScroll {...props} threshold={50} />

// New component for notifications
const NotificationsPopover = ({
  isVisible,
  setIsVisible,
  history,
}: {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  history: any // Should be from react-router's History type
}) => {
  const knock = useKnockClient()
  const profileData = useAppSelector(selectProfileData)
  const name = profileData ? getUserFullName(profileData) || 'Anonymous' : 'Anonymous'
  const userEmail = profileData.userEmail
  const userProfileImageUrl = profileData.userProfilePicture

  useEffect(() => {
    knock.user.identify({
      name: name,
      email: userEmail || '',
      image: userProfileImageUrl,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, userEmail, userProfileImageUrl])

  return (
    <>
      <IconNotification
        badgeCountType="unread"
        onClick={(e) => {
          setIsVisible(true)
        }}
      />

      <IonPopover
        isOpen={isVisible}
        onDidDismiss={() => setIsVisible(false)}
        reference="trigger"
        trigger="notis-menu"
        side="bottom"
        alignment="end"
        style={{
          '--border-radius': '40px',
          '--min-width': '419px',
          '--max-height': '412px',
        }}
      >
        <IonContent>
          <div className="h-[412px] overflow-y-scroll no-scrollbar flex flex-col p-[10px] bg-SeabiscuitGrayThemeColor">
            <div className="flex flex-1 overflow-y-scroll no-scrollbar w-full">
              <NotificationFeed
                renderHeader={() => (
                  <h2 className="text-xl py-2 px-4 text-black h-10">Notifications</h2>
                )}
                onNotificationClick={(item) => {
                  history.push(`${CONST.ROUTES.NOTIFICATIONS.URL}`)
                }}
                renderItem={({ item, ...props }) => {
                  const actor = item.actors?.[0]

                  return (
                    <NotificationCell
                      {...props}
                      item={item}
                      // You can use any properties available on the `actor` for the name and avatar
                      avatar={
                        <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                          <img
                            src={
                              actor && 'image' in actor && actor.image
                                ? actor.image
                                : IMAGE_CONSTS.PLACEHOLDERS.USER
                            }
                            className="w-11 h-11 rounded-full object-cover"
                            alt="sender"
                            onError={(e) =>
                              ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
                            }
                          />
                        </span>
                      }
                      archiveButton={
                        <div className="flex-1 flex justify-end items-center p-2">
                          {!item.read_at && (
                            <div className="w-2 h-2 bg-[#F7074F] rounded-full"></div>
                          )}
                        </div>
                      }
                    >
                      <span
                        className={clsx(
                          'block text-sm',
                          !item.read_at ? 'text-[#F7074F]' : 'text-[#122B4680]'
                        )}
                      >
                        {formatTimestamp(item.inserted_at)}
                      </span>
                    </NotificationCell>
                  )
                }}
              />
            </div>
            <button
              onClick={() => history.push(`${CONST.ROUTES.NOTIFICATIONS.URL}`)}
              className="w-full p-2 bg-white"
            >
              <span className="text-xs text-black">See all &gt;</span>
            </button>
          </div>
        </IonContent>
      </IonPopover>
    </>
  )
}

const NotificationsPopoverWithKnock = withKnockProvider(NotificationsPopover)
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface TopNavigationRightMenuCommonComponentProps {}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const TopNavigationRightMenuCommonComponent: React.FC<
  TopNavigationRightMenuCommonComponentProps
> = () => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const userName = useAppSelector(selectUserNameAc)
  const userProfileImageUrl = useAppSelector(selectUserProfileImageUrl)
  const profileData = useAppSelector(selectProfileData)
  const userId = profileData.id
  const history = useHistory()
  const handleModal = useContext(HandleModalContext)?.handleModal
  const { show } = useIntercom()
  const [displayMenu, setDisplayMenu] = useState(false)
  const [messagesMenu, setMessagesMenu] = useState(false)
  const [comingSoon, setComingSoon] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const messages_filters: ChannelFilters = {
    type: 'messaging',
    members: { $in: [userId] },
  }
  const options: ChannelOptions = { state: true, presence: true, limit: 15 }
  const sort: ChannelSort = { last_message_at: -1 }

  const onSwithAccountOptionClick = () => {
    setDisplayMenu(false)
    handleModal?.(true, MODAL_CONSTS.SWITCH_ACCOUNT)
  }

  const handleLogout = async () => {
    dispatch(setUserMode(null))
    dispatch(clearUserStoredData(true))
    dispatch(setActiveFilters(null))
    await FirebaseApp.auth.signOut()
    window.location.replace(CONST.ROUTES.LOGOUT.URL)

    if (0) console.log({ onSwithAccountOptionClick })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div id="" className="flex-shrink-0 block">
        <div className="flex items-center">
          <>
            <div
              className="mr-4 text-[#9ca2b5] rounded-lg p-1 px-4 border-[1px] border-[#9ca2b5] cursor-pointer"
              onClick={() => {
                setComingSoon(true)
              }}
            >
              BETA
            </div>

            <button
              onClick={() => {
                handleModal && handleModal(true, MODAL_CONSTS.AI_RULE_BOOK)
              }}
              className="group relative cursor-pointer mr-3"
            >
              <IconRulebook />
            </button>

            <button
              className="group relative cursor-pointer mr-3"
              id="messages-menu"
              onClick={() => {
                setMessagesMenu(true)
              }}
            >
              <IconMessagingWithStream />
            </button>
            <NotificationsPopoverWithKnock
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              history={history}
            />
          </>

          <div
            className="rounded-full"
            onClick={() => {
              setDisplayMenu(true)
            }}
            id="right-side-menu"
          >
            <img
              className="inline-block rounded-full p-1"
              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              src={!!userProfileImageUrl ? userProfileImageUrl : IMAGE_CONSTS.PLACEHOLDERS.USER}
              onError={(e) => {
                return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
              }}
              alt=""
            />
          </div>
          <span
            onClick={() => {
              setDisplayMenu(true)
            }}
          ></span>
        </div>
      </div>

      <IonPopover
        isOpen={displayMenu}
        reference="trigger"
        trigger="right-side-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => setDisplayMenu(false)}
        style={{ '--border-radius': '40px' }}
      >
        <IonList style={{ borderRadius: '40px !important' }}>
          <IonItem className="flex items-center capitalize">
            <div className="flex items-center capitalize">
              <div className="rounded-full bg-[#F0F3FA] flex justify-center items-center mr-4">
                <Link to="/allevent" className="cursor-pointer block w-[35px] h-[35px]">
                  <img
                    className="inline-block w-full h-full rounded-full object-cover"
                    src={
                      !!userProfileImageUrl ? userProfileImageUrl : IMAGE_CONSTS.PLACEHOLDERS.USER
                    }
                    onError={(e) => {
                      return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
                    }}
                    alt=""
                  />
                </Link>
              </div>
              <div className="cursor-pointer flex item-center">{userName}</div>
            </div>

            <div
              style={{
                content: '""',
                position: 'fixed',
                bottom: '56px',
                left: '20px',
                right: '20px',
                height: '1px',
                background: '#000',
              }}
            ></div>
          </IonItem>

          <IonItem className="cursor-pointer" onClick={onSwithAccountOptionClick}>
            Switch
          </IonItem>

          <IonItem
            className="cursor-pointer"
            style={{ '--border-style': 'none' }}
            onClick={handleLogout}
          >
            Logout
          </IonItem>
        </IonList>
      </IonPopover>

      <IonPopover
        isOpen={messagesMenu}
        reference="trigger"
        trigger="messages-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => {
          setMessagesMenu(false)
        }}
        style={{
          '--border-radius': '40px',
          '--min-width': '419px',
          '--max-height': '412px',
        }}
      >
        <div className="h-[412px] overflow-y-scroll no-scrollbar p-[10px] flex flex-col">
          <h2 className="text-xl py-2 px-4">Messages</h2>
          <ChatHOC>
            <ChannelList
              filters={messages_filters}
              options={options}
              sort={sort}
              LoadingIndicator={() => (
                <div className="flex flex-1 justify-center items-center">
                  <IonSpinner name="crescent" className="mx-auto" />
                </div>
              )}
              EmptyStateIndicator={() => (
                <div className="flex flex-1 h-full justify-center items-center">
                  <button onClick={() => history.push(`${CONST.ROUTES.MESSAGES.URL}`)}>
                    New Message
                  </button>
                </div>
              )}
              Preview={(props) => (
                <CustomChannelPreview
                  {...props}
                  headerContainerClassName="shrink-0 justify-between w-full"
                  onSelect={() => {
                    setMessagesMenu(false)
                    history.push(`${CONST.ROUTES.MESSAGES.URL}`, { channelId: props.channel.id })
                  }}
                  className="cursor-pointer border-b-[1px] border-[#F6F7FB] bg-transparent"
                  unreadClassName="-mt-4"
                  editable={false}
                  key={props.channel.id}
                  showCount={false}
                  showUnread={false}
                />
              )}
              Paginator={Paginator}
            />

            <button
              onClick={() => {
                history.push(`${CONST.ROUTES.MESSAGES.URL}`)
                setMessagesMenu(false)
              }}
              className="w-full p-2"
            >
              <span className="text-xs">See all &gt;</span>
            </button>
          </ChatHOC>
        </div>
      </IonPopover>
    </>
  )
}

export default TopNavigationRightMenuCommonComponent
