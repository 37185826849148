import React from 'react'

import { IconSearch } from '../../components/icons/IconSearch'

import { IPaperworkTab, paperworkTabs } from './PaperworkRootPage'
import { PillButtonElement } from '../../components/elements/buttons/pill/PillButtonElement'

type PaperWorkTabsProps = {
  searchValue: string
  setSearchValue: (value: string) => void
  setActiveTab: (value: IPaperworkTab) => void
  activeTab: IPaperworkTab
}

const PaperWorkTabs = ({
  activeTab,
  setActiveTab,
  searchValue,
  setSearchValue,
}: PaperWorkTabsProps) => {
  return (
    <div className="flex flex-wrap gap-3 items-center justify-between mb-4 w-full">
      <div className="flex overflow-x-auto gap-x-2 tabScroll pb-2">
        {paperworkTabs.map((tab) => (
          <PillButtonElement
            key={tab.key}
            onButtonClicked={() => setActiveTab(tab.key)}
            className="h-[45px] w-[120px] border-none text-SeabiscuitDark200ThemeColor"
            selected={activeTab === tab.key}
            text={tab.label}
            value={tab.key}
          />
        ))}
      </div>
      <div className="search-div-wrapper border relative h-[46px] rounded-lg w-full md:w-[449px] border-SeabiscuitSkeletonColor]">
        <div className="opacity-50 absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
          <IconSearch />
        </div>
        <input
          type="search"
          className="rounded-lg block w-full bg-transparent p-0 pl-10 text-sm border-0 h-full text-gray-900 placeholder:text-SeabiscuitDark200ThemeColor"
          placeholder="Search documents..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
    </div>
  )
}

export default PaperWorkTabs
