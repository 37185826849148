import React from 'react'
import { IconError } from '../../icons/IconError'
import { IconInfo } from '../../icons/IconInfo'

interface ScratchWarningProp {
  isInfo?: boolean
}

const messages = {
  info: [
    'Any paperwork associated with this entry will not be deleted and will remain accessible.',
    'To scratch individual entries or fees, do so on the previous screen.',
    'Refunds will not be automatically processed. You can refund the payments manually in the finances tab in line with your refund policy.',
  ],
  warning: [
    'WARNING',
    'This will scratch all entries, ride times & scores.',
    'This cannot be undone.',
  ],
}

export const ScratchWarning: React.FC<ScratchWarningProp> = ({ isInfo }) => {
  const messageList = isInfo ? messages.info : messages.warning
  const IconComponent = isInfo ? IconInfo : IconError

  return (
    <div className={'bg-SeabiscuitGrayThemeColor flex items-start gap-5 p-5 rounded-lg w-full'}>
      <IconComponent />
      <div className={'flex gap-6 flex-col w-full'}>
        {messageList.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </div>
  )
}
