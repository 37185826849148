// Components
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'

// Constants
import { PAPERWORK_DATA } from './data/tabs'

import { PillButtonElement } from '../../components/elements/buttons/pill/PillButtonElement'
import FollowingListing from './FollowingListing'
import { useState } from 'react'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

// Types
export type INotifivationListingDoc = {
  message: string
  sender_name: string
  sender_image: string
}

export type INotifivationSettingsDoc = {
  setting_logo: string
  setting_title: string
  setting_description: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO todo
 */
const FollowingRootPage = () => {
  // Hooks and vars
  const [tabs, setTabs] = useState<{ active: number }>({ active: 0 })

  return (
    <WrapperContainer title="Following">
      <>
        <div className="mb-10 flex overflow-x-auto gap-x-2 tabScroll w-full">
          {PAPERWORK_DATA.TABS.map((currTab, tabIndex) => {
            return (
              <PillButtonElement
                key={`${currTab.tab}${tabIndex}`}
                text={currTab.tab}
                className="h-[45px] w-[150px] border-none inline-block "
                onButtonClicked={() => setTabs({ active: tabIndex })}
                value={tabIndex}
                selected={tabIndex === tabs.active}
              />
            )
          })}
        </div>

        <div className="my-5">
          <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1">
            {PAPERWORK_DATA.TABS[tabs.active].tab}
          </h1>
          <div className="text-SeabiscuitLightParagraphTextColor capitalize font-normal">
            {PAPERWORK_DATA.TABS[tabs.active].tabDescription}
          </div>
        </div>

        <FollowingListing type={tabs.active} />
      </>
    </WrapperContainer>
  )
}

export default FollowingRootPage
