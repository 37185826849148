// web/src/components/icons/DotsIcon.tsx
import React from 'react'

interface DotsIconProps {
  fillColor?: string
}

const DotsIcon: React.FC<DotsIconProps> = ({ fillColor = '#122B46' }) => (
  <svg width="15" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75098 0C1.24055 0 0 1.24055 0 2.75098C0 4.26141 1.24055 5.50195 2.75098 5.50195C4.26109 5.50195 5.50195 4.26141 5.50195 2.75098C5.50195 1.24055 4.26109 0 2.75098 0ZM18.751 0C17.2405 0 16 1.24055 16 2.75098C16 4.26141 17.2405 5.50195 18.751 5.50195C20.2611 5.50195 21.502 4.26141 21.502 2.75098C21.502 1.24055 20.2611 0 18.751 0ZM10.751 0.000976562C9.24077 0.000976562 8.00098 1.24132 8.00098 2.75098C8.00098 4.26086 9.24109 5.50098 10.751 5.50098C12.2609 5.50098 13.501 4.26086 13.501 2.75098C13.501 1.24109 12.2609 0.000976562 10.751 0.000976562Z"
      fill={fillColor}
      className="group-hover:fill-[#122B46]"
    />
  </svg>
)

export default DotsIcon
