import React, { useEffect, useState } from 'react'

import { where } from 'firebase/firestore'
import { groupBy } from 'lodash'

// Components
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import PaperWorkTabs from './PaperWorkTabs'
import PaperworkDataList from './component/PaperworkDataList'

// Redux
import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'

import { getAllRiders } from '../../helpers/riders'

// Services
import FirestoreService from '../../services/firestoreService'

// Models
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { EventModel } from '../../models/events/event.model'
import { IEventInterface } from '../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'

// Constants
import { DATA_FIRESTORE_V01_CONST } from '../../const/data/v01/firestore-v01-const'
const COLLECTIONS = DATA_FIRESTORE_V01_CONST.COLLECTIONS

export type IPaperworkTab = 'all' | 'signed' | 'unsigned'

export const paperworkTabs: { key: IPaperworkTab; label: string }[] = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'signed',
    label: 'Signed',
  },
  {
    key: 'unsigned',
    label: 'Unsigned',
  },
]

const PaperworkRootPage = () => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const [allDocs, setAllDocs] = useState<IUserDocument[]>([])
  const [filteredDocs, setFilteredDocs] = useState<{ [key: string]: IUserDocument[] }>({})
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [activeTab, setActiveTab] = useState<IPaperworkTab>('all')
  const [events, setEvents] = useState<IEventInterface[]>([])
  const [registrationsByDay, setRegistrationsByDay] = useState<IRegistrationByDayInterface[]>([])

  const getDocsFromDb = async () => {
    setLoading(true)
    try {
      let docsList: IUserDocument[] = []
      let eventIds: string[] = []
      let events_: IEventInterface[] = []

      let usersDocumentsSnaps = await FirestoreService.filterItems(
        COLLECTIONS.USERS_DOCUMENTS.NAME,
        [where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY, '==', userId)]
      )

      usersDocumentsSnaps.forEach((currDoc) => {
        let document = UserDocumentModel.fromFirestoreDoc(currDoc).toObject()
        if (document.eventId) {
          docsList.push(document)
          eventIds.push(document.eventId)
        }
      })

      let eventsSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.EVENTS.NAME, eventIds)

      eventsSnaps.forEach((currDoc) => {
        let event = EventModel.fromFirestoreDoc(currDoc).toObject()
        events_.push(event)
      })

      const registrationsByDay = await getAllRiders(eventIds)

      setAllDocs([...docsList])
      setEvents([...events_])
      setRegistrationsByDay([...registrationsByDay])
    } catch (error) {
      console.error(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDocsFromDb().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    let filtered: IUserDocument[] = allDocs

    if (searchValue) {
      filtered = allDocs.filter((doc) =>
        doc.eventName?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    }

    if (activeTab === 'signed') {
      filtered = filtered.filter((currDoc) => currDoc.status === 'Signed')
    } else if (activeTab === 'unsigned') {
      filtered = filtered.filter((currDoc) => currDoc.status === 'Not Signed')
    }

    setFilteredDocs(groupBy(filtered, 'eventId'))
  }, [activeTab, allDocs, searchValue])

  return (
    <WrapperContainer title="My Paperwork">
      <div className="w-full flex mb-4 flex-wrap">
        <PaperWorkTabs
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />

        <PaperworkDataList
          filteredDocs={filteredDocs}
          allDocs={allDocs}
          setAllDocs={setAllDocs}
          events={events}
          activeTab={activeTab}
          loading={loading}
          registrationsByDay={registrationsByDay}
        />
      </div>
    </WrapperContainer>
  )
}

export default PaperworkRootPage
