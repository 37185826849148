import React from 'react'
import { EntryCard } from './EntryCard'

interface EntriesSectionProps {
  title: string
  entries: { icon?: React.ReactNode; name: string; details: string }[]
}

export const EntriesSection: React.FC<EntriesSectionProps> = ({ title, entries }) => {
  return (
    <div>
      <h4 className="text-[18px]">{title}</h4>
      <div className="flex flex-col gap-2.5 mt-[22px]">
        {entries.map((entry, index) => (
          <EntryCard key={index} icon={entry.icon} title={entry.name} subtitle={entry.details} />
        ))}
      </div>
    </div>
  )
}
