import { where } from 'firebase/firestore'

import { getConvertedData } from '../models/interface.helper'
import FirestoreService from '../services/firestoreService'

import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'

import { RegistrationByDayModel } from '../models/registrations-by-day/registrationByDay.model'

import { CONST } from '../const/const'
import { PAYMENT_STATUS, PAYMENT_TYPE } from '../types/payment'
import { IHorseData } from '../models/horse/horse.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const getAllRiders = async (eventIds: string[]) => {
  try {
    const registeredRiders: IRegistrationByDayInterface[] = []

    const registeredRidersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME
    )

    registeredRidersSnaps.docs.forEach((currDoc) => {
      const current = getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      if (eventIds.includes(current?.eventId ?? '')) registeredRiders.push(current)
    })

    return registeredRiders
  } catch (error) {
    console.error(error, 'error')
    return []
  }
}

export const getAllRidersByUser = async (eventId: string, userId: string) => {
  try {
    const registeredRiders: IRegistrationByDayInterface[] = []

    const registeredRidersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY, '==', userId),
      ]
    )

    registeredRidersSnaps.docs.forEach((currDoc) => {
      const current = getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      registeredRiders.push(current)
    })

    return registeredRiders
  } catch (error) {
    console.error(error, 'error')
    return []
  }
}

export const getRegisteredRiders = async (eventId: string[]) => {
  const riders = await getAllRiders(eventId)
  return riders.filter((rider) => {
    const notPaid =
      rider.paymentType === PAYMENT_TYPE.DEFAULT && rider.paymentStatus === PAYMENT_TYPE.DEFAULT
    const isPendingPayment = rider.paymentStatus === PAYMENT_STATUS.PENDING
    const isScratched = rider.isScratched
    return !(notPaid || isPendingPayment) && !isScratched
  })
}

export const getRiderHorses = (horses: IHorseData[], horseIds: string[]) => {
  if (horses[0] && horseIds[0]) {
    return horses.filter((item) => horseIds?.includes(item.id || ''))
  } else {
    return []
  }
}
