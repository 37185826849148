import { useEffect, useRef, useState } from 'react'

// Third party
import clsx from 'clsx'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import AmountInput from '../../../../components/common/inputs/AmountInput'
import {
  selectResetRefundsTotals,
  setExhibitorRefundErrors,
  setExhibitorRefundTotals,
} from '../../../../store/exhibitor/exhibitorSlice'
import { IRegistrationTicketInterface } from '../../../../models/registration-tickets/registrationTicket.interface'
import { IOnRefundAmountChangeFn } from '../ExhibitorProfileDisplayTab'
import { toFixed } from '../../../../helpers/helpers'
import { formatDateWithTime } from '../../../../helpers/time'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorTicketsRefundAccordion = {
  onRefundAmountChange?: IOnRefundAmountChangeFn
  registrationticketData: IRegistrationTicketInterface[]
}

type IErrorsRef = {
  [key: string]: {
    message: string
    toastMessage: string
  }
}

type IValidate = (
  inputName: string,
  maxCost: number,
  value: number,
  ticketName: string,
  itemId: string | null
) => void

type IDetailRowProps = {
  errors: React.MutableRefObject<IErrorsRef>
  refundAmount?: number
  validate: IValidate
  title: string
  date: string
  registrationTicket: IRegistrationTicketInterface
  cost?: number
  units: number
  itemId?: string
  isScratched?: boolean
}

const ExhibitorTicketsInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRow = ({
  title,
  cost,
  units,
  isScratched,
  registrationTicket,
  errors,
  validate,
  date,
}: IDetailRowProps) => {
  let currAmountFieldName = `${registrationTicket.id}`
  let [currentValue, setCurrentValue] = useState<number | string>(registrationTicket.amountRefunded)

  return (
    <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 flex-grow"
        data={title}
      />

      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 flex-grow"
        data={date}
      />

      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={units}
      />

      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={`$${Number((cost ?? 0) * units).toFixed(2)}`}
      />

      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={isScratched ? 'Scratched' : 'No'}
      />

      <AmountInput
        withDecimals
        name={currAmountFieldName}
        prefix="$"
        placeholder="Event registeration fees"
        inputClassName={clsx(
          'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor',
          !!errors?.current?.[currAmountFieldName] && 'refundError text-[#EB5757]'
        )}
        disable={registrationTicket.refundAmountAdded || !isScratched}
        className={clsx(
          'text-[14px] border !border-solid rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor w-1/6 m-1 focus:bg-white',
          !registrationTicket.refundAmountAdded
            ? !!errors?.current?.[currAmountFieldName] &&
                'text-[#EB5757] !bg-[#EB57571A] border-red-500'
            : '!bg-SeabiscuitGrayThemeColor',
          registrationTicket.refundAmountAdded || !isScratched
            ? 'bg-SeabiscuitGrayThemeColor'
            : 'bg-white'
        )}
        onChange={(value: string) => {
          if (registrationTicket.refundAmountAdded) return

          const newValue = value.replace('$', '')
          setCurrentValue(newValue)

          validate(
            currAmountFieldName,
            (cost ?? 0) * units,
            Number(newValue),
            title,
            `${registrationTicket.id}`
          )
        }}
        value={currentValue}
      />
    </div>
  )
}

const ExhibitorTicketsRefundAccordion = ({
  registrationticketData,
  onRefundAmountChange,
}: IExhibitorTicketsRefundAccordion) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const resetRefundTotals = useAppSelector(selectResetRefundsTotals)

  const errors = useRef<IErrorsRef>({})

  const [refundsTotal, setRefundsTotal] = useState(0)
  const [itemsSelectedForRefund, setItemsSelectedForRefund] = useState(0)
  const [listOfItemsToRefund, setListOfItemsToRefund] = useState<Set<string>>(new Set())

  useEffect(() => {
    setListOfItemsToRefund(new Set())
  }, [resetRefundTotals])

  useEffect(() => {
    let selectedIds: string[] = []
    let total = 0

    registrationticketData.forEach((registrationTicket) => {
      if (registrationTicket.amountRefunded > 0 && !registrationTicket.refundAmountAdded) {
        total += registrationTicket.amountRefunded
        selectedIds.push(registrationTicket.id ?? '')
      }
    })

    setRefundsTotal(total)
    setItemsSelectedForRefund(selectedIds.length)

    const selectedRegistrationticketData = registrationticketData.filter((registrationticket) =>
      selectedIds.includes(registrationticket.id ?? '')
    )

    dispatch(
      setExhibitorRefundTotals({
        keyToUpdate: 'ticketsRefundPriceTotal',
        value: selectedRegistrationticketData,
      })
    )
  }, [registrationticketData])

  // Functions
  const handleScratchList = (itemId: string | null, refundAmount: null | number) => {
    if (!itemId) return

    let listToScratch_ = new Set(listOfItemsToRefund)

    let alreadyPresent = listToScratch_.has(itemId)

    if (refundAmount) {
      if (!alreadyPresent) listToScratch_.add(itemId)
    } else {
      listToScratch_.delete(itemId)
    }

    setListOfItemsToRefund(new Set(listToScratch_))
  }

  const validate: IValidate = (inputName, maxCost, value, ticketName, itemId) => {
    let message: string | null = null

    if (!maxCost || !inputName) {
      return delete errors.current?.[inputName] as any
    }

    if (value > maxCost)
      errors.current = {
        ...errors.current,
        [inputName]: {
          message: `max value that can be refunded is $${maxCost}`,
          toastMessage: `${ticketName.replace(/\s+/gi, '')}'s max value that can be refunded is $${maxCost}`,
        },
      }
    else delete errors.current?.[inputName]

    onRefundAmountChange?.({
      type: 'tickets',
      itemDetails: {
        itemId: inputName,
        itemRefundAmount: value,
      },
    })

    if (Object.keys(errors.current).length)
      message = errors.current[Object.keys(errors.current)[0]]?.toastMessage ?? null

    dispatch(
      setExhibitorRefundErrors({
        keyToUpdate: 'ticketsError',
        message,
      })
    )

    handleScratchList(itemId, value)
  }

  return (
    <>
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorTicketsInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-1 font-semibold flex-grow')}
          data={`Line item`}
        />

        <ExhibitorTicketsInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-1 font-semibold flex-grow')}
          data={`Date`}
        />

        <ExhibitorTicketsInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Number of units`}
        />

        <ExhibitorTicketsInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Amount paid`}
        />

        <ExhibitorTicketsInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Scratched?`}
        />

        <ExhibitorTicketsInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Enter Refund Amount`}
        />
      </div>

      {registrationticketData.map((currentItem, index) => {
        return (
          <DetailRow
            key={`${JSON.stringify(currentItem)}${index}`}
            errors={errors}
            date={`${currentItem.created ? formatDateWithTime(currentItem.created) : ''}`}
            validate={validate}
            registrationTicket={currentItem}
            refundAmount={currentItem?.amountRefunded}
            title={currentItem.ticketTitle ?? ''}
            units={currentItem.selectedUnitsCount ?? 0}
            cost={currentItem.ticketPrice ?? 0}
            itemId={currentItem?.ticketItemId ?? ''}
            isScratched={currentItem?.isScratched ?? false}
          />
        )
      })}

      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorTicketsInput
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-normal h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-1/6'
          )}
          data="Total"
        />

        <ExhibitorTicketsInput
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-normal h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-5/6 text-right'
          )}
          data={`${itemsSelectedForRefund} item, $${toFixed(refundsTotal)} refunded`}
        />
      </div>
    </>
  )
}

export default ExhibitorTicketsRefundAccordion
