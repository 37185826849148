import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { sumBy } from 'lodash'
import { IRegistrationByDayEx } from '../../../ManageClinicNOtherOperationsTab'

import { TotalPointsInput } from '../components/TotalPointsInput/TotalPointsInput'

import { generateName } from '../../../../../../../../helpers/helpers'

import { RegistrationFeesType } from '../../../../../../../../models/event-fees/event-fees.interface'
import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RiderTeamMemberModel } from '../../../../../../../../models/rider-team-member/riderTeamMember.model'

import { IMAGE_CONSTS } from '../../../../../../../../const/image-const'

export const getColumnsScoring = ({
  type,
  getTime,
  eventTrainers,
  fee,
  riderModeIsAsc,
  onSortScore,
  setRegistrationsByDay,
  registrationsByDay,
  onRank,
  onScore,
  scoringLoading,
}: {
  type: 'class' | 'rider'
  eventTrainers: RiderTeamMemberModel[]
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  registrationsByDay: IRegistrationByDayInterface[] | null
  onRank: (row: IRegistrationByDayEx, fee?: RegistrationFeesType | null) => void
  onScore: (row: IRegistrationByDayEx) => void
  onSortScore: () => void
  getTime: ({ rideTime }: { rideTime: string | null; index: number }) => string
  scoringLoading: boolean

  // class based
  fee?: RegistrationFeesType | null

  // rider based
  riderModeIsAsc?: boolean
}) => [
  {
    name: (
      <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
        <span className="whitespace-nowrap">Ride Time</span>
      </span>
    ),
    cell: (row: IRegistrationByDayEx, index: number) => (
      <div className="flex items-center">
        {row.loading ? (
          <Skeleton
            variant="rounded"
            width={60}
            height={20}
            className="mb-2"
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex capitalize">
            {row.orderOfGoScratched ? (
              <span className="text-SeabiscuitDark200ThemeColor/50">Scratched</span>
            ) : (
              getTime({ rideTime: row?.rideTime ?? null, index })
            )}
          </div>
        )}
      </div>
    ),
    width: '10%',
  },
  {
    name: (
      <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
        <span className="whitespace-nowrap">Rider & Back Number</span>
      </span>
    ),
    cell: (row: IRegistrationByDayEx) => {
      return (
        <div className="flex items-center gap-4">
          <span className="w-11 h-11 rounded-full block shrink-0 my-2 overflow-hidden">
            {row.loading ? (
              <Skeleton
                variant="circular"
                width={44}
                height={44}
                className="mb-2"
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <img
                src={row.riderProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                alt="icons"
                className="object-cover w-full h-full rounded-full"
                onError={(e) =>
                  ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                    row.riderName ?? ''
                  )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            )}
          </span>

          {row.loading ? (
            <Skeleton
              variant="rounded"
              width={100}
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <div className="flex flex-col">
              <span className="text-SeabiscuitDark200ThemeColor text-base">{row?.riderName}</span>
              <span className="text-SeabiscuitDark200ThemeColor/80 text-nr flex items-center">
                {Number(row.backNumber) > 0 ? (
                  <>{row.backNumber}</>
                ) : (
                  <span className="inline-flex items-center text-SeabiscuitDark200ThemeColor/60">
                    No back number
                  </span>
                )}
              </span>
            </div>
          )}
        </div>
      )
    },
    width: type === 'rider' ? '25%' : '30%',
  },
  {
    name: (
      <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
        <span className="whitespace-nowrap">Horse</span>
      </span>
    ),
    cell: (row: IRegistrationByDayEx) => {
      const trainer = eventTrainers?.find(
        (eventTrainer) => eventTrainer.registrationByDayUniqId === row.uniqId
      )

      return (
        <div className="flex items-center gap-4">
          <span className="w-11 h-11 rounded-full block shrink-0 my-2 overflow-hidden">
            {row.loading ? (
              <Skeleton
                variant="circular"
                width={44}
                height={44}
                className="mb-2"
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <img
                src={row.horseProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                alt="icons"
                className="object-cover w-full h-full rounded-full"
                onError={(e) =>
                  ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                    row.horseName ?? ''
                  )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            )}
          </span>

          {row.loading ? (
            <Skeleton
              variant="rounded"
              width={100}
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <div className="flex flex-col">
              <span className="text-SeabiscuitDark200ThemeColor  text-base">
                {row?.horseName ?? 'No Horse'}
              </span>
              <span className="text-SeabiscuitDark200ThemeColor/60 text-nr flex items-center">
                {trainer ? (
                  <span>{trainer.teamMemberName}</span>
                ) : (
                  <span className=" inline-flex items-center text-SeabiscuitDark200ThemeColor/60">
                    No trainer
                  </span>
                )}
              </span>
            </div>
          )}
        </div>
      )
    },
    width: type === 'rider' ? '25%' : '30%',
  },
  ...(type === 'rider'
    ? [
        {
          id: 'class',
          accessorFn: (row: any) => row.registrationByDayName || '',
          name: (
            <span className="text-gray-400 font-normal text-[0.875rem] flex items-center justify-center pr-6 ">
              <span className="whitespace-nowrap">Class</span>
            </span>
          ),
          cell: (row: IRegistrationByDayEx) => (
            <span className="text-SeabiscuitDark200ThemeColor text-sm whitespace-nowrap block pr-6 ">
              {row.registrationByDayName}
            </span>
          ),
        },
      ]
    : []),

  {
    cell: (row: IRegistrationByDayEx) => {
      return (
        <div className="justify-end w-full flex">
          {Number(row.score?.totalPoints) > 0 && (
            <img className="w-5" src="/assets/cp_icons/Lock.svg" alt="" />
          )}
        </div>
      )
    },
    width: type === 'rider' ? '10%' : '5%',
  },
  {
    name: (
      <div className="text-gray-400 font-normal text-[0.875rem] flex items-center justify-end w-full">
        <button
          className="whitespace-nowrap flex items-center gap-1 hover:opacity-70 transition-all"
          onClick={onSortScore}
        >
          Score
          <img
            src="/assets/og_icons/arrow.svg"
            alt=""
            className={clsx(
              'w-2 invert-[0.7] sepia-[0.1] saturate-[0.05] hue-rotate-[5deg] brightness-[0.6] contrast-[1.2]',
              type === 'class'
                ? fee?.isAsc
                  ? 'rotate-0'
                  : 'rotate-180'
                : riderModeIsAsc
                  ? 'rotate-0'
                  : 'rotate-180'
            )}
          />
        </button>
      </div>
    ),
    cell: (row: IRegistrationByDayEx) => {
      let max = 0
      if (row.score?.judges) {
        max = sumBy(row.score.judges[0]?.sections, (section) => section.max)
      }

      const rowFee = type === 'rider' ? row.fee : fee

      return (
        <div className="justify-end w-full flex">
          {row.loading ? (
            <Skeleton
              variant="rounded"
              width={80}
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <>
              {rowFee?.scoreCard && rowFee?.scoreCard !== 'Default' ? (
                <button
                  className="cursor-pointer text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all"
                  onClick={() => onScore(row)}
                >
                  {Number(row.score?.totalPoints) > 0 ? (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">{row.score?.totalPoints}</span>{' '}
                      <p className="text-gray-500">•</p>{' '}
                      <p>{((Number(row.score?.totalPoints) / Number(max)) * 100).toFixed(3)}%</p>
                    </div>
                  ) : (
                    <span className="opacity-50">Enter number</span>
                  )}
                </button>
              ) : (
                <div className="cursor-pointer text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all">
                  <TotalPointsInput
                    row={row}
                    setRegistrationsByDay={setRegistrationsByDay}
                    registrationsByDay={registrationsByDay}
                    fee={rowFee ?? null}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )
    },
    width: '15%',
  },
  {
    name: (
      <div className="w-full text-gray-400 font-normal text-[0.875rem] flex justify-center items-center">
        <span className="whitespace-nowrap">Rank</span>
      </div>
    ),
    cell: (row: IRegistrationByDayEx) => {
      return (
        <div className="flex items-center justify-center w-full">
          {row.loading || scoringLoading ? (
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <button
              className={clsx(
                'rounded-full text-base flex items-center justify-center w-10 h-10 hover:opacity-70',
                row.score?.rank === 1 && Number(row.score?.totalPoints)
                  ? 'bg-SeabiscuitGreenThemeColor text-white'
                  : row.score?.rank === 2 && Number(row.score?.totalPoints)
                    ? 'bg-[#FFBD00] text-white'
                    : row.score?.rank === 3 && Number(row.score?.totalPoints)
                      ? 'bg-[#3B6AF6] text-white'
                      : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
              )}
              onClick={() => onRank(row, type === 'rider' ? row.fee : fee)}
            >
              {row.score?.rank ? row.score.rank : 0}
            </button>
          )}
        </div>
      )
    },
    width: '10%',
  },
]
