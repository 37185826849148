// Redux
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'

import SelectedEventFeeList from './tabComponents/confirmation/SelectedEventFeeList'
import SelectedEventGrandTotal from './tabComponents/confirmation/SelectedEventGrandTotal'
import SelectedEventList from './tabComponents/confirmation/SelectedEventList'
import { SelectedEventPaperworkList } from './tabComponents/confirmation/SelectedEventPaperworkList'
import SelectedEventTicketList from './tabComponents/confirmation/SelectedEventTicketList'
import SelectedEventGovernanceFeeList from './tabComponents/confirmation/SelectedEventGovernanceFeeList'

import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

const EventClinicConfirmationTab = ({
  activeTab,
  setEventTab,
  manageInfo,
  isManage,
}: {
  activeTab: {
    tab: string
    step: number
  }
  setEventTab: any
  manageInfo: IManageInfo
  isManage?: boolean
}) => {
  // Hooks and vars
  const selectedEventDetails = useAppSelector(selectedEvent)
  let { registrationPrice } = selectedEventDetails.basicEventDetails

  return (
    <>
      <SelectedEventList
        activeTab={activeTab}
        setEventTab={setEventTab}
        registrationPrice={registrationPrice}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventFeeList setEventTab={setEventTab} manageInfo={manageInfo} isManage={isManage} />

      <SelectedEventGovernanceFeeList
        setEventTab={setEventTab}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventTicketList
        setEventTab={setEventTab}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventPaperworkList
        setEventTab={setEventTab}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventGrandTotal manageInfo={manageInfo} isManage={isManage} />
    </>
  )
}

export default EventClinicConfirmationTab
