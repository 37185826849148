import { ChangeEvent, useEffect, useState } from 'react'

import { Spinner } from '@knocklabs/react'
import { where } from 'firebase/firestore'
import { Clear } from '@mui/icons-material'

import { useAppSelector } from '../../../store/hooks'
import { selectProfileData, selectUserId } from '../../../store/user/userSlice'

import EventWaitlistService from '../../../services/eventWaitlistService'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getRegisteredRiders } from '../../../helpers/riders'
import helpers from '../../../commonHelpers/helpers'
import customImageComponent from '../../common/CustomImageComponent'

import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { IRiderTeamMember } from '../../../models/users/user.interface'
import { getUserFullName } from '../../../helpers/helpers'

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

type Props = {
  show: boolean
  handleModal: any
  dataToPassOn: any
}

const RegisterForCompetitionModal = ({ show, handleModal, dataToPassOn }: Props) => {
  const toastFunction = useToasterHelper()

  const profileDetails = useAppSelector(selectProfileData)
  const userId = useAppSelector(selectUserId)

  const [userList, setUserList] = useState<IRiderTeamMember[]>([])
  const [step, setStep] = useState<number>(1)
  const [riders, setRiders] = useState<IRegistrationByDayInterface[]>([])
  const [loading, setLoading] = useState(false)

  const existOrNot = async () => {
    try {
      let data: any[] = []
      const result = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST.NAME,
        [where('eventId', '==', (dataToPassOn as any)?.id), where('userId', '==', userId)]
      )
      if (result.size) {
        result?.forEach((doc: any) => data.push(doc))
        return data
      }

      return data
    } catch (err) {
      throw err
    }
  }

  useEffect(() => {
    let userTeamMembers = profileDetails.userTeamMembers?.map((item) => ({
      ...item,
      selected: false,
    }))
    setUserList([
      {
        selected: false,
        memberDob: profileDetails.userDOB,
        memberId: profileDetails.id,
        memberName: profileDetails ? getUserFullName(profileDetails) : '',
        memberEmail: profileDetails.userEmail,
        memberProfilePicture: profileDetails.userProfilePicture,
      },
      ...(userTeamMembers ?? []),
    ])
  }, [profileDetails])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, teamMember: IRiderTeamMember) => {
    const select = e.target.checked
    if (userList) {
      let addedWaitListMembersArrCopy = [...userList]
      addedWaitListMembersArrCopy = addedWaitListMembersArrCopy.map((currUser) => {
        currUser = {
          ...currUser,
          selected: false,
        }
        if (currUser.selected) currUser.selected = false
        if (currUser.memberId === teamMember?.memberId) currUser.selected = select
        return currUser
      })
      setUserList([...addedWaitListMembersArrCopy])
    }
  }

  const handleAddMemberWaitlist = async () => {
    setLoading(true)

    const waitlist = userList.filter((item) => item.selected)
    try {
      const data = {
        eventId: (dataToPassOn as any)?.id,
        id: '',
        userId,
        owner: dataToPassOn.owner,
        waitlist,
      }

      if (!data.waitlist || !data.waitlist.length) {
        return toastFunction.error({ message: MESSAGES_CONST.ERROR_MEMBER_ADD_TO_WAITLIST })
      }

      const exist = await existOrNot()

      if (!exist.length) {
        await EventWaitlistService.createEventWaitlist(data)
      } else {
        await EventWaitlistService.updateEventWaitlist(exist[0].id, data)
      }
      setStep(step + 1)
      toastFunction.success({ message: MESSAGES_CONST.MEMBER_ADDED_TO_WAITLIST })
      handleModal(true, MODAL_CONSTS.REGISTER_FOR_COMPETETION, data.waitlist.length)
    } catch (err: any) {
      helpers.logger({
        isError: true,
        message: err,
      })
    }
    setLoading(false)
  }

  const getEventRiders = async () => {
    setLoading(true)
    const registeredRiders = await getRegisteredRiders([dataToPassOn.id])

    setRiders(registeredRiders)
    setLoading(false)
  }

  useEffect(() => {
    getEventRiders().then()
  }, [])

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter01"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-3/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
          <span
            onClick={() => handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETETION)}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>

          {step === 1 && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollableLabel"
                >
                  Event full
                </h5>
              </div>
              <div className="detailModal">
                <p className="text-SeabiscuitDark200ThemeColor text-sm py-4">
                  This event has reached its registration limit.
                </p>
                <p className="text-SeabiscuitDark200ThemeColor text-sm pb-4">
                  Those that join the waitlist will be added to the event as registered exhibitors
                  scratch (in order of registration).
                </p>

                <div className="registeredUser border border-SeabiscuitGray500ThemeColor rounded-md p-1 flex items-center">
                  <span className="text-SeabiscuitMainThemeColor p-2 w-12 text-center bg-SeabiscuitDullMainThemeColor flex justify-center items-center rounded-md">
                    {loading ? <Spinner color="white" /> : riders.length}
                  </span>
                  <span className="ml-2 text-SeabiscuitDark200ThemeColor">registered</span>
                </div>

                {dataToPassOn.waitlist ? (
                  <div className="registeredUser border border-SeabiscuitGray500ThemeColor rounded-md p-1 flex items-center mt-2">
                    <span className="text-SeabiscuitMainThemeColor p-2 w-12 text-center bg-SeabiscuitDullMainThemeColor rounded-md">
                      {dataToPassOn.waitlistCount}
                    </span>
                    <span className="ml-2 text-SeabiscuitDark200ThemeColor">on the waitlist</span>
                  </div>
                ) : null}

                {dataToPassOn.waitlist ? (
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => setStep(step + 1)}
                    className="w-full h-12 mt-24 mx-auto py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColorDark hover:text-white transition-all"
                  >
                    JOIN WAITLIST
                  </button>
                ) : null}
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollableLabel2"
                >
                  Add team members to waitlist
                </h5>
              </div>

              <div className="uselListWarp mt-4 flex flex-col min-h-[250px] overflow-y-auto">
                <div className="form-check mb-3">
                  {userList && userList.length ? (
                    userList.map((item, index) => {
                      return (
                        <label
                          key={index}
                          className="form-check mb-3 cursor-pointer"
                          htmlFor={'teammember' + index}
                        >
                          <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                            <div className="min-w-fit flex items-center">
                              {customImageComponent(
                                item.memberProfilePicture,
                                item.memberName,
                                '!w-[40px] !h-[40px] rounded-full mr-2 avatarImg'
                              )}
                              <div className="hourseDetails w-10/12">
                                <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                                  <p className="text-SeabiscuitDark200ThemeColor text-lg">
                                    {item?.memberName}
                                  </p>
                                  <p className="text-xs">
                                    {item?.defaultRole
                                      ? item?.defaultRole
                                      : profileDetails.id === userId && 'You'}
                                    , {item?.memberEmail}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {item?.selected ? (
                              <img
                                src={CHECKED_ICON}
                                className="relative right-[0px] top-[0px]"
                                width={20}
                                height={20}
                                alt="checked"
                              />
                            ) : null}
                            <input
                              onChange={(e) => {
                                handleChange(e, item)
                              }}
                              checked={item?.selected}
                              id={'teammember' + index}
                              className={`mr-[2px] form-check-input appearance-none h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor ${item?.selected ? 'hidden' : ''}`}
                              type="checkbox"
                            />
                          </div>
                        </label>
                      )
                    })
                  ) : (
                    <div className="min-h-[200px] flex items-center justify-center">
                      No team member data found for join waitlist
                    </div>
                  )}
                </div>
              </div>

              <button
                type="button"
                disabled={loading}
                onClick={handleAddMemberWaitlist}
                className="w-full h-12 mt-24 mx-auto py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColorDark hover:text-white transition-all"
              >
                SAVE
              </button>
            </>
          )}

          {step === 3 && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollable3"
                >
                  Success!
                </h5>
              </div>

              <p className="text-SeabiscuitDark200ThemeColor text-sm py-4">
                Selected members were added to the waitlist.
              </p>
              <p className="text-SeabiscuitDark200ThemeColor text-sm pb-4 mb-32">
                Waitlisted exhibitors will be added to the event as people scratch, on a first come,
                first served basis.
              </p>
            </>
          )}

          <button
            onClick={() => handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETETION)}
            disabled={loading}
            type="button"
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor transition-all"
          >
            {step !== 3 ? 'CANCEL' : 'CLOSE'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegisterForCompetitionModal
