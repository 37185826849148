import { FC, useContext } from 'react'
import { useParams } from 'react-router'

// Third party
import clsx from 'clsx'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'

// Component
import InfiniteScrollDataTable from '../../../../../../../../components/common/tables/InfiniteScrollDataTable'
import NoDataAvailable from '../../../NoDataAvailable'
import { HandleModalContext } from '../../../../../../../../layout/mainlayout/MainLayout'
import { HorseSkeleton } from '../HorseSkeleton/HorseSkeleton'

import { IHorsesDataTypes } from '../../data/horses.data.types'

// Styles
import '../../HorsesManage.css'

// Constants
import { MANAGE_CLINIC_HORSES_CONSTANTS } from '../../data/horses.data.const'
import { MODAL_CONSTS } from '../../../../../../../../const/modal-const'

import { ROUTES_CONST } from '../../../../../../../../const/routes-const'

const MCHC = MANAGE_CLINIC_HORSES_CONSTANTS

interface HorsesListProps {
  loading: boolean
  horses: IHorsesDataTypes[]
  isFullScreen?: boolean
  searchValue: string
}
const HorsesList: FC<HorsesListProps> = ({
  loading,
  horses,
  isFullScreen = false,
  searchValue,
}) => {
  const { eventId } = useParams<{ eventId?: any }>()
  const router = useIonRouter()
  const history = useHistory()

  const handleModal = useContext(HandleModalContext)?.handleModal

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCHC.COLS.PROFILE}</span>
        </span>
      ),
      cell: (row: IHorsesDataTypes) => (
        <div className="flex items-center  py-2">
          <span
            className="w-full h-full rounded-full block shrink-0 cursor-pointer transition-all hover:opacity-70"
            onClick={() => {
              router.push(
                `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row.userId}/${eventId}/${row.registrationId}#horses`
              )
              history.push(
                `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row.userId}/${eventId}/${row.registrationId}#horses`
              )
            }}
          >
            <img
              src={row.horseProfilePicture ?? ''}
              alt="icons"
              className="object-cover w-[45px] h-[45px] rounded-full"
              onError={(e) =>
                ((e.target as any).src =
                  `https://ui-avatars.com/api/?name=${generateName(row.horseName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
            />
          </span>
        </div>
      ),
      width: '16%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCHC.COLS.NAME}</span>
        </span>
      ),
      cell: (row: IHorsesDataTypes) => (
        <div className="flex items-center mr-[5px]">
          <div
            className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize cursor-pointer transition-all hover:opacity-70"
            onClick={() => {
              router.push(
                `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row.userId}/${eventId}/${row.registrationId}#horses`
              )
              history.push(
                `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row.userId}/${eventId}/${row.registrationId}#horses`
              )
            }}
          >
            {row.horseName}
          </div>
        </div>
      ),
      width: '21%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCHC.COLS.RIDER}</span>
        </span>
      ),
      cell: (row: IHorsesDataTypes) => (
        <div className="flex items-center mr-[5px]">
          <div
            className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize cursor-pointer transition-all hover:opacity-70"
            onClick={() => {
              router.push(
                `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row.userId}/${eventId}/${row.registrationId}`
              )
              history.push(
                `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row.userId}/${eventId}/${row.registrationId}`
              )
            }}
          >
            {row.riderName}
          </div>
        </div>
      ),
      width: '21%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCHC.COLS.TRAINER}</span>
        </span>
      ),
      cell: (row: IHorsesDataTypes) => (
        <div className="flex items-center">
          <span className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize">
            {row?.trainer ?? '-'}
          </span>
        </div>
      ),
      width: '21%',
    },
    {
      label: MCHC.COLS.ELIGIBILITY,
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCHC.COLS.ELIGIBILITY}</span>
        </span>
      ),
      cell: (row: IHorsesDataTypes) => (
        <div className="justify-center w-full flex">
          {row.eligibility ? (
            <img src={MCHC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img src={MCHC.IMAGE_URLS.NOT_OK} alt="icon" />
          )}
        </div>
      ),
      width: '21%',
    },
  ]

  function generateName(fullName: string) {
    return fullName
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase()
  }

  const openManageEventModal = () => {
    if (handleModal)
      handleModal(true, MODAL_CONSTS.MANAGE_EVENT_MODAL, {
        eventId,
        headingClassName: '!text-SeabiscuitDark200ThemeColor',
      })
  }

  return (
    <>
      {!loading && !horses.length && !searchValue && !isFullScreen ? (
        <NoDataAvailable
          text={!!searchValue ? 'No data found.' : 'No one has registered yet'}
          url=""
          height={isFullScreen ? null : 240}
          linkText="Edit registrations >"
          imgSrc="assets/placeholders/noUserFound.svg"
          mainClasses="!mx-0"
          onClickOfLink={openManageEventModal}
        />
      ) : (
        <InfiniteScrollDataTable
          keyField="index"
          hasMore={false}
          fixedHeader={true}
          data={horses}
          columns={columns}
          progressPending={loading}
          disableInfiniteScroll={true}
          className={clsx(
            '!rounded-none horsesListTable !transition-none',
            isFullScreen ? '!h-[58vh]' : (horses.length > 0 || loading) && '!min-h-[300px]',
            horses.length > 0 && 'mt-5'
          )}
          noDataComponent={
            <NoDataAvailable
              text={!!searchValue ? 'No data found.' : 'No one has registered yet'}
              url=""
              height={isFullScreen ? null : 240}
              linkText="Edit Event >"
              imgSrc="assets/placeholders/noUserFound.svg"
              onClickOfLink={openManageEventModal}
              mainClasses="!mx-0"
            />
          }
          progressComponent={
            <div className="w-full flex flex-col flex-wrap items-left justify-start my-10">
              <HorseSkeleton />
              <HorseSkeleton />
              <HorseSkeleton />
              <HorseSkeleton />
            </div>
          }
        />
      )}
    </>
  )
}

export default HorsesList
