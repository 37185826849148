import { FC } from 'react'
import clsx from 'clsx'
import customImageComponent from '../../common/CustomImageComponent'
import { IconDelete2 } from '../../icons/IconDelete2'
import { IProfileHasAccess, IRemoveProfile } from './types/switchAccountTypes'

interface SwitchAccountWhoHasAccessProps {
  profiles: IProfileHasAccess[]
  removeProfile: (data: IRemoveProfile) => void
}
export const SwitchAccountWhoHasAccess: FC<SwitchAccountWhoHasAccessProps> = ({
  profiles,
  removeProfile,
}) => {
  const formatDate = (dateStr: IProfileHasAccess['lastSignInTime']) => {
    if (!dateStr) return ''
    const date = new Date(dateStr)

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }

    return date.toLocaleString('en-US', options)
  }

  return (
    <div className={'min-h-[306px] flex flex-col w-full gap-5'}>
      {profiles.length ? (
        profiles.map((profile, index) => (
          <div key={profile.userDocId} className={'flex gap-3 items-center'}>
            <div className={'flex flex-1 gap-3 items-center'}>
              <div className={clsx('w-10 h-10 shrik-0 rounded-full border border-black/20')}>
                {customImageComponent(
                  profile.userProfilePicture,
                  profile.name,
                  'w-full h-full object-cover rounded-full'
                )}
              </div>
              <div className={clsx('flex flex-col gap-0.5')}>
                <h4 className={clsx('text-nr text-SeabiscuitDarkThemeColor capitalize')}>
                  {profile.name}
                </h4>
                <p className={'text-SeabiscuitLightParagraphTextColor text-sm'}>
                  {formatDate(profile.lastSignInTime) &&
                    `Last seen ${formatDate(profile.lastSignInTime)}`}
                </p>
              </div>
            </div>

            <button
              className={'transition-all hover:opacity-50 active:opacity-20'}
              onClick={() => removeProfile({ id: profile.userDocId, index })}
            >
              <IconDelete2 />
            </button>
          </div>
        ))
      ) : (
        <div className={'text-black/50 text-sm text-center pt-5'}>
          <p>No one else has access to your account.</p>
        </div>
      )}
    </div>
  )
}
