import moment from 'moment'
import { PDFImage, StandardFonts, cmyk } from 'pdf-lib'
import { collection, doc, where } from 'firebase/firestore'

import FirestoreService from '../../../services/firestoreService'
import FirebaseStorageService from '../../../services/storageService'
import FirebaseApp from '../../../services/firebaseApp'
import TimeLib from '../../../lib/Time'
import PdfService from '../../../services/pdfService'

import { sendEmailForSign } from '../sendEmail/sendEmailForSign'
import { capitalize, createString, getUserFullName } from '../../../helpers/helpers'
import { getConvertedData } from '../../../models/interface.helper'

import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IDocument } from '../../../fakeData/fakeDocumentList'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../../models/users/user.interface'
import { IUserDocument } from '../../../models/user-documents/user-documents.interface'

import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'

import { CONST } from '../../../const/const'
import { paperworkPdfPageParams } from '../../../helpers/paperwork'

interface IPaperworkTemplateData {
  userData: IUserInterfaceExtended
  EventDetails: IEventDetails
  organizerDetails: IUserInterface | null
  paperwork: IDocument
  eventId: string
  ridersTeamMember: IRiderTeamMemberInterface
  registrationByDay?: IRegistrationByDayInterface
  isMature: boolean
  eventDraftId: string
  isStaff?: boolean
  isSigned?: boolean
  teamMemberRole?: string[]
  index?: number
  dateOfSignature: Date
}

type TCertificate = {
  SX: number
  SY: number
  EX: number
  EY: number
}

export const USEFLiabilityWaiverPDFTemplate = async (
  {
    userData,
    EventDetails,
    organizerDetails,
    paperwork,
    eventId,
    ridersTeamMember,
    registrationByDay,
    isMature,
    isStaff,
    isSigned,
    eventDraftId,
    teamMemberRole,
    index,
    dateOfSignature,
  }: IPaperworkTemplateData,
  customDocId?: string
) => {
  const d = new Date()
  let time = d.getTime()

  const pdfService = new PdfService(paperworkPdfPageParams)
  pdfService.registerFontkit()

  // Fonts
  const boldFont = await pdfService.embedFont(StandardFonts.TimesRomanBold)
  const timeRomanFont = await pdfService.embedFont(StandardFonts.TimesRoman)
  const timeRomanBoldItalicFont = await pdfService.embedFont(StandardFonts.TimesRomanBoldItalic)
  const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
  const signatureFont = await pdfService.embedFont(fontByte)

  /**
   * -----------------------------------------------------
   * First page
   * -----------------------------------------------------
   */
  await pdfService.writeFirstPage({
    title: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`,
    EventDetails,
    organizerDetails,
    isStaff,
    registrationByDay,
    ridersTeamMember,
    teamMemberRole,
  })

  // First Page Content End %%%%%%%%%%%%%%%%%%
  const secondPage = await pdfService.addNewPdfPage()
  secondPage.drawText('WAIVER AND RELEASE OF LIABILITY, ASSUMPTION', {
    x: 45,
    y: 660,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`OF RISK AND INDEMNITY AGREEMENT`, {
    x: 45,
    y: 640,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`USEF: 4001 WING COMMANDER WAY: LEXINGTON, KY,`, {
    x: 45,
    y: 610,
    font: boldFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 16,
  })
  secondPage.drawText(`40511: 859.258.2472 : FAX 859.231.6662 : USEF.ORG`, {
    x: 45,
    y: 590,
    font: boldFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 16,
  })

  secondPage.drawText(`${EventDetails.competitionName}`, {
    x: 45,
    y: 550,
    font: timeRomanFont,
    size: 14,
  })
  secondPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 45,
    y: 530,
    font: timeRomanFont,
    size: 14,
  })
  secondPage.drawText(`${EventDetails.competitionAddressLine1}`, {
    x: 45,
    y: 510,
    font: timeRomanFont,
    size: 10,
  })

  secondPage.drawText(
    `For and in consideration of United States Equestrian Federation, Inc. dba US Equestrian`,
    { x: 45, y: 470, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `(“USEF”) allowing me, the undersigned, to participate in any capacity (including as a`,
    { x: 45, y: 450, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach, official, trainer or`,
    { x: 45, y: 430, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `volunteer) in a USEF sanctioned, licensed or approved event or activity, including but not`,
    { x: 45, y: 410, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `limited to equestrian clinics, practices, shows, and related or incidental activities and`,
    { x: 45, y: 390, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `${EventDetails.competitionName} (“USEF Event” or “USEF Events”); I, for myself, and on behalf of my spouse,`,
    { x: 45, y: 370, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `children, heirs and next of kin, and any legal and personal representatives, executors, administrators,`,
    { x: 45, y: 350, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `successors,, and assigns, hereby agree to and make the following contractual representations`,
    { x: 45, y: 330, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(`pursuant to this Agreement (the “Agreement”):`, {
    x: 45,
    y: 310,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  secondPage.drawText(`A. RULES AND REGULATIONS:`, {
    x: 45,
    y: 270,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  secondPage.drawText(`I hereby agree to be bound and abide by the rules,`, {
    x: 225,
    y: 270,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })
  secondPage.drawText(
    `regulations, and policies of USEF as published in USEF Rule Book and on the website at`,
    { x: 45, y: 250, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(`www.usef.org, as amended from time to time.`, {
    x: 45,
    y: 230,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  secondPage.drawText(`B. ACKNOWLEDGMENT OF RISK:`, {
    x: 45,
    y: 190,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  secondPage.drawText(`I knowingly, willingly, and voluntarily acknowledge the`, {
    x: 245,
    y: 190,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })
  secondPage.drawText(
    `inherent risks associated with the sport of equestrian and know that horseback and related`,
    { x: 45, y: 170, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `equestrian activities are inherently dangerous, and that participation in any USEF`,
    { x: 45, y: 150, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `Event involves risks and dangers including, with- out limitation, the potential for serious`,
    { x: 45, y: 130, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(
    `bodily injury (including broken bones, head or neck injuries), sickness and disease (including`,
    { x: 45, y: 110, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  secondPage.drawText(`communicable diseases),`, {
    x: 45,
    y: 90,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  // Third Page
  const thirdPage = await pdfService.addNewPdfPage()
  thirdPage.drawText(
    `trauma, pain & suffering, permanent disability, paralysis and death; loss of or damage to`,
    { x: 45, y: 660, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `personal property (including my mount & equipment arising out of the unpredictable behavior`,
    { x: 45, y: 640, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `of horses; exposure to extreme conditions and circumstances; accidents involving other`,
    { x: 45, y: 620, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `participants, event staff, volunteers or spectators; contact or collision with other participants`,
    { x: 45, y: 600, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `and horses, natural or manmade objects; adverse weather conditions; facilities issues`,
    { x: 45, y: 580, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `and premises conditions; failure of protective equipment (including helmets); inadequate safety`,
    { x: 45, y: 560, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `measures; participants of varying skill levels; situations beyond the immediate control`,
    { x: 45, y: 540, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `of the USEF Event organizers and competition management; and other undefined, not`,
    { x: 45, y: 520, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(`readily foreseeable and presently unknown risks and dangers (“Risks”).`, {
    x: 45,
    y: 500,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  thirdPage.drawText(`EQUINE ACTIVITY LIABILITY ACT WARNING:`, {
    x: 140,
    y: 460,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  thirdPage.drawText(`CAUTION: HORSEBACK RIDING AND EQUINE ACTIVITIES CAN BE DANGEROUS. `, {
    x: 50,
    y: 420,
    color: cmyk(0, 0, 0, 0.9),
    font: timeRomanBoldItalicFont,
    size: 12,
  })
  thirdPage.drawText(`RIDE AT YOUR OWN RISK.`, {
    x: 190,
    y: 400,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })

  const ItalicFont = await pdfService.embedFont(StandardFonts.TimesRomanItalic)

  thirdPage.drawText(
    `Under the laws of most States, an equine activity sponsor or equine professional is not liable for`,
    { x: 45, y: 360, font: ItalicFont, color: cmyk(0, 0, 0, 0.9), size: 12 }
  )
  thirdPage.drawText(
    `any injury to, or the death of, a participant in equine activities resulting the inherent risks of`,
    { x: 40, y: 340, font: ItalicFont, color: cmyk(0, 0, 0, 0.9), size: 12 }
  )
  thirdPage.drawText(`equine activities.`, {
    x: 220,
    y: 320,
    font: ItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })

  thirdPage.drawText(`C. ASSUMPTION OF RISK:`, {
    x: 45,
    y: 260,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  thirdPage.drawText(`I understand that the aforementioned Risks whole or in part or`, {
    x: 195,
    y: 260,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })
  thirdPage.drawText(
    `result directly or indirectly from the negligence of my own actions or inactions, the actions`,
    { x: 45, y: 240, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `or inactions of others participating in the USEF Events, or the negligent acts or`,
    { x: 45, y: 220, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `omissions and knowingly assume all such Risks and responsibility for any damages, liabilities,`,
    { x: 45, y: 200, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `losses, or expenses that I incur as a result of my participation in any USEF Events. I also `,
    { x: 45, y: 180, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(
    `agree to be responsible for any injury or damage caused by me, my horse, my employees or`,
    { x: 45, y: 160, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  thirdPage.drawText(`contractors under my direction and control at any USEF Event.`, {
    x: 45,
    y: 140,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })
  thirdPage.drawText(`D. WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY:`, {
    x: 45,
    y: 100,
    font: timeRomanBoldItalicFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  // Fourth Page
  const fourthPage = await pdfService.addNewPdfPage()
  fourthPage.drawText(
    `In conjunction with my participation in any USEF Event, I hereby release, waive and`,
    { x: 45, y: 660, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `covenant not to sue, and further agree to indemnify, defend and hold harmless the`,
    { x: 45, y: 640, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `following parties: USEF, USEF Recognized Affiliate Associations, the United States`,
    { x: 45, y: 620, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `Olympic & Paralympic Committee (USOPC), USEF clubs, members, Event participants`,
    { x: 45, y: 600, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `(including athletes/riders, coaches, trainers, judges/officials, and other personnel) ,the`,
    { x: 45, y: 580, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `Event owner, licensee, and competition managers; the promoters, sponsors, or advertisers`,
    { x: 45, y: 560, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `of any USEF Event; any charity or other beneficiary which may benefit from the USEF Event;`,
    { x: 45, y: 540, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `the owners, managers, or lessors of any facilities or premises where a USEF Event may be`,
    { x: 45, y: 520, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `held; and all directors, officers, employees, agents, contractors, and  volunteers of any`,
    { x: 45, y: 500, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `of the aforementioned parties (Individually and Collectively, the “Released Parties” or`,
    { x: 45, y: 480, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `“Event Organizers”), with respect to any liability, claim(s), demand(s), cause(s) of`,
    { x: 45, y: 460, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `action, damage(s), loss, or expense (including court costs and reasonable attorney fees)`,
    { x: 45, y: 440, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `of any kind or nature (“Liability”) which may arise out of, result from, or relate in any`,
    { x: 45, y: 420, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `way to my participation in the USEF Events, including claims for Liability caused in whole`,
    { x: 45, y: 400, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(`or in part by the negligent acts or omissions of the Released Parties.`, {
    x: 45,
    y: 380,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  fourthPage.drawText(`E. COMPLETE AGREEMENT AND SEVERABILITY CLAUSE:`, {
    x: 45,
    y: 340,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  fourthPage.drawText(
    `This Agreement represents the complete understanding between the parties regarding these`,
    { x: 45, y: 320, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `issues and no oral representations, statements or inducements have been made apart from`,
    { x: 45, y: 300, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `this Agreement. If any provision of this Agreement is held to be unlawful, void, or for any`,
    { x: 45, y: 280, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `reason unenforceable, then that provision shall be deemed severable from this Agreement and`,
    { x: 45, y: 260, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fourthPage.drawText(
    `shall not affect the validity and enforceability of any remaining provisions`,
    { x: 45, y: 240, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )

  fourthPage.drawText(`I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL`, {
    x: 45,
    y: 200,
    font: boldFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  fourthPage.drawText(`OF ITS TERMS AND CONDITIONS, AND KNOW IT CONTAINS AN ASSUMPTION OF,`, {
    x: 45,
    y: 180,
    font: boldFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  fourthPage.drawText(`RISK RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS `, {
    x: 45,
    y: 160,
    font: boldFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  fourthPage.drawText(`AND INDEMNIFICATION OBLIGATIONS.`, {
    x: 45,
    y: 140,
    font: boldFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })

  fourthPage.drawText(
    `By signing below, I (as the participant or as the Parent/Legal Guardian of the minor`,
    { x: 45, y: 100, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )

  // Fifth Page Start
  const fifthPage = await pdfService.addNewPdfPage()
  fifthPage.drawText(
    `identified below) hereby accept and agree to the terms and conditions of this Agreement in`,
    { x: 45, y: 680, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(
    `connection with my (or the minor’s) participation in any USEF Event. If, despite this `,
    { x: 45, y: 660, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(
    `Agreement, I, or anyone on my behalf or the minor’s behalf, makes a claim for Liability`,
    { x: 45, y: 640, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(
    `against any of the Released Parties, I will indemnify, defend and hold harmless each of`,
    { x: 45, y: 620, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(
    `the Released Parties from any such Liabilities as the result of such claim. The parties`,
    { x: 45, y: 600, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(
    `agree that this agreement may be electronically signed. The parties agree that the`,
    { x: 45, y: 580, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(
    `electronic signatures appearing on this agreement are the same as handwritten signatures`,
    { x: 45, y: 560, font: timeRomanFont, size: 12, color: cmyk(0, 0, 0, 0.9) }
  )
  fifthPage.drawText(`for the purposes of validity enforceability, and admissibility.`, {
    x: 45,
    y: 540,
    font: timeRomanFont,
    size: 12,
    color: cmyk(0, 0, 0, 0.9),
  })

  fifthPage.drawText(`Signers name :`, { x: 45, y: 500, font: boldFont, size: 12 })
  fifthPage.drawText(`${ridersTeamMember.teamMemberName}`, {
    x: 150,
    y: 500,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(`Date :`, { x: 45, y: 480, font: boldFont, size: 12 })
  fifthPage.drawText(`${moment(dateOfSignature).format('MM-DD-YYYY')}`, {
    x: 150,
    y: 480,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(`Signature:`, { x: 45, y: 460, font: boldFont, size: 12 })

  // Preparing pdf

  if (isSigned) {
    let AllLogs: {
      logs: any
      id?: string
      signatoryId?: string
      riderId?: string
      eventId?: string
    }

    AllLogs = {
      id: '',
      signatoryId: ridersTeamMember.teamMemberId ?? '',
      riderId: ridersTeamMember.riderId ?? '',
      eventId: ridersTeamMember.eventId ?? '',
      logs: [
        {
          time: TimeLib.utcTimestamp(),
          audit: `Document signed by ${ridersTeamMember.teamMemberName}`,
        },
      ],
    }

    fifthPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 45,
      y: 425,
      font: signatureFont,
      size: 28,
    })

    let certificateBorder = [
      { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
      { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
      { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
      { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
    ]
    const auditTrailPage = await pdfService.addNewPdfPage()

    certificateBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 2,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    let documentRef = doc(
      collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME)
    )
    let id = documentRef.id
    let documentNameAsPerPdf = `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`
    auditTrailPage.drawText(`Signature Certificate`, {
      x: 80,
      y: 640,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 14,
    })
    auditTrailPage.drawText(`Document Name: ${documentNameAsPerPdf}`, {
      x: 80,
      y: 630,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })
    auditTrailPage.drawText(`Unique Document Id: ${id}`, {
      x: 80,
      y: 620,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 8,
    })

    let Image = ridersTeamMember.teamMemberProfilePicture
      ? ridersTeamMember.teamMemberProfilePicture
      : '/assets/img/Empty user.png'
    let imagetype: string = ''

    const getImageArray = async (imageUrl: string) => {
      return await fetch(imageUrl).then((res) => {
        const contentType = res.headers.get('content-type') ?? ''
        imagetype = contentType?.split('/')[1]
        return res.arrayBuffer()
      })
    }

    let jpgImageBy = await getImageArray(Image)

    let userImage: PDFImage
    if (imagetype === 'jpeg') {
      userImage = await pdfService.embedJpg(jpgImageBy)
    } else if (imagetype === 'png') {
      userImage = await pdfService.embedPng(jpgImageBy)
    } else {
      const defaultImage = await getImageArray('/assets/img/Empty user.png')
      userImage = await pdfService.embedPng(defaultImage)
    }

    auditTrailPage.drawImage(userImage, {
      x: 80,
      y: 480,
      width: 80,
      height: 80,
      opacity: 1,
    })

    // //%%%%%%%%%%%
    const capatlize_name: string = capitalize(createString(ridersTeamMember.teamMemberName), {
      capitalizeAll: true,
    })
    auditTrailPage.drawText(capatlize_name, { x: 170, y: 550, color: cmyk(0, 0, 0, 0.9), size: 10 })
    auditTrailPage.drawText(`Security Level: Email`, {
      x: 170,
      y: 535,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })

    let signatureBorder = [
      { SX: 330, SY: 560, EX: 460, EY: 560 }, // Top Border
      { SX: 330, SY: 560, EX: 330, EY: 490 }, // Left Border
      { SX: 460, SY: 560, EX: 460, EY: 490 }, // Right Border
      { SX: 330, SY: 490, EX: 460, EY: 490 }, // Bottom Border
    ]

    signatureBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 1,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    auditTrailPage.drawText(`Digital Signature:`, {
      x: 340,
      y: 540,
      color: cmyk(0, 0, 0, 0.9),
      font: boldFont,
      size: 11,
    })
    auditTrailPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 340,
      y: 510,
      color: cmyk(0, 0, 0, 0.9),
      font: signatureFont,
      size: 28,
    })

    if (AllLogs.logs && AllLogs.logs.length) {
      auditTrailPage.drawText(`Timestamp`, {
        x: 110,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      auditTrailPage.drawText(`Audit Trails`, {
        x: 270,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      let logY = 420
      AllLogs.logs.forEach((log: any) => {
        let parsedDate = Date.parse(getConvertedData({ time: log.time }).time)
        let firstLineText = ''
        let secondLineText = ''
        let thirdLineText = ''

        if (log.audit.length > 40 && log.audit.length < 80) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
        } else if (log.audit.length > 40 && log.audit.length < 120) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
          thirdLineText = log.audit.slice(81, 120)
        } else if (log.audit.length < 40) {
          firstLineText = log.audit
        }

        auditTrailPage.drawText(`${moment(parsedDate).format(`MMMM DD, YYYY h:mm:ss a`)}`, {
          x: 110,
          y: logY,
          color: cmyk(0, 0, 0, 0.9),
          size: 8,
        })

        if (firstLineText && firstLineText !== '') {
          auditTrailPage.drawText(`${firstLineText}`, {
            x: 270,
            y: logY,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (secondLineText && secondLineText !== '') {
          auditTrailPage.drawText(`${secondLineText}`, {
            x: 270,
            y: logY - 10,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (thirdLineText && thirdLineText !== '') {
          auditTrailPage.drawText(`${thirdLineText}`, {
            x: 270,
            y: logY - 20,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        logY -= 40
        if (logY < 140) {
          logY = 620

          let certificateBorder = [
            { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
            { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
            { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
            { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
          ]

          certificateBorder.forEach((item: TCertificate) => {
            auditTrailPage.drawLine({
              start: { x: item.SX, y: item.SY },
              end: { x: item.EX, y: item.EY },
              thickness: 2,
              color: cmyk(0, 0, 0, 0.5),
              opacity: 0.8,
            })
          })
        }
      })
    }

    auditTrailPage.drawLine({
      start: { x: 70, y: 140 },
      end: { x: 470, y: 140 },
      thickness: 2,
      color: cmyk(0, 0, 0, 0.5),
      opacity: 0.8,
    })

    auditTrailPage.drawText(`This audit trail report provides a detailed record of the`, {
      x: 160,
      y: 110,
      font: boldFont,
      size: 10,
    })
    auditTrailPage.drawText(`online activity and events recorded for this contact.`, {
      x: 170,
      y: 90,
      font: boldFont,
      size: 10,
    })
  }

  const pdfBytes = await pdfService.generatedPdf
  var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

  // Step 2: Convert Blob to File
  var file = new File([blob], `${paperwork.document_name}.pdf`, { type: 'application/pdf' })

  const fileUrl = await FirebaseStorageService.uploadFile(
    file,
    `/user/documents/${ridersTeamMember.teamMemberRole}${time}/${ridersTeamMember.registrationDocId}/${ridersTeamMember.teamMemberId}/${eventId}/${paperwork.document_name}/${ridersTeamMember.riderId}-${index}`
  )

  const USER_DOCUMENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS
  let retunedData: any = {}

  try {
    const eventsSnapshot = await FirestoreService.filterItems(USER_DOCUMENT.NAME, [
      where(USER_DOCUMENT.FIELDS.SIGNATORY_ID.KEY, '==', ridersTeamMember.teamMemberId),
      where(USER_DOCUMENT.FIELDS.EVENT_ID.KEY, '==', eventId),
      where(USER_DOCUMENT.FIELDS.RIDER_ID.KEY, '==', ridersTeamMember.riderId),
      where(USER_DOCUMENT.FIELDS.DOCUMENT_NAME.KEY, '==', paperwork.document_name),
      where(USER_DOCUMENT.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY, '==', ridersTeamMember.teamMemberRole),
      where(USER_DOCUMENT.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY, '==', registrationByDay?.id),
    ])

    let obj: IUserDocument = {
      documentNameAsPerPdf: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`,
      documentName: paperwork.key,
      documentOriginalName: paperwork.document_name,
      documentUrl: (fileUrl as string) ?? '',
      eventId,
      eventLogo: EventDetails.eventLogo,
      eventName: EventDetails.competitionName,
      status: isSigned ? 'Signed' : 'Not Signed',
      riderId: ridersTeamMember.riderId,
      riderName: isStaff ? 'N/A' : ridersTeamMember.riderName,
      signatoryId: ridersTeamMember.teamMemberId,
      signatoryName: ridersTeamMember.teamMemberName,
      signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
      activityUser: getUserFullName(userData),
      documentOwner: ridersTeamMember.teamMemberId,
      competitorId: userData.id,
      signatoryDefaultRole: ridersTeamMember.teamMemberRole,
      signatoryEmail: ridersTeamMember.teamMemberEmail,
      reminder: false,
      registrationDocId: ridersTeamMember.registrationDocId,
      riderTeamMemberDocId: ridersTeamMember.id || '',
      registrationByDayDocId: registrationByDay?.id || '',
      registrationByDayUuid: registrationByDay?.uuid || '',
      registrationByDayUniqId: registrationByDay?.uniqId || '',
      coordinatesToSignOn: {
        x: null,
        y: null,
      },
      pageNumberToSignOn: null,
      modified: new Date().toString(),
      dateOfSignature: isSigned ? dateOfSignature : null,
    }

    if (eventsSnapshot.size) {
      let docId = ''
      eventsSnapshot.forEach((doc) => {
        docId = UserDocumentModel.fromFirestoreDoc(doc).toObject().id
      })

      await FirestoreService.updateItem(USER_DOCUMENT.NAME, customDocId || docId, obj)

      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        customDocId || docId,
        {
          id: customDocId || docId,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
      retunedData = {
        docId: customDocId || docId,
      }
    } else {
      obj.created = new Date().toString()
      const document = await FirestoreService.createItem(USER_DOCUMENT.NAME, obj)
      retunedData = {
        docId: document.id,
      }
      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        document.id,
        {
          id: document.id,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
    }
  } catch (err: any) {
    console.log(err, 'error')
  }

  if (fileUrl && fileUrl !== '' && eventId) {
    await sendEmailForSign({
      ridersTeamMember,
      paperwork,
      paperworkUrl: fileUrl as string,
      eventId,
      isMature,
      isStaff,
      eventDraftId,
      senderData: {
        senderName: getUserFullName(userData),
        senderEmail: userData.userEmail || '',
      },
    })
  }

  return retunedData
}
