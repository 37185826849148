import React, { useMemo, useState, useRef, useEffect } from 'react'
import { DefaultGenerics } from 'stream-chat'
import {
  useTranslationContext,
  ChannelPreviewUIComponentProps,
  DefaultStreamChatGenerics,
} from 'stream-chat-react'
import { DeleteModal } from '../modals/DeleteModal'
import ProfileImage from './ProfileImage'
import clsx from 'clsx'
import DotsIcon from '../../../components/icons/DotsIcon'

export default function CustomChannelPreview(
  props: ChannelPreviewUIComponentProps<DefaultStreamChatGenerics | DefaultGenerics> & {
    editable?: boolean
    titleClassName?: string
    headerContainerClassName?: string
    unreadClassName?: string
    showCount?: boolean
    showUnread?: boolean
    timestampSeparator?: string | null
  }
) {
  const {
    channel,
    displayImage,
    displayTitle,
    latestMessage,
    unread,
    active,
    setActiveChannel,
    onSelect: customOnSelectChannel,
    watchers,
    className = '',
    titleClassName = '',
    headerContainerClassName = '',
    unreadClassName = '',
    showCount = true,
    showUnread = true,
    timestampSeparator,
  } = props
  const { userLanguage } = useTranslationContext()
  const [openEdit, setOpenEdit] = useState(false)
  const [showDeleteMessage, setShowDeleteMessage] = useState(false)
  const latestMessageAt = channel.state.last_message_at
  const createdAt = channel.data?.created_at

  const isUnread = unread !== undefined && unread > 0
  const editable = props.editable ?? true
  const { vertical, user_role } = (channel.data || {}) as { vertical: string; user_role: string }

  const channelPreviewButton = useRef<HTMLDivElement | null>(null)

  const timestamp = useMemo(() => {
    if (!latestMessageAt && !createdAt) {
      return ''
    }
    const date = latestMessageAt ? new Date(latestMessageAt) : new Date(createdAt as Date)

    const now = new Date()
    const diff = now.getTime() - date.getTime()
    const minutes = Math.floor(diff / 60000)
    const hours = Math.floor(diff / 3600000)
    const days = Math.floor(diff / 86400000)
    const weeks = Math.floor(diff / 604800000)
    const months = Math.floor(diff / 2629800000)
    const years = Math.floor(diff / 31536000000)

    if (diff < 60000) return 'just now'
    if (minutes < 60) return `${minutes}m`
    if (hours < 24) return `${hours}h`
    if (days < 7) return `${days}d`
    if (weeks < 5) return `${weeks}w`
    if (months < 12) return `${months}m`
    return `${years}y`
  }, [latestMessageAt])

  const onSelectChannel = (e: React.MouseEvent<HTMLDivElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e)
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers)
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur()
    }
  }

  const editMenuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editMenuRef.current &&
        !editMenuRef.current.contains(event.target as Node) &&
        !channelPreviewButton.current?.contains(event.target as Node)
      ) {
        setOpenEdit(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <React.Fragment key={channel.id}>
      <div
        onClick={onSelectChannel}
        className={clsx(
          'flex items-start flex-1 gap-4 py-4 px-2 mr-4 mt-2 transition-colors relative font-[Poppins] hover:bg-[#F6F7FB] rounded-2xl max-w-full h-[85px] group',
          active ? 'bg-[#F6F7FB]' : '',
          className
        )}
        ref={channelPreviewButton}
      >
        <ProfileImage
          src={displayImage}
          lazyLoad={true}
          className={'h-10 w-10 object-cover rounded-full hidden sm:block'}
        />

        <div className="flex flex-1 flex-col w-full gap-1">
          <div
            className={clsx(
              'flex flex-row flex-1 items-center justify-between w-full shrink',
              headerContainerClassName
            )}
          >
            <div
              className={clsx(
                'text-SeabiscuitDark200ThemeColor truncate',
                titleClassName,
                !titleClassName ? (isUnread ? 'font-semibold' : 'font-normal') : ''
              )}
            >
              {displayTitle?.slice(0, 25)}
            </div>
            {timestamp && (
              <time
                dateTime={latestMessageAt?.toISOString()}
                className="text-xs text-muted-foreground text-center text-[#122B46]/[0.5] text-nowrap group-hover:hidden block"
              >
                {timestampSeparator ? `${timestampSeparator}${timestamp}` : timestamp}
              </time>
            )}
            {editable && (
              <button
                className="h-[5px] w-[15px] group-hover:block hidden"
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenEdit((s) => !s)
                }}
              >
                <DotsIcon fillColor={isUnread ? '#122B46' : 'rgba(18, 43, 70, 0.5)'} />
              </button>
            )}
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col flex-1">
              <p
                className={`text-sm text-muted-foreground text-[#122B46] line-clamp-2 ${isUnread ? 'font-semibold' : 'font-normal'}`}
              >
                {latestMessage}
              </p>
            </div>
            <div className="flex flex-col w-[21px]">
              {showUnread && isUnread && (
                <div
                  className={clsx(
                    'flex flex-none items-center justify-center bg-primary text-primary-foreground rounded-full text-xs font-medium text-white bg-[#F7074F] h-6 w-6',
                    unreadClassName
                  )}
                >
                  {showCount && <span>{unread}</span>}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row">
            {vertical && user_role && (
              <p className="text-[0.7rem] text-muted-foreground text-left text-[#122B46]/[0.5]">
                {`${vertical[0].toUpperCase()}${vertical.slice(1)} • ${user_role}`}
              </p>
            )}
          </div>
        </div>

        {editable && openEdit && (
          <div
            ref={editMenuRef}
            className="absolute h-[98px] w-[184px] border-2 rounded-md right-8 bg-white flex flex-col z-10 justify-center shadow-md top-10"
          >
            <button
              onClick={(e) => {
                e.stopPropagation()
                channel.markRead()
                setOpenEdit(false)
              }}
              className="hover:bg-[#F6F7FB] h-[40px] text-right mx-2 rounded-md text-sm p-2"
            >
              Mark as read
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation()
                //channel.hide()
                setShowDeleteMessage(true)
                setOpenEdit(false)
              }}
              className="hover:bg-[#F6F7FB] h-[40px] text-right mx-2 rounded-md text-sm p-2"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      <DeleteModal
        open={showDeleteMessage}
        onDelete={() => {
          channel.hide()
          setShowDeleteMessage(false)
        }}
        onClose={() => setShowDeleteMessage(false)}
      />
    </React.Fragment>
  )
}
