import { IRegisterTab } from '../models/event-registered-users/event-registered-users.interface'
import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'

interface IRegistrationByDayInterfaceExtended extends IRegistrationByDayInterface {
  isChildren?: boolean
}
export const getRegistrations = (registerTabData: IRegisterTab[] | null) => {
  const parentRegistrations: IRegistrationByDayInterface[] = []
  const childrenRegistrations: IRegistrationByDayInterfaceExtended[] = []

  registerTabData?.forEach((register) => {
    register.registrationsByDay.forEach((registrationByDay) => {
      parentRegistrations.push(registrationByDay)
    })
    register.children?.forEach((children) => {
      children.registrationsByDay.forEach((registrationByDay) => {
        childrenRegistrations.push({ ...registrationByDay, isChildren: true })
      })
    })
  })

  return {
    parentRegistrations: parentRegistrations,
    childrenRegistrations: childrenRegistrations as IRegistrationByDayInterfaceExtended[],
    allRegistrations: [
      ...parentRegistrations,
      ...childrenRegistrations,
    ] as IRegistrationByDayInterfaceExtended[],
  }
}
