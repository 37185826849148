import { RegistrationFeesModel } from '../../../../../../models/registration-fees/registrationFees.model'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'

export const DUMMY_REGISTRATION_FEES_STATS = [
  { ...new RegistrationFeesModel(), loading: true },
  { ...new RegistrationFeesModel(), loading: true },
  { ...new RegistrationFeesModel(), loading: true },
  { ...new RegistrationFeesModel(), loading: true },
]

export const sortByOrder = (array: any[]) => {
  return array.sort((a, b) => {
    if (a.order === 0 && b.order === 0) {
      return 0
    }
    if (a.order === 0) {
      return 1
    }
    if (b.order === 0) {
      return -1
    }
    return a.order - b.order
  })
}

export const sortByRank = (array: IRegistrationByDayInterface[], direction = 'desc') => {
  return array.sort((a, b) => {
    const totalPointsA = Number(a.score?.totalPoints)
    const totalPointsB = Number(b.score?.totalPoints)

    if (totalPointsA === 0 && totalPointsB === 0) {
      return 0
    }
    if (totalPointsA === 0) {
      return 1
    }
    if (totalPointsB === 0) {
      return -1
    }
    return direction === 'asc' ? totalPointsA - totalPointsB : totalPointsB - totalPointsA
  })
}
