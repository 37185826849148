import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import ViewsLoader from '../../../loader/ViewsLoader'
import { ScheduleByDayItem } from '../../common/ScheduleByDayItem/ScheduleByDayItem'
import { RedTitle } from './global/RedTitle'

import { daysOfWeek, sortScheduling } from '../../../../helpers/time'
import FirestoreService from '../../../../services/firestoreService'

import { IEventFees } from '../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import {
  IEventScheduleInterface,
  TCustomSchedule,
} from '../../../../models/event-scheduling/event-scheduling.interface'
import { EventSchedulingModel } from '../../../../models/event-scheduling/event-scheduling.model'
import ITypes from '../../../../pages/competitor/event-registration-tabs/hooks/useEventRegistrationTabs.types'

import { CONST } from '../../../../const/const'

const EventScheduleTab = () => {
  const { eventId } = useParams<ITypes['IParams']>()

  const [schedules, setSchedules] = useState<{ [key: string]: TCustomSchedule[] }>({})
  const [loading, setLoading] = useState(true)
  const [scheduleBy, setScheduleBy] = useState<'class' | 'rider'>('class')

  const fetchData = async () => {
    let eventScheduling: IEventScheduleInterface['custom'] = []
    let eventEntries: IEventFees['registrationFees'] = []

    const eventSchedulingSnaps = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SCHEDULING.NAME,
      eventId
    )

    if (eventSchedulingSnaps.exists()) {
      eventScheduling =
        EventSchedulingModel.fromFirestoreDoc(eventSchedulingSnaps).toObject().custom
    }

    const scheduleByDataPoint = eventSchedulingSnaps?.data()?.scheduleBy ?? 'class'

    setScheduleBy(scheduleByDataPoint)

    const eventEntriesSnaps = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_FEES.NAME,
      eventId
    )

    if (eventEntriesSnaps.exists()) {
      eventEntries = EventFeesModel.fromFirestoreDoc(eventEntriesSnaps).toObject().registrationFees
    }

    const sortedSchedules = sortScheduling([...eventScheduling, ...eventEntries])

    const schedules_ = sortedSchedules.reduce((acc: any, currentEvent) => {
      const date = `${daysOfWeek[new Date(currentEvent.startDate)?.getDay()]}_${new Date(currentEvent.startDate)?.getDate()}`
      if (date) acc[date] = [...(acc[date] ?? []), currentEvent]
      return acc
    }, {})

    setSchedules(schedules_)
    setLoading(false)
  }

  useEffect(() => {
    if (eventId) fetchData().then()
  }, [eventId])

  return (
    <>
      <RedTitle text="Schedule" className="mb-1" />

      {loading && (
        <div className="flex justify-center items-center h-[100px]">
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F70763"
          />
        </div>
      )}

      {Object.keys(schedules)?.length > 0
        ? Object.keys(schedules).map((schedule, index) => (
            <ScheduleByDayItem
              schedules={schedules}
              schedule={schedule}
              key={index}
              scheduleBy={scheduleBy}
            />
          ))
        : null}
    </>
  )
}

export default EventScheduleTab
