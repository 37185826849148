import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Tooltip } from '@mui/material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import EventFormContainerComponent from '../container/EventFormContainerComponent'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import AmountInput from '../../../common/inputs/AmountInput'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import SwitchElement from './SwitchElement'
import Input from '../../../common/inputs/Input'
import { PriceWithPercent } from '../../common/PriceWithPercent/PriceWithPercent'

import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { EventTicketingModel } from '../../../../models/event-ticketing/event-ticketing.model'
import { ModelBaseModel } from '../../../../models/model-base/model-base.model'

const eventTicketingFormDefaultValues = new EventTicketingModel().toObject()

const eventTicketingFormValidationSchema = EventTicketingModel.validationSchema()

interface EventTicketingFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal?: any
  dataToPassOn?: any
  nextEventTab?: string
  eventTab?: string
}

const EventTicketingFormComponent: React.FC<EventTicketingFormComponentProps> = (props) => {
  /* Hooks and vars */
  const handleModalContext = useContext(HandleModalContext)
  const { handleSubmit, register, setValue, watch, trigger, reset, control } = useForm({
    defaultValues: { ...eventTicketingFormDefaultValues },
    resolver: yupResolver(eventTicketingFormValidationSchema),
    mode: 'onChange',
  })

  let valid = false

  const [dataIncrementAttrProps, setDataIncrementAttrProps] = useState({})

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tickets',
  })

  const TableTitle = ({
    children,
    tooltipText,
  }: {
    children: React.ReactNode
    tooltipText: string
  }) => (
    <div className="text-[12px] font-semibold text-SeabiscuitDark200ThemeColor  flex items-center">
      {/* mini:block */}
      {children}
      <Tooltip title={<h1 className="tooltip_title">{tooltipText}</h1>} placement="top" arrow>
        <button className="ml-1">
          <TooltipIcon color="#122B46" />
          {/* <img className="w-3 h-3" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
        </button>
      </Tooltip>
    </div>
  )

  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventTicketingFormDefaultValues)
    }
    trigger().then()

    switch (props.data?.paymentOption) {
      case 'IncreaseAllPrices':
        setDataIncrementAttrProps({
          'data-increment-by': 5,
        })
        break

      case 'ChargeRidersAndSpectators':
        setDataIncrementAttrProps({
          'data-increment-by': 2.9,
        })
        break

      default:
        setDataIncrementAttrProps({})
    }
  }, [props.data, reset, trigger])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  /**
   * @param noteInputRef Input reference
   * @info Opens note modal
   */
  const openAddNotesModal = (noteInputRef: any) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef,
      setValue,
    })
  }

  return (
    <EventFormContainerComponent>
      <div>
        <div className="w-full">
          <EventFormHeaderComponent
            title="Tickets"
            description="Sell spectator tickets for your event"
          >
            <EventFormFooterCommonComponent
              eventTab={props.eventTab}
              nextEventTab={props.nextEventTab}
              onNext={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      tabName: 'EventTickets',
                      validFormHandler: props.onValid,
                    }),
                  props.onInvalid
                )(e)
              }}
              onSaveAndExit={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      onSaveAndExit: true,
                      tabName: 'EventTickets',
                      validFormHandler: props.onValidAnExit,
                    }),
                  props.onInvalidAndExit
                )(e)
              }}
            />
          </EventFormHeaderComponent>

          <div className="flex mini:items-center mb-6 gap-2 mini:gap-10 flex-col mini:flex-row pt-1">
            <label className="xl:text-[16px] 2xl:text-xl text-SeabiscuitDark200ThemeColor font-normal">
              Do you want to sell spectator tickets at this event?
            </label>

            <Controller
              name="sellSpectatorTickets"
              control={control}
              render={({ field: { value } }) => (
                <SwitchElement
                  onSwitch={() => setValue('sellSpectatorTickets', !value)}
                  on={value}
                  onText="Yes"
                  offText="No"
                  width={100}
                />
              )}
            />
          </div>

          {watch('sellSpectatorTickets') ? (
            <>
              <div className="w-full text-xs overflow-x-auto">
                <div className="w-full grid grid-cols-[auto] mini:grid-cols-[270px_minmax(300px,auto)_170px_170px_50px] gap-2">
                  <TableTitle tooltipText="Give the ticket a name">Name of ticket</TableTitle>

                  <TableTitle tooltipText="Describe what this ticket grants access to">
                    Description
                  </TableTitle>
                  <TableTitle tooltipText="Number of these tickets you wish to sell">
                    Number of units
                  </TableTitle>
                  <TableTitle tooltipText="Price you will charge per ticket">
                    <div {...dataIncrementAttrProps}>Price per unit</div>
                  </TableTitle>
                </div>

                <div className="w-full grid grid-cols-[auto] mini:grid-cols-[270px_minmax(300px,auto)_170px_170px_50px] gap-2">
                  {fields.map((field, index) => {
                    valid = !!(
                      new ModelBaseModel().getNum(watch(`tickets.${index}.actualCostAlias`)) &&
                      !!watch(`tickets.${index}.name`) &&
                      !!watch(`tickets.${index}.note`) &&
                      !!watch(`tickets.${index}.note`)
                    )

                    return (
                      <React.Fragment key={field.id}>
                        <Input
                          valid={valid}
                          showPostFixIcon={false}
                          placeholder="Ticket Name"
                          register={register}
                          name={`tickets.${index}.name`}
                          value={watch(`tickets.${index}.name`) as any}
                          className="!h-[58px] px-6 !rounded-xl relative"
                          validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                          invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                        />

                        <Input
                          valid={valid}
                          placeholder="Note"
                          register={register}
                          showPostFixIcon={false}
                          name={`tickets.${index}.note`}
                          onClick={(e) => openAddNotesModal(e.target)}
                          value={watch(`tickets.${index}.note`) as any}
                          className="!h-[58px] px-6 !rounded-xl relative"
                          validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                          invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                        />

                        <Input
                          type="number"
                          valid={valid}
                          register={register}
                          showPostFixIcon={false}
                          placeholder="Number of units"
                          name={`tickets.${index}.available`}
                          value={watch(`tickets.${index}.available`) as any}
                          className="!h-[58px] px-6 !rounded-xl relative"
                          validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                          invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                        />

                        <div className={'relative h-[58px]'}>
                          <AmountInput
                            name={`tickets.${index}.actualCostAlias`}
                            register={register}
                            prefix="$"
                            valid={valid}
                            placeholder="Price per unit"
                            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                            className="flex items-center h-full"
                            inputClassName="!pl-4"
                            value={watch(`tickets.${index}.actualCostAlias`)}
                          />
                          <PriceWithPercent
                            status={true}
                            price={watch(`tickets.${index}.actualCostAlias`)}
                            percent={+(dataIncrementAttrProps as any)['data-increment-by'] || 0}
                          />
                        </div>

                        <div className={`text-[14px] w-full flex items-center h-12`}>
                          <button
                            onClick={() => {
                              props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                                removeItem: function () {
                                  remove(index)
                                },
                              })
                            }}
                          >
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM15.2432 7.98926C15.0451 7.99394 14.8569 8.07679 14.7197 8.21973L12 10.9395L9.28027 8.21973C9.21036 8.14773 9.12672 8.0905 9.03429 8.05142C8.94187 8.01233 8.84254 7.99219 8.74219 7.99219C8.59293 7.99222 8.44707 8.03679 8.32328 8.12019C8.19949 8.20358 8.1034 8.32202 8.0473 8.46033C7.99119 8.59865 7.97763 8.75056 8.00835 8.89662C8.03907 9.04269 8.11267 9.17627 8.21973 9.28027L10.9395 12L8.21973 14.7197C8.14775 14.7888 8.09028 14.8716 8.0507 14.9632C8.01111 15.0548 7.9902 15.1534 7.98918 15.2532C7.98817 15.3529 8.00707 15.4519 8.04479 15.5443C8.08251 15.6367 8.13828 15.7206 8.20883 15.7912C8.27939 15.8617 8.36332 15.9175 8.4557 15.9552C8.54808 15.9929 8.64706 16.0118 8.74684 16.0108C8.84662 16.0098 8.9452 15.9889 9.03679 15.9493C9.12839 15.9097 9.21116 15.8523 9.28027 15.7803L12 13.0605L14.7197 15.7803C14.7888 15.8523 14.8716 15.9097 14.9632 15.9493C15.0548 15.9889 15.1534 16.0098 15.2532 16.0108C15.3529 16.0118 15.4519 15.9929 15.5443 15.9552C15.6367 15.9175 15.7206 15.8617 15.7912 15.7912C15.8617 15.7206 15.9175 15.6367 15.9552 15.5443C15.9929 15.4519 16.0118 15.3529 16.0108 15.2532C16.0098 15.1534 15.9889 15.0548 15.9493 14.9632C15.9097 14.8716 15.8523 14.7888 15.7803 14.7197L13.0605 12L15.7803 9.28027C15.8893 9.17559 15.9642 9.0404 15.9951 8.89242C16.026 8.74444 16.0115 8.59058 15.9534 8.451C15.8953 8.31143 15.7965 8.19266 15.6697 8.11026C15.543 8.02787 15.3943 7.98569 15.2432 7.98926Z" fill="#00B6AA" />
                                                </svg> */}
                            <div className="cursor-pointer">
                              <img
                                src="/assets/og_icons/Cancel.svg"
                                className="h-[24px] w-[24px]"
                                alt="Cancel"
                              />
                            </div>
                          </button>
                        </div>
                        <div className="row-seperator mt-6 mini:hidden"></div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </>
          ) : null}
          {watch('sellSpectatorTickets') ? (
            <>
              <div className="flex items-center gap-2 justify-between">
                <button
                  onClick={() => {
                    append({
                      sold: 0,
                      cost: 0,
                      name: '',
                      note: '',
                      available: 0,
                      uuid: uuidv4(),
                      actualCost: 0,
                      actualCostAlias: '$0',
                      costAlias: '$0',
                    })
                  }}
                  className="my-3 text-SeabiscuitMainThemeColor font-[400] text-[12px] flex items-center gap-2"
                >
                  <img
                    src={`/assets/og_icons/Cancel-2.svg`}
                    className=" rotate-45"
                    alt="plus icon"
                  />
                  <span>Add ticket</span>
                </button>
                <button
                  onClick={() => {
                    remove()
                  }}
                  className="my-3 text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
                >
                  <span>Clear All</span>
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </EventFormContainerComponent>
  )
}

export default EventTicketingFormComponent
