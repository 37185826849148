import React, { FC, useMemo } from 'react'

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, type DragEndEvent } from '@dnd-kit/core'

import { DraggableRow } from './components/DraggableRow'
import { AddBreak } from './components/AddBreak'
import { OrderOfGoTableHeader } from './components/OrderOfGoTableHeader'

import FirestoreService from '../../../../../../../services/firestoreService'

import useToasterHelper from '../../../../../../../helpers/ToasterHelper'
import { useEventTrainers } from '../../../../../../../hooks/useEventTrainers'
import { handleDragEnd, onScratch } from './data/order-of-go-utils'
import { getColumns } from './data/columns'

import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks'
import { selectedEvent, setSelectedEventKey } from '../../../../../../../store/events/eventsSlice'

import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationFeesType } from '../../../../../../../models/event-fees/event-fees.interface'
import { IBreak, IBreakRegistrationByDay } from '../../../../../../../models/events/event.interface'

import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import { CONST } from '../../../../../../../const/const'

interface OrderOfGoTableByClassProps {
  eventId: string
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  registrationsByDay: IRegistrationByDayInterface[] | null
  fee: RegistrationFeesType | null
  increment: string
  registrationsByDayByClass: IBreakRegistrationByDay[]
  setRegistrationsByDayByClass: (value: IBreakRegistrationByDay[]) => void
  selectedClass: string
}
export const OrderOfGoTableByClass: FC<OrderOfGoTableByClassProps> = ({
  setRegistrationsByDayByClass,
  eventId,
  setRegistrationsByDay,
  registrationsByDay,
  fee,
  increment,
  registrationsByDayByClass,
  selectedClass,
}) => {
  const toastFunctions = useToasterHelper()
  const { eventTrainers } = useEventTrainers(eventId)
  const dispatch = useAppDispatch()

  const currentEvent = useAppSelector(selectedEvent)

  const registrationsToDisplay = useMemo(() => {
    return registrationsByDayByClass
  }, [registrationsByDayByClass])

  const updateBreaks = async (breaks: IBreak[], eventId: string) => {
    dispatch(
      setSelectedEventKey({
        key: 'Event',
        value: { ...currentEvent.Event, breaks },
      })
    )
    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
      eventId,
      {
        breaks,
      }
    )
  }

  const handleScratch = async (row: IBreakRegistrationByDay, state: boolean) => {
    try {
      await onScratch({
        registrationsToDisplay,
        row,
        state,
        registrationsByDay,
        onUpdate: async (newData, breaks, newRegistrationsByDay) => {
          setRegistrationsByDayByClass([...newData])
          setRegistrationsByDay(newRegistrationsByDay)
          await updateBreaks(breaks, eventId)
        },
      })

      toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCRATCHED_UPDATED })
    } catch (error) {
      console.error('error', error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const onDragEnd = async (event: DragEndEvent) => {
    try {
      await handleDragEnd({
        event,
        registrationsToDisplay,
        registrationsByDay,
        onUpdate: async (newArray, breaks, newRegistrationsByDay) => {
          setRegistrationsByDayByClass([...newArray])
          setRegistrationsByDay(newRegistrationsByDay)
          await updateBreaks(breaks, eventId)
        },
      })

      toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const saveHandlerBreak = async (h: string, m: string, a: string, id: string) => {
    const newData = [...registrationsToDisplay]
    const breakIndex = newData.findIndex((e) => e.id === id)

    if (breakIndex < 0) {
      console.error(breakIndex, 'breakIndex')
      return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }

    newData[breakIndex] = {
      ...newData[breakIndex],
      time: `${h}:${m}:${a}`,
    }
    const breaks = newData.filter((itm) => itm.isBreakDrag)

    try {
      await updateBreaks(breaks, eventId)
      setRegistrationsByDayByClass([...newData])
      toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
    } catch (error) {
      console.error('error', error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const columns = useMemo<ColumnDef<IBreakRegistrationByDay>[]>(() => {
    return getColumns({ eventTrainers, saveHandlerBreak, fee, increment, type: 'class' })
  }, [eventTrainers, fee, increment])

  const table = useReactTable({
    data: registrationsToDisplay,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id, // required because row indexes will change
  })

  const addBreak = async () => {
    try {
      const breaks = registrationsByDayByClass.filter((itm) => itm.isBreakDrag)

      const dataToCreate = {
        id: `${new Date().getTime()}`,
        order: registrationsByDayByClass.length + 1,
        time: '00:00:00',
        isBreakDrag: true,
        orderOfGoScratched: false,
        registrationByDayName: selectedClass,
      }

      await updateBreaks([...breaks, dataToCreate], eventId)
      const newData = [...registrationsByDayByClass, dataToCreate]

      setRegistrationsByDayByClass([...newData])
    } catch (error) {
      console.error('error', error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  return (
    <DndContext key={registrationsToDisplay.length} onDragEnd={onDragEnd}>
      <table className={'w-full'}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <OrderOfGoTableHeader headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody>
          <SortableContext
            items={registrationsToDisplay?.map(({ id }) => id)}
            strategy={verticalListSortingStrategy}
          >
            {registrationsToDisplay.map((row, rowIndex) => (
              <DraggableRow
                key={row.id}
                row={row}
                tableRow={table.getRowModel().rows[rowIndex]}
                rowIndex={rowIndex}
                isLastChild={rowIndex === registrationsToDisplay.length - 1}
                registrationsToDisplay={registrationsToDisplay}
                handleScratch={handleScratch}
                type="class"
              />
            ))}
          </SortableContext>
          <AddBreak addBreak={addBreak} />
        </tbody>
      </table>
    </DndContext>
  )
}
