import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { Control, UseFormRegister, UseFormReset, UseFormWatch } from 'react-hook-form'

import { ScheduleItem } from './ScheduleItem'

import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form'
import { daysOfWeek } from '../../../../../helpers/time'
import { EventFeesModel } from '../../../../../models/event-fees/event-fees.model'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'
import { IFilters } from '../SchedulingScreenEventSchedulingFormComponent'

interface EntriesSchedulesProps {
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  locationOptions: { label: string; value: string }[]
  control: Control<any>
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  errors: any
  register: UseFormRegister<any>
  trigger: UseFormTrigger<any>
  reset: UseFormReset<any>
  filters: IFilters
  scheduleBy: 'rider' | 'class'
}

const eventFeesFormDefaultValues = new EventFeesModel().toObject()

export const EntriesSchedules: FC<EntriesSchedulesProps> = ({
  handleModal,
  locationOptions,
  control,
  reset,
  trigger,
  register,
  watch,
  setValue,
  errors,
  filters,
  scheduleBy,
}) => {
  const selectedEventR = useAppSelector(selectedEvent)

  const areAllFieldsValid = (index: number) => {
    let tagIsValid = !!watch(`registrationFees.${index}.tag`)
    let locationIsValid = !!watch(`registrationFees.${index}.location`)
    let nameIsValid = !!watch(`registrationFees.${index}.name`)?.trim()
    let noteIsValid = !!watch(`registrationFees.${index}.note`)?.trim()
    let startDateIsValid = moment(watch(`registrationFees.${index}.startDate`)).isValid()
    return nameIsValid && startDateIsValid && tagIsValid && locationIsValid && noteIsValid
  }

  useEffect(() => {
    if (selectedEventR.EventFees) reset(selectedEventR.EventFees)
    else reset(eventFeesFormDefaultValues)
  }, [reset, selectedEventR.EventFees, trigger])

  return (
    <>
      {watch('registrationFees').map((field: any, index: number) =>
        (filters.facility.length <= 0 || filters.facility.includes(field.location)) &&
        (filters.day.length <= 0 ||
          filters.day.includes(daysOfWeek[new Date(field.startDate)?.getDay()])) ? (
          <ScheduleItem
            key={index}
            index={index}
            handleModal={handleModal}
            register={register}
            control={control}
            watch={watch}
            trigger={trigger}
            setValue={setValue}
            errors={errors}
            areAllFieldsValid={areAllFieldsValid}
            locationOptions={locationOptions}
            selector="registrationFees"
            scheduleBy={scheduleBy}
          />
        ) : null
      )}
    </>
  )
}
