import { useContext, useEffect, useState } from 'react'

// Third party
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { components } from 'react-select'

// Component imports
import Accordion from '../../../../components/accordion/common/Accordion'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import CustomReactSelect from '../../../../components/common/select/react-select/CustomReactSelect'
import Tooltip from '../../../../components/common/tooltip/Tooltip'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

// Styles
import { CompetitorReactSelectCustomStyle } from '../../../../customStyles/CompetitorReactSelectCustomStyle'
import './competitorEventRegisterClassesTab.css'

// Redux
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import {
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  selectRegistration,
  selectUserTeamMembersR,
} from '../../../../store/registration/registrationSlice'

// Services
import helpers from '../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { IPaperworkTab_ } from '../../../../models/event-registered-users/event-registered-users.interface'
import { calculateAge } from '../../../../models/interface.helper'

import IUseEventRegistrationTabsTypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { MEMBER_ROLES } from '../../../../const/ui/ui-register.const'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { GuardianBadge } from '../../event-registration-tabs/components/GuardianBadge'
const FILE_NAME = 'CompetitorEventsRegisterPaperworkTab'

// Types
type Props = {
  title?: string
  description?: string
  onAddRiderButtonClick: IUseEventRegistrationTabsTypes['IOnAddRiderButtonClickFn']
  onRemoveRiderTeamMemberClick: IUseEventRegistrationTabsTypes['IOnRemoveRiderTeamMemberClickFn']
  onRiderTeamMemberChange: IUseEventRegistrationTabsTypes['IOnRiderTeamMemberChangeFn']
  onRiderTeamMemberRoleChange: IUseEventRegistrationTabsTypes['IOnRiderTeamMemberRoleChangeFn']
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}

// Constants
const TO_REPLACE = '[NAME]'

const GENERATE_NAME_PIC_URL =
  'https://ui-avatars.com/api/?name=[NAME]&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true'

const IconCard = (props: {
  icon: string
  riderName: string | null
  horseName: string | null
  teamMateId?: string | null
}) => {
  const { icon, riderName, horseName, teamMateId } = props

  return (
    <div className="flex items-center font-normal">
      <div className="mr-2 rounded-full">
        <Link to={`${CONST.ROUTES.USER_PROFILE.URL}/${teamMateId}`} className="cursor-pointer">
          <div className="w-[48px] h-[48px]">
            <img
              src={icon}
              alt="horseIcon"
              className="w-full h-full object-cover rounded-lg shadow-sm"
              onError={(e) =>
                ((e.target as any).src = GENERATE_NAME_PIC_URL.replace(
                  TO_REPLACE,
                  helpers.generateName(riderName ?? '')
                ))
              }
            />
          </div>
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="text-SeabiscuitDark200ThemeColor capitalize text-base">
          {riderName ?? 'Unknown'}
        </div>
        <div className="text-SeabiscuitLightParagraphTextColor text-sm">
          <div className="flex gap-2 items-center ">
            <img src={'assets/og_icons/YearofHorse.svg'} alt="horseIcon" className="w-4" />
            {horseName ?? 'No Horse'}
          </div>
        </div>
      </div>
    </div>
  )
}

const CompetitorEventsRegisterPaperworkTab = ({
  title,
  description,
  onAddRiderButtonClick,
  onRemoveRiderTeamMemberClick,
  onRiderTeamMemberChange,
  onRiderTeamMemberRoleChange,
  isManage,
  manageInfo,
  setManageInfo,
}: Props) => {
  // Hooks and vars
  let isMature = false

  const handleModalContext = useContext(HandleModalContext)
  const toastFunction = useToasterHelper()

  const selectedEvent_ = useAppSelector(selectedEvent)
  const paperworkTabData_ = useAppSelector(selectPaperworkTabData)
  const filteredPaperworksDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const registration = useAppSelector(selectRegistration)

  const [currentPaperworkTabData, setCurrentPaperworkTabData] = useState<IPaperworkTab_[]>([])

  // Functions
  const checkIsMinorFn = (dob?: Date | string | null): boolean => {
    const MINORITY_AGE = 18
    let valueToReturn = false

    if (!dob || dob === '') {
      return valueToReturn
    } else {
      return calculateAge(dob) < MINORITY_AGE
    }
  }

  const onViewDocumentLinkClick = (documentName: string) => {
    const eventDetails = selectedEvent_?.EventDetails

    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      eventDetails,
      handleModal: handleModalContext?.handleModal,
    })

    if (emptyVarName) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      console.error(`${emptyVarName} is ${emptyVarValue}`)
    }

    handleModalContext?.handleModal?.(true, MODAL_CONSTS.SIGN_DOCUMENT, {
      data: {
        documentOriginalName: documentName,
        eventLogo: eventDetails?.eventLogo ?? null,
        eventName: eventDetails?.competitionName ?? '',
        eventId: selectedEvent_?.basicEventDetails?.id ?? eventDetails?.id ?? null,
        registrationDocId: registration?.id,
      },
      type: 'Registration',
    })
  }

  useEffect(() => {
    const paperwork = isManage ? manageInfo.paperwork : paperworkTabData_

    const sortedData = paperwork.map((record) => ({
      ...record,
      ridersTeamMembers: [...record.ridersTeamMembers].sort((a, b) => {
        return a.teamMemberRole === 'Rider' ? -1 : b.teamMemberRole === 'Rider' ? 1 : 0
      }),
    }))

    setCurrentPaperworkTabData(sortedData)
  }, [isManage, manageInfo.paperwork, paperworkTabData_])

  return (
    <CompetitorEventRegisterWrapper title={title} description={description}>
      <div id="rtc">
        {filteredPaperworksDocuments.length > 0 ? (
          <Accordion
            key={`${JSON.stringify(filteredPaperworksDocuments)}`}
            icon={<AddCircleOutline className="add_icon text-[red]" />}
            className="mb-2 !rounded-lg"
            childrenContainerClassName="!pt-0"
            initialState={false}
            onOpen={() => {}}
            onClose={() => {}}
            header={
              <div className={'flex flex-col md:flex-row justify-between gap-3'}>
                <div>
                  Event paperwork{' '}
                  <span className={'text-SeabiscuitDark200ThemeColor/50 lowercase'}>
                    • team members listed below will be sent documents to sign
                  </span>
                </div>
                <div className="flex items-center text-SeabiscuitDark200ThemeColor normal-case !font-normal text-[14px]">
                  View {filteredPaperworksDocuments.length} document
                  {filteredPaperworksDocuments.length > 1 ? 's' : ''}
                </div>
              </div>
            }
            headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
            activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
          >
            {filteredPaperworksDocuments.map((currPaperwork, paperworkIndex) => {
              return (
                <div
                  className="flex-col flex mb-[25px]"
                  key={`${JSON.stringify(currPaperwork)}${paperworkIndex}`}
                >
                  <div className="flex w-full text-SeabiscuitDark200ThemeColor text-base flex-col items-start">
                    <div className="flex items-center">
                      <span className="w-[50px] h-[50px] overflow-hidden rounded-full mr-6 flex items-center">
                        <img
                          src={currPaperwork.document_logo}
                          className="object-cover w-full h-full"
                          alt="logo"
                        />
                      </span>
                      <div className="flex flex-col">
                        <div className="capitalize mb-[3px]">{currPaperwork.document_name}</div>
                        <Link
                          to={`#`}
                          role="button"
                          onClick={() => onViewDocumentLinkClick(currPaperwork.document_name)}
                          className="cursor-pointer text-sm md:text-nr text-SeabiscuitLightParagraphTextColor"
                        >
                          {`View document >`}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Accordion>
        ) : null}

        {/* Render when the organizer do not add any paperwork in the event */}
        {filteredPaperworksDocuments.length <= 0 ? (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text="No Paperworks have been added to this event"
          />
        ) : currentPaperworkTabData.length ? (
          <>
            <hr className="mb-2 mt-5"></hr>
            <div className="my-6">
              <div className="text-SeabiscuitDark200ThemeColor font-bold text-lg">Entries</div>
              <div className="text-nr text-SeabiscuitLightParagraphTextColor">
                Add team members and select roles
              </div>
            </div>
          </>
        ) : null}

        {/*
            Paperwork's are added by organizer in the event,
            but the competitor has not selected the members or horses against them
        */}
        {filteredPaperworksDocuments.length > 0 && !currentPaperworkTabData.length ? (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text=" Please register a user in this event"
          />
        ) : null}

        {filteredPaperworksDocuments.length > 0 && currentPaperworkTabData.length
          ? currentPaperworkTabData.map((currentPaperworkRow, index) => {
              isMature = !checkIsMinorFn(currentPaperworkRow.riderDob)

              const guardian = currentPaperworkRow.ridersTeamMembers.find(
                (member) => member.teamMemberRole === MEMBER_ROLES.GUARDIAN
              )
              const isGuardianIsMature = guardian ? checkIsMinorFn(guardian.teamMemberDob) : false

              return (
                <Accordion
                  key={`${JSON.stringify(currentPaperworkRow)}${index}`}
                  icon={<AddCircleOutline className="add_icon text-[red]" />}
                  className="mb-2 !rounded-lg"
                  childrenContainerClassName="!pt-0"
                  header={
                    <div
                      className={`text-SeabiscuitDark200ThemeColor flex flex-col md:flex-row justify-start md:justify-between md:items-center`}
                    >
                      <div
                        className={'flex flex-wrap items-start flex-col-reverse md:flex-row gap-3'}
                      >
                        <IconCard
                          horseName={currentPaperworkRow?.horseName ?? null}
                          icon={currentPaperworkRow.riderProfilePicture ?? ''}
                          riderName={currentPaperworkRow?.riderName ?? null}
                          teamMateId={currentPaperworkRow.riderId ?? null}
                        />
                        {!isMature && (
                          <>
                            <GuardianBadge
                              hasGuardian={!!guardian}
                              isLabel
                              classes={'mt-1'}
                              isGuardianIsMinor={isGuardianIsMature}
                            />
                          </>
                        )}
                      </div>

                      <div className="flex items-center details mt-2 md:mt-0">
                        <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor whitespace-nowrap lowercase">
                          {currentPaperworkRow.ridersTeamMembers.length ?? 0} team member
                          {currentPaperworkRow.ridersTeamMembers.length > 1 ? 's' : ''}
                        </div>
                      </div>
                    </div>
                  }
                  footer={
                    !isMature && (
                      <GuardianBadge
                        hasGuardian={!!guardian}
                        isGuardianIsMinor={isGuardianIsMature}
                      />
                    )
                  }
                  headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
                  activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
                >
                  <div id={`container${index}`}>
                    {currentPaperworkRow.ridersTeamMembers.map(
                      (currRidersTeamMember, ridersTeamMemberIndex) => {
                        isMature = !checkIsMinorFn(currRidersTeamMember.teamMemberDob)

                        return (
                          <div
                            key={`container${ridersTeamMemberIndex}${index}`}
                            className={clsx(
                              'flex flex-col lg:flex-row transition-all items-center relative pr-8',
                              ridersTeamMemberIndex <
                                currentPaperworkRow.ridersTeamMembers.length - 1 && 'mb-2'
                            )}
                            title={
                              !isMature
                                ? "The team member is either minor or he/she haven't set his/her dob"
                                : ''
                            }
                          >
                            <CustomReactSelect
                              targetId={`container${index}`}
                              isMulti={false}
                              options={teamMembersWithUser.map((teamMember) => ({
                                label: teamMember.memberName ?? '',
                                value: teamMember.memberId ?? '',
                              }))}
                              menuShouldScrollIntoView={true}
                              styles={CompetitorReactSelectCustomStyle}
                              className={clsx(
                                'w-full rounded-xl p-1 border focus:ring-0 focus:outline-0 flex-grow lg:mr-2',
                                currRidersTeamMember.teamMemberName
                                  ? 'bg-[#F6F7FB] border-transparent'
                                  : 'border-SeabiscuitLightThemeColorD3'
                              )}
                              value={{
                                label: currRidersTeamMember.teamMemberName ?? '',
                                value: currRidersTeamMember.teamMemberId ?? '',
                              }}
                              isDisabled={
                                currRidersTeamMember.teamMemberRole ===
                                CONST.UI.TEAM_MEMBERS_ROLES.RIDER
                              }
                              placeholder={
                                <div className="flex text-SeabiscuitDark200ThemeColor/50">
                                  Select...
                                </div>
                              }
                              onChange={(option: any) => {
                                const filtered = teamMembersWithUser.filter(
                                  (member) => member.memberId === option.value
                                )
                                if (filtered.length > 0)
                                  onRiderTeamMemberChange({
                                    paperworkIndex: index,
                                    currentPaperworkTabData: currentPaperworkTabData,
                                    currentTeamMember: filtered[0],
                                    ridersTeamMemberIndex: ridersTeamMemberIndex,
                                    manageInfo,
                                    setManageInfo,
                                    isManage,
                                  })
                              }}
                              isClearable={false}
                              isSearchable={true}
                            />

                            <CustomReactSelect
                              targetId={`container${index}`}
                              isMulti={false}
                              options={CONST.UI.ROLES.filter(
                                (role) => role.value !== CONST.UI.TEAM_MEMBERS_ROLES.RIDER
                              )}
                              menuShouldScrollIntoView={true}
                              styles={CompetitorReactSelectCustomStyle}
                              value={
                                CONST.UI.ROLES.filter((current) => {
                                  return current.label === currRidersTeamMember.teamMemberRole
                                })[0] ?? ''
                              }
                              isDisabled={
                                currRidersTeamMember.teamMemberRole ===
                                CONST.UI.TEAM_MEMBERS_ROLES.RIDER
                              }
                              components={{
                                DropdownIndicator: (props) => {
                                  if (
                                    currRidersTeamMember.teamMemberRole ===
                                    CONST.UI.TEAM_MEMBERS_ROLES.RIDER
                                  )
                                    return null
                                  return <components.DropdownIndicator {...props} />
                                },
                              }}
                              placeholder={
                                <div className="flex text-SeabiscuitDark200ThemeColor/50">
                                  Select role...
                                </div>
                              }
                              onChange={async (e: any) => {
                                await onRiderTeamMemberRoleChange({
                                  paperworkIndex: index,
                                  currentPaperworkTabData: currentPaperworkTabData,
                                  teamMemberId: currRidersTeamMember.teamMemberId,
                                  teamMemberRole: e.value,
                                  ridersTeamMemberIndex: ridersTeamMemberIndex,
                                  manageInfo,
                                  setManageInfo,
                                  isManage,
                                })
                              }}
                              className={clsx(
                                'rounded-xl p-1 focus:ring-0 focus:outline-0 border w-full lg:w-[250px] mt-2 lg:mt-0',
                                currRidersTeamMember.teamMemberRole
                                  ? 'bg-SeabiscuitGrayThemeColor border-transparent'
                                  : 'border-SeabiscuitLightThemeColorD3'
                              )}
                              isClearable={false}
                              isSearchable={true}
                            />

                            {currRidersTeamMember.teamMemberRole !==
                              CONST.UI.TEAM_MEMBERS_ROLES.RIDER && (
                              <span
                                className="flex items-center justify-center h-full text-SeabiscuitMainThemeColor cursor-pointer absolute right-0"
                                role="button"
                                onClick={() => {
                                  handleModalContext?.handleModal?.(
                                    true,
                                    MODAL_CONSTS.REMOVE_CONFIRMATION,
                                    {
                                      removeItem: () =>
                                        onRemoveRiderTeamMemberClick({
                                          paperworkIndex: index,
                                          currentPaperworkTabData: currentPaperworkTabData,
                                          teamMemberIndex: ridersTeamMemberIndex,
                                          manageInfo,
                                          setManageInfo,
                                          isManage,
                                        }),
                                      alertText: (
                                        <div>
                                          <h5 className="font-normal text-xl text-SeabiscuitDark200ThemeColor">
                                            Are you sure you want to remove this team member?
                                          </h5>
                                        </div>
                                      ),
                                    }
                                  )
                                }}
                              >
                                <img src={'assets/og_icons/Cancel.svg'} alt="delete" />
                              </span>
                            )}
                          </div>
                        )
                      }
                    )}
                  </div>

                  <div className="w-full flex lg:justify-end mt-4 pe-16">
                    <Tooltip
                      label="Please fill all the fields to add more"
                      alignment="top"
                      arrow
                      hideTooltip={!currentPaperworkRow.disableAddButton}
                    >
                      <button
                        className={
                          'text-[14px] text-SeabiscuitBlackThemeColor/50 underline transition-all hover:text-SeabiscuitBlackThemeColor hover:no-underline'
                        }
                        onClick={() => {
                          if (!currentPaperworkRow.disableAddButton)
                            onAddRiderButtonClick({
                              paperworkIndex: index,
                              row: currentPaperworkRow,
                              currentPaperworkTabData: currentPaperworkTabData,
                              manageInfo,
                              setManageInfo,
                              isManage,
                            })
                        }}
                      >
                        <span>Add team member</span>
                      </button>
                    </Tooltip>
                  </div>
                </Accordion>
              )
            })
          : null}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventsRegisterPaperworkTab
