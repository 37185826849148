import React, { FC, MouseEvent, useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

// Third party
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

// Constants
import { CONST } from '../../../../const/const'

// Types
import { IEventInterface } from '../../../../models/events/event.interface'

// Styles
import './EventOrganizationHorizontalCardComponent.css'
import 'swiper/css'
import 'swiper/css/lazy'

import customImageComponent from '../../../common/CustomImageComponent'
import { ModelBaseModel } from '../../../../models/model-base/model-base.model'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { IconDuplicate } from '../../../icons/IconDuplicate'
import Tooltip from '@mui/material/Tooltip'
import { httpService } from '../../../../services/httpService'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { EventModel } from '../../../../models/events/event.model'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { useHistory } from 'react-router'
import ViewsLoader from '../../../loader/ViewsLoader'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { getFloatPrice } from '../../../../helpers/price'

// Constants
const DUMMY_USER_IMAGE = 'assets/img/Empty user.png'
const DEFAULT_CARD_IMAGE_URL = 'assets/img/Empty Event Portrait.png'
const ORGANIZER_EDIT_EVENT_URL = '/organizer-edit/'

const ORGANIZER_MANAGE_EVENT_URL = CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL + '/'

interface ExtendedSwiper extends SwiperCore {
  swiper?: any
}

export interface EventOrganizationHorizontalCardComponentProps {
  event: IEventInterface
  registeredRiders: IRegistrationByDayInterface[]
  registeredUsersImages: string[]
}

interface IImagesArr {
  src: string
  alt: string
  key: string
  className?: string
}

const imagesArr: IImagesArr[] = [
  {
    src: '/assets/og_icons/Prize-1.svg',
    alt: 'Registration',
    key: 'registration',
    className: '!mr-1',
  },
  { src: '/assets/og_icons/Two Tickets-1.svg', alt: 'Ineligible', key: 'ineligible' },
  { src: '/assets/og_icons/Medal-1.svg', alt: 'Messages', key: 'messages' },
  { src: '/assets/cp_icons/DepartmentShop-1.svg', alt: 'Sales', key: 'sales' },
]

const getEventDetails = (event: IEventInterface) => {
  const getVal = (curr: IImagesArr) => {
    let strToReturn = ''
    let registrationsRevenue = new ModelBaseModel().getNum(event.totalRegistrationRevenue)
    switch (curr.key) {
      case 'sales':
        strToReturn = 'Vendor sale coming soon '
        break
      case 'ineligible':
        strToReturn = `${getFloatPrice(`${event.currentTicketRevenue || '$0'}`)} ticket sales`
        break
      case 'messages':
        strToReturn = 'Sponsor sale coming soon'
        break
      case 'registration':
        strToReturn = `${getFloatPrice(`${registrationsRevenue}`)} registration sales`
        break
    }

    return strToReturn
  }

  return imagesArr.map((curr) => (
    <div className={'flex items-center mb-1'} key={curr.src}>
      <img
        src={curr?.src}
        alt={curr?.alt}
        className={clsx('object-cover mr-2', curr.className)}
        height="18px"
        width={curr.key === 'registration' ? '21px' : '18px'}
      />
      <span className="capitalize text-SeabiscuitDark200ThemeColor text-[12px]">
        {getVal(curr)}
      </span>
    </div>
  ))
}

const EventOrganizationHorizontalCardComponent: FC<
  EventOrganizationHorizontalCardComponentProps
> = ({ event, registeredUsersImages, registeredRiders }) => {
  const handleModalContext = useContext(HandleModalContext)

  const swiper = useRef<ExtendedSwiper>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [duplicateLoading, setDuplicateLoading] = useState(false)

  const toastMethods = useToasterHelper()
  const history = useHistory()
  const disablePrev = currentIndex === 0
  const disableNext = swiper.current?.swiper?.isEnd || !event.eventImages?.length

  const goNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slideNext()
    }
  }

  const goPrev = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slidePrev()
    }
  }

  const customNavigation = (length: number) => {
    if (length > 1) {
      return (
        <div className={clsx(`flex flex-row  absolute z-10 bottom-[10px]`)}>
          {[...Array(length <= 6 ? length : 6)].map((data, currDotIndex) => {
            return (
              <span
                key={`${JSON.stringify(data)}${currDotIndex}`}
                className={` rounded ${currDotIndex <= currentIndex ? `bg-[white]` : `bg-[white]`} p-[4px] mx-[2px] ${currDotIndex > currentIndex ? `opacity-[0.4]` : ''}`}
              >
                {data}
              </span>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }

  const location = useLocation()
  const pathSegments = location.pathname.split('/')
  const lastSegment = pathSegments[pathSegments.length - 1]

  let linkTo: string

  if (lastSegment === 'draft') {
    linkTo = ORGANIZER_EDIT_EVENT_URL
  } else {
    linkTo = ORGANIZER_MANAGE_EVENT_URL
  }

  const duplicateEvent = async (eventId: string) => {
    if (duplicateLoading) return null
    setDuplicateLoading(true)
    try {
      const res: any = await httpService({
        url: `replicate_event/${eventId}`,
        method: 'POST',
      })

      if (!res.status) throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      let event = EventModel.fromObject(res.data)
      toastMethods.success({
        message: `${MESSAGES_CONST.EVENT_DUPLICATED} with name ${event?.eventName}`,
      })

      history.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${event.id}`)
    } catch (error) {
      console.error(error, 'error')
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setDuplicateLoading(false)
    }
  }

  return (
    <div className="event-post-horizontal-card-component transition-shadow duration-300 ease-in-out relative mb-4 h-full w-auto">
      <div className={'relative'}>
        <Link to={`${linkTo}${event.id}`}>
          <Swiper
            lazy={{ enabled: true, loadPrevNext: true, loadPrevNextAmount: 1 }}
            ref={swiper as any}
            modules={[Navigation, Pagination, Scrollbar, A11y, Lazy]}
            className="mb-4 group rounded-xl w-full aspect-square h-[296px] overflow-hidden"
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onActiveIndexChange={(activeIndex_) => {
              setCurrentIndex(activeIndex_.activeIndex)
            }}
          >
            {event.eventImages?.length && typeof event.eventImages === 'object' ? (
              event.eventImages.map((currImage, index) => {
                return (
                  <SwiperSlide key={`${currImage}${index}`} className="rounded-xl overflow-hidden">
                    {currentIndex === index && customNavigation(Number(event.eventImages?.length))}
                    {customImageComponent(
                      currImage ?? DEFAULT_CARD_IMAGE_URL,
                      event.eventName,
                      'event-post-horizontal-card-component__image object-cover cursor-pointer swiper-lazy w-full !h-full sliderImage',
                      true
                    )}
                    <div className="swiper-lazy-preloader-wrapper rounded-xl border border-SeabiscuitMainThemeColor/20 absolute z-10 left-0 top-0 h-full w-full bg-white">
                      <div className="swiper-lazy-preloader border-r-SeabiscuitMainThemeColor border-l-SeabiscuitMainThemeColor border-b-SeabiscuitMainThemeColor"></div>
                    </div>
                  </SwiperSlide>
                )
              })
            ) : (
              <SwiperSlide className="rounded-xl overflow-hidden">
                {customImageComponent(
                  event.eventCardCoverImageUrl
                    ? event.eventCardCoverImageUrl
                    : DEFAULT_CARD_IMAGE_URL,
                  event.eventName,
                  'event-post-horizontal-card-component__image cursor-pointer swiper-lazy w-full !h-full',
                  true
                )}
                <div className="swiper-lazy-preloader-wrapper rounded-xl border border-SeabiscuitMainThemeColor/20 absolute z-10 left-0 top-0 h-full w-full bg-white">
                  <div className="swiper-lazy-preloader border-r-SeabiscuitMainThemeColor border-l-SeabiscuitMainThemeColor border-b-SeabiscuitMainThemeColor"></div>
                </div>
              </SwiperSlide>
            )}

            {!disablePrev ? (
              <button
                onClick={goPrev}
                className="absolute z-[1] group-hover:visible invisible top-[calc(50%-16px)] transition py-[2px] px-1 ease-in left-3  rounded-full"
              >
                <img src="/assets/next_swiper.svg" alt="next" className="h-[40px]" />
              </button>
            ) : null}

            {!disableNext ? (
              <button
                onClick={goNext}
                className="absolute z-[1] group-hover:visible invisible top-[calc(50%-16px)] transition py-[2px] px-1 ease-in right-3 rounded-full"
              >
                <img src="/assets/prev_swiper.svg" alt="next" height={50} className="h-[40px]" />
              </button>
            ) : null}
          </Swiper>

          {event.status ===
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL ? (
            <div className="event-post-horizontal-card-component__bookmark-label !w-fit flex items-center content-center z-50 text-white bg-[#FC2804] px-3 rounded-lg text-nr py-4 capitalize !left-4">
              Cancelled
            </div>
          ) : (
            <div className="event-post-horizontal-card-component__date-label z-50">
              <div className="event-post-horizontal-card-component__upper-date-day__text">
                {new Date(event.eventStartDate ?? '').getDate()}
              </div>
              <div className="event-post-horizontal-card-component__upper-date-month__text">
                {new Date(event.eventStartDate ?? '').toLocaleString('default', { month: 'short' })}
              </div>
            </div>
          )}
          <div className="event-post-details">
            <div className="event-post-horizontal-card-component__card__title w-full overflow-hidden mb-4 text-SeabiscuitDark200ThemeColor cursor-pointer capitalize">
              {event.eventName}
            </div>
            {/* EVENT DETAILS */}
            {getEventDetails(event)}
            <div className="event-post-horizontal-card-component__card__registration__counter items-center flex pt-2">
              <div className="flex min-w-[56px] -space-x-2 relative z-0 overflow-hidden">
                {registeredUsersImages?.map((currUserImage, index) => {
                  return (
                    <img
                      style={{
                        zIndex: `${registeredUsersImages.length - index}`,
                      }}
                      alt={'UI'}
                      onError={(e) => ((e.target as any).src = DUMMY_USER_IMAGE)}
                      src={currUserImage}
                      key={index}
                      className={clsx(
                        `relative inline-block object-cover h-[24px] w-[24px] rounded-full  bg-white`
                      )}
                    />
                  )
                })}
              </div>
              <div className="text-sm text-SeabiscuitDark200ThemeColor pl-2">
                <span className="text-SeabiscuitMainThemeColor font-semibold pr-1">
                  {registeredRiders.length} Entries,
                </span>
                0 on waitlist
              </div>
            </div>
          </div>
        </Link>
        <Tooltip title={'Duplicate Event'} placement="top" arrow>
          <button
            onClick={() => {
              handleModalContext?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                removeItem: () => event?.id && duplicateEvent(event.id),
                alertText: (
                  <div>
                    <h5 className="font-semibold text-xl">
                      Are you sure you want to duplicate "{event.eventName}" event?
                    </h5>
                    <p className="text-sm mt-2">
                      When you click ‘duplicate’, you are creating a new event with the same
                      details.
                    </p>
                  </div>
                ),
                submitButtonName: 'Yes',
              })
            }}
            disabled={duplicateLoading}
            className={clsx(
              'absolute top-[16px] right-[16px] w-[45px] h-[45px] flex rounded-[7px] bg-black/20  z-50 items-center justify-center group hover:bg-white',
              { '!bg-white': duplicateLoading }
            )}
          >
            {duplicateLoading ? (
              <ViewsLoader />
            ) : (
              <IconDuplicate classes={'group-hover:fill-black'} />
            )}
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default EventOrganizationHorizontalCardComponent
