import { arrayMove } from '@dnd-kit/sortable'
import type { DragEndEvent } from '@dnd-kit/core'

import FirestoreService from '../../../../../../../../services/firestoreService'
import { sortByOrder } from '../../../data/operations-tab-utils'

import {
  IBreak,
  IBreakRegistrationByDay,
} from '../../../../../../../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../../../../models/registrations-by-day/registrationByDay.model'

import { CONST } from '../../../../../../../../const/const'

export const onScratchBreak = async ({
  registrationsToDisplay,
  row,
}: {
  registrationsToDisplay: IBreakRegistrationByDay[]
  row: IBreakRegistrationByDay
}) => {
  let newData: IBreakRegistrationByDay[] = []
  const breaks: IBreak[] = []

  registrationsToDisplay.forEach((itm, index) => {
    if (itm.id !== row.id) {
      if (itm.isBreakDrag) breaks.push(itm as IBreak)
      newData.push({ ...(itm as IBreakRegistrationByDay), order: index + 1 })
    }
  })

  return { newData, breaks }
}

export const onScratch = async ({
  registrationsToDisplay,
  row,
  state,
  registrationsByDay,
  onUpdate,
}: {
  registrationsToDisplay: IBreakRegistrationByDay[]
  row: IBreakRegistrationByDay
  state: boolean
  registrationsByDay: IRegistrationByDayInterface[] | null
  onUpdate: (
    newData: IBreakRegistrationByDay[],
    breaks: IBreak[],
    newRegistrationsByDay: IRegistrationByDayInterface[]
  ) => Promise<void>
}) => {
  let newData = registrationsToDisplay.map((registration) =>
    registration.id === row.id ? { ...registration, orderOfGoScratched: state } : registration
  )
  const breaks: IBreak[] = []
  const riders: IRegistrationByDayInterface[] = []

  let count = 0
  newData = newData.map((registration) => {
    if (registration.orderOfGoScratched) {
      return { ...registration, order: 0 }
    } else {
      count++
      return { ...registration, order: count }
    }
  })

  newData.forEach((item) => {
    if (item.isBreakDrag && !item.orderOfGoScratched) {
      breaks.push(item as IBreak)
    }
    if (!item.isBreakDrag) {
      riders.push(item as IRegistrationByDayInterface)
    }
  })

  newData = sortByOrder(newData)

  const newRegistrationsByDay =
    registrationsByDay?.map((registrationByDay) => {
      const newRegistrationByDay = newData.find((current) => current.id === registrationByDay.id)
      if (newRegistrationByDay) {
        return newRegistrationByDay as IRegistrationByDayInterface
      } else {
        return registrationByDay as IRegistrationByDayInterface
      }
    }) ?? []

  await onUpdate(newData, breaks, newRegistrationsByDay)

  const updatedPromises = riders.map(async (item) => {
    const newData = new RegistrationByDayModel(item)

    return FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      newData.id,
      newData.toFirestore()
    )
  })

  await Promise.all(updatedPromises)
}

export async function handleDragEnd({
  event,
  registrationsToDisplay,
  registrationsByDay,
  onUpdate,
}: {
  event: DragEndEvent
  registrationsToDisplay: IBreakRegistrationByDay[]
  registrationsByDay: IRegistrationByDayInterface[] | null
  onUpdate: (
    newData: IBreakRegistrationByDay[],
    breaks: IBreak[],
    newRegistrationsByDay: IRegistrationByDayInterface[]
  ) => Promise<void>
}) {
  const { active, over } = event
  let newArray: IBreakRegistrationByDay[] = []

  if (active && over && active.id !== over.id) {
    const oldIndex = registrationsToDisplay?.map(({ id }) => id).indexOf(String(active.id))
    const newIndex = registrationsToDisplay?.map(({ id }) => id).indexOf(String(over.id))
    newArray = arrayMove(registrationsToDisplay, oldIndex, newIndex)

    let count = 0
    newArray = newArray.map((item) => {
      if (item.orderOfGoScratched) {
        return { ...item, order: 0 }
      } else {
        count++
        return { ...item, order: count }
      }
    })

    if (oldIndex !== -1 && newIndex !== -1) {
      const oldItem = newArray[oldIndex]
      const newItem = newArray[newIndex]

      //  Swap rideTime only between valid registrations (not breaks)
      if (!oldItem.isBreakDrag && !newItem.isBreakDrag) {
        let tempRideTime = oldItem.rideTime
        newArray[oldIndex].rideTime = newItem.rideTime
        newArray[newIndex].rideTime = tempRideTime
      }
    }

    const breaks: IBreak[] = []
    const riders: IRegistrationByDayInterface[] = []

    newArray.forEach((item) => {
      if (item.isBreakDrag) {
        breaks.push(item as IBreak)
      }
      if (!item.isBreakDrag) {
        riders.push(item as IRegistrationByDayInterface)
      }
    })

    const newRegistrationsByDay =
      registrationsByDay?.map((registrationByDay) => {
        const newRegistrationByDay = newArray.find((current) => current.id === registrationByDay.id)
        if (newRegistrationByDay) {
          return newRegistrationByDay as IRegistrationByDayInterface
        } else {
          return registrationByDay as IRegistrationByDayInterface
        }
      }) ?? []

    await onUpdate(newArray, breaks, newRegistrationsByDay)

    const updatedPromises = riders.map(async (item) => {
      const newData = new RegistrationByDayModel(item)

      return FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        newData.id,
        newData.toFirestore()
      )
    })

    await Promise.all(updatedPromises)
  }
}
