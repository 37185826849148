import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import React, { FC } from 'react'

import InfiniteScrollDataTable from '../../../../../../../components/common/tables/InfiniteScrollDataTable'

import { IBreakRegistrationByDay } from '../../../../../../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationByDayEx } from '../../ManageClinicNOtherOperationsTab'

import { generateName } from '../../../../../../../helpers/helpers'
import { useEventTrainers } from '../../../../../../../hooks/useEventTrainers'

import FirestoreService from '../../../../../../../services/firestoreService'

import { CONST } from '../../../../../../../const/const'
import { IMAGE_CONSTS } from '../../../../../../../const/image-const'
import { DUMMY_REGISTRATION_FEES_STATS } from '../../data/operations-tab-utils'
import { getFloatPrice } from '../../../../../../../helpers/price'

interface PrizeMoneyTableProps {
  loading: boolean
  eventId: string
  registrationsByDayByClass: IBreakRegistrationByDay[]
  registrationsByDay: IRegistrationByDayInterface[] | null
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  registrationFees: any
}

export const PrizeMoneyTable: FC<PrizeMoneyTableProps> = ({
  loading,
  registrationsByDayByClass,
  registrationsByDay,
  setRegistrationsByDay,
  registrationFees,
  eventId,
}) => {
  const { eventTrainers } = useEventTrainers(eventId)

  const onChangeEarning = async (event: any, row: IRegistrationByDayEx) => {
    const newRow = {
      ...row,
      score: {
        ...row.score,
        earning: event.target.value,
        sheet: row.score?.sheet ?? null,
        judges: row.score?.judges ?? [],
      },
    }

    let registrationsByDay_ =
      registrationsByDay?.map((registration) => {
        if (registration.id === newRow.id) {
          return newRow
        } else {
          return registration
        }
      }) ?? []

    setRegistrationsByDay([...registrationsByDay_])

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      newRow.id,
      newRow
    )
  }

  const columnsPrizeMoney = [
    {
      name: (
        <div className="w-full text-gray-400 font-normal text-[0.875rem] flex items-center">
          <span className="whitespace-nowrap">Rank</span>
        </div>
      ),
      cell: (row: IRegistrationByDayEx) => (
        <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
          {row.loading ? (
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <div
              className={clsx(
                'rounded-full text-base flex items-center justify-center w-10 h-10',
                row.score?.rank === 1 && Number(row.score?.totalPoints)
                  ? 'bg-SeabiscuitGreenThemeColor text-white'
                  : row.score?.rank === 2 && Number(row.score?.totalPoints)
                    ? 'bg-[#FFBD00] text-white'
                    : row.score?.rank === 3 && Number(row.score?.totalPoints)
                      ? 'bg-[#3B6AF6] text-white'
                      : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
              )}
            >
              {row.score?.rank ? row.score.rank : 0}
            </div>
          )}
        </div>
      ),
      width: '10%',
    },
    {
      name: (
        <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
          <span className="whitespace-nowrap">Rider & Score</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx) => (
        <div className="flex items-center gap-4">
          <span className="w-11 h-11 rounded-full block shrink-0 my-2 overflow-hidden">
            {row.loading ? (
              <Skeleton
                variant="circular"
                width={44}
                height={44}
                className="mb-2"
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <img
                src={row.riderProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                alt="icons"
                className="object-cover w-full h-full rounded-full"
                onError={(e) =>
                  ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                    row.riderName ?? ''
                  )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            )}
          </span>

          {row.loading ? (
            <Skeleton
              variant="rounded"
              width={100}
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <div className="flex flex-col">
              <span className="text-SeabiscuitDark200ThemeColor text-base">{row?.riderName}</span>
              <span className="text-SeabiscuitDark200ThemeColor/60 text-nr">
                {Number(row.score?.totalPoints) > 0 ? row.score?.totalPoints : 'No score'}
              </span>
            </div>
          )}
        </div>
      ),
      width: '30%',
    },
    {
      name: (
        <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
          <span className="whitespace-nowrap">Horse & Trainer</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx) => {
        const trainer = eventTrainers?.find(
          (eventTrainer) => eventTrainer.registrationByDayUniqId === row.uniqId
        )
        return (
          <div className="flex items-center gap-4">
            <span className="w-11 h-11 rounded-full block shrink-0 my-2 overflow-hidden">
              {row.loading ? (
                <Skeleton
                  variant="circular"
                  width={44}
                  height={44}
                  className="mb-2"
                  style={{ backgroundColor: '#F1F3F8' }}
                />
              ) : (
                <img
                  src={row.horseProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                  alt="icons"
                  className="object-cover w-full h-full rounded-full"
                  onError={(e) =>
                    ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                      row.horseName ?? ''
                    )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                  }
                />
              )}
            </span>

            {row.loading ? (
              <Skeleton
                variant="rounded"
                width={100}
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <div className="flex flex-col">
                <span className="text-SeabiscuitDark200ThemeColor  text-base">
                  {row?.horseName ?? 'No Horse'}
                </span>
                <span className="text-SeabiscuitDark200ThemeColor/60 text-nr flex items-center">
                  {trainer ? (
                    <span>{trainer.teamMemberName}</span>
                  ) : (
                    <span className=" inline-flex items-center text-SeabiscuitDark200ThemeColor/60">
                      No trainer
                    </span>
                  )}
                </span>
              </div>
            )}
          </div>
        )
      },
      width: '30%',
    },
    {
      name: (
        <span className="text-gray-400  text-[0.875rem] font-normal flex items-center justify-center w-full">
          <span className="whitespace-nowrap">Earning %</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx) => (
        <div className="justify-center w-full flex">
          {row.loading ? (
            <Skeleton
              variant="rounded"
              width={80}
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <input
              type="number"
              className={clsx(
                'p-1.5 rounded-full w-[60px] border-0 outline-0 text-base text-center hover:opacity-70 transition-all cursor-pointer placeholder:text-base',
                row.score?.rank === 1 && Number(row.score?.totalPoints)
                  ? 'bg-SeabiscuitGreenThemeColor/10 text-SeabiscuitGreenThemeColor'
                  : row.score?.rank === 2 && Number(row.score?.totalPoints)
                    ? 'bg-[#F8DA78]/40 text-[#CE9903]'
                    : row.score?.rank === 3 && Number(row.score?.totalPoints)
                      ? 'bg-[#3B6AF6]/10 text-[#3B6AF6]'
                      : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
              )}
              placeholder="0%"
              defaultValue={row.score?.earning ?? ''}
              onBlur={(event) => onChangeEarning(event, row)}
            />
          )}
        </div>
      ),
      width: '15%',
    },
    {
      name: (
        <span className="text-gray-400  text-[0.875rem] font-normal flex items-center justify-center w-full">
          <span className="whitespace-nowrap">Prize Money</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx) => {
        const registrationFee = [...(registrationFees?.registrationFees ?? [])]?.find(
          (registrationFee) => registrationFee?.uuid === row.uuid
        )

        return (
          <div className="justify-center w-full flex">
            {row.loading ? (
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : registrationFee?.prizeMoney && row.score?.earning ? (
              <span
                className={clsx(
                  'bg-SeabiscuitGrayThemeColor p-2 rounded-full min-w-[100px] text-center',
                  row.score?.rank === 1 &&
                    Number(row.score?.totalPoints) &&
                    'font-bold bg-SeabiscuitGreenThemeColor text-white',
                  row.score?.rank === 2 &&
                    Number(row.score?.totalPoints) &&
                    'font-bold bg-[#FFBD00] text-white',
                  row.score?.rank === 3 &&
                    Number(row.score?.totalPoints) &&
                    'font-bold bg-[#3B6AF6] text-white'
                )}
              >
                {getFloatPrice(
                  `${(Number(registrationFee.prizeMoney) * Number(row.score.earning)) / 100})`
                )}
              </span>
            ) : (
              <span className="text-SeabiscuitDark200ThemeColor/60 text-base font-normal">$0</span>
            )}
          </div>
        )
      },
      width: '15%',
    },
  ]

  return (
    <InfiniteScrollDataTable
      hasMore={false}
      className="exhibitorListTable"
      columns={columnsPrizeMoney}
      data={loading ? DUMMY_REGISTRATION_FEES_STATS : registrationsByDayByClass}
    />
  )
}
