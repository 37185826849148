import { InfoOutlined as TooltipIcon } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import React, { FC, useEffect, useState } from 'react'

import {
  FieldErrors,
  UseFieldArrayReturn,
  UseFieldArrayUpdate,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'

import MessageHelperComp from '../../../../../helpers/MessageHelper'
import Input from '../../../../common/inputs/Input'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

import { AddCircleOutline } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'
import { clearAllArr } from '../../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { useDebounceWithSetter } from '../../../../../hooks/useDebounceWithSetter'
import { IEventScheduleInterface } from '../../../../../models/event-scheduling/event-scheduling.interface'
import Accordion from '../../../../accordion/common/Accordion'
import TooltipTextToggle from '../../../../elements/toggles/toggles/TooltipTextToggle'

interface FacilitiesProps {
  watch: UseFormWatch<IEventScheduleInterface>
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  facilities: UseFieldArrayReturn<IEventScheduleInterface, 'facilities'>
  update: UseFieldArrayUpdate<any, 'custom'>
  fields: Record<'id', string>[]
  setLocationOptions: (value: { label: string; value: string }[]) => void
  errors: FieldErrors<IEventScheduleInterface>
  setValue: UseFormSetValue<IEventScheduleInterface>
  register: UseFormRegister<IEventScheduleInterface>
  handleSaveScheduleBy: (scheduleBy: 'class' | 'rider') => Promise<void>
}
export const Facilities: FC<FacilitiesProps> = ({
  watch,
  handleModal,
  facilities,
  update,
  fields,
  setLocationOptions,
  errors,
  setValue,
  register,
  handleSaveScheduleBy,
}) => {
  const [listingRemove, setListingRemove] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState(true)
  const toastFunctions = useToasterHelper()
  const scheduleBy = watch('scheduleBy')

  const handleToggleScheduleBy = async () => {
    const newScheduleBy = scheduleBy === 'rider' ? 'class' : 'rider'
    setValue('scheduleBy', newScheduleBy)

    try {
      await handleSaveScheduleBy(newScheduleBy)
    } catch (error) {
      //roll back the form value to previous state on failure
      setValue('scheduleBy', scheduleBy)
      toastFunctions.error({
        message: 'Failed to update schedule type. Please try again.',
      })
    }
  }
  const removeFacility = (index: number) => {
    handleModal(true, MODAL_CONSTS.CONFIRM_REMOVE_MODAL, {
      confirmDelete: () => {
        let value = facilities.fields[index]
        let indexsOfCustomToUpdate: number[] = []

        fields.forEach((curr, index) => {
          if ((curr as any)?.location === (value as any)?.facility)
            indexsOfCustomToUpdate.push(index)
        })

        if (indexsOfCustomToUpdate.length) {
          indexsOfCustomToUpdate.forEach((i) => {
            update(i, {
              ...fields[i],
              location: null,
            })
          })
        }
        facilities.remove(index)
        setListingRemove(!listingRemove)
        handleModal(false, MODAL_CONSTS.CONFIRM_REMOVE_MODAL)
      },
      removeCardId: index,
    })
  }

  const handleInputChange = (e: any, index: any) => {
    // Call your debounceSetter function here
    debounceSetter({ index, value: e.target.value })
  }

  const { debounceSetter, debouncedValue } = useDebounceWithSetter({
    delay: 300,
    value: null,
    functionToFireOnDelay: updateFields,
  })

  function updateFields() {
    let index = debouncedValue?.index ?? -1
    let value = debouncedValue?.value ?? ''
    let customScheduleFields = watch().custom
    let locationsSet: Set<string> = new Set()

    if (watch().facilities && watch().facilities?.length) {
      const locations = watch().facilities?.reduce((acc: any, current: any, currIndex: number) => {
        if (!!current.facility || currIndex === index) {
          if (currIndex === index) {
            acc.push({
              label: value,
              value: value,
            })
            locationsSet.add(value)
          } else {
            acc.push({
              label: current.facility,
              value: current.facility,
            })
            locationsSet.add(current.facility)
          }
        }
        return acc
      }, [])

      setLocationOptions([...locations])
      setValue(
        'custom',
        customScheduleFields?.map((currCustomScheduleField: any) => {
          return {
            ...currCustomScheduleField,
            location: locationsSet.has(currCustomScheduleField.location)
              ? currCustomScheduleField.location
              : '',
          }
        })
      )
    } else {
      setValue(
        'custom',
        customScheduleFields?.map((currCustomScheduleField: any) => {
          return {
            ...currCustomScheduleField,
            location: '',
          }
        })
      )
      setLocationOptions([])
    }
  }

  useEffect(() => {
    updateFields()
  }, [facilities.fields, listingRemove, debouncedValue])

  return (
    <>
      <Accordion
        onClose={() => setIsOpen(true)}
        onOpen={() => setIsOpen(false)}
        header="Schedule and Facility settings"
        info={isOpen ? 'Close' : 'Edit'}
      >
        <div className="flex flex-col gap-4 pt-2 ">
          <TooltipTextToggle
            on={scheduleBy === 'rider'}
            onSwitch={handleToggleScheduleBy}
            label="Would you like to schedule by rider?"
            tooltip="Turn on to schedule all ride times by rider, instead of scheduling by class."
          />

          <div className="flex items-center justify-between  ">
            <div className="flex items-center gap-2">
              <h4 className="text-md font-medium text-SeabiscuitDark200ThemeColor">
                Show Facilities
              </h4>
              <Tooltip
                title={
                  <h6 className="tooltip_title">
                    Add facilities to select when building your show schedule.
                  </h6>
                }
                placement="top"
                arrow
              >
                <TooltipIcon
                  style={{ width: '16px', height: '16px' }}
                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              </Tooltip>
            </div>
            <span className="text-sm text-SeabiscuitGray600ThemeColor">
              {facilities?.fields?.length || 0}{' '}
              {facilities?.fields?.length === 1 ? 'facility' : 'facilities'}
            </span>
          </div>
          {facilities.fields.map((field, index) => (
            <div key={field.id} className="mb-0">
              <div className="relative flex items-center">
                <Input
                  placeholder="Eg. Ring 1, Secretary’s Office"
                  register={register}
                  name={`facilities.${index}.facility`}
                  onChange={(e) => handleInputChange(e, index)}
                  className="px-6 pr-11 py-3 !rounded-lg relative"
                  valid={!!watch(`facilities.${index}.facility`)}
                  value={watch(`facilities.${index}.facility`) as any}
                  validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                  invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                />
                {index !== 0 && (
                  <div
                    className="absolute z-10 right-3 top-1/2 -translate-y-1/2 w-6 flex items-center cursor-pointer hover:opacity-70"
                    onClick={() => removeFacility(index)}
                  >
                    <img
                      src="/assets/og_icons/Cancel.svg"
                      alt="remove"
                      className="w-full h-full cursor-pointer"
                    />
                  </div>
                )}
              </div>
              {!!errors?.facilities?.[index]?.facility?.message && (
                <MessageHelperComp
                  isError={true}
                  message={errors?.facilities?.[index]?.facility?.message}
                />
              )}
            </div>
          ))}
          <div className="mt-2 flex gap-4 items-center justify-between">
            <button
              className="text-SeabiscuitDark200ThemeColor/50 flex items-center gap-2 text-sm group"
              onClick={() => {
                facilities.append({
                  uuid: uuidv4(),
                  facility: '',
                })
              }}
            >
              <AddCircleOutline className="!w-5" />
              <span className="underline group-hover:no-underline">Add Facility</span>
            </button>

            <button
              onClick={() => {
                facilities.remove(clearAllArr(facilities.fields.length, 0))
              }}
              className="text-SeabiscuitDark200ThemeColor/50 text-sm underline hover:no-underline"
            >
              Clear All
            </button>
          </div>
        </div>
      </Accordion>
      <div className="mt-1">
        {!isOpen &&
          errors?.facilities &&
          errors.facilities.length &&
          errors.facilities.length > 0 && (
            <MessageHelperComp isError={true} message="Facility is a required field" />
          )}
      </div>
    </>
  )
}
