import { DateSeparator, DateSeparatorProps } from 'stream-chat-react'
import './layout.css'

export const CustomDateSeparator = (props: DateSeparatorProps) => {
  const { date } = props
  function formatDate(d: Date) {
    return d.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
  }

  return <DateSeparator formatDate={formatDate} date={date} position={'center'} />
}
