import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { JudgingTable, OperationsHeader, PrizeMoneyTable, ScoringTableByClass } from './components'
import { OrderOfGoTableByRider } from './components/OrderOfGoTable/OrderOfGoTableByRider'
import { OrderOfGoTableByClass } from './components/OrderOfGoTable/OrderOfGoTableByClass'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import { ScoringTableByRider } from './components/ScoringTable/ScoringTableByRider'

import FirestoreService from '../../../../../services/firestoreService'

import {
  IEventFees,
  RegistrationFeesType,
} from '../../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../../models/event-fees/event-fees.model'
import { EventStaffModel } from '../../../../../models/event-staff/event-staff.model'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../models/registrations-by-day/registrationByDay.model'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'

import { EventReviewPublishModel } from '../../../../../models/event-review-publish/event-review-publish.model'
import { IEventStaffInterface } from '../../../../../models/event-staff/event-staff.interface'
import { IBreak, IBreakRegistrationByDay } from '../../../../../models/events/event.interface'

import { getUserFullName } from '../../../../../helpers/helpers'
import { getRegisteredRiders } from '../../../../../helpers/riders'
import { getConvertedData } from '../../../../../models/interface.helper'

import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'
import { selectAllUsers } from '../../../../../store/users/usersSlice'

import { CONST } from '../../../../../const/const'
import { MANAGE_OPERATIONS_CONSTS } from '../data/operations.data.const'

import { sheets } from '../data/sheets'
import { sortByOrder, sortByRank } from './data/operations-tab-utils'
import { PAYMENT_STATUS, PAYMENT_TYPE } from '../../../../../types/payment'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export type IOperationsTabs = 'judging' | 'order-of-go' | 'scoring' | 'prize-money'

export interface IRegistrationByDayEx extends IRegistrationByDayInterface {
  loading?: boolean
}

export interface RegistrationFeesTypeEx extends RegistrationFeesType {
  loading?: boolean
}

const ManageClinicNOtherOperationsTab = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const currentEvent = useAppSelector(selectedEvent)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState<IOperationsTabs>('judging')
  const [registrationsByDayByClass, setRegistrationsByDayByClass] = useState<
    IBreakRegistrationByDay[]
  >([])
  const [classOptions, setClassOptions] = useState<{ value: string; label: string }[]>([])
  const [selectedClass, setSelectedClass] = useState<string>('')
  const [dayOptions, setDayOptions] = useState<{ label: string; value: string }[]>([])
  const [selectedDay, setSelectedDay] = useState<string>('')
  const [increment, setIncrement] = useState<string>('')
  const [registrationFees, setRegistrationFees] = useState<IEventFees | null>(null)
  const [fee, setFee] = useState<RegistrationFeesType | null>(null)
  const [registrationsByDay, setRegistrationsByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)
  const [judgesOptions, setJudgesOptions] = useState<{ label: string; value: string }[] | null>(
    null
  )
  const [scheduleBy, setScheduleBy] = useState<'class' | 'rider' | null>(null)
  const [registrationsGroupedByDay, setRegistrationsGroupedByDay] = useState<{
    [key: string]: IBreakRegistrationByDay[]
  }>({})

  const users = useAppSelector(selectAllUsers)

  const getEventStaff = useCallback(async () => {
    const publishedEventSnapShot = await FirestoreService.getItem(
      COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId
    )
    const publishedEvent_ =
      EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()

    const staffs: IEventStaffInterface = new EventStaffModel(
      publishedEvent_?.EventStaff
    ).toFirestore()
    const judgesStaff_ = staffs.eventStaff?.find((staff) => staff.title === 'Judges')

    if (judgesStaff_?.value && judgesStaff_.value.length > 0) {
      const usersSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.USERS.NAME,
        judgesStaff_.value
      )

      const usersList: IUserInterface[] = []
      usersSnaps.forEach((currSnap) =>
        usersList.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      )

      if (usersList.length > 0)
        setJudgesOptions(
          usersList.map((user) => ({
            label: getUserFullName(user),
            value: user.id,
          }))
        )
    }
  }, [eventId])

  // This function is getting invoked by the dependency array on the useEffect when the active tab changes therefore this will always handle updating local state - this will fetch ALL registrations that belong to an event
  const getRegistrationsByDay = useCallback(async () => {
    const registrationsByDay_ = await getRegisteredRiders([eventId])
    setRegistrationsByDay(registrationsByDay_)
    return registrationsByDay_
  }, [eventId])

  const handleGetFeesData = useCallback(async () => {
    setLoading(true)

    const currentRegistrationsByDay = await getRegistrationsByDay()
    await getEventStaff()

    const eventFeesSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)

    const registrationFees_ = new EventFeesModel(eventFeesSnapShot.data()).toFirestore()

    // fetch the event scheduling
    const eventSchedulingSnapShot = await FirestoreService.getItem(
      COLLECTIONS.EVENT_SCHEDULING.NAME,
      eventId
    )

    // default to class for backwards compatability
    const scheduleByDataPoint = eventSchedulingSnapShot?.data()?.scheduleBy ?? 'class'

    // registrationFees key in the eventFeesSnapShot data is an array of classes for the event
    const fees_ = registrationFees_.registrationFees.map(
      (registrationFee: RegistrationFeesType) => {
        const count = currentRegistrationsByDay
          ?.filter((registrationByDay) => !registrationByDay.isScratched)
          .reduce((acc, itm) => (itm.uuid === registrationFee.uuid ? acc + 1 : acc), 0)

        return {
          value: registrationFee.uuid || '',
          label: `${registrationFee.name || ''} <span class="text-SeabiscuitDark200ThemeColor/50"> • ${count ?? 0} Rides</span>`,
        }
      }
    )

    setScheduleBy(scheduleByDataPoint)
    setClassOptions(fees_)
    setRegistrationFees(registrationFees_)
    setLoading(false)
  }, [eventId, getEventStaff, getRegistrationsByDay])

  useEffect(() => {
    const options = Object.keys(registrationsGroupedByDay)
      .map((groupKey) => {
        const [dayKey, facility] = groupKey.split('_')
        const validEntries = registrationsGroupedByDay[groupKey].filter(
          (entry) => !entry.isBreakDrag
        )

        return {
          label: `${new Date(dayKey).toLocaleDateString('en-US', { weekday: 'long' })} Rides • ${facility} • ${validEntries.length} entries`,
          value: groupKey,
          entryCount: validEntries.length,
        }
      })
      .sort((a, b) => b.entryCount - a.entryCount)

    setDayOptions(options)
  }, [registrationsGroupedByDay])

  const getRidersByClass = useCallback(
    async (activeTab: string) => {
      setLoading(true)

      let registrationsByDayByClass_: IRegistrationByDayInterface[] =
        registrationsByDay?.filter(
          (registrationByDay) => registrationByDay.uuid === selectedClass
        ) ?? []

      let uniqueRegistrationsByDayByClass = registrationsByDayByClass_.reduce(
        (acc: IRegistrationByDayInterface[], currentItem, index) => {
          // Check if the riderId of the current item is already in the accumulator
          const isRiderIdExist = acc.some(
            (item) => item.riderId === currentItem.riderId && item.horseId === currentItem.horseId
          )
          const riderAsUserObj = users.find((user) => user.id === currentItem.riderId)
          const sheet = sheets.find((current) => current.id === fee?.scoreCard)

          // If riderId doesn't exist, add the current item to the accumulator
          if (!isRiderIdExist) {
            const newItem = {
              ...currentItem,
              riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : currentItem.riderName,
              score: {
                ...currentItem.score,
                judges:
                  currentItem.score?.judges && currentItem.score.judges.length > 0
                    ? currentItem.score?.judges
                    : (fee?.judges?.map((judge) => {
                        const user = users.find((user) => user.id === judge)
                        return {
                          id: judge,
                          name: user ? getUserFullName(user) : 'N/A',
                          furtherRemarks: cloneDeep(sheet?.furtherRemarks) ?? '',
                          sections: cloneDeep(sheet?.sections) ?? [],
                          errors: cloneDeep(sheet?.errors) ?? 0,
                        }
                      }) ?? []),
                sheet: currentItem.score?.sheet?.name
                  ? currentItem.score?.sheet
                  : fee?.scoreCard
                    ? {
                        name: cloneDeep(sheet?.name) ?? '',
                        type: cloneDeep(sheet?.type) ?? '',
                      }
                    : null,
                rank: currentItem.score?.rank ?? null,
                totalPoints: currentItem.score?.totalPoints ?? null,
                details: currentItem.score?.details ?? '',
                file: currentItem.score?.file ?? '',
              },
              order: currentItem.orderOfGoScratched ? 0 : currentItem.order || index + 1,
            }
            acc.push(newItem)
          }
          return acc
        },
        []
      )

      if (activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value) {
        // Sort array depends on order
        const event = currentEvent?.Event

        // New IBreak is optional for class to support the rider group day based scheduling, now we explicitly check that the break object has the class object and a value when filtering
        const breaks: IBreak[] =
          event && Array.isArray(event.breaks)
            ? event.breaks.filter(
                (itm) => itm.registrationByDayName === selectedClass && !itm.groupKey
              )
            : []
        uniqueRegistrationsByDayByClass = sortByOrder([
          ...uniqueRegistrationsByDayByClass,
          ...breaks,
        ])
      } else if (
        activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value ||
        activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value
      ) {
        // Sort array depends on rank
        uniqueRegistrationsByDayByClass = sortByRank(
          uniqueRegistrationsByDayByClass,
          fee?.isAsc ? 'asc' : 'desc'
        )
        uniqueRegistrationsByDayByClass = uniqueRegistrationsByDayByClass.map(
          (registrationByDay, index) => {
            return {
              ...registrationByDay,
              ...(registrationByDay.score && {
                score: {
                  ...registrationByDay.score,
                  rank: index + 1,
                },
              }),
            }
          }
        )
      }

      setRegistrationsByDayByClass(uniqueRegistrationsByDayByClass as IBreakRegistrationByDay[])
      setLoading(false)
    },
    [
      currentEvent,
      fee?.isAsc,
      fee?.judges,
      fee?.scoreCard,
      registrationsByDay,
      selectedClass,
      users,
    ]
  )

  const getRidersByDay = useCallback(
    async (activeTab: string) => {
      setLoading(true)
      const registrationsByDay_ = await getRegisteredRiders([eventId])

      const eventSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
      const eventData = eventSnapShot.data()

      const eventBreaks: any[] = eventData?.breaks ?? []

      const feesArray = registrationFees?.registrationFees ?? []

      const classesByDayFacility: Record<string, { classes: string[]; facility: string }> = {}

      feesArray.forEach((fee: RegistrationFeesType) => {
        // startDate is stored in EST in the database so parse to the same time zone as database for accurate day
        const dayKey = new Date(fee.startDate).toLocaleDateString('en-CA')
        const facility = fee.location || 'No Facility Assigned'

        const key = `${dayKey}_${facility}`

        if (!classesByDayFacility[key]) {
          classesByDayFacility[key] = { classes: [], facility }
        }
        classesByDayFacility[key].classes.push(fee.name ?? '')
      })

      const groupedByDayFacility: Record<string, any[]> = {}

      Object.entries(classesByDayFacility).forEach(([groupKey, { classes }]) => {
        // Get all registrations that belong to any class on this day
        let registrationsForGroup = registrationsByDay_.filter(
          (registration: IRegistrationByDayInterface) =>
            classes.includes(registration.registrationByDayName ?? '') &&
            (registration.paymentType !== PAYMENT_TYPE.DEFAULT ||
              registration.paymentStatus !== PAYMENT_STATUS.DEFAULT) &&
            registration.paymentStatus !== PAYMENT_STATUS.PENDING
        )

        // Enrich each registration with its matching fee's scoring details
        registrationsForGroup = registrationsForGroup.map(
          (registration: IRegistrationByDayInterface, index: number) => {
            // Find the fee whose name matches the registration's class name
            const matchingFee: RegistrationFeesType | undefined = feesArray.find(
              (feeItem: RegistrationFeesType) => feeItem.name === registration.registrationByDayName
            )

            // Look up the riders user object
            const riderAsUserObj = users.find(
              (user: IUserInterface) => user.id === registration.riderId
            )

            // Get the scoring sheet from the sheets array if a matching fee is found
            const sheet: any | undefined = matchingFee
              ? sheets.find((s: any) => s.id === matchingFee.scoreCard)
              : undefined

            // Build the enriched score object
            const enrichedScore = {
              ...registration.score,
              judges:
                registration.score?.judges && registration.score.judges.length > 0
                  ? registration.score.judges
                  : (matchingFee?.judges?.map((judgeId: string): any => {
                      const user: IUserInterface | undefined = users.find(
                        (user: IUserInterface) => user.id === judgeId
                      )
                      return {
                        id: judgeId,
                        name: user ? getUserFullName(user) : 'N/A',
                        furtherRemarks: sheet ? cloneDeep(sheet?.furtherRemarks) : '',
                        sections: sheet ? cloneDeep(sheet?.sections) : [],
                        errors: sheet ? cloneDeep(sheet?.errors) : 0,
                      }
                    }) ?? []),
              sheet: registration.score?.sheet?.name
                ? registration.score.sheet
                : matchingFee?.scoreCard
                  ? {
                      name: sheet ? cloneDeep(sheet?.name) : '',
                      type: sheet ? cloneDeep(sheet?.type) : '',
                    }
                  : null,
              rank: registration.score?.rank ?? null,
              totalPoints: registration.score?.totalPoints ?? null,
              details: registration.score?.details ?? '',
              file: registration.score?.file ?? '',
            }

            return {
              ...registration,
              riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : registration.riderName,
              order: registration.order ?? index + 1,
              score: enrichedScore,
              // add the matching fee to state so we can reference the correct fee for rider based logic
              fee: matchingFee,
            }
          }
        )

        // Add ranking by class for scoring tab
        if (activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value) {
          // Group registrations by class name
          const registrationsByClass: Record<string, IRegistrationByDayInterface[]> = {}

          registrationsForGroup.forEach((registration) => {
            const className = registration.registrationByDayName || ''
            if (!registrationsByClass[className]) {
              registrationsByClass[className] = []
            }
            registrationsByClass[className].push(registration)
          })

          // Create a new array to hold all the ranked registrations
          let rankedRegistrations: IRegistrationByDayInterface[] = []

          Object.entries(registrationsByClass).forEach(([className, classRegistrations]) => {
            // Find the class-specific fee
            const classFee = feesArray.find((fee: RegistrationFeesType) => fee.name === className)

            if (classFee) {
              // Sort by total points - just use the matching fee as a starting point
              const sortedRegistrations = sortByRank(
                classRegistrations,
                classFee.isAsc ? 'asc' : 'desc'
              )

              // assign ranks within the class
              const rankedClassRegistrations = sortedRegistrations.map((registration, index) => ({
                ...registration,
                score: {
                  ...registration.score,
                  rank: index + 1,
                },
              }))

              // Add these ranked riders to our result array
              rankedRegistrations = [...rankedRegistrations, ...rankedClassRegistrations]
            }
          })

          // Replace the original registrations with the ranked ones
          registrationsForGroup = rankedRegistrations
        }

        let combinedList = registrationsForGroup

        if (activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value) {
          // getting the breaks on the event object for the day and merging only if in order of go tab
          const breaksForGroup = eventBreaks.filter((brk) => brk.groupKey === groupKey)
          combinedList = [...registrationsForGroup, ...breaksForGroup]
        }

        combinedList = combinedList.sort((a, b) => a.order - b.order)

        groupedByDayFacility[groupKey] = combinedList
      })
      setRegistrationsGroupedByDay(groupedByDayFacility)

      setLoading(false)
    },
    [eventId, registrationFees?.registrationFees, users]
  )

  useEffect(() => {
    handleGetFeesData().then()
  }, [])

  useEffect(() => {
    if (scheduleBy === 'class' && selectedClass && activeTab && fee) {
      getRidersByClass(activeTab).then()
    }
  }, [activeTab, selectedClass, fee, registrationsByDay, scheduleBy])

  useEffect(() => {
    if (scheduleBy === 'rider' && activeTab) {
      getRidersByDay(activeTab).then()
    }
  }, [activeTab, scheduleBy])

  useEffect(() => {
    if (selectedClass && registrationFees?.registrationFees) {
      setFee(
        [...(registrationFees.registrationFees ?? [])].find(
          (item) => item.uuid === selectedClass
        ) ?? null
      )
    }
  }, [registrationFees, selectedClass])

  useEffect(() => {
    if (
      activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value &&
      registrationFees?.registrationFees &&
      selectedClass
    ) {
      getRidersByClass(activeTab).then()
    }
  }, [activeTab, registrationFees, selectedClass])

  const updateFeesKeys = async (keys: object, uuid: string) => {
    let registrationFees_ = cloneDeep(registrationFees)

    const indexToUpdateFees = registrationFees_?.registrationFees?.findIndex(
      (item: any) => item.uuid === uuid
    )

    if (indexToUpdateFees !== -1) {
      const updatedRegistrationFees = [...(registrationFees?.registrationFees ?? [])] // Create a copy of the array
      updatedRegistrationFees[indexToUpdateFees!] = {
        ...updatedRegistrationFees[indexToUpdateFees!], // Copy existing properties
        ...keys,
      }
      // Update the state with the modified array
      registrationFees_ = { ...registrationFees_, registrationFees: [...updatedRegistrationFees] }
    }

    setRegistrationFees(registrationFees_)

    const docToStore = new EventFeesModel(registrationFees_!).toFirestore()

    await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, eventId, {
      EventFees: docToStore,
    })

    await FirestoreService.updateItem(COLLECTIONS.EVENT_FEES.NAME, eventId, {
      ...docToStore,
      id: eventId,
    })
  }

  const updateRegistrationByDayName = async (
    feeName: string,
    sheetId: string,
    values?: { value: string; label: string }[]
  ) => {
    const promises: Promise<void>[] = []

    const sheet = sheets.find((current) => current.id === sheetId)

    const registrationsByDayByClass = registrationsByDay?.filter(
      (item) => item.registrationByDayName === feeName
    )

    registrationsByDayByClass?.forEach((registrationByDay) => {
      const item = new RegistrationByDayModel({
        ...registrationByDay,
        score: {
          totalPoints: null,
          details: null,
          rank: null,
          file: null,
          earning: null,
          sheet: {
            name: sheet?.name ?? '',
            type: sheet?.type ?? '',
          },
          judges:
            values?.map((judge) => ({
              id: judge.value,
              name: judge.label,
              furtherRemarks: sheet?.furtherRemarks ?? '',
              sections: sheet?.sections ?? [],
              errors: sheet?.errors ?? 0,
            })) ?? null,
        },
      })

      promises.push(
        FirestoreService.updateItem(
          COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          registrationByDay.id,
          item.toFirestore()
        )
      )
    })

    await Promise.all(promises)
  }

  const onChangeJudge = async (
    row: RegistrationFeesTypeEx,
    values: { label: string; value: string }[]
  ) => {
    if (values) {
      await updateFeesKeys({ judges: values.map((value) => value.value) }, row.uuid)

      const sheet = sheets.find((sheet) => sheet.id === row?.scoreCard)
      await updateRegistrationByDayName(row.name ?? '', sheet?.id ?? '', values)
      await getRegistrationsByDay()
    } else {
      await updateFeesKeys({ judges: [] }, row.uuid)
      await updateRegistrationByDayName(row.name ?? '', '', [])
      await getRegistrationsByDay()
    }
  }

  if (!scheduleBy) {
    return (
      <div className="w-full min-h-[400px] flex justify-center items-center">
        <ViewsLoader size="lg" color="#F70763" />
      </div>
    )
  }

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-full flex flex-col">
      <OperationsHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        classOptions={classOptions}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        dayOptions={dayOptions}
        registrationFees={registrationFees}
        setIncrement={setIncrement}
        updateFeesKeys={updateFeesKeys}
        registrationsGroupedByDay={registrationsGroupedByDay}
        fee={fee}
        eventId={eventId}
        scheduleBy={scheduleBy}
        setRegistrationsGroupedByDay={setRegistrationsGroupedByDay}
        setRegistrationFees={setRegistrationFees}
      />

      {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.JUDGING.value ? (
        <JudgingTable
          getRegistrationsByDay={getRegistrationsByDay}
          loading={loading}
          updateFeesKeys={updateFeesKeys}
          registrationFees={registrationFees}
          judgesOptions={judgesOptions}
          onChangeJudge={onChangeJudge}
          updateRegistrationByDayName={updateRegistrationByDayName}
        />
      ) : (
        <div className="mt-8">
          {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value && (
            <>
              {scheduleBy === 'class' && (
                <OrderOfGoTableByClass
                  selectedClass={selectedClass}
                  registrationsByDayByClass={registrationsByDayByClass}
                  setRegistrationsByDayByClass={setRegistrationsByDayByClass}
                  increment={increment}
                  fee={fee}
                  eventId={eventId}
                  registrationsByDay={registrationsByDay}
                  setRegistrationsByDay={setRegistrationsByDay}
                />
              )}
              {scheduleBy === 'rider' && selectedDay && (
                <OrderOfGoTableByRider
                  increment={increment}
                  eventId={eventId}
                  selectedDay={selectedDay}
                  registrationsGroupedByDay={registrationsGroupedByDay}
                  setRegistrationsGroupedByDay={setRegistrationsGroupedByDay}
                  registrationsByDay={registrationsByDay}
                  setRegistrationsByDay={setRegistrationsByDay}
                />
              )}
            </>
          )}

          {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value && (
            <>
              {scheduleBy === 'class' && (
                <ScoringTableByClass
                  loading={loading}
                  registrationsByDayByClass={registrationsByDayByClass}
                  registrationsByDay={registrationsByDay}
                  setRegistrationsByDay={setRegistrationsByDay}
                  getRegistrationsByDay={getRegistrationsByDay}
                  selectedClass={selectedClass}
                  increment={increment}
                  updateFeesKeys={updateFeesKeys}
                  fee={fee}
                  onChangeJudge={onChangeJudge}
                />
              )}
              {scheduleBy === 'rider' && selectedDay && (
                <ScoringTableByRider
                  loading={loading}
                  registrationsGroupedByDay={registrationsGroupedByDay}
                  selectedDay={selectedDay}
                  setRegistrationsByDay={setRegistrationsByDay}
                  registrationsByDay={registrationsByDay}
                  activeTab={activeTab}
                  getRegistrationsByDay={getRegistrationsByDay}
                  onChangeJudge={onChangeJudge}
                  getRidersByDay={getRidersByDay}
                />
              )}
            </>
          )}

          {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value &&
            registrationsByDayByClass.length > 0 && (
              <PrizeMoneyTable
                loading={loading}
                registrationsByDayByClass={registrationsByDayByClass}
                registrationsByDay={registrationsByDay}
                setRegistrationsByDay={setRegistrationsByDay}
                eventId={eventId}
                registrationFees={registrationFees}
              />
            )}

          {(scheduleBy === 'class' && registrationsByDayByClass.length === 0) ||
          (scheduleBy === 'rider' && selectedDay && !registrationsGroupedByDay[selectedDay]) ? (
            <div className="!rounded-none exhibitorListTable flex justify-center p-12">
              There are no records to display
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default ManageClinicNOtherOperationsTab
