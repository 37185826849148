import React from 'react'
import { IconDelete } from '../../../../../components/icons/IconDelete'
import { IconArrowRight } from '../../../../../components/icons/IconArrowRight'
import { IconLoader } from '../../../../../components/icons/IconLoader'
import { IconMessaging } from '../../../../../components/icons/IconMessaging'

interface ITableBtn {
  onClick?: () => void
  type: 'remove' | 'go' | 'message' | 'scratched'
}

export const TableBtn: React.FC<ITableBtn> = ({ onClick, type }) => {
  const renderIcon = () => {
    switch (type) {
      case 'scratched':
        return <IconLoader />
      case 'remove':
        return <IconDelete />
      case 'message':
        return <IconMessaging />
      default:
        return <IconArrowRight />
    }
  }
  return (
    <button
      onClick={onClick}
      className={`w-[45px] min-w-[45px] h-[45px] ${type === 'scratched' && 'cursor-default'} flex items-center justify-center border-[1px] rounded-[8px] border-solid ${type === 'go' ? 'border-[#F6F7FB]' : 'border-[#D3DAEE]'} ${type === 'go' && 'bg-[#F6F7FB]'}`}
    >
      {renderIcon()}
    </button>
  )
}
