import React, { useEffect } from 'react'

// Third party
import clsx from 'clsx'
import { v4 } from 'uuid'
import { Tooltip } from '@mui/material'

// Redux
import { useAppSelector } from '../../../../store/hooks'
import { selectRegistrationR } from '../../../../store/exhibitor/exhibitorSlice'
import { selectAllUsers } from '../../../../store/users/usersSlice'

import { getUserFullName } from '../../../../helpers/helpers'

import TooltipIcon from '../../../../helpers/TooltipIcon'

import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { PAYMENT_STATUS } from '../../../../types/payment'
import { getRiderHorses } from '../../../../helpers/riders'
import { getFloatPrice } from '../../../../helpers/price'

type IExhibitorRegistrationAccordionProps = {
  registrationsByDay: IRegistrationByDayInterface[]
  registrationPrice: number
  showScratchedView: boolean
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
  horses: IHorseData[]
}

type IDetailRowProps = {
  title: string
  cost?: number | null
  showScratchedView: boolean
  listToScratch: string[]
  eventDate: Date | string | null
  member: IRegistrationByDayInterface
  handleScratchList: (memberId: string) => void
  horses: IHorseData[]
  horseName: string | null
}

const ExhibitorRegisrationInput: React.FC<{
  className?: string
  data?: any
  data1?: any
  icon?: string
}> = ({ className, icon, data }) => {
  return (
    <div className={`${className}`}>
      {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
      <div className="ml-2">{data}</div>
    </div>
  )
}

const DetailRow = (props: IDetailRowProps) => {
  let id = `${v4()}${Date.now()}`
  let { title, cost, member, showScratchedView, listToScratch } = props
  let checked = member.id ? listToScratch.find((data) => data === member.id) : false

  if (isNaN(Number(cost))) return null

  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row w-full">
      <div className="lg:hidden font-medium text-[12px]">Class</div>
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center text-sm text-SeabiscuitDark200ThemeColor min-h-[48px] bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4"
        data={
          <div className="flex flex-wrap items-center gap-1">
            {title}
            {member.isScratched ? (
              <span
                className={'bg-[#122B461A] text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'}
              >
                Scratched
              </span>
            ) : (
              ''
            )}
            {member.isQualify ? (
              <span
                className={
                  'border border-SeabiscuitDark200ThemeColor text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'
                }
              >
                Qualifying
              </span>
            ) : (
              ''
            )}
          </div>
        }
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Horse</div>
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center relative"
        data={props.horses[0] ? props.horses.map((item) => item.horseName).join(', ') : 'N/A'}
        icon={'/assets/og_icons/YearofHorse-1.svg'}
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Rider</div>
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center relative"
        data={member ? member.riderName || member.riderEmail : 'N/A'}
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Price</div>
      <ExhibitorRegisrationInput
        className="relative rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center"
        data={
          member?.isScratched ? (
            'Scratched'
          ) : (
            <span>
              {getFloatPrice(`${cost}`)}{' '}
              {member.isQualify && (
                <span>
                  • {getFloatPrice(`${member.qualifyFee}`)} {member.qualifyFeeName || 'qual'}
                </span>
              )}
            </span>
          )
        }
        icon={
          member.paymentStatus === PAYMENT_STATUS.PAID
            ? '/assets/img/dark/credit-card.svg'
            : '/assets/img/dark/cash.svg'
        }
      />

      {showScratchedView ? (
        <label
          htmlFor={id}
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor bg-transparent border border-[#d3daee] p-3 m-1 w-1/4 text-center relative flex items-center justify-center',
            !member?.isScratched && 'cursor-pointer'
          )}
        >
          {member?.isScratched ? (
            'Scratched'
          ) : (
            <>
              Scratch
              <div
                className={clsx(
                  'ml-2 !ring-0 !shadow-none absolute shrink-0 flex items-center justify-center',
                  !checked
                    ? 'border-2 border-SeabiscuitDark200ThemeColor rounded-full w-5 h-5 right-[14px]'
                    : 'w-6 h-6 right-3'
                )}
              >
                {checked ? (
                  <img
                    src="/assets/og_icons/Ok-3.svg"
                    alt="Ok"
                    className="w-full h-full object-cover"
                  />
                ) : null}
              </div>
              <input
                type="checkbox"
                className="hidden"
                id={id}
                checked={!!checked}
                onChange={() => props.handleScratchList(member?.id ? member?.id.toString() : '')}
              />
            </>
          )}
        </label>
      ) : null}
    </div>
  )
}

const ExhibitorRegistrationAccordion = ({
  registrationsByDay,
  showScratchedView,
  setListToScratch,
  listToScratch,
  horses,
}: IExhibitorRegistrationAccordionProps) => {
  const users = useAppSelector(selectAllUsers)

  const registrationR = useAppSelector(selectRegistrationR)

  useEffect(() => {
    setListToScratch([])
  }, [showScratchedView, registrationR])

  const handleScratchList = (memberId: string) => {
    if (listToScratch.find((data) => data === memberId)) {
      let filter = listToScratch.filter((data) => data !== memberId)
      setListToScratch(filter)
    } else {
      setListToScratch([...listToScratch, memberId])
    }
  }
  return (
    <>
      <div className="hidden lg:flex items-center w-full">
        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Class</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Horse</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Rider</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Price</span>}
        />

        {showScratchedView ? (
          <ExhibitorRegisrationInput
            className={clsx(
              'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold w-1/5'
            )}
            data={
              <span className="flex items-center text-xs">
                Scratch
                <Tooltip title={<h1 className="tooltip_title">Scratch</h1>} placement="top" arrow>
                  <button className="">
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </span>
            }
          />
        ) : null}
      </div>

      {registrationsByDay && registrationsByDay.length
        ? registrationsByDay.map((currentItem, index) => {
            let riderName = currentItem.riderName
            if (
              !currentItem.riderName ||
              !(currentItem.riderName && !currentItem.riderName.replace(/\s+/g, ''))
            ) {
              const user = users.find((user) => user.id === currentItem.riderId)

              riderName = user ? getUserFullName(user) : ''
            }

            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                title={currentItem.registrationByDayName ?? 'N/A'}
                listToScratch={listToScratch}
                member={{ ...currentItem, riderName }}
                horses={getRiderHorses(horses, currentItem.horseIds ?? [])}
                eventDate={currentItem?.eventDate ? currentItem?.eventDate : ''}
                handleScratchList={handleScratchList}
                showScratchedView={showScratchedView}
                cost={currentItem.registrationPrice}
                horseName={currentItem.horseName || null}
              />
            )
          })
        : null}
    </>
  )
}

export default ExhibitorRegistrationAccordion
