import React, { FC } from 'react'

import { flexRender, HeaderGroup } from '@tanstack/react-table'

import { IBreakRegistrationByDay } from '../../../../../../../../models/events/event.interface'

interface OrderOfGoTableHeaderProps {
  headerGroup: HeaderGroup<IBreakRegistrationByDay>
}
export const OrderOfGoTableHeader: FC<OrderOfGoTableHeaderProps> = ({ headerGroup }) => {
  return (
    <tr key={headerGroup.id} style={{ borderBottom: '1px solid rgb(233, 236, 239)' }}>
      {headerGroup.headers.map((header) => {
        return (
          <th key={header.id} className="pb-2">
            {!header.isPlaceholder &&
              flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        )
      })}
    </tr>
  )
}
