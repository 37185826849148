import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router'

import InfiniteScrollDataTable from '../../../../../../../components/common/tables/InfiniteScrollDataTable'

import { IBreakRegistrationByDay } from '../../../../../../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'

import { getColumnsScoring } from './data/columns'

import {
  IOperationsTabs,
  IRegistrationByDayEx,
  RegistrationFeesTypeEx,
} from '../../ManageClinicNOtherOperationsTab'
import { DUMMY_REGISTRATION_FEES_STATS } from '../../data/operations-tab-utils'
import { convertTo12HourFormat } from '../../../../../../../helpers/time'
import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'
import { useEventTrainers } from '../../../../../../../hooks/useEventTrainers'
import { onRank } from './data/scoring-utils'

import { MODAL_CONSTS } from '../../../../../../../const/modal-const'

interface ScoringTableByRiderProps {
  loading: boolean
  selectedDay: string
  registrationsGroupedByDay: { [key: string]: IBreakRegistrationByDay[] }
  registrationsByDay: IRegistrationByDayInterface[] | null
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  activeTab: IOperationsTabs
  getRegistrationsByDay: () => Promise<IRegistrationByDayInterface[]>
  onChangeJudge: (
    row: RegistrationFeesTypeEx,
    values: { label: string; value: string }[]
  ) => Promise<void>
  getRidersByDay: (value: string) => Promise<void>
}

export const ScoringTableByRider: FC<ScoringTableByRiderProps> = ({
  loading,
  selectedDay,
  registrationsByDay,
  setRegistrationsByDay,
  registrationsGroupedByDay,
  activeTab,
  getRegistrationsByDay,
  onChangeJudge,
  getRidersByDay,
}) => {
  const { eventId } = useParams<{ eventId: string }>()

  const { eventTrainers } = useEventTrainers(eventId)

  const handleModalContext = useContext(HandleModalContext)

  const [riderModeIsAsc, setRiderModeIsAsc] = useState(false)
  const [scoringLoading, setScoringLoading] = useState(false)

  const registrationsToDisplay = useMemo(() => {
    let registrations = selectedDay ? [...(registrationsGroupedByDay[selectedDay] || [])] : []

    const registrationsByClass: Record<string, IRegistrationByDayInterface[]> = {}

    registrations.forEach((reg) => {
      const className = reg.registrationByDayName || ''
      if (!registrationsByClass[className]) {
        registrationsByClass[className] = []
      }
      registrationsByClass[className].push(reg as IRegistrationByDayInterface)
    })

    let sortedRegistrations: IRegistrationByDayInterface[] = []

    Object.entries(registrationsByClass).forEach(([_, classRegs]) => {
      // Sort by total points
      const sorted = [...classRegs].sort(
        (a: IRegistrationByDayInterface, b: IRegistrationByDayInterface) => {
          const hasPointsA =
            a.score?.totalPoints !== null &&
            a.score?.totalPoints !== undefined &&
            Number(a.score?.totalPoints) > 0
          const hasPointsB =
            b.score?.totalPoints !== null &&
            b.score?.totalPoints !== undefined &&
            Number(b.score?.totalPoints) > 0

          // neither has points, maintain original order
          if (!hasPointsA && !hasPointsB) return 0

          // put entries with no points at the end of their class grouping
          if (!hasPointsA) return 1
          if (!hasPointsB) return -1

          // both have points - compare
          const pointsA = Number(a.score?.totalPoints)
          const pointsB = Number(b.score?.totalPoints)

          return riderModeIsAsc
            ? pointsA - pointsB // Asc
            : pointsB - pointsA // Desc
        }
      )

      sortedRegistrations.push(...sorted)
    })

    return sortedRegistrations
  }, [selectedDay, registrationsGroupedByDay, riderModeIsAsc])

  const onSortScore = () => {
    setRiderModeIsAsc(!riderModeIsAsc)
  }
  const onScore = (row: IRegistrationByDayEx) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.SCORING, {
      rider: row,
      onSaveScoring: () => getRidersByDay('scoring'),
      editable: true,
    })
  }

  const getTime = ({ rideTime }: { rideTime: string | null; index: number }) => {
    if (rideTime) {
      const { hour, minute, second, period } = convertTo12HourFormat(rideTime)
      return `${hour}:${minute}:${second} ${period}`
    } else {
      return '00:00:00 AM'
    }
  }

  useEffect(() => {
    if (eventId && activeTab) getRidersByDay(activeTab).then()
  }, [eventId, activeTab])

  return (
    <InfiniteScrollDataTable
      hasMore={false}
      className="exhibitorListTable"
      columns={getColumnsScoring({
        type: 'rider',
        eventTrainers,
        scoringLoading,
        registrationsByDay,
        setRegistrationsByDay,
        getTime,
        onSortScore,
        onScore,
        onRank: (row, fee) =>
          onRank({
            row,
            fee,
            setScoringLoading,
            getRegistrationsByDay,
            handleModalContext,
            onChangeJudge,
          }),
      })}
      data={loading ? DUMMY_REGISTRATION_FEES_STATS : registrationsToDisplay}
    />
  )
}
