import { useEffect, useState } from 'react'

// Third party
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

// Types
import { ILightboxProps } from '../../../../types/competitor_types'

// Constants
import { cloneDeep } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ISignatoryForMail as isfm } from '../../../../models/event-registered-users/event-registered-users.interface'
import { isMinorFn } from '../../../../models/interface.helper'
import { IRiderTeamMemberInterface } from '../../../../models/rider-team-member/riderTeamMember.interface'
import {
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  selectRegistration,
  selectRidersTeamMembersR,
  selectSendMailLoadingR,
  selectSignTabDataR,
  setSignTab,
} from '../../../../store/registration/registrationSlice'
import { selectProfileData } from '../../../../store/user/userSlice'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { eligibleToSign } from '../../../../helpers/sign'
import { NoDocumentsToSign } from './components/NoDocumentsToSign'
import { Paperwork } from './components/Paperwork'

import { MEMBER_ROLES } from '../../../../const/ui/ui-register.const'

type ICompetitorEventRegisterSignTabProps = {
  title?: string
  description?: string
  message: string | null
  saveRidersTeamMembers: (args: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => Promise<void>
  handleLightBox: (props: ILightboxProps) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  userId: string
}

export interface ISignatoryForMail extends isfm {}

const CompetitorEventRegisterSignTab = (props: ICompetitorEventRegisterSignTabProps) => {
  const dispatch = useAppDispatch()

  // Hooks and vars
  const signTabData = useAppSelector(selectSignTabDataR)
  const ridersTeamMembers = useAppSelector(selectRidersTeamMembersR)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const filteredPaperworksDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const sendMailLoading = useAppSelector(selectSendMailLoadingR)
  const userData = useAppSelector(selectProfileData)
  const registration = useAppSelector(selectRegistration)

  const [message, setMessage] = useState<string | null>(null)

  useEffect(() => {
    let showGuardianMessage = false
    let eventOwnerIsMinor = false
    let eventOwnerAdded = false

    let paperworkTabData_ = cloneDeep(paperworkTabData)
    if (props.isManage) paperworkTabData_ = props.manageInfo.paperwork

    paperworkTabData_.forEach((currPaperworkTabRow) => {
      currPaperworkTabRow.ridersTeamMembers.forEach((currRidersTeamMember) => {
        if (currRidersTeamMember.teamMemberId === registration?.userId) eventOwnerAdded = true
      })
    })

    if (eventOwnerAdded) {
      let ownerIsMinor = isMinorFn(userData.userDOB)
      if (ownerIsMinor) eventOwnerIsMinor = true
    }

    paperworkTabData_.forEach((currPaperworkTabRow) => {
      let riderIsMinor = isMinorFn(currPaperworkTabRow.riderDob)

      if (riderIsMinor) {
        const haveGuardian = currPaperworkTabRow.ridersTeamMembers.filter(
          (currRidersTeamMember) => currRidersTeamMember.teamMemberRole === MEMBER_ROLES.GUARDIAN
        )
        if (haveGuardian.length <= 0) showGuardianMessage = true
      }
    })

    dispatch(
      setSignTab({
        showGuardianMessage,
        eventOwnerIsMinor,
        eventOwnerAdded,
      })
    )
  }, [paperworkTabData, props.manageInfo.paperwork, userData, props.isManage])

  useEffect(() => {
    let message_ = null

    switch (true) {
      case signTabData.eventOwnerIsMinor:
        message_ =
          'Your date of birth identifies you as a minor. Your paperwork will be sent to the person you identified as your guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case !signTabData.eventOwnerAdded:
        message_ =
          'You have no paperwork to sign because you have not listed yourself as a rider or team member. If you are attending this event, please list yourself as a team member to receive paperwork to sign.'
        break

      case signTabData.showGuardianMessage:
        message_ =
          'Your one rider date of birth identifies you as a minor. So paperwork will be sent to the person you identified as minor rider guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break
    }

    setMessage(message_)
  }, [signTabData])

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      {!filteredPaperworksDocuments.length && (
        <NoDocumentsToSign message="No Paperworks have been added to this event" />
      )}
      {!!filteredPaperworksDocuments.length && message && <NoDocumentsToSign message={message} />}
      {!!filteredPaperworksDocuments.length && !message && (
        <div className={'flex-1 bg-[#F6F7FB80] p-5 rounded-[8px]'}>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 items-start justify-start">
            {filteredPaperworksDocuments.map((paperwork, index) => {
              let { notSignedOwner } = eligibleToSign({
                paperworkDocuments: [paperwork],
                userId: registration?.userId ?? props.userId,
                ridersTeamMembers,
                isManage: props.isManage,
                manageInfo: props.manageInfo,
              })

              return (
                <Paperwork
                  key={`${JSON.stringify(paperwork)}${index}`}
                  index={index}
                  isSigned={!notSignedOwner}
                  paperwork={paperwork}
                  loading={
                    sendMailLoading.sendMailLoading && sendMailLoading.sendMailIndex === index
                  }
                  isManage={props.isManage}
                  manageInfo={props.manageInfo}
                  setManageInfo={props.setManageInfo}
                  ridersTeamMembers={ridersTeamMembers}
                  saveRidersTeamMembers={props.saveRidersTeamMembers}
                  handleLightBox={props.handleLightBox}
                />
              )
            })}
          </div>
        </div>
      )}
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterSignTab
