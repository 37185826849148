import clsx from 'clsx'
import React, { FC } from 'react'

interface DaysTabsProps {
  days: string[]
  setActiveDay: (day: string) => void
  activeDay: string
  isRiderMode?: boolean
}

export const DaysTabs: FC<DaysTabsProps> = ({ days, activeDay, setActiveDay, isRiderMode }) => {
  return (
    <div className="flex flex-wrap gap-2 text-[14px] mb-6">
      <button
        className={clsx(
          'min-w-[80px] p-2 rounded-lg',
          activeDay === 'All'
            ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor transition-all hover:text-SeabiscuitMainThemeColor'
            : 'bg-transparent text-SeabiscuitDark200ThemeColor'
        )}
        onClick={() => setActiveDay('All')}
      >
        All
      </button>
      {days.map((day) => {
        // Convert YYYY-MM-DD to day name if in Rider mode
        const displayDay =
          isRiderMode && /^\d{4}-\d{2}-\d{2}$/.test(day) // Check if format is YYYY-MM-DD
            ? new Date(day).toLocaleDateString('en-US', { weekday: 'long' })
            : day

        return (
          <button
            key={day}
            className={clsx(
              'min-w-[80px] p-2 rounded-lg transition-all hover:text-SeabiscuitMainThemeColor',
              activeDay === day
                ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
                : 'bg-transparent text-SeabiscuitDark200ThemeColor'
            )}
            onClick={() => setActiveDay(day)}
          >
            {displayDay}
          </button>
        )
      })}
    </div>
  )
}
