import { createSlice } from '@reduxjs/toolkit'
import { UserModel } from '../../models/users/user.model'
import { EventModel } from '../../models/events/event.model'
import { RootState } from '../store'

export interface IOrganizer extends UserModel {
  events: EventModel[]
  pastEvents: EventModel[]
  followingDocId: string | undefined
}

interface IInitialState {
  originalOrganizers: IOrganizer[]
  organizers: IOrganizer[]
}

const initialState: IInitialState = {
  originalOrganizers: [],
  organizers: [],
}

const organizersSlice = createSlice({
  name: 'organizers',
  initialState: initialState,
  reducers: {
    setOrganizers: function (state, { payload }) {
      state.organizers = payload
      state.originalOrganizers = payload
    },
    updateOrganizers: function (state, { payload }) {
      state.organizers = payload
    },
  },
})

export const { setOrganizers, updateOrganizers } = organizersSlice.actions

export const selectOrganizers = (state: RootState) => state?.organizers.organizers
export const selectOriginalOrganizers = (state: RootState) => state?.organizers.originalOrganizers

export const selectCurrentEntries = (state: RootState) => state.organizer.currentEntries
export default organizersSlice.reducer
