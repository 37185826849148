import { cmyk, PDFDocument, PDFFont, PDFImage, PDFPage, StandardFonts } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import moment from 'moment/moment'
import { collection, doc, where } from 'firebase/firestore'

import FirebaseStorageService from './storageService'
import FirestoreService from './firestoreService'
import helpers from '../commonHelpers/helpers'

import { capitalize, createString, CustomError, getUserFullName } from '../helpers/helpers'
import { sendEmailForSign } from '../templates/pdf/sendEmail/sendEmailForSign'

import {
  GetFileUrlProps,
  IAddNewPdfPageArgs,
  IPdfServiceArgs,
  IWriteLineArgs,
  SavePaperworkProps,
  TCertificate,
  WriteAuditTrailPageProps,
  WriteFirstPageProps,
} from '../types/pdf'
import { IUserDocument } from '../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../models/user-documents/user-documents.model'

import { MESSAGES_CONST } from '../const/messages-const'
import { CONST } from '../const/const'
import TimeLib from '../lib/Time'
import FirebaseApp from './firebaseApp'
import { getConvertedData } from '../models/interface.helper'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const CUSTOM_ERROR_PROPS = {
  fileName: 'pdfService',
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

class PdfService {
  private currentX: number = 45
  private currentY: number = 720
  private pdfPages: PDFPage[] = []
  readonly pageWidth: number = 550
  private pdfDoc: PDFDocument | null = null
  readonly pageHeight: number = 760
  private currPageNumber: number = 1
  readonly distanceBetweenLines: number = 20
  private currPdfPage: PDFPage | null = null
  readonly pageVerticalEndPoint: number = 80
  readonly pageVerticalStartPoint: number = 720
  readonly pageHorizontalEndPoint: number = 715
  readonly pageHorizontalStartPoint: number = 45

  constructor(args: IPdfServiceArgs) {
    this.pageWidth = args.pageWidth
    this.pageHeight = args.pageHeight
    this.distanceBetweenLines = args.distanceBetweenLines
    this.pageVerticalStartPoint = args.pageVerticalStartPoint
    this.pageHorizontalStartPoint = args.pageHorizontalStartPoint
    this.pageVerticalEndPoint =
      args.pageVerticalEndPoint ?? this.pageHeight - this.pageVerticalStartPoint
    this.pageHorizontalEndPoint =
      args.pageHorizontalEndPoint ?? this.pageWidth - this.pageHorizontalStartPoint
  }

  get getPdfInstance(): Promise<PDFDocument> {
    return new Promise(async (resolve) => {
      let created = false
      let pdfDoc: typeof this.pdfDoc

      if (this.pdfDoc) pdfDoc = this.pdfDoc
      else {
        pdfDoc = await PDFDocument.create()
        created = true
      }

      if (!pdfDoc) {
        let error = CustomError.somethingWentWrong({
          ...CUSTOM_ERROR_PROPS,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `this.pdfDoc is null`,
          moduleName: 'addNewPdfPage',
        })

        helpers.logger({
          message: error,
        })

        throw error
      }

      this.pdfDoc = pdfDoc

      if (created) this.registerFontkit()

      resolve(pdfDoc)
    })
  }

  get getPdfPagesCount(): number {
    return this.pdfPages.length
  }

  get getCurrentX(): number {
    return this.currentX
  }

  get getCurrentY(): number {
    return this.currentY
  }

  addNewPdfPage(args?: IAddNewPdfPageArgs): Promise<PDFPage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance

      let { width = this.pageWidth, height = this.pageHeight } = args ?? {}

      this.pdfDoc = pdfDoc
      this.currPdfPage = this.pdfDoc.addPage([width, height])
      this.pdfPages.push(this.currPdfPage)
      this.currPageNumber = this.getPdfPagesCount
      this.currentY = this.pageVerticalStartPoint - 40
      resolve(this.currPdfPage)
    })
  }

  get getCurrentPageNumber(): number {
    return this.currPageNumber
  }

  get generatedPdf(): Promise<Uint8Array> {
    return new Promise(async (resolve) => {
      this.pdfDoc = await this.getPdfInstance
      let pdfBytes = await this.pdfDoc.save()
      resolve(pdfBytes)
    })
  }

  embedFont(font_: ArrayBuffer | string): Promise<PDFFont> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const font = await pdfDoc.embedFont(font_)
      resolve(font)
    })
  }

  embedJpg(jpg: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedJpg(jpg)
      resolve(image)
    })
  }

  embedPng(png: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedPng(png)
      resolve(image)
    })
  }

  embedJpeg(jpg: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedJpg(jpg)
      resolve(image)
    })
  }

  registerFontkit(): void {
    this.pdfDoc?.registerFontkit(fontkit)
  }

  async writeLine(args: IWriteLineArgs): Promise<void> {
    let {
      content,
      x,
      y,
      fontSize,
      factor = 0.55,
      font = undefined,
      stateFromNewPage = false,
    } = args

    await new Promise(async (resolve) => {
      let workGettingBreak = false

      const charWidth = fontSize * factor
      let charactersPerLine = Math.floor(this.pageWidth / charWidth)
      let strToWrite = content.substring(0, charactersPerLine)
      content = content.substring(charactersPerLine)

      if (!strToWrite.endsWith(' ') && !content.startsWith(' ')) workGettingBreak = true

      if (workGettingBreak && !!content) {
        content = `${strToWrite.split(' ').at(-1)}${content}`
        strToWrite = strToWrite
          .split(' ')
          .splice(0, strToWrite.split(' ').length - 1)
          .join(' ')
      }

      const totalWidthToWriteContent =
        (strToWrite.length > 10 ? strToWrite.length - 10 : strToWrite.length) * charWidth

      this.currentX = x
      this.currentY = y

      if (this.getPdfPagesCount === 0) this.currPdfPage = await this.addNewPdfPage()

      if (this.currPdfPage) {
        if (this.currentY < this.pageVerticalEndPoint || stateFromNewPage) {
          this.currPdfPage = await this.addNewPdfPage()
        }

        if (this.currentX + strToWrite.length > this.pageHorizontalEndPoint)
          this.currentX = this.pageHorizontalStartPoint

        this.currPdfPage.drawText(strToWrite, {
          x: this.currentX,
          y: this.currentY,
          font,
          size: fontSize,
        })

        this.currentX = this.currentX + totalWidthToWriteContent
        this.currentY = this.currentY - this.distanceBetweenLines

        resolve(true)
      }
    })

    if (!!content)
      await this.writeLine({
        content,
        x: this.currentX,
        y: this.currentY,
        font,
        fontSize,
        factor,
      })
  }

  async writeFirstPage({
    title,
    EventDetails,
    organizerDetails,
    isStaff,
    registrationByDay,
    ridersTeamMember,
    teamMemberRole,
  }: WriteFirstPageProps) {
    const boldFont = await this.embedFont(StandardFonts.TimesRomanBold)
    const timeRomanFont = await this.embedFont(StandardFonts.TimesRoman)

    let shortLocation =
      EventDetails?.competitionAddressLine1 && EventDetails?.competitionAddressLine1.length < 30
        ? EventDetails.competitionAddressLine1
        : `${EventDetails.competitionCity}, ${EventDetails.competitionState}, ${EventDetails.competitionCountry}`

    if (this.getPdfPagesCount === 0) this.currPdfPage = await this.addNewPdfPage()

    if (this.currPdfPage) {
      await this.writeLine({
        content: `${title.toUpperCase()}`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY - 140,
        font: boldFont,
        fontSize: 22,
      })

      await this.writeLine({
        content: `Event:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY - this.distanceBetweenLines,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${capitalize(EventDetails.competitionName)}`,
        x: 87,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Host:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${capitalize(organizerDetails ? getUserFullName(organizerDetails) : 'No organizer on file')}`,
        x: 80,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Event location:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY - this.distanceBetweenLines,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${shortLocation}`,
        x: 138,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Event date:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`,
        x: 117,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Horse:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY - this.distanceBetweenLines,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${isStaff ? 'No horse on file' : capitalize(registrationByDay?.horseName ?? 'No horse on file')}`,
        x: 87,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Rider:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${isStaff ? 'No rider on file' : capitalize(ridersTeamMember.riderName)}`,
        x: 87,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Rider nationality:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${capitalize(ridersTeamMember.teamMemberCountry && ridersTeamMember.teamMemberCountry !== '' ? ridersTeamMember.teamMemberCountry : 'No nationality on file')}`,
        x: 156,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Signer role at event:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY - this.distanceBetweenLines,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${teamMemberRole}`,
        x: 168,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })

      await this.writeLine({
        content: `Signer name:`,
        x: this.pageHorizontalStartPoint,
        y: this.getCurrentY,
        font: boldFont,
        fontSize: 14,
      })
      await this.writeLine({
        content: `${ridersTeamMember.teamMemberName}`,
        x: 128,
        y: this.getCurrentY + this.distanceBetweenLines,
        font: timeRomanFont,
        fontSize: 14,
      })
    }

    return { shortLocation }
  }

  async getFileUrl({ ridersTeamMember, paperwork, eventId, index }: GetFileUrlProps) {
    const pdfBytes = await this.generatedPdf

    // Step 1: Convert Uint8Array to Blob
    const blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

    // Step 2: Convert Blob to File
    const file = new File([blob], `${paperwork.document_name}.pdf`, { type: 'application/pdf' })

    const fileUrl = await FirebaseStorageService.uploadFile(
      file,
      `/user/documents/${ridersTeamMember.teamMemberRole}${new Date().getTime()}/${ridersTeamMember.registrationDocId}/${ridersTeamMember.teamMemberId}/${eventId}/${paperwork.document_name}/${ridersTeamMember.riderId}-${index}`
    )

    return { fileUrl }
  }
  async savePaperwork({
    title,
    ridersTeamMember,
    eventId,
    paperwork,
    registrationByDay,
    isSigned,
    userData,
    isStaff,
    EventDetails,
    coordinatesToSignOn,
    isMature,
    customDocId,
    organizerDetails,
    eventDraftId,
    index,
    dateOfSignature,
  }: SavePaperworkProps) {
    const { fileUrl } = await this.getFileUrl({ paperwork, ridersTeamMember, eventId, index })

    let returnedData = {}

    try {
      const queries = [
        where(
          COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY,
          '==',
          ridersTeamMember.teamMemberId
        ),
        where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.RIDER_ID.KEY, '==', ridersTeamMember.riderId),
        where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_NAME.KEY, '==', paperwork.document_name),
        where(
          COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY,
          '==',
          ridersTeamMember.teamMemberRole
        ),
        where(
          COLLECTIONS.USERS_DOCUMENTS.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY,
          '==',
          registrationByDay?.id
        ),
      ]

      const eventsSnapshot = await FirestoreService.filterItems(
        COLLECTIONS.USERS_DOCUMENTS.NAME,
        queries
      )

      let obj: IUserDocument = {
        eventId,
        status: isSigned ? 'Signed' : 'Not Signed',
        documentName: paperwork.key,
        riderId: ridersTeamMember.riderId,
        activityUser: getUserFullName(userData),
        competitorId: userData.id,
        riderName: isStaff ? 'N/A' : ridersTeamMember.riderName,
        signatoryId: ridersTeamMember.teamMemberId,
        eventLogo: EventDetails.eventLogo,
        documentOwner: ridersTeamMember.teamMemberId,
        signatoryName: ridersTeamMember.teamMemberName,
        documentUrl: (fileUrl as string) ?? '',
        eventName: EventDetails.competitionName,
        documentOriginalName: paperwork.document_name,
        signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
        documentNameAsPerPdf: `${title}`,
        pageNumberToSignOn: this.getCurrentPageNumber,
        coordinatesToSignOn: coordinatesToSignOn,
        signatoryDefaultRole: ridersTeamMember.teamMemberRole,
        signatoryEmail: ridersTeamMember.teamMemberEmail,
        reminder: false,
        registrationDocId: ridersTeamMember.registrationDocId,
        riderTeamMemberDocId: ridersTeamMember.id ?? '',
        registrationByDayDocId: registrationByDay?.id ?? '',
        registrationByDayUuid: registrationByDay?.uuid ?? '',
        registrationByDayUniqId: registrationByDay?.uniqId || '',
        modified: new Date().toString(),
        dateOfSignature: isSigned ? dateOfSignature : null,
      }
      if (eventsSnapshot.size) {
        let docId = ''
        eventsSnapshot.forEach((doc) => {
          docId = UserDocumentModel.fromFirestoreDoc(doc).toObject().id
        })

        await FirestoreService.updateItem(
          COLLECTIONS.USERS_DOCUMENTS.NAME,
          customDocId || docId,
          obj
        )

        await FirestoreService.createItemWithCustomId(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
          customDocId || docId,
          {
            id: customDocId || docId,
            riderId: ridersTeamMember.riderId,
            eventId,
            signatoryId: ridersTeamMember.teamMemberId,
            logs: [
              {
                time: new Date(),
                audit: `${title} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
              },
            ],
          }
        )
        returnedData = {
          docId: customDocId || docId,
        }
      } else {
        const document = await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, obj)
        returnedData = {
          docId: document.id,
        }
        await FirestoreService.createItemWithCustomId(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
          document.id,
          {
            id: document.id,
            riderId: ridersTeamMember.riderId,
            eventId,
            signatoryId: ridersTeamMember.teamMemberId,
            logs: [
              {
                time: new Date(),
                audit: `${title} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
              },
            ],
          }
        )
      }
    } catch (error) {
      console.log(error, 'error')
    }

    if (fileUrl && fileUrl !== '' && eventId) {
      await sendEmailForSign({
        ridersTeamMember,
        paperwork,
        paperworkUrl: fileUrl as string,
        eventId,
        isMature,
        isStaff,
        eventDraftId,
        senderData: {
          senderName: getUserFullName(userData),
          senderEmail: userData.userEmail || '',
        },
      })
    }

    return { returnedData }
  }

  async writeAuditTrailPage({ title, ridersTeamMember }: WriteAuditTrailPageProps) {
    let imageType: string = ''

    const boldFont = await this.embedFont(StandardFonts.TimesRomanBold)

    const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
    const signatureFont = await this.embedFont(fontByte)

    const auditTrailPage = await this.addNewPdfPage()

    const AllLogs = {
      id: '',
      signatoryId: ridersTeamMember.teamMemberId ?? '',
      riderId: ridersTeamMember.riderId ?? '',
      eventId: ridersTeamMember.eventId ?? '',
      logs: [
        {
          time: TimeLib.utcTimestamp(),
          audit: `Document signed by ${ridersTeamMember.teamMemberName}`,
        },
      ],
    }

    let certificateBorder = [
      { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
      { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
      { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
      { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
    ]

    certificateBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 2,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    let documentRef = doc(collection(FirebaseApp.firestore, COLLECTIONS.USERS_DOCUMENTS.NAME))
    let id = documentRef.id

    auditTrailPage.drawText(`Signature Certificate`, {
      x: 80,
      y: 640,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 14,
    })

    auditTrailPage.drawText(`Document Name: ${title}`, {
      x: 80,
      y: 630,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })

    auditTrailPage.drawText(`Unique Document Id: ${id}`, {
      x: 80,
      y: 620,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 8,
    })

    let Image = ridersTeamMember.teamMemberProfilePicture
      ? ridersTeamMember.teamMemberProfilePicture
      : '/assets/img/Empty user.png'

    const getImageArray = async (imageUrl: string) => {
      return await fetch(imageUrl).then((res) => {
        const contentType = res.headers.get('content-type') ?? ''
        imageType = contentType?.split('/')[1]
        return res.arrayBuffer()
      })
    }

    let jpgImageBy = await getImageArray(Image)

    let userImage: PDFImage
    if (imageType === 'jpeg') {
      userImage = await this.embedJpg(jpgImageBy)
    } else if (imageType === 'png') {
      userImage = await this.embedPng(jpgImageBy)
    } else {
      const defaultImage = await getImageArray('/assets/img/Empty user.png')
      userImage = await this.embedPng(defaultImage)
    }

    auditTrailPage.drawImage(userImage, {
      x: 80,
      y: 480,
      width: 80,
      height: 80,
      opacity: 1,
    })

    const capatlize_name: string = capitalize(createString(ridersTeamMember.teamMemberName), {
      capitalizeAll: true,
    })
    auditTrailPage.drawText(capatlize_name, { x: 170, y: 550, color: cmyk(0, 0, 0, 0.9), size: 10 })

    auditTrailPage.drawText(`Security Level: Email`, {
      x: 170,
      y: 535,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })

    let signatureBorder = [
      { SX: 330, SY: 560, EX: 460, EY: 560 }, // Top Border
      { SX: 330, SY: 560, EX: 330, EY: 490 }, // Left Border
      { SX: 460, SY: 560, EX: 460, EY: 490 }, // Right Border
      { SX: 330, SY: 490, EX: 460, EY: 490 }, // Bottom Border
    ]

    signatureBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 1,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    auditTrailPage.drawText(`Digital Signature:`, {
      x: 340,
      y: 540,
      color: cmyk(0, 0, 0, 0.9),
      font: boldFont,
      size: 11,
    })

    auditTrailPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 340,
      y: 510,
      color: cmyk(0, 0, 0, 0.9),
      font: signatureFont,
      size: 28,
    })

    if (AllLogs.logs && AllLogs.logs.length) {
      auditTrailPage.drawText(`Timestamp`, {
        x: 110,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })

      auditTrailPage.drawText(`Audit Trails`, {
        x: 270,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })

      let logY = 420

      AllLogs.logs.forEach((log) => {
        let parsedDate = Date.parse(getConvertedData({ time: log.time as any }).time)

        let firstLineText = ''
        let secondLineText = ''
        let thirdLineText = ''

        if (log.audit.length > 40 && log.audit.length < 80) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
        } else if (log.audit.length > 40 && log.audit.length < 120) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
          thirdLineText = log.audit.slice(81, 120)
        } else if (log.audit.length < 40) {
          firstLineText = log.audit
        }

        auditTrailPage.drawText(`${moment(parsedDate).format(`MMMM DD, YYYY h:mm:ss a`)}`, {
          x: 110,
          y: logY,
          color: cmyk(0, 0, 0, 0.9),
          size: 8,
        })

        if (firstLineText && firstLineText !== '') {
          auditTrailPage.drawText(`${firstLineText}`, {
            x: 270,
            y: logY,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (secondLineText && secondLineText !== '') {
          auditTrailPage.drawText(`${secondLineText}`, {
            x: 270,
            y: logY - 10,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (thirdLineText && thirdLineText !== '') {
          auditTrailPage.drawText(`${thirdLineText}`, {
            x: 270,
            y: logY - 20,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        logY -= 40
        if (logY < 140) {
          logY = 620
          let certificateBorder = [
            { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
            { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
            { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
            { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
          ]

          certificateBorder.forEach((item: TCertificate) => {
            auditTrailPage.drawLine({
              start: { x: item.SX, y: item.SY },
              end: { x: item.EX, y: item.EY },
              thickness: 2,
              color: cmyk(0, 0, 0, 0.5),
              opacity: 0.8,
            })
          })
        }
      })
    }

    auditTrailPage.drawLine({
      start: { x: 70, y: 140 },
      end: { x: 470, y: 140 },
      thickness: 2,
      color: cmyk(0, 0, 0, 0.5),
      opacity: 0.8,
    })

    auditTrailPage.drawText(`This audit trail report provides a detailed record of the`, {
      x: 160,
      y: 110,
      font: boldFont,
      size: 10,
    })

    auditTrailPage.drawText(`online activity and events recorded for this contact.`, {
      x: 170,
      y: 90,
      font: boldFont,
      size: 10,
    })
  }
}

export default PdfService
