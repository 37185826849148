import React from 'react'

interface IProps {
  width?: string
  height?: string
}

export const IconClock: React.FC<IProps> = ({ width = 18, height = 21 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 21"
    >
      <path
        fill="#122B46"
        d="M7.75.5a.75.75 0 100 1.5h.5v1.038C3.638 3.421 0 7.291 0 12c0 4.962 4.038 9 9 9s9-4.038 9-9a8.953 8.953 0 00-2.134-5.805l.912-.913a.75.75 0 10-1.06-1.06l-.913.912A8.952 8.952 0 009.75 3.038V2h.5a.75.75 0 100-1.5h-2.5zm1.161 4.005a.75.75 0 00.182 0 7.471 7.471 0 015.25 2.228A7.474 7.474 0 0116.5 12c0 4.151-3.349 7.5-7.5 7.5A7.489 7.489 0 011.5 12a7.486 7.486 0 017.411-7.495zm3.324 3.488a.75.75 0 00-.515.227l-2.333 2.333a1.5 1.5 0 101.061 1.06L12.78 9.28a.75.75 0 00-.545-1.287z"
      ></path>
    </svg>
  )
}
