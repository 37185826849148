export const USEF_REQUIRED_COLUMNS = [
  'COMP YEAR',
  'USEF Comp ID number',
  'Class Number ',
  'Class Title',
  'USEF Section Code',
  "Hunter Fence Height   3, 3'3, 3'6, 3'9",
  'USHJA Declarations',
  'Fence  Height',
  'Fence Type',
  'Number of entries',
  'Prize Money awarded  ',
  'Prize Money Offered',
  'Qualifier Dressage or Eventing (Dressage mandatory)',
  'Placing',
  'Exhibitor Number ',
  'Horse USEF Number',
  'Horse Passport Number',
  'Horse Name',
  'Horse Registration/Breed Affiliate Number',
  'Horse FEI Number or Discipline Affiliate Number (USDF, USEA, etc.)',
  'Horse Year of Birth',
  'Horse Breed',
  'Rider USEF Number',
  'If No USEF Number, WHY?',
  'Rider Name',
  'Rider Street Address',
  'Rider City',
  'Rider State',
  'Rider Zip',
  'Rider Breed Affiliate No. (IAHA, AHA,  AMA, etc.)',
  'Rider Discipline Number or FEI Number or Additional Affiliate Number (USDF, USEA, etc.) ',
  'Owner USEF Number',
  'If No USEF Number, WHY?',
  'Owner Name',
  'Owner Street Address',
  'Owner City',
  'Owner State',
  'Owner Zip',
  'Owner Breed Affiliate No. (,AHA, IAHA, etc.)',
  'Owner Discipline Number or FEI Number or Additional Affiliate Number (USDF, USEA, etc.)',
  'Trainer USEF Number',
  'If No USEF Number, WHY?',
  'Trainer Name',
  'Trainer Address',
  'Trainer City',
  'Trainer State',
  'Trainer Zip',
  'Trainer Affiliate Number (USDF, AHA, IAHA, etc.)',
  'Judge 1 First Name',
  'Judge 1 Last Name',
  'Judge 1 Percentage',
  'Judge 1 Score (Optional except Dressage)',
  'Judge 2 First Name',
  'Judge 2 Last Name',
  'Judge 2 Percentage',
  'Judge 2 Score (Optional except Dressage)',
  'Judge 3 First Name',
  'Judge 3 Last Name',
  'Judge 3 Percentage',
  'Judge 3 Score (Optional except Dressage)',
  'Judge 4 First Name',
  'Judge 4 Last Name',
  'Judge 4 Percentage',
  'Judge 4 Score (Optional except Dressage)',
  'Judge 5 First Name',
  'Judge 5 Last Name',
  'Judge 5 Percentage',
  'Judge 5 Score (Optional except Dressage)',
  'Judge 6 First Name',
  'Judge 6 Last Name',
  'Judge 6 Percentage',
  'Judge 6 Score (Optional except Dressage)',
  'Dressage Score (Total)',
  'Dressage Percentage (Total)',
  'Dressage Level',
  'Dressage Rider Status',
  'Eventing Dressage Total Score',
  'Eventing Dressage Percentage',
  'Eventing XC Jump Pen',
  'Eventing XC Time Pen',
  'Eventing Score After XC',
  'Eventing SJ Jump',
  'Eventing SJ Time',
  'Eventing Final Score',
  'Dangerous Ride',
  '1st Inspection',
  'Final Inspection',
  'First Round Faults  (Jumping)',
  'First Round Time (Jumping)',
  'Second Round Faults (Jumping)',
  'Second Round Time (Jumping)',
  'Jump-off Faults',
  'Jump-off Time',
  'Reining Total Score',
  'Vaulting Round 1 Score',
  'Vaulting Round 2 Score',
  'Vaulting Overall Score',
  'Combined Driving Dressage Penalties',
  'Combined Driving Place after Dressage',
  'Time Pen A',
  'Time Pen D',
  'Time Pen E',
  'Combined Driving Place after Marathon',
  'Combined Driving Marathon score',
  'CONES Combined Driving  Time OB  Pen',
  'CONES Combined Driving OB Pen',
  'Combined Driving Cones Score',
  'Combined Driving Place in Cones ',
  'Combined Driving Total Pen',
  'Combined Driving Final Placing',
  'Arabian class codes (AHA)',
  'FEI Rider Nationality',
  'FEI Horse Nationality',
  'Riders Email Address',
  'Owner Email Address',
  'Number that Competed',
  'Number that Completed',
  'Add Back Money',
  'Horse/Rider Falls',
  'Judge 1 USEF ID',
  'Judge 2 USEF ID',
  'Judge 3 USEF ID',
  'Judge 4 USEF ID',
  'Judge 5 USEF ID',
  'Judge 6 USEF ID',
  'Judge 7 USEF ID',
  'Judge 7 First Name',
  'Judge 7 Last Name',
  'Judge 7 Percentage',
  'Judge7 Score (Optional except Dressage)',
  'Course Designer 1 USEF ID',
  'Course Designer 1 First Name',
  'Course Designer 1 Last Name',
  'Course Designer 2 USEF ID',
  'Course Designer 2 First Name',
  'Course Designer 2 Last Name',
] as const

// Type for a record based on the required columns
export type USEFRecord = {
  [key in (typeof USEF_REQUIRED_COLUMNS)[number]]: string | number
}
