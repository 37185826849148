// Components
import SwitchElementUpdated from '../../components/events/forms/sponsors/SwitchElement'
// Constants
import { IMAGE_CONSTS } from '../../const/image-const'

// Types
type IProps = {
  key: any
  currDoc: any
  type: string
  onToggle: (key: string, on: boolean) => void
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const SinglePaperworkRow = (props: IProps) => {
  const not_data = props?.currDoc

  return (
    <>
      <div className="flex flex-wrap justify-between gap-3 items-center w-full text-SeabiscuitDark200ThemeColor text-[14px]">
        {props?.type !== 'Settings' ? (
          <>
            <div className="w-[25%] flex items-center rounded-[12px]">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img
                  src={
                    not_data?.sender_image
                      ? not_data?.sender_image
                      : IMAGE_CONSTS.PLACEHOLDERS.HORSE
                  }
                  className="w-11 h-11"
                  alt="sender"
                />
              </span>
              <span
                className="capitalize text-ellipsis overflow-hidden whitespace-nowrap font-bold"
                title={not_data?.sender_name}
              >
                {not_data?.sender_name}
              </span>
            </div>

            <div className="w-[50%] flex items-center  rounded-[12px] text-ellipsis overflow-hidden">
              <span
                className=" capitalize text-ellipsis px-2 text-center overflow-hidden whitespace-nowrap"
                title={not_data?.message}
              >
                {not_data?.message}
              </span>
            </div>

            <div className="flex justify-end">
              <button className="inline-flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor">
                {`View >`}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex rounded-[12px] items-center w-full">
              <span className="p-1 shrink-0 mr-3 overflow-hidden flex items-center justify-center w-10 h-10">
                <img
                  src={not_data?.setting_logo}
                  className="w-full h-full object-cover"
                  alt="settings"
                />
              </span>
              <div className="flex items-center justify-between grow">
                <div className="flex flex-col">
                  <span
                    className="w-40 capitalize text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                    title={not_data?.setting_title}
                  >
                    {not_data?.setting_title}
                  </span>
                  <span className="capitalize font-normal text-[12px] leading-[25px]  text-SeabiscuitLightParagraphTextColor">
                    {not_data?.setting_description}
                  </span>
                </div>
                <SwitchElementUpdated
                  onSwitch={() => props?.onToggle(not_data?.key, !not_data?.on)}
                  on={not_data?.on}
                  onText="On"
                  offText="Off"
                  width={100}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <hr className="my-[30px]" />{' '}
    </>
  )
}

export default SinglePaperworkRow
