import AutorenewIcon from '@mui/icons-material/Autorenew'
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import { sumBy } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import * as XLSX from 'xlsx'

import InfiniteScrollDataTable from '../../../../../../../../components/common/tables/InfiniteScrollDataTable'
import { ReportsTabWrapper } from '../ReportsTabWrapper/ReportsTabWrapper'

import useToasterHelper from '../../../../../../../../helpers/ToasterHelper'
import { getUserFullName, toFixed } from '../../../../../../../../helpers/helpers'

import { useAppSelector } from '../../../../../../../../store/hooks'
import { selectUserReducer } from '../../../../../../../../store/user/userSlice'

import TimeLib from '../../../../../../../../lib/Time'

import { IUSEFESection, sectionCodesByDisciplines } from '../../../../data/reports/USEF'
import { IReports } from '../../ManageClinicReportsRoot'

import FirestoreService from '../../../../../../../../services/firestoreService'

import { RegistrationFeesType } from '../../../../../../../../models/event-fees/event-fees.interface'
import { EventResultsReportsModel } from '../../../../../../../../models/event-results-reports/event-results-reports.model'
import { IEventInterface } from '../../../../../../../../models/events/event.interface'
import { IHorseData } from '../../../../../../../../models/horse/horse.interface'
import { getConvertedData } from '../../../../../../../../models/interface.helper'
import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { ITeamMember, IUserInterface } from '../../../../../../../../models/users/user.interface'

import { CONST } from '../../../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../../../const/messages-const'
import { formatOptionLabel } from '../../../../../../../../helpers/select'
import { selectEventDetails } from '../../../../../../../../store/events/eventsSlice'
import { USEF_REQUIRED_COLUMNS, USEFRecord } from './usef-report-columns'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface IUSEFResultsReport {
  class: { uuid: string; name: string }
  discipline: string
  USEFSection: { code: string; name: string }
}

interface USEFResultsReportProps {
  event: IEventInterface | null
  registrationFees: any
  activeOption: IReports
  setActiveOption: (value: IReports) => void
  setActiveResult: (value: string) => void
  registeredUsersByDay: IRegistrationByDayInterface[] | null
  horses: IHorseData[] | null
  users: IUserInterface[] | null
  teamMembers: ITeamMember[] | null
}

function parseAddress(address: string) {
  const addressRegex = /^(.*),\s*(.*),\s*([A-Z]{2})\s*(\d{5})(?:,\s*USA)?$/
  const matches = address.match(addressRegex)

  if (matches) {
    return {
      street: matches[1],
      city: matches[2],
      state: matches[3],
      zip: matches[4],
    }
  } else {
    return null // Address format does not match the expected pattern
  }
}

export const USEFResultsReport: FC<USEFResultsReportProps> = ({
  event,
  registrationFees,
  activeOption,
  setActiveOption,
  setActiveResult,
  registeredUsersByDay,
  horses,
  users,
  teamMembers,
}) => {
  const toastFunctions = useToasterHelper()
  const eventDetails = useAppSelector(selectEventDetails)
  const user = useAppSelector(selectUserReducer)

  const [rows, setRows] = useState<IUSEFResultsReport[]>([])
  const [isReadyToExport, setIsReadyToExport] = useState(false)
  const [discipline, setDiscipline] = useState('')
  const [reportId, setReportId] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const isFilled = rows.filter((row) => row.discipline && row.USEFSection?.code)
    if (isFilled.length >= 1) {
      setIsReadyToExport(true)
    } else {
      setIsReadyToExport(false)
    }
  }, [rows])

  useEffect(() => {
    if (discipline) {
      const newRows = rows.map((row) => ({
        class: row.class,
        discipline: discipline,
        USEFSection: { code: '', name: '' },
      }))
      setRows(newRows)
      saveEventReport(newRows).then()
    }
  }, [discipline])

  useEffect(() => {
    getEventReport().then()
  }, [registrationFees])

  const saveEventReport = async (rows: IUSEFResultsReport[]) => {
    try {
      if (reportId) {
        await FirestoreService.updateItem(COLLECTIONS.EVENT_RESULTS_REPORTS.NAME, reportId, {
          rows,
          modified: TimeLib.utcTimestamp(),
        })
      } else {
        const ref = await FirestoreService.createItem(
          COLLECTIONS.EVENT_RESULTS_REPORTS.NAME,
          new EventResultsReportsModel({
            userId: user.userId,
            eventId: event?.id ?? '',
            eventName: event?.eventName ?? '',
            reportType: 'USEF Results',
            rows,
          }).toFirestore()
        )
        setReportId(ref.id)
      }
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const getEventReport = async () => {
    setLoading(true)

    const eventResultsReportSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_RESULTS_REPORTS.NAME,
      [
        where(COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.EVENT_ID.KEY, '==', event?.id),
        where(COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.USER_ID.KEY, '==', user.userId),
        where(COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.REPORT_TYPE.KEY, '==', 'USEF Results'),
      ]
    )

    if (eventResultsReportSnaps.size > 0) {
      const eventResultsReport = getConvertedData(
        EventResultsReportsModel.fromFirestoreDoc(eventResultsReportSnaps.docs[0]).toObject()
      )

      setRows(eventResultsReport?.rows ?? [])
      setReportId(eventResultsReport?.id ?? '')
    } else {
      const rows_: IUSEFResultsReport[] = []

      const registrationFee = [...registrationFees?.registrationFees]

      registrationFee.forEach((fee) => {
        rows_.push({
          class: { uuid: fee.uuid, name: fee.name },
          discipline: '',
          USEFSection: { code: '', name: '' },
        })
      })

      setRows(rows_)
      getEventReport().then()
    }

    setLoading(false)
  }

  const onExport = () => {
    if (isReadyToExport) {
      const requiredColumns = USEF_REQUIRED_COLUMNS
      // type for record based on the required columns

      const data: USEFRecord[] = []
      let maxJudges = 0

      registeredUsersByDay?.forEach((registeredUserByDay) => {
        const length = registeredUserByDay?.score?.judges?.length ?? 0
        if (maxJudges < length) maxJudges = length
      })

      registeredUsersByDay?.forEach((registeredUserByDay) => {
        const classes = registeredUsersByDay
          ?.filter(
            (registered) =>
              registered.riderId === registeredUserByDay.riderId &&
              registered.horseId === registeredUserByDay.horseId
          )
          .map((user) => user.registrationByDayName)

        const prizeMoneyTotal = [...registrationFees?.registrationFees].find(
          (registrationFee) => registrationFee.uuid === registeredUserByDay.uuid
        )?.prizeMoney

        const horse = horses?.find((horse) => horse.id === registeredUserByDay.horseId)
        const rider = users?.find((user) => user.id === registeredUserByDay.riderId)
        const owner = users?.find((user) => user.id === horse?.horseOwnerId)

        const teamMember = teamMembers?.find(
          (member) => member.horseId === horse?.id && member.memberRole === 'Trainer'
        )

        // if no trainer don't default to owner anymore
        const trainer = users?.find((user) => user.id === teamMember?.memberId)

        // base record with empty values for all required columns
        const record = {} as USEFRecord

        // Initialize all fields with empty strings
        requiredColumns.forEach((column) => {
          record[column] = ''
        })

        // Calculate judge information
        type JudgeInfo = {
          [key: string]: string | number
        }

        const judgeInfo: JudgeInfo = {}

        registeredUserByDay.score?.judges?.forEach((judge, index) => {
          let totalPoints = 0

          judge.sections.forEach((section) =>
            section.rows.forEach((row) => {
              if (row.coefficient) {
                totalPoints += Number(row.coefficient) * Number(row.score)
              } else {
                totalPoints += Number(row.score)
              }
            })
          )

          const maxPoints = sumBy(judge.sections, (section) => section.max)

          const firstName = `Judge ${index + 1} First Name` as string
          const lastName = `Judge ${index + 1} Last Name` as string
          const percentage = `Judge ${index + 1} Percentage` as string
          const score = `Judge ${index + 1} Score (Optional except Dressage)` as string

          judgeInfo[firstName] = judge.name.split(' ')[0] || ''
          judgeInfo[lastName] = judge.name.split(' ')[1] || ''
          judgeInfo[percentage] = maxPoints ? toFixed((totalPoints / maxPoints) * 100, 3) : ''
          judgeInfo[score] = totalPoints || ''
        })

        // Calculate dressage percentage
        let dressagePercentageTotal = 0
        let max = 0
        if (registeredUserByDay.score?.judges) {
          max = sumBy(registeredUserByDay.score.judges[0]?.sections, (section) => section.max)
          if (max) {
            dressagePercentageTotal =
              (Number(registeredUserByDay.score?.totalPoints ?? 0) / max) * 100
          }
        }

        // Fill in the data we have - won't have all USEF col data yet
        // Event and Class details
        record['COMP YEAR'] = new Date(registeredUserByDay.created as Date).getFullYear() || ''
        record['USEF Comp ID number'] = eventDetails.bodyCompId || ''
        record['Class Title'] = registeredUserByDay.registrationByDayName || ''
        record['USEF Section Code'] =
          rows.find((row) => row.class.uuid === registeredUserByDay.uuid)?.USEFSection.code || ''
        record['Number of entries'] = classes.length || ''
        record['Prize Money awarded  '] =
          toFixed(
            (prizeMoneyTotal * Number(registeredUserByDay.score?.earning) > 0
              ? Number(registeredUserByDay.score?.earning)
              : 0) / 100,
            3
          ) || ''
        record['Prize Money Offered'] = Number(prizeMoneyTotal) || ''
        record['Placing'] = registeredUserByDay.score?.rank || ''
        record['Exhibitor Number '] = registeredUserByDay.backNumber || ''

        // Horse details
        record['Horse USEF Number'] = horse?.horseUsefNumber || ''
        record['Horse Passport Number'] =
          horse?.horsePassportNumber && horse?.horsePassportNumber !== 'N/A'
            ? horse.horsePassportNumber
            : ''
        record['Horse Name'] = horse?.horseName || ''
        record['Horse FEI Number or Discipline Affiliate Number (USDF, USEA, etc.)'] =
          horse?.horseFeiNumber || ''
        record['Horse Year of Birth'] =
          new Date(horse?.horseDob as Date).getFullYear() > 0
            ? new Date(horse?.horseDob as Date).getFullYear()
            : ''
        record['Horse Breed'] = (horse?.horseBreed as string) || ''

        // Rider details
        record['Rider USEF Number'] = rider?.userUSEF?.documentNumber || ''
        record['Rider Name'] = rider?.id ? getUserFullName(rider) : ''

        const riderAddress = parseAddress(rider?.userAddress || '')
        record['Rider Street Address'] = riderAddress?.street || ''
        record['Rider City'] = riderAddress?.city || ''
        record['Rider State'] = riderAddress?.state || ''
        record['Rider Zip'] = riderAddress?.zip || ''
        record['FEI Rider Nationality'] = rider?.userNationality || ''
        record['Riders Email Address'] = rider?.userEmail || ''

        // Owner details
        record['Owner USEF Number'] = owner?.userUSEF?.documentNumber || ''
        record['Owner Name'] = owner?.id ? getUserFullName(owner) : ''

        const ownerAddress = parseAddress(owner?.userAddress || '')
        record['Owner Street Address'] = ownerAddress?.street || ''
        record['Owner City'] = ownerAddress?.city || ''
        record['Owner State'] = ownerAddress?.state || ''
        record['Owner Zip'] = ownerAddress?.zip || ''
        record['Owner Email Address'] = owner?.userEmail || ''

        // Trainer details
        record['Trainer USEF Number'] = trainer?.userUSEF?.documentNumber || ''
        record['Trainer Name'] = trainer?.id ? getUserFullName(trainer) : ''

        const trainerAddress = parseAddress(trainer?.userAddress || '')
        record['Trainer Address'] = trainerAddress?.street || ''
        record['Trainer City'] = trainerAddress?.city || ''
        record['Trainer State'] = trainerAddress?.state || ''
        record['Trainer Zip'] = trainerAddress?.zip || ''

        // Add judge information
        // We need to handle this carefully to avoid TypeScript errors
        for (const [key, value] of Object.entries(judgeInfo)) {
          if (requiredColumns.includes(key as any)) {
            ;(record as any)[key] = value
          }
        }

        // Dressage information
        record['Dressage Score (Total)'] = registeredUserByDay.score?.totalPoints || ''
        record['Dressage Percentage (Total)'] = `${
          dressagePercentageTotal > 0 && dressagePercentageTotal !== Infinity
            ? toFixed(dressagePercentageTotal, 3)
            : 0
        }%`
        record['Dressage Level'] =
          rows.find((row) => row.class.uuid === registeredUserByDay.uuid)?.USEFSection.name || ''
        record['Dressage Rider Status'] = rider?.userAmateur || ''

        // Completion information
        record['Number that Completed'] = classes.length || ''

        data.push(record)
      })

      // Convert data to a worksheet format
      const headers = requiredColumns
      const sheetRows = [
        // Create a new array of uppercase headers
        headers.map((header) => header.toUpperCase()) as unknown as string[],
        ...data.map((record) => headers.map((header) => record[header] || '')),
      ]

      // Calculate column widths
      const columnWidths = headers.map((header, colIndex) => {
        return {
          wch: Math.max(
            header.length,
            ...data.map((record) => (record[header] ? String(record[header]).length : 0)),
            30 // Minimum width
          ),
        }
      })

      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(sheetRows)
      ws['!cols'] = columnWidths

      // Create a workbook and add the worksheet
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'USEF Results')

      // Write the workbook to an Excel file
      XLSX.writeFile(wb, `Event Reports -USEF Results - ${event?.eventName}.xlsx`)
    } else {
      toastFunctions.success({
        message: MESSAGES_CONST.REPORT_EXPORT_IS_READY,
      })
    }
  }

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm">
          <span className="whitespace-nowrap">Class</span>
        </span>
      ),
      cell: (row: IUSEFResultsReport) => (
        <div
          className={clsx(
            'my-2 p-2 min-h-[56px] w-full rounded-md flex items-center border border-SeabiscuitLightThemeColorD3',
            row.discipline && row.USEFSection.code && 'bg-SeabiscuitGrayThemeColor'
          )}
        >
          {row.class.name}
        </div>
      ),
      width: '33.3%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm">
          <span className="whitespace-nowrap">Discipline</span>
        </span>
      ),
      cell: (row: IUSEFResultsReport, index: number) => (
        <div className="flex items-center w-full pl-2">
          <Select
            className="w-full"
            classNamePrefix="clinic-select"
            isClearable={false}
            isSearchable={false}
            placeholder="Select Discipline"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: '#D3DAEE',
                backgroundColor: row.discipline && row.USEFSection.code ? '#F6F7FB' : '#fff',
              }),
            }}
            options={Object.keys(sectionCodesByDisciplines).map((discipline) => ({
              label: discipline,
              value: discipline,
            }))}
            value={row.discipline ? { label: row.discipline, value: row.discipline } : null}
            onChange={async (value) => {
              rows[index].discipline = value?.value ?? ''
              rows[index].USEFSection = { code: '', name: '' }
              setRows([...rows])
              await saveEventReport(rows)
            }}
          />
        </div>
      ),
      width: '33.3%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm">
          <span className="whitespace-nowrap">Section codes</span>
        </span>
      ),
      cell: (row: IUSEFResultsReport, index: number) => {
        let sectionCodesOptions: { class: string; code: string }[]

        if (row.discipline) {
          sectionCodesOptions = sectionCodesByDisciplines[row.discipline as keyof IUSEFESection]
        } else {
          sectionCodesOptions = []
        }
        return (
          <div className="flex items-center w-full pl-2">
            <Select
              className="w-full"
              classNamePrefix="clinic-select"
              isClearable={false}
              isSearchable={false}
              placeholder={row.discipline ? 'Select Section Code' : 'Select a Discipline'}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: '#D3DAEE',
                  backgroundColor: row.discipline && row.USEFSection.code ? '#F6F7FB' : '#fff',
                }),
              }}
              options={
                sectionCodesOptions
                  ? sectionCodesOptions.map((discipline) => ({
                      label: discipline.class,
                      value: discipline.code,
                    }))
                  : []
              }
              value={
                row.USEFSection.code
                  ? { label: row.USEFSection.name, value: row.USEFSection.code }
                  : null
              }
              onChange={async (value) => {
                rows[index].USEFSection = value?.value
                  ? { code: value.value, name: value.label }
                  : { code: '', name: '' }
                setRows([...rows])
                await saveEventReport(rows)
              }}
            />
          </div>
        )
      },
      width: '33.3%',
    },
  ]

  return (
    <ReportsTabWrapper
      title="USEF Results"
      description="Match classes to section codes to print"
      activeOption={activeOption}
      setActiveOption={setActiveOption}
      onBack={() => setActiveResult('')}
      onExport={onExport}
      isReadyToExport={isReadyToExport}
      filledRows={
        rows.length - rows.filter((row) => row.discipline && row.USEFSection?.code).length
      }
      additional={
        <Select
          value={
            discipline
              ? {
                  label: discipline,
                  value: discipline,
                }
              : null
          }
          options={Object.keys(sectionCodesByDisciplines).map((discipline) => ({
            label: discipline,
            value: discipline,
          }))}
          className="min-w-[300px]"
          isSearchable={false}
          placeholder="Discipline"
          formatOptionLabel={formatOptionLabel}
          styles={{
            control: (styles) => ({
              ...styles,
              borderRadius: '8px',
              borderColor: '#D3DAEE',
            }),
          }}
          onChange={(value) => {
            setDiscipline(value?.value as IReports)
          }}
          components={{
            DropdownIndicator: (props_) => {
              return (
                <components.DropdownIndicator {...props_} className="[&>svg>path]:fill-[#122B46]" />
              )
            },
            IndicatorSeparator: () => null,
          }}
        />
      }
    >
      {!loading ? (
        <InfiniteScrollDataTable
          hasMore={false}
          className="exhibitorListTable"
          columns={columns}
          data={rows}
        />
      ) : (
        <div className="flex items-center justify-center p-4">
          <AutorenewIcon fontSize="small" className="animate-spin" />
        </div>
      )}
    </ReportsTabWrapper>
  )
}
