// Third party
import { UseFormReturn } from 'react-hook-form'

// Custom imports
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import FeesRoot from '../../event-registration-tabs/components/fees/FeesRoot'

// Types
import { IFees } from '../../../../models/event-drafts/event-draft.interface'

import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { IOnChangeFees } from '../../event-registration-tabs/hooks/useEventRegistrationTabs/useEventRegistrationTabs'

type CompetitorEventRegisterFeesTabProps = {
  title?: string
  feesItemsForm: UseFormReturn
  description?: string
  fees: IFees[]
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  onChangeFees: ({ setManageInfo, manageInfo }: IOnChangeFees) => Promise<null | undefined>
}

const CompetitorEventRegisterFeesTab = (props: CompetitorEventRegisterFeesTabProps) => {
  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <div id="rtc">
        <FeesRoot
          feesItemsForm={props.feesItemsForm}
          handleModal={props.handleModal}
          onChangeFees={props.onChangeFees}
          isManage={props.isManage}
          manageInfo={props.manageInfo}
          setManageInfo={props.setManageInfo}
        />
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterFeesTab
