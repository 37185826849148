// Library
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

// MUI
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'

// CONST
import { CONST } from '../../../../const/const'
import { RedTitle } from './global/RedTitle'

// Component
import Accordion from '../../../accordion/common/Accordion'
import ExhibitorResultsAccordion from './accordions/ExhibitorResultsAccordion'
import ViewsLoader from '../../../loader/ViewsLoader'

// FireBase
import FirestoreService from '../../../../services/firestoreService'

// Helper
import { getRegisteredRiders } from '../../../../helpers/riders'
import { getUserFullName } from '../../../../helpers/helpers'

// Models
import { useAppSelector } from '../../../../store/hooks'
import { selectAllUsers } from '../../../../store/users/usersSlice'

import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const EventResultsTab = () => {
  // Hooks and vars
  const eventId = useParams<{ eventId: string }>().eventId ?? null

  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [classOption, setClassOptions] = useState<any[]>([])

  const users = useAppSelector(selectAllUsers)

  // Functions
  const handleGetFeesData = async () => {
    try {
      setLoading(true)
      const snapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)
      // let docExists = snapShot?.exists()
      const data = snapShot.data()

      const tempOptions = Object.values(
        [...data?.registrationFees].reduce((acc: any, item: any) => {
          acc[item.uuid] = {
            value: item.uuid,
            label: item.name,
            publishScore: item.publishScore,
            publishPrize: item.publishPrize,
            prizeMoney: item.prizeMoney,
            isAsc: item.isAsc,
          }
          return acc
        }, {})
      )
      setClassOptions(tempOptions)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // Fetching Riders and Horses from REGISTRATION_BY_DAY Collections (Depends on Class)
  const handleGetOrderData = async () => {
    setLoading(true)

    try {
      const filterClasses = await getRegisteredRiders([eventId])

      const uniqueData = filterClasses.reduce((acc: IRegistrationByDayInterface[], currentItem) => {
        // Check if the riderId of the current item is already in the accumulator
        const isRiderIdExist = acc.filter(
          (item: any) => item.riderId === currentItem.riderId && item.uuid === currentItem.uuid
        )

        // If riderId doesn't exist, add the current item to the accumulator
        if (isRiderIdExist.length <= 0) {
          const riderAsUserObj = users.find((user) => user.id === currentItem.riderId)

          acc.push({
            ...currentItem,
            riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : currentItem.riderName,
          })
        }
        return acc
      }, [])

      const combinedArray = classOption.map((item1: any) => {
        const correspondingItem = uniqueData.filter((item2: any) => item1.value === item2.uuid)
        return {
          ...item1,
          data: correspondingItem,
        }
      })

      setData(combinedArray)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetFeesData().then()
  }, [])

  useEffect(() => {
    handleGetOrderData().then()
  }, [classOption])

  return (
    <>
      <RedTitle text="Results" className="mb-4 sm:mb-7" />

      {loading && (
        <div className="flex justify-center items-center h-[100px]">
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F70763"
          />
        </div>
      )}

      {Object.keys(data)?.length
        ? data.map((item: any, index: number) => (
            <Accordion
              key={index}
              icon={<AddCircleOutline className="add_icon text-[red]" />}
              initialState={false}
              status={!item.publishScore ? 'Pending' : 'Available'}
              className="mb-2"
              header={<div className="flex gap-2 text-[14px]">{item.label}</div>}
              headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
              activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
            >
              <ExhibitorResultsAccordion {...item} eventId={eventId} />
            </Accordion>
          ))
        : !loading && <p className="text-SeabiscuitDark200ThemeColor/50">Results not found</p>}
    </>
  )
}

export default EventResultsTab
