import React, { useEffect, useRef, useState } from 'react'

import { Add, AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { sumBy } from 'lodash'

import MainModal from 'web/src/components/modals/common/MainModal'
import TogglesElement from '../elements/toggles/toggles/TogglesElement'
import { IconEmpty } from '../icons/IconEmpty'
import { IconFile } from '../icons/IconFile'
import { JudgeItem } from './components'

// Redux
import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'

// Helpers
import useToasterHelper from '../../helpers/ToasterHelper'

// Services
import FirestoreService from '../../services/firestoreService'
import FirebaseStorageService from '../../services/storageService'

//Constants
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'

// Components
import helpers from '../../commonHelpers/helpers'
import { getUserFullName, toFixed } from '../../helpers/helpers'

// Models
import { RegistrationFeesType } from '../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../models/event-fees/event-fees.model'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import { EventReviewPublishModel } from '../../models/event-review-publish/event-review-publish.model'
import { IEventStaffInterface } from '../../models/event-staff/event-staff.interface'
import { EventStaffModel } from '../../models/event-staff/event-staff.model'
import { getConvertedData } from '../../models/interface.helper'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
import { IUserInterface } from '../../models/users/user.interface'
import { UserModel } from '../../models/users/user.model'

import { IMAGE_CONSTS } from '../../const/image-const'
import { MODAL_CONSTS } from '../../const/modal-const'

type ScoringModalProps = {
  show: boolean
  handleModal: any
  dataToPassOn: {
    editable?: boolean
    onSaveScoring?: () => void
    onChangeJudge?: (
      row: RegistrationFeesType,
      values: { label: string; value: string }[]
    ) => Promise<void>
    rider: IRegistrationByDayInterface
    prizeMoney?: string
  }
}

interface IUserInterfaceNew extends IUserInterface {
  selected?: boolean
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const ScoringModal = ({ dataToPassOn, show, handleModal }: ScoringModalProps) => {
  const [row, setRow] = useState<IRegistrationByDayInterface>(dataToPassOn.rider)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [file, setFile] = useState<any>(dataToPassOn.rider?.score?.file ?? null)
  const [results, setResults] = useState<IRegistrationByDayInterface['score']>(
    dataToPassOn.rider?.score
  )
  const [maxPoints, setMaxPoints] = useState(0)
  const [totalPoints, setTotalPoints] = useState(0)
  const [feedback, setFeedback] = useState<string>(dataToPassOn.rider?.score?.details ?? '')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<IUserInterface[] | null>(null)
  const [publishedEvent, setPublishedEvent] = useState<IEventReviewPublish | null>(null)
  const [isLocked, setIsLocked] = useState(false)
  const [isRemarks, setIsRemarks] = useState(false)

  const fileInputRef = useRef() as any
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)

  async function fileChangedHandler(event: any) {
    const files = event.target.files
    const file = files[0]

    if (!file) return toastFunctions.success({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setUploadLoading(true)

    const PATH = CONST.DATA.STORAGE.EVENTS.EVENT_SCORE.PREFIX

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(file, `${PATH}/${userId}`)

      const newData = new RegistrationByDayModel(row)

      newData.score = { ...newData.score, file: downloadUrl as string }
      setFile(downloadUrl)

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        newData.id,
        newData.toFirestore()
      )
      setRow(newData)

      toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCORE_UPLOAD })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setUploadLoading(false)
    }
  }

  const handleSave = async () => {
    setLoading(true)

    const updatedRegistrationByDay = {
      ...row,
      score: {
        ...row.score,
        totalPoints: results?.sheet?.name ? totalPoints : (row.score?.totalPoints ?? null),
        sheet: results?.sheet ?? null,
        judges: results?.judges ?? null,
        details: feedback ?? '',
        file: file ?? null,
      },
    }

    // Update firestore collection
    const item = new RegistrationByDayModel(updatedRegistrationByDay)

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      item.id,
      item.toFirestore()
    )

    if (dataToPassOn?.onSaveScoring) dataToPassOn.onSaveScoring()

    toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCORE_UPDATED })
    setLoading(false)
    handleModal(false, MODAL_CONSTS.SCORING)
  }

  useEffect(() => {
    if (results?.sheet?.name && results?.judges && results.judges.length > 0) {
      setMaxPoints(sumBy(results.judges[0].sections, (section) => section.max))

      let totalPoints_ = 0
      results.judges.forEach((judge) => {
        judge.sections.forEach((section) =>
          section.rows.forEach((row) => {
            if (row.coefficient) {
              totalPoints_ += Number(row.coefficient) * Number(row.score)
            } else if (row.fraction === 'minus') {
              totalPoints_ -= Number(row.score)
            } else {
              totalPoints_ += Number(row.score)
            }
          })
        )

        totalPoints_ -= judge.errors
      })

      setTotalPoints(Math.round((totalPoints_ / results.judges.length) * 1000) / 1000)
    } else {
      setMaxPoints(0)
    }
  }, [results])

  useEffect(() => {
    if (Number(row.score?.totalPoints) > 0) {
      setIsLocked(true)
    }
  }, [row.score?.totalPoints])

  const getEventAndUsers = async () => {
    const users_: IUserInterface[] = []

    if (row.eventId) {
      const publishedEventSnapShot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        row.eventId
      )

      const publishedEvent_ =
        EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()
      setPublishedEvent(publishedEvent_)

      const staffs: IEventStaffInterface = new EventStaffModel(
        publishedEvent_?.EventStaff
      ).toFirestore()

      const judgesStaff_ = staffs.eventStaff?.find((staff) => staff.title === 'Judges')

      if (judgesStaff_?.value && judgesStaff_.value.length > 0) {
        const usersSnap = await FirestoreService.getItemsUsingIds(
          COLLECTIONS.USERS.NAME,
          judgesStaff_.value
        )

        usersSnap.forEach((curr) => {
          users_.push(getConvertedData(UserModel.fromFirestoreDoc(curr).toObject()))
        })

        const usersWithSelection = users_.map((user) => {
          const current = results?.judges?.find((judge) => judge.id === user.id)
          if (current?.id) {
            return { ...user, selected: true }
          } else {
            return { ...user, selected: false }
          }
        })

        setUsers(usersWithSelection)
      }
    }
  }

  useEffect(() => {
    getEventAndUsers().then()
  }, [row.eventId])

  const onSaveJudge = async (current: IUserInterface[]) => {
    try {
      const dataToSave: IEventStaffInterface = new EventStaffModel(
        publishedEvent?.EventStaff
      ).toFirestore()

      const usersIds = current.map((user) => user.id)

      const newEventStaff = dataToSave.eventStaff?.map((eventStaff) => {
        if (eventStaff.id === 5 && eventStaff.title === 'Judges') {
          return {
            ...eventStaff,
            value: usersIds,
          }
        } else {
          return eventStaff
        }
      })

      const updatedData = new EventStaffModel({
        eventStaff: newEventStaff ?? dataToSave.eventStaff,
        otherStaff: dataToSave.otherStaff,
        id: row.eventId,
        owner: userId,
      })

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_STAFF.NAME,
        row.eventId,
        updatedData.toFirestore()
      )

      await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, row.eventId, {
        ...publishedEvent,
        EventStaff: { ...publishedEvent?.EventStaff, eventStaff: newEventStaff },
      })

      if (row.eventId) {
        const registrationFees = new EventFeesModel(publishedEvent?.EventFees).toFirestore()

        if (registrationFees) {
          const registrations = [...(registrationFees?.registrationFees ?? [])]

          const currentFee = registrations.find((registration) => registration?.uuid === row.uuid)
          const values: { label: string; value: string }[] = current.map((user) => ({
            label: getUserFullName(user),
            value: user.id,
          }))

          if (dataToPassOn.onChangeJudge && currentFee)
            await dataToPassOn.onChangeJudge(currentFee, values)
        }
      }
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const onAddJudge = async () => {
    handleModal(false, MODAL_CONSTS.SCORING)
    handleModal(true, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD, {
      id: 5,
      row: users?.filter((user: IUserInterfaceNew) => user.selected),
      addNewPersonFeature: true,
      onSaveJudge: (newUser: IUserInterface) => {
        if (users && users.length > 0) {
          onSaveJudge([...users, newUser])
        } else {
          onSaveJudge([newUser])
        }
      },
      onSaveStaff: async (current: { selected: IUserInterface[] }) => {
        await onSaveJudge(current.selected)

        // update and reopen Scoring Modal
        const registrationsByDaySnapShot = await FirestoreService.getItemsUsingIds(
          COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          [row.id as string]
        )

        let registrationsByDay_: IRegistrationByDayInterface | null = null

        registrationsByDaySnapShot.forEach((curr) => {
          registrationsByDay_ = getConvertedData(
            RegistrationByDayModel.fromFirestoreDoc(curr).toObject()
          )
        })

        handleModal(true, MODAL_CONSTS.SCORING, {
          rider: registrationsByDay_,
          onSaveScoring: dataToPassOn.onSaveScoring,
          onChangeJudge: dataToPassOn.onChangeJudge,
          editable: true,
        })

        handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)
      },
    })
  }

  const showLockedModal = () => {
    handleModal(true, MODAL_CONSTS.RESULTS_LOCKED, {
      ...dataToPassOn,
      setIsLocked,
    })
  }

  let max = 0
  if (row.score?.judges) {
    max = sumBy(row.score.judges[0]?.sections, (section) => section.max)
  }

  return (
    <MainModal
      title="Results"
      titleClassName="my-4"
      size="xl"
      type="SCORING"
      show={show}
      buttons={[
        {
          hidden: !dataToPassOn.editable || !!(!results?.judges && results?.sheet?.name),
          loading: loading,
          label: 'Save & Lock',
          bgClass: 'bg-SeabiscuitMainThemeColor',
          textClass: 'text-white',
          onClick: () => handleSave(),
          borderClass: 'border !border-SeabiscuitMainThemeColor !w-full',
          onHoverClass: 'hover:bg-[#d70443]',
        },
        {
          label: 'Cancel',
          hidden: !!(!results?.judges && results?.sheet?.name),
          bgClass: 'bg-transparent',
          textClass: 'text-SeabiscuitMainThemeColor',
          onClick: () => handleModal(false, MODAL_CONSTS.SCORING),
          borderClass: 'border !border-SeabiscuitMainThemeColor !w-full',
          onHoverClass: 'hover:text-white hover:bg-SeabiscuitMainThemeColor',
        },
      ]}
    >
      <div className="text-SeabiscuitDark200ThemeColor ">
        {/* Rider Info */}
        <div className="flex justify-between mb-6">
          <div>
            <div className="flex gap-3 mb-2">
              <img
                className="w-[45px] h-[45px] rounded-lg object-cover"
                src={
                  row?.riderProfilePicture
                    ? row.riderProfilePicture
                    : IMAGE_CONSTS.PLACEHOLDERS.USER
                }
                alt=""
              />
              <div>
                <div className="opacity-50 text-sm">Rider</div>
                <div>{row?.riderName}</div>
              </div>
            </div>

            {/* Horse Info */}
            <div className="flex gap-3">
              <img
                className="w-[45px] h-[45px] rounded-lg object-cover"
                src={
                  row?.horseProfilePicture
                    ? row.horseProfilePicture
                    : 'https://via.placeholder.com/150'
                }
                alt=""
              />
              <div>
                <div className="opacity-50 text-sm">Horse</div>
                <div>{row?.horseName ?? 'N/A'}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end justify-between">
            <div className="flex items-center gap-2">
              <div className="text-right items-center">
                <div className="text-xs opacity-50">Back Number</div>
                <span>{Number(row.backNumber) > 0 ? row.backNumber : 'N/A'}</span>
              </div>
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Shortlist-2.svg" className="w-6" alt="" />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-right items-center">
                <div className="text-xs opacity-50">File</div>
                <div>
                  <label
                    className={clsx(
                      (dataToPassOn.editable || file) &&
                        'underline cursor-pointer hover:no-underline'
                    )}
                    onClick={(e) => {
                      if (file) {
                        if (!dataToPassOn.editable) {
                          e.preventDefault()
                          window.open(file, '_blank')
                        }
                      }
                    }}
                  >
                    {uploadLoading ? (
                      <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
                    ) : file ? (
                      dataToPassOn.editable ? (
                        'Update File'
                      ) : (
                        'View File'
                      )
                    ) : dataToPassOn.editable ? (
                      'Add File'
                    ) : (
                      'N/A'
                    )}
                    {dataToPassOn.editable && !uploadLoading && (
                      <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={(e: any) => fileChangedHandler(e)}
                      />
                    )}
                  </label>
                </div>
              </div>

              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <a target="_blank" href={file} rel="noreferrer">
                  <IconFile className="w-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {results?.sheet?.name && (
          <>
            <hr />
            <div className="mt-4">
              <div className="flex flex-wrap justify-between items-center gap-2">
                <h3 className="text-[25px] font-bold w-full md:w-8/12">{results.sheet.name}</h3>
                <div className="flex items-center gap-2">
                  <p className="text-base text-SeabiscuitDark200ThemeColor/50">Remarks</p>
                  <TogglesElement
                    uncheckedMessage=""
                    checkedMessage=""
                    on={isRemarks}
                    buttonClassName="h-[38px] rounded-full w-[70px]"
                    switchClassName="!rounded-full"
                    onToggle={() => {
                      if (userId === row.userId || dataToPassOn.editable) {
                        setIsRemarks(!isRemarks)
                      } else {
                        toastFunctions.info({ message: MESSAGES_CONST.NOT_REGISTERED })
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {/* Select and Input Fields */}
        <div className="my-4 flex gap-2">
          <div
            className={clsx(
              'py-2 px-3 flex justify-center items-center rounded-lg border border-[#D3DAEE] min-w-[56px]',
              dataToPassOn.rider?.score?.rank === 1 && Number(row.score?.totalPoints)
                ? 'bg-SeabiscuitGreenThemeColor text-white'
                : dataToPassOn.rider?.score?.rank === 2 && Number(row.score?.totalPoints)
                  ? 'bg-[#FFBD00] text-white'
                  : dataToPassOn.rider?.score?.rank === 3 && Number(row.score?.totalPoints)
                    ? 'bg-[#3B6AF6] text-white'
                    : dataToPassOn.editable
                      ? 'text-SeabiscuitDark200ThemeColor/50'
                      : 'bg-[#d3daee50] text-SeabiscuitDark200ThemeColor/50'
            )}
          >
            {dataToPassOn.rider.score?.rank && Number(row.score?.totalPoints)
              ? dataToPassOn.rider.score?.rank
              : 'Rank'}
          </div>
          <div
            className={clsx(
              'px-4 py-2 flex items-center rounded-lg border-SeabiscuitLightThemeColorD3 w-full bg-SeabiscuitLightThemeColorD3/50'
            )}
          >
            {results?.sheet?.name ? (
              <>
                Total Points:
                <div className="ml-auto flex items-center gap-3">
                  <p>
                    <span className="font-bold">{totalPoints || 0}</span> / {maxPoints}
                  </p>
                  <p className="bg-white rounded-full p-2 min-w-[72px] text-center">
                    {totalPoints > 0 ? toFixed((Number(totalPoints) / Number(max)) * 100, 3) : 0}%
                  </p>
                </div>
              </>
            ) : totalPoints ? (
              totalPoints
            ) : (
              '0'
            )}
          </div>
        </div>
        <hr />
        {results?.sheet?.name &&
          (results?.judges && results.judges.length > 0 ? (
            <div className="mt-4 flex flex-col gap-2">
              {results.judges.map((judge, judgeIndex) => (
                <JudgeItem
                  key={judgeIndex}
                  judge={judge}
                  judgeIndex={judgeIndex}
                  results={results}
                  setResults={setResults}
                  editable={dataToPassOn.editable ?? false}
                  isLocked={isLocked}
                  showLockedModal={showLockedModal}
                  maxPoints={maxPoints}
                  isRemarks={isRemarks}
                />
              ))}
              {dataToPassOn.editable && (
                <button
                  className="flex gap-2 items-center justify-end mt-2 text-base text-SeabiscuitMainThemeColor hover:opacity-70"
                  onClick={async () => {
                    if (isLocked) {
                      showLockedModal()
                    } else {
                      await onAddJudge()
                    }
                  }}
                >
                  <Add
                    fontSize="small"
                    className="border rounded-full border-SeabiscuitMainThemeColor"
                  />
                  Add Judge
                </button>
              )}
            </div>
          ) : dataToPassOn.editable ? (
            <div className="py-10 px-4 flex items-center justify-center flex-col">
              <p>You must add a judge to load scoring form</p>
              <div className="w-[138px] py-10 mx-auto">
                <IconEmpty className="w-full" />
              </div>
              <button
                className="flex gap-2 items-center justify-center text-base text-SeabiscuitMainThemeColor hover:opacity-70"
                onClick={async () => {
                  if (isLocked) {
                    showLockedModal()
                  } else {
                    await onAddJudge()
                  }
                }}
              >
                <Add
                  fontSize="small"
                  className="border rounded-full border-SeabiscuitMainThemeColor"
                />
                Add Judge
              </button>
            </div>
          ) : (
            <div className="py-10 px-4 flex items-center justify-center flex-col">
              <p>No Added Judges</p>
            </div>
          ))}

        {!results?.judges ||
          (!results?.sheet?.name && (
            <textarea
              disabled={!dataToPassOn.editable}
              placeholder={dataToPassOn.editable ? 'Enter notes' : 'No notes'}
              className={clsx(
                'rounded-lg border-[#D3DAEE] w-full h-72 mt-4 mb-4',
                !dataToPassOn.editable && 'bg-[#d3daee50]'
              )}
              value={feedback}
              onChange={(e) => {
                if (isLocked) {
                  showLockedModal()
                } else {
                  setFeedback(e.target.value)
                }
              }}
            />
          ))}
      </div>
    </MainModal>
  )
}

export default ScoringModal
