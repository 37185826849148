import { useContext, useEffect, useState } from 'react'
import { where } from 'firebase/firestore'

// Components
import MainModal from './common/MainModal'
import LightboxCommonComponent from '../common/dialogs/full-screen/LightboxCommonComponent'

import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import useToasterHelper from '../../helpers/ToasterHelper'
import { createString } from '../../helpers/helpers'
import { createMailLog, generatePaperworkDocument } from '../../helpers/sign'
import useGetEventData from '../../hooks/users/common/useGetEventData'
import { getConvertedData } from '../../models/interface.helper'

import FirestoreService from '../../services/firestoreService'

import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'

import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { ILightboxProps } from '../../types/competitor_types'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'

// Constants
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import fakeDocumentList from '../../fakeData/fakeDocumentList'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IViewModalProps = {
  dataToPassOn: {
    type?: 'Registration'
    data: {
      eventId: string
      eventName: string
      eventLogo: string
      documentOriginalName: string
      signatoryName?: string
      signatoryDefaultRole?: string
      registrationDocId?: string
      riderTeamMemberDocId?: string
    }
    onSignSuccess: () => void
  }
}
const SignDocumentModal = (props: IViewModalProps) => {
  const handleModalContext = useContext(HandleModalContext)
  const toastFunctions = useToasterHelper()
  const { getAllData } = useGetEventData()

  const userData = useAppSelector(selectProfileData)

  const [loading, setLoading] = useState(false)
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })
  const [registrationsByDay, setRegistrationsByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)

  const { onSignSuccess } = props.dataToPassOn
  const userDocument = props.dataToPassOn?.data

  const isCalledFromRegistration = props.dataToPassOn?.type === 'Registration'

  const handleAnnex = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REVIEW_ANNEX, {
      data: {
        eventId: userDocument.eventId ?? '',
        eventName: userDocument.eventName ?? '',
      },
      dataToPassOn: props.dataToPassOn,
    })
    handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT)
  }

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
      return riderTeamMembers
    } catch (error) {
      console.error(error, 'error')
      return []
    }
  }

  useEffect(() => {
    const getRegistrationsByDay = async () => {
      let registrationsByDay_: IRegistrationByDayInterface[] = []

      const registrationsByDaySnaps = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', userDocument.eventId)]
      )

      registrationsByDaySnaps.docs.forEach((currDoc) => {
        registrationsByDay_.push(
          getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      setRegistrationsByDay(registrationsByDay_)
    }
    getRegistrationsByDay().then()
  }, [userDocument.eventId])

  const handleSendMail = async () => {
    let registrationByDay: IRegistrationByDayInterface | null = null

    try {
      if (!userDocument.eventId) return null
      setLoading(true)
      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(
        userDocument?.registrationDocId ?? ''
      )

      const currRidersTeamMember = ridersTeamMembersInDb__.find(
        (member) => member.id === userDocument.riderTeamMemberDocId
      )

      registrationsByDay?.forEach((registrationByDay_) => {
        if (registrationByDay_.id === currRidersTeamMember?.registrationByDayDocId) {
          registrationByDay = registrationByDay_
        }
      })

      if (!registrationByDay) throw new Error('Error registration by day not found')

      if (!currRidersTeamMember) throw new Error('Error get Rider Team Member')

      function getDocumentType(): string {
        if (userDocument.documentOriginalName?.includes('USEF Entry')) {
          return 'usefentry'
        }
        return userDocument.documentOriginalName?.split(' ')[0].toLowerCase() || ''
      }

      const paperwork = fakeDocumentList.find((d) => d.document_type === getDocumentType())

      if (!paperwork) throw new Error('Error getting paperwork')

      const eventData = await getAllData(userDocument.eventId, ['v01_event_details'])

      if (!eventData || !eventData.v01_event_details)
        throw new Error('Error getting v01_event_details')

      await generatePaperworkDocument({
        paperwork,
        currRidersTeamMember,
        registrationByDay,
        userData,
        eventId: userDocument.eventId,
        EventDetails: eventData.v01_event_details,
        dateOfSignature: new Date(),
      })

      const teamMembersWithMailLog = await createMailLog({
        ridersTeamMembers: [currRidersTeamMember],
        teamMemberId: currRidersTeamMember.teamMemberId ?? '',
        paperworkDocument: paperwork,
      })

      if (!teamMembersWithMailLog) throw new Error('Error get Rider Team Member')

      await FirestoreService.updateItem(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        teamMembersWithMailLog[0].id,
        new RiderTeamMemberModel(teamMembersWithMailLog[0]).toFirestore()
      )

      onSignSuccess()
      toastFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })
      handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT)
    } catch (error) {
      console.error('error', error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleLightBox = (props: ILightboxProps) => {
    setLightBox({
      ...props,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param documentName of the paperwork doc whose image needs to be open in lightbox
   * @info Opens lightbox with paperwork document image
   */
  const openLightBox = (documentName: string) => {
    let paperworkImages = fakeDocumentList.find(
      (document) => document.document_name === documentName
    )?.document_image

    if (!Array.isArray(paperworkImages)) paperworkImages = paperworkImages ? [paperworkImages] : []

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({ src: current, title: documentName })
      return acc
    }, [])

    handleLightBox({
      visible: true,
      slides,
    })
  }

  /**
   * @info Document this
   */
  // Closes lightbox
  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  return (
    <MainModal
      title={isCalledFromRegistration ? 'More' : 'Review and Sign'}
      show={true}
      type="SIGN_DOCUMENT"
      size="md"
      footerClassName="justify-between pt-6"
      buttons={[
        ...(!isCalledFromRegistration
          ? [
              {
                label: 'Sign',
                className: '',
                bgClass: 'bg-SeabiscuitMainThemeColor',
                fullWidth: true,
                onClick: handleSendMail,
                onHoverClass: 'hover:bg-SeabiscuitMainThemeColorDark',
                textClass: 'text-white',
                disabled: loading,
                loading,
              },
            ]
          : []),
        {
          label: 'Cancel',
          fullWidth: true,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          borderClass: '!border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          disabled: loading,
          onClick: () => handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT),
        },
      ]}
    >
      <div>
        <div className="flex items-center border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] px-2 py-0.5 mt-2">
          <img
            onError={(e) => ((e.target as any).src = '/assets/Pegasus_app_logo.svg')}
            src={
              userDocument?.eventLogo && userDocument?.eventLogo !== ''
                ? userDocument?.eventLogo
                : '/assets/Pegasus_app_logo.svg'
            }
            className="w-[42px] h-[42px] rounded-lg shrink-0 mr-3  my-1"
            alt="Pegasus app logo"
          />
          <span
            className="w-full text-ellipsis text-SeabiscuitDark200ThemeColor font-normal overflow-hidden capitalize whitespace-wrap"
            title={'hello'}
          >
            {createString(userDocument.eventName)}
          </span>
        </div>
        <div className="flex-col items-center text-SeabiscuitDark200ThemeColor border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] mt-2 p-4">
          <div className="font-bold mb-3">Document Details:</div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/DocumentFlat.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'General Liability Waiver'}
              >
                {userDocument.documentOriginalName}
              </span>
            </div>
            <div
              className="underline cursor-pointer"
              onClick={() => openLightBox(userDocument.documentOriginalName ?? '')}
            >
              View {'>'}
            </div>
          </div>

          {userDocument.documentOriginalName === 'General Liability Waiver' && (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                  <img src="/assets/og_icons/DocumentFolded.svg" className="w-7 h-7" alt="logo" />
                </span>
                <span
                  className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                  title={'Signing as'}
                >
                  Annex
                </span>
              </div>
              <div className="underline cursor-pointer" onClick={handleAnnex}>
                View {'>'}
              </div>
            </div>
          )}

          {userDocument.signatoryDefaultRole && (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                  <img src="/assets/og_icons/Quill Pen-1.svg" className="w-7 h-7" alt="logo" />
                </span>
                <span
                  className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                  title={'Signing as'}
                >
                  Signing as:
                </span>
              </div>
              <div>{userDocument.signatoryDefaultRole}</div>
            </div>
          )}

          {userDocument.signatoryName && (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                  <img src="/assets/og_icons/People-1.svg" className="w-7 h-7" alt="logo" />
                </span>
                <span
                  className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                  title={'On Behalf of'}
                >
                  On Behalf of:
                </span>
              </div>
              <div>{userDocument.signatoryName}</div>
            </div>
          )}
        </div>
        <div className="flex border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2">
          <span className="max-h-[30px] mt-2 p-1 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img src="/assets/og_icons/People-1.svg" className="w-7 h-7" alt="peopleIcon" />
          </span>
          <div className="flex flex-col ">
            <span
              className="w-40 mb-4 mt-2 text-ellipsis text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
              title={'Who must sign?'}
            >
              {'Who must sign?'}
            </span>

            <span className="font-normal leading-[25px] mb-3  text-SeabiscuitDark200ThemeColor">
              All people attending the event in person, as well as riders, trainers, owners, coaches
              or legal guardians associated with a horse competing in this event.
            </span>
          </div>
        </div>
        <div className="flex  border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2">
          <span className="max-h-[30px] p-1 mt-2 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img src="/assets/og_icons/Error-1.svg" className="w-7 h-7" alt="errorIcon" />
          </span>
          <div className="flex flex-col ">
            <span
              className="w-40 mt-2 text-ellipsis text-SeabiscuitDark200ThemeColor font-normal leading-[25px] overflow-hidden mb-4 whitespace-nowrap"
              title={'Disclaimer'}
            >
              {'Disclaimer'}
            </span>
            <span className="font-normal leading-[25px] mb-3 text-SeabiscuitDark200ThemeColor">
              By clicking sign, you confirm you have read and agree to the terms and conditions in
              both this document and annex.
            </span>
          </div>
        </div>
      </div>

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </MainModal>
  )
}

export default SignDocumentModal
