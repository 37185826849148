// Libraries
import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  EmailAuthProvider,
  getAuth,
  signInWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'

// Common
import MainModal from '../common/MainModal'

// Helpers
import MessageHelperComp from '../../../helpers/MessageHelper'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'

// Models
import { UserModel } from '../../../models/users/user.model'
import { ProfileModel } from '../../../models/profiles/profile.model'
import { getConvertedData } from '../../../models/interface.helper'

// Services
import FirestoreService from '../../../services/firestoreService'
import { httpService } from '../../../services/httpService'

// Store
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { storeUseMode } from '../../../store/system/systemThunk'
import {
  selectUserId,
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import { selectAllUsers } from '../../../store/users/usersSlice'

// CONSTS
import { CONST } from '../../../const/const'
import FIREBASE_CONST from '../../../const/firebase-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { SwitchAccountFirstStep } from './SwitchAccountFirstStep'
import { ESwitchScreen, SwitchAccountTabs } from './SwitchAccountTabs'
import { SwitchAccountWhoHasAccess } from './SwitchAccountWhoHasAccess'
import { IProfile, IProfileHasAccess, IRemoveProfile } from './types/switchAccountTypes'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

type IProps = {
  show: boolean
  handleModal: (show: boolean, modal_name: string, data?: any) => void
  dataToPassOn: any
}

interface IRespLoginUserInfo {
  uid: string
  lastSignInTime: IProfileHasAccess['lastSignInTime']
}

const formschema = yup.object().shape({
  formData: yup.object().shape({
    email: yup.string().email('provide valid email').required('This field is required'),
    password: yup.string().required('This field is required'),
  }),
  message: yup.string(),
  selectedProfileIndex: yup.number().nullable(),
  profiles: yup
    .array(
      yup.object().shape({
        userDocId: yup.string(),
        name: yup.string().nullable(),
        userType: yup.string().nullable(),
        userProfilePicture: yup.string().nullable(),
      })
    )
    .nullable(),
})

const FORM_DEFAULT = {
  formData: {
    email: '',
    password: '',
  },
  message: '',
  selectedProfileIndex: null as number | null,
  profiles: [] as IProfile[],
  hasAccessProfiles: [] as IProfileHasAccess[],
}

const SwitchAccounts = (props: IProps) => {
  const userId = useAppSelector(selectUserId)
  const users = useAppSelector(selectAllUsers)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(true)
  const [addingAccount, setAddingAccount] = useState(false)
  const [switchingAccount, setSwitchingAccount] = useState(false)
  const [activeScreen, setActiveScreen] = useState<ESwitchScreen>(ESwitchScreen.yourAccounts)
  const [allProfiles, setAllProfiles] = useState<ProfileModel[]>([])
  const [innerType, setInnerType] = useState('password')
  const [profileToRemove, setProfileToRemove] = useState<IRemoveProfile | null>(null)

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formschema),
    defaultValues: { ...FORM_DEFAULT },
  })

  const { append, fields } = useFieldArray({
    control,
    name: 'profiles',
  })
  const {
    append: appendHasAccess,
    remove: removeAccessFiled,
    fields: fieldsHasAccess,
  } = useFieldArray({
    control,
    name: 'hasAccessProfiles',
  })

  useEffect(() => {
    if (userId) fetchAllProfiles().then()
    else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const getLoginInfoByIds = async (userIds: string[]): Promise<IRespLoginUserInfo[]> => {
    try {
      const res = await httpService({
        url: `get_users_login_info_by_ids`,
        method: 'POST',
        data: {
          userIds,
        },
      })
      return res.users || ([] as IRespLoginUserInfo[])
    } catch (e) {
      console.error(e)
      return []
    }
  }

  const fetchAllProfiles = async () => {
    try {
      setLoading(true)
      const profilesSnap = await FirestoreService.filterItems(COLLECTIONS.PROFILES.NAME)
      const myProfiles: IProfile[] = []
      const profilesModel: ProfileModel[] = []
      const hasAccessProfiles: IProfileHasAccess[] = []

      profilesSnap.forEach((profileSnap) => {
        const profile = getConvertedData(ProfileModel.fromFirestoreDoc(profileSnap))
        profilesModel.push(profile)
      })

      const myProfile: ProfileModel | undefined = profilesModel.find(
        (profile) => profile.id === userId
      )
      const hasAccessUsersIds: string[] = []
      profilesModel.forEach((profile) => {
        if (profile.allowedUids.includes(userId!)) {
          const user = users.find((user) => user.id === profile.id)
          if (user) {
            hasAccessUsersIds.push(user.id)
            hasAccessProfiles.push({
              userDocId: user?.id,
              name: getUserFullName(user),
              userType: user?.userType ?? null,
              userProfilePicture: user?.userProfilePicture ?? null,
              lastSignInTime: '',
            })
          }
        }
      })

      if (hasAccessProfiles.length) {
        const usersLoginData = await getLoginInfoByIds(hasAccessUsersIds)

        usersLoginData.forEach((userLoginData) => {
          const profileIndex = hasAccessProfiles.findIndex(
            (profile) => profile.userDocId === userLoginData.uid
          )
          if (profileIndex >= 0) {
            hasAccessProfiles[profileIndex] = {
              ...hasAccessProfiles[profileIndex],
              lastSignInTime: userLoginData.lastSignInTime,
            }
          }
        })

        setValue('hasAccessProfiles', hasAccessProfiles)
      }

      setAllProfiles(profilesModel)

      if (myProfile) {
        myProfile.allowedUids.forEach((uid) => {
          const user = users.find((user) => user.id === uid)
          if (user?.id && user?.id !== userId) {
            myProfiles.push({
              userDocId: user?.id,
              name: getUserFullName(user),
              userType: user?.userType ?? null,
              userProfilePicture: user?.userProfilePicture ?? null,
            })
          }
        })
      }
      if (!myProfiles.length) {
        return setValue('message', 'No profiles are added to this account')
      }

      setValue('profiles', myProfiles)
    } catch (e) {
      console.error(e)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  const getProfiles = async () => {
    const profiles: IProfile[] = []

    const profilesSnaps = await FirestoreService.getItem(COLLECTIONS.PROFILES.NAME, userId ?? '')

    const profile = getConvertedData(ProfileModel.fromFirestoreDoc(profilesSnaps).toObject())

    profile.allowedUids.forEach((uid) => {
      const user = users.find((user) => user.id === uid)
      const hasUser = fields.find((existUser) => existUser.userDocId === user?.id)
      if (user?.id && user?.id !== userId && !hasUser) {
        profiles.push({
          userDocId: user?.id,
          name: getUserFullName(user),
          userType: user?.userType ?? null,
          userProfilePicture: user?.userProfilePicture ?? null,
        })
      }
    })

    return profiles
  }
  const onSwitchAccountButtonClick = async () => {
    try {
      if (typeof watch('selectedProfileIndex') !== 'number') {
        throw new Error('Please select a profile to proceed further')
      }

      setSwitchingAccount(true)

      const res = await httpService({
        url: `get_switch_profile_token/${fields[watch('selectedProfileIndex')!].userDocId}`,
        method: 'GET',
      })

      const auth = getAuth()
      const userCredentials = await signInWithCustomToken(auth, res.data.token)

      const user_data_doc = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        userCredentials.user.uid
      )

      const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

      dispatch(setEmail(userData.userEmail?.trim()))
      dispatch(setUsername(userData.userName?.trim()))
      dispatch(setDisplayName(getUserFullName(userData)))
      dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

      if (userData.userType === CONST.USE_MODE.COMPETITOR) {
        dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
        history.replace('/home', { direction: 'none' })
      } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
        dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
        history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, { direction: 'none' })
      }

      dispatch(storeUserId(userCredentials.user.uid))
      dispatch(setUserId(userCredentials.user.uid))

      await auth.updateCurrentUser(userCredentials.user)

      props.handleModal(false, MODAL_CONSTS.SWITCH_ACCOUNT)
    } catch (error: any) {
      toastFunctions.error({ message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setSwitchingAccount(false)
    }
  }

  const onSubmit = async (data: any) => {
    data = data.formData

    setAddingAccount(true)

    try {
      const auth = getAuth()
      const user = auth.currentUser
      const credential = EmailAuthProvider.credential(data.email, data.password)

      const updatedUser = await signInWithCredential(auth, credential)

      const updatedUserUid = updatedUser.user.uid

      const userHasProfile = allProfiles.find((profile) => profile.id === updatedUserUid)
      const headers = {
        url: 'add_profile',
        method: 'POST',
      }
      await Promise.all([
        auth.updateCurrentUser(user),

        httpService({
          ...headers,
          data: {
            currentUserId: userId,
            profileId: updatedUserUid,
          },
        }),
      ])

      if (!userHasProfile) {
        await httpService({
          ...headers,
          data: {
            currentUserId: updatedUserUid,
          },
        })
      }

      const profiles = await getProfiles()
      append(profiles)

      toastFunctions.success({
        message: MESSAGES_CONST.ACCOUNT_ADDED,
      })

      setActiveScreen(ESwitchScreen.yourAccounts)
    } catch (error: any) {
      console.error(error)

      let message = MESSAGES_CONST.SOMETHING_WENT_WRONG

      if (error.code === FIREBASE_CONST.USER_NOT_FOUND) {
        message = "This account doesn't exists. Please sign up or try again"
        return toastFunctions.error({ message })
      }

      if (error.code === FIREBASE_CONST.WRONG_PASSWORD) {
        message = 'Credentials are incorrect'
        return toastFunctions.error({ message })
      }

      if (error.code === FIREBASE_CONST.TOO_MANY_REQUESTS) {
        message = 'Firebase login Maximum attempts reached'
        return toastFunctions.error({ message })
      }

      if (error instanceof AxiosError) {
        message = error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      } else if (error?.message) {
        message = error?.message
      }

      return toastFunctions.error({ message })
    } finally {
      setAddingAccount(false)
    }
  }

  const [selected, setSelected] = useState(false)

  const cancelHandler = () => {
    switch (activeScreen) {
      case ESwitchScreen.yourAccounts:
      case ESwitchScreen.whoHasAccess:
        return props.handleModal(false, MODAL_CONSTS.SWITCH_ACCOUNT)

      case ESwitchScreen.confirmRemoveAccount:
        return setActiveScreen(ESwitchScreen.whoHasAccess)

      default:
        return setActiveScreen(ESwitchScreen.yourAccounts)
    }
  }

  const isMainScreen = [ESwitchScreen.yourAccounts, ESwitchScreen.whoHasAccess].includes(
    activeScreen
  )

  const removeProfileHasAccess = async () => {
    if (!userId || !profileToRemove) return

    setSwitchingAccount(true)
    try {
      const { id, index } = profileToRemove
      const userProfile = allProfiles.find((profile) => profile.id === id)
      if (userProfile) {
        userProfile.allowedUids = userProfile.allowedUids.filter((uid) => uid !== userId)
        await FirestoreService.updateItem(COLLECTIONS.PROFILES.NAME, id, {
          allowedUids: userProfile.allowedUids,
        })
        removeAccessFiled(index)
        setProfileToRemove(null)
        setActiveScreen(ESwitchScreen.whoHasAccess)
      }

      toastFunctions.success({
        message: MESSAGES_CONST.ACCOUNT_REMOVED,
      })
    } catch (e) {
      console.error(e)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setSwitchingAccount(false)
    }
  }

  const getButtonProps = (
    label: string,
    onClick: () => void,
    loading: boolean,
    disabled: boolean = false,
    additionalClasses: string = ''
  ) => ({
    label,
    bgClass: 'bg-SeabiscuitMainThemeColor',
    textClass: 'text-white',
    className: `!md:max-w-[400px] !w-full m-4 !h-[48px] ${additionalClasses}`,
    onClick,
    loading,
    disabled,
  })

  const modalButtons = [
    ...(activeScreen === ESwitchScreen.yourAccounts
      ? [
          getButtonProps(
            'SWITCH',
            onSwitchAccountButtonClick,
            switchingAccount,
            typeof watch('selectedProfileIndex') !== 'number'
          ),
        ]
      : []),

    ...(activeScreen === ESwitchScreen.confirmRemoveAccount
      ? [getButtonProps('REMOVE', removeProfileHasAccess, switchingAccount)]
      : []),

    ...(activeScreen === ESwitchScreen.addAccount && selected
      ? [
          getButtonProps(
            'ADD ACCOUNT',
            () => handleSubmit(onSubmit)(),
            addingAccount,
            false,
            'border border-solid !border-SeabiscuitMainThemeColor'
          ),
        ]
      : []),

    {
      label: 'CANCEL',
      className: '!md:max-w-[400px] !w-full m-4 !h-[48px]',
      bgClass: '!bg-SeabiscuitLightThemeColor',
      borderClass: 'border border-transparent',
      textClass: '!text-SeabiscuitLightTextColor',
      onClick: cancelHandler,
    },
  ]

  return (
    <MainModal
      show={props.show}
      title={
        activeScreen === ESwitchScreen.confirmRemoveAccount ? 'Remove account' : 'Switch accounts'
      }
      type="SWITCH_ACCOUNT"
      setHeightAsPerContent={true}
      className="!px-0 !my-8 "
      size="md"
      buttons={modalButtons}
    >
      <div>
        {isMainScreen && (
          <>
            <SwitchAccountTabs activeTab={activeScreen} clickHandler={setActiveScreen} />
            {activeScreen === ESwitchScreen.yourAccounts ? (
              <SwitchAccountFirstStep
                selectedIndex={watch('selectedProfileIndex')}
                loading={loading}
                fields={fields as IProfile[]}
                selectHandler={(index) => {
                  setValue(
                    'selectedProfileIndex',
                    watch('selectedProfileIndex') === index ? null : index
                  )
                }}
                addAccountHandler={() => setActiveScreen(ESwitchScreen.addAccount)}
                notAvailableText={watch('message')}
              />
            ) : (
              <SwitchAccountWhoHasAccess
                profiles={fieldsHasAccess as IProfileHasAccess[]}
                removeProfile={(data) => {
                  setProfileToRemove(data)
                  setActiveScreen(ESwitchScreen.confirmRemoveAccount)
                }}
              />
            )}
          </>
        )}

        {activeScreen === ESwitchScreen.confirmRemoveAccount && (
          <div className={'text-black min-h-[306px]'}>
            <p>By clicking ‘Remove’, this account will no longer have access to your account. </p>
            <p className={'mt-4'}>Are you sure you want to remove their access?</p>
          </div>
        )}

        {activeScreen === ESwitchScreen.addAccount && (
          <>
            <form className="flex flex-col justify-between py-3" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="AccountEmail flex flex-wrap items-center ">
                  <div className="flex-grow mx-14">
                    <label className="mb-3 !text-SeabiscuitDark200ThemeColor flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-SeabiscuitLightThemeColorD3 border-[1px] bg-transparent">
                      <span className="w-[22px]">
                        <img src="/assets/og_icons/Mail-1.svg" alt="icon" className="" />
                      </span>
                      <input
                        {...register('formData.email')}
                        placeholder="Enter Email"
                        className="flex-1 border-0 outline-0 !text-SeabiscuitDark200ThemeColor !bg-white placeholder:text-SeabiscuitDark200ThemeColor w-full focus:border-none p-2"
                      />
                    </label>
                    {errors.formData?.email && (
                      <MessageHelperComp isError={true} message={errors.formData?.email.message} />
                    )}
                  </div>
                </div>

                <div className="AccountEmail flex flex-wrap items-center">
                  <div className="flex-grow mx-14">
                    <label className="mb-3 text-SeabiscuitDark200ThemeColor flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-SeabiscuitLightThemeColorD3 border-[1px] bg-transparent">
                      <span>
                        <img src="/assets/og_icons/Lock-1.svg" alt="icon" className="" />
                      </span>
                      <input
                        type={innerType}
                        {...register('formData.password')}
                        placeholder="Enter Password"
                        className="flex-1 border-0 outline-0 !text-SeabiscuitDark200ThemeColor bg-transparent placeholder:text-SeabiscuitDark200ThemeColor focus:ring-transparent w-full focus:border-none p-2"
                      />
                      <div
                        onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}
                      >
                        {innerType === 'password' ? (
                          <VisibilityOffOutlined className="text-SeabiscuitDark200ThemeColor" />
                        ) : (
                          <Visibility className="text-SeabiscuitDark200ThemeColor" />
                        )}
                      </div>
                    </label>
                    {errors.formData?.password && (
                      <MessageHelperComp
                        isError={true}
                        message={errors.formData?.password.message}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="displineCheckBoxfilter mx-14 flex">
                <input
                  className="form-check-input rounded_checkboxes appearance-none h-4 w-4 border border-[#D3DAEE] bg-white checked:bg-pink-600 checked:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="checkbox"
                  checked={selected}
                  id="checkbox"
                  onChange={(e) => setSelected(e.target.checked)}
                />
                <label
                  htmlFor="checkbox"
                  className="labelFilter cursor-pointer text-SeabiscuitDark200ThemeColor text-sm"
                >
                  By ticking this box I confirm that I have been given permission to access this
                  account by the account owner, and I accept that I am personally responsible for
                  this accounts activity.
                </label>
              </div>
            </form>
          </>
        )}
      </div>
    </MainModal>
  )
}

export default SwitchAccounts
