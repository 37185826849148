import { FC, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FEES_CATEGORY_CONST } from '../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { useAppSelector } from '../../../../../store/hooks'
import { selectFeesTabDataR } from '../../../../../store/registration/registrationSlice'
import FeesRow from './FeesRow'
import DataNotAvailable from '../../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IManageInfo } from '../../EventRegistrationTabs'
import { IOnChangeFees } from '../../hooks/useEventRegistrationTabs/useEventRegistrationTabs'

// Types
type FeesRootProps = {
  feesItemsForm: UseFormReturn
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  onChangeFees: ({
    setManageInfo,
    manageInfo,
    isManage,
  }: IOnChangeFees) => Promise<null | undefined>
}

const FeesRoot: FC<FeesRootProps> = ({
  feesItemsForm,
  handleModal,
  manageInfo,
  setManageInfo,
  isManage,
  onChangeFees,
}) => {
  // Hooks and vars
  const feesTabData = useAppSelector(selectFeesTabDataR)

  useEffect(() => {
    let selectedUnitsCount = 0
    let feesStateInHookForm: Record<string, number> = {}

    feesTabData.forEach((currFeesRow) => {
      if (currFeesRow.registrationFees) {
        let fees = currFeesRow

        if (isManage) {
          const filtered = manageInfo.fees.filter(
            (manageInfoFees) => manageInfoFees.feesItemId === fees.feesItemId
          )
          if (filtered.length > 0) {
            fees = { ...currFeesRow, registrationFees: filtered[0].registrationFees }
          } else {
            fees = { ...currFeesRow, registrationFees: null }
          }
        }

        selectedUnitsCount = fees?.registrationFees?.selectedUnitsCount ?? 0
        feesStateInHookForm = {
          ...feesStateInHookForm,
          [currFeesRow.feesItemId]: selectedUnitsCount,
        }
      }
    })

    feesItemsForm.reset(feesStateInHookForm)
  }, [feesTabData, feesItemsForm, isManage])

  if (!feesTabData.length) {
    return (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
        text="No Fees items have been added to this event"
      />
    )
  }

  return (
    <>
      <FeesRow
        feesTabData={feesTabData}
        onChangeFees={onChangeFees}
        feesCategory={FEES_CATEGORY_CONST.STALLS}
        feesItemsForm={feesItemsForm}
        handleModal={handleModal}
        showHeader
        isManage={isManage}
        manageInfo={manageInfo}
        setManageInfo={setManageInfo}
      />

      <FeesRow
        feesTabData={feesTabData}
        onChangeFees={onChangeFees}
        feesCategory={FEES_CATEGORY_CONST.BEDDING}
        feesItemsForm={feesItemsForm}
        handleModal={handleModal}
        isManage={isManage}
        manageInfo={manageInfo}
        setManageInfo={setManageInfo}
      />

      <FeesRow
        feesTabData={feesTabData}
        onChangeFees={onChangeFees}
        feesCategory={FEES_CATEGORY_CONST.FEED}
        feesItemsForm={feesItemsForm}
        isManage={isManage}
        manageInfo={manageInfo}
        setManageInfo={setManageInfo}
        handleModal={handleModal}
      />

      <FeesRow
        feesTabData={feesTabData}
        onChangeFees={onChangeFees}
        feesCategory={FEES_CATEGORY_CONST.GOVERNANCE}
        feesItemsForm={feesItemsForm}
        handleModal={handleModal}
        isManage={isManage}
        manageInfo={manageInfo}
        setManageInfo={setManageInfo}
      />

      <FeesRow
        feesTabData={feesTabData}
        onChangeFees={onChangeFees}
        feesCategory={FEES_CATEGORY_CONST.OTHER}
        feesItemsForm={feesItemsForm}
        handleModal={handleModal}
        isManage={isManage}
        manageInfo={manageInfo}
        setManageInfo={setManageInfo}
      />
    </>
  )
}

export default FeesRoot
