export const calculatePriceWithPercent = (price: number, percentage: number) => {
  if (price < 0 || percentage < 0) {
    return price
  }
  const increment = (price * percentage) / 100

  return Number((price + increment).toFixed(2))
}

export const convertPrice = (price: number) => parseFloat(Number(price).toFixed(2))

export const getFloatPrice = (price: string) => {
  const num = parseFloat(price.replace('$', ''))
  return num % 1 === 0 ? `$${num}` : `$${num.toFixed(2)}`
}
