import { useMessageContext, MessageText, Attachment } from 'stream-chat-react'
import ProfileImage from './ProfileImage'
import clsx from 'clsx'

const CustomMessageUiMetadata = () => {
  const {
    message: { created_at: createdAt, message_text_updated_at: messageTextUpdatedAt },
  } = useMessageContext()
  return (
    <div className="flex flex-row items-center gap-2 font-[Poppins]">
      <span className="text-[16px] text-[#122B46]/[.5]">
        {' '}
        •{' '}
        {createdAt instanceof Date
          ? createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          : createdAt}
      </span>
      {messageTextUpdatedAt && (
        <div className="" title={messageTextUpdatedAt}>
          Edited
        </div>
      )}
    </div>
  )
}

const CustomMessageUiActions = () => {
  const { handleDelete, handleFlag, handleOpenThread, handlePin, message, threadList } =
    useMessageContext()

  if (threadList) return null
  return (
    <div className="custom-message-ui__actions">
      <div className="custom-message-ui__actions-group">
        <button onClick={handlePin} title={message.pinned ? 'Unpin' : 'Pin'}>
          {message.pinned ? '📍' : '📌'}
        </button>
        <button onClick={handleDelete} title="Delete">
          🗑️
        </button>
        <button onClick={handleOpenThread} title="Open thread">
          ↩️
        </button>
        <button onClick={handleFlag} title="Flag message">
          🚩
        </button>
      </div>
    </div>
  )
}

export const CustomMessageUi = () => {
  const { isMyMessage, message, handleAction, groupStyles } = useMessageContext()

  if (groupStyles?.includes('middle')) {
    return (
      <div
        className={'font-[Poppins] text-[16px] flex flex-row items-start px-2 py-[0.5px] gap-2'}
        data-message-id={message.id}
      >
        <div className="w-10" />
        <div className="flex flex-col custom-message-ui__body">
          <MessageText />
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'font-[Poppins] text-[16px] flex flex-row items-start px-2 gap-2',
        groupStyles?.includes('bottom') && 'pb-3',
        groupStyles?.includes('single') && 'py-3',
        groupStyles?.includes('top') && 'pt-3'
      )}
      data-message-id={message.id}
    >
      {/*<Avatar image={message.user?.image} name={message.user?.name || message.user?.id} />*/}
      <ProfileImage
        src={message.user?.image}
        lazyLoad={true}
        className={'h-10 w-10 object-cover rounded-full hidden sm:block'}
      />
      <div className="flex flex-col custom-message-ui__body">
        <div className="flex flex-row items-center gap-2">
          <span className="font-semibold text-[#122B46]">
            {isMyMessage() ? 'You' : message.user?.name || message.user?.id}
          </span>
          <CustomMessageUiMetadata />
          <CustomMessageUiActions />
        </div>
        <MessageText />
        {message.attachments?.length && !message.quoted_message ? (
          <Attachment actionHandler={handleAction} attachments={message.attachments} />
        ) : null}
      </div>
    </div>
  )
}
