import React from 'react'
import { useKnockFeed } from '@knocklabs/react'
import { NotificationIconButtonProps } from '@knocklabs/react'
import { BadgeCountType } from '@knocklabs/react'
import { FeedMetadata } from '@knocklabs/client'
import NotificationIcon from '../../components/icons/IconNotification'

function selectBadgeCount(badgeCountType: BadgeCountType, metadata: FeedMetadata) {
  switch (badgeCountType) {
    case 'all':
      return metadata.total_count
    case 'unread':
      return metadata.unread_count
    case 'unseen':
      return metadata.unseen_count
  }
}

export const IconNotification = ({
  onClick,
  badgeCountType = 'unseen',
}: NotificationIconButtonProps) => {
  const { feedClient, useFeedStore } = useKnockFeed()

  React.useEffect(() => {
    // When the feed client changes, or the status changes issue a re-fetch
    feedClient.fetch({ status: badgeCountType })
  }, [feedClient, badgeCountType])

  const badgeCountValue = useFeedStore((state) => selectBadgeCount(badgeCountType, state.metadata))

  return (
    <button id="notis-menu" className="relative group mr-3" onClick={onClick}>
      <NotificationIcon />
      {badgeCountValue > 0 && (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute -top-[1px] -right-[1px]"
        >
          <circle cx="4" cy="4" r="4" fill="#F7074F" />
        </svg>
      )}
    </button>
  )
}
