import React, { useState } from 'react'

import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'

import MainModal from '../../modals/common/MainModal'
import { IconUser } from '../../icons/IconUser'
import { IconEmailImg } from '../../icons/IconEmailImg'
import { IconDate } from '../../icons/IconDate'
import { IconError } from '../../icons/IconError'
import { InputUploadMemberPictureRow } from '../../inputs/InputUploadMemberPictureRow'
import { InputNewMemberRow, IOption } from '../../inputs/InputNewMemberRow'
import { InputCheckMemberInfo } from '../../inputs/InputCheckMemberInfo'

import helpers from '../../../commonHelpers/helpers'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { isValidEmail } from '../../../helpers/isValidEmail'

import FirebaseStorageService from '../../../services/storageService'
import FirebaseApp from '../../../services/firebaseApp'
import { httpService } from '../../../services/httpService'
import FirestoreService from '../../../services/firestoreService'

import { MESSAGES_CONST } from '../../../const/messages-const'
import { CONST, USER_DEF_PASS } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'

import { IUserInterface } from '../../../models/users/user.interface'
import { IInputUserData, IInputUserKey } from '../types/inputData'
import { IconUserName } from '../../icons/IconUserName'
import { NationalityIconImg } from '../../icons/NationalityIconImg'
import { CountryList } from '../../../fakeData/countryList'
import { IconHorseImg } from '../../icons/IconHorseImg'
import { disciplineData } from '../../../fakeData/disciplineList'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    onSaveAddUser?: (user: IUserInterface) => void
  }
}

export const AddNewUserModal = ({ handleModal, show, dataToPassOn }: Props) => {
  const userIcon = '/assets/img/User2.png'
  const [picture, setPicture] = React.useState('')
  const [inputData, setInputDate] = React.useState<IInputUserData>({
    userFullName: {
      name: 'userFullName',
      value: '',
      required: true,
      placeholder: 'Enter full name...',
      type: 'text',
      label: 'Full Name',
      icon: <IconUser />,
    },
    userName: {
      name: 'userName',
      value: '',
      required: true,
      placeholder: 'Enter user name...',
      type: 'text',
      label: 'User Name',
      icon: <IconUserName />,
    },
    email: {
      name: 'email',
      value: '',
      required: true,
      placeholder: 'Enter address...',
      type: 'email',
      label: 'Email address',
      icon: <IconEmailImg />,
    },
    date: {
      name: 'date',
      value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      required: true,
      placeholder: 'Select date...',
      type: 'date',
      icon: <IconDate />,
      label: 'Date of birth',
    },
    nationality: {
      name: 'nationality',
      value: '',
      required: false,
      placeholder: 'Select nationality...',
      type: 'select',
      label: 'Nationality',
      icon: <NationalityIconImg />,
      selectData: CountryList,
    },
    discipline: {
      name: 'discipline',
      value: '',
      required: false,
      placeholder: 'Select discipline....',
      type: 'select',
      label: 'Discipline',
      icon: <IconHorseImg />,
      selectData: disciplineData,
    },
  })

  const toastFunctions = useToasterHelper()

  const [isValid, setIsValid] = React.useState(false)
  const [isConfirmed, setIsConfirmed] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = React.useState(false)

  React.useEffect(() => {
    const checkValidity = () => {
      for (const key in inputData) {
        const field = inputData[key as IInputUserKey]
        if (field.required && !field.value) {
          return false
        }
      }
      return true
    }

    setIsValid(isConfirmed && checkValidity())
  }, [inputData, isConfirmed])

  const onChangeHandler = (key: string, value: string | Date | IOption) => {
    const newInputData = { ...inputData }
    if (newInputData[key as IInputUserKey]) {
      newInputData[key as IInputUserKey].value = value
      newInputData[key as IInputUserKey].hasError = false
      setInputDate(newInputData)
    }
  }

  const submitHandler = async () => {
    setLoading(true)

    const { email, userFullName, userName, date, nationality, discipline } = inputData

    if (!email || !email.value || !isValidEmail(`${email.value}`)) {
      const newInputData = { ...inputData }
      newInputData['email'].hasError = true
      setInputDate(newInputData)
      toastFunctions.error({
        message: 'Invalid email address',
      })
      return null
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        FirebaseApp.auth,
        `${email.value}`.toLowerCase(),
        USER_DEF_PASS
      )
      const user = userCredential.user

      await updateProfile(userCredential.user, {
        displayName: `${userFullName.value}`,
      })

      const resp = await httpService({
        url: `create_user`,
        method: 'POST',
        data: {
          uuid: user.uid,
          userName: `${userFullName.value}`,
          userFullName: `${userName.value}`,
          email: `${email.value}`.toLowerCase(),
          userType: 'competitor',
          userDOB: date.value as Date,
          userNationality: (nationality?.value as IOption).label as string,
          userDiscipline: (discipline?.value as IOption).label as string,
        },
      })

      if (!resp.data.user) {
        alert('No User')
        return null
      }
      const respUser = resp.data.user

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        respUser.id,
        {
          userProfilePicture: picture,
          userFullName: `${userFullName.value}`,
          userName: `${userName.value}`,
          userDOB: date.value,
          userNationality: (nationality?.value as IOption).label as string,
          userDiscipline: (discipline?.value as IOption).label as string,
        }
      )

      if (dataToPassOn.onSaveAddUser) dataToPassOn.onSaveAddUser(respUser)

      handleModal(false, MODAL_CONSTS.ADD_NEW_USER)
      toastFunctions.success({ message: MESSAGES_CONST.USER_CREATED })
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (downloadUrl) {
        setPicture(`${downloadUrl}`)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  return (
    <>
      <MainModal
        title="Add new user"
        show={show}
        type="ADD_NEW_USER"
        size="md"
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={[
          {
            loading,
            label: 'NEXT >',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: submitHandler,
            className: 'outline-none !w-full',
            textClass: 'text-white',
            disabled: !isValid,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none !w-full',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => handleModal(false, MODAL_CONSTS.ADD_NEW_USER),
          },
        ]}
      >
        <div className={'flex flex-col gap-4 mt-4'}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              title={'Profile picture'}
              isLoading={imgLoading}
              picture={picture}
              onchangeHandler={updateProfilePic}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key) => (
              <InputNewMemberRow
                key={key}
                value={inputData[key as IInputUserKey].value}
                onChangeHandler={onChangeHandler}
                placeholder={inputData[key as IInputUserKey].placeholder}
                inputName={inputData[key as IInputUserKey].name}
                name={inputData[key as IInputUserKey].label}
                isError={inputData[key as IInputUserKey].hasError}
                icon={inputData[key as IInputUserKey].icon}
                isRequired={inputData[key as IInputUserKey].required}
                type={inputData[key as IInputUserKey].type}
                selectData={inputData[key as IInputUserKey].selectData || []}
              />
            ))}
          </div>

          <InputCheckMemberInfo
            isChecked={isConfirmed}
            onClick={() => setIsConfirmed(!isConfirmed)}
            text={
              'By ticking this box I confirm that I have been given permission to create a Pegasus\n' +
              'account on behalf of this person, and I am authorized to agree to Pegasus’ terms and\n' +
              'conditions on their behalf, as well as register them for events.'
            }
          />

          <div className={'flex mt-4 gap-4 bg-[#F6F7FB] py-[12px] px-[20px] rounded-[12px]'}>
            <div className={'mt-1'}>
              <IconError />
            </div>

            <p className={'flex-1 text-[#122B46] text-[14px] m-0'}>
              The email address listed above will be notified of this account creation and provided
              with login details to join Pegasus and complete their profile.
            </p>
          </div>
        </div>
      </MainModal>
    </>
  )
}
