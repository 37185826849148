import React, { useEffect, useState } from 'react'
import { useChatClient } from '../../pages/messages/components/withStreamClient'
import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'
import { IconMessaging } from './IconMessaging'

interface IProps {
  fill?: string
}

export const IconMessagingWithStream: React.FC<IProps> = ({ fill }) => {
  const userId = useAppSelector(selectUserId)
  const [unreadCount, setUnreadCount] = useState(0)
  const { client } = useChatClient()

  useEffect(() => {
    if (!client) return

    client.on((event) => {
      if (event.total_unread_count !== undefined) {
        setUnreadCount(event.total_unread_count)
      }
    })

    if (userId) {
      client.getUnreadCount(userId).then((res) => {
        setUnreadCount(res.total_unread_count)
      })
    }
  }, [client, userId])

  return (
    <>
      <IconMessaging fill={fill} />
      {unreadCount > 0 && (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute -top-[1px] -right-[1px]"
        >
          <circle cx="4" cy="4" r="4" fill="#F7074F" />
        </svg>
      )}
    </>
  )
}
