import { useEffect, useState } from 'react'

import { sumBy } from 'lodash'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Redux
import { useAppSelector } from '../../../../../../store/hooks'
import {
  selectRegistrationTicketsR,
  selectTicketTabData,
} from '../../../../../../store/registration/registrationSlice'

import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'

import { CONST } from '../../../../../../const/const'
import { convertPrice, getFloatPrice } from '../../../../../../helpers/price'

type SelectedEventTicketListProps = {
  setEventTab?: any
  manageInfo: IManageInfo
  isManage?: boolean
}

const FILE_NAME = 'SelectedEventTicketList'

const SelectedEventTicketList = (props: SelectedEventTicketListProps) => {
  const ticketsTabData = useAppSelector(selectTicketTabData)
  const registrationTickets = useAppSelector(selectRegistrationTicketsR)

  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
  }>({ totalItems: 0, totalPrice: 0 })

  let heading = [
    { title: 'Line item', tooltipText: 'Tickets you added during registration' },
    { title: 'Number of units', tooltipText: 'Number of tickets you added per ticket item' },
    { title: 'Price per unit', tooltipText: 'Price per individual ticket' },
  ]

  useEffect(() => {
    const tickets = props.isManage ? props.manageInfo.tickets : ticketsTabData

    const totalItems = convertPrice(
      sumBy(tickets, (ticket) => ticket.registrationTicket?.selectedUnitsCount ?? 0)
    )

    const totalPrice = convertPrice(
      sumBy(
        tickets,
        (ticket) =>
          (ticket.registrationTicket?.selectedUnitsCount ?? 0) *
          (ticket.registrationTicket?.ticketPrice ?? 0)
      )
    )
    setSelectedUnits({
      totalItems,
      totalPrice,
    })
  }, [props.isManage, props.manageInfo.tickets, ticketsTabData])

  return (
    <ConfirmationCompsWrapper
      title="Tickets"
      id={FILE_NAME}
      setEventTab={props.setEventTab}
      redirectTab={CONST.UI.REGISTER.TABS.TICKETS}
      accordion={{ open, setOpen }}
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Tickets' : 'Ticket'}, ${getFloatPrice(`${selectedUnits.totalPrice}`)}`}
      showList={!!registrationTickets.length}
      colsClassName={{
        0: '!w-1/3 flex items-center',
        1: '!w-1/3 flex items-center',
        2: '!w-1/3 flex items-center',
      }}
      cols={heading}
    >
      <>
        {[...ticketsTabData, ...props.manageInfo.tickets].map(
          (ticketRow, index) =>
            ticketRow.registrationTicket && (
              <div
                key={`${JSON.stringify(ticketRow)}${index}`}
                className="flex flex-col lg:flex-row items-center justify-between w-full mb-2"
              >
                <div className="lg:hidden w-full mb-1 font-medium text-[12px]">Line Item</div>

                <p
                  className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor line-clamp-1 w-full lg:w-1/3 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
                >
                  {ticketRow.ticketTitle}
                </p>
                <div className="lg:hidden w-full mb-1 font-medium text-[12px] mt-2">
                  Number Of Units
                </div>

                <p
                  className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor line-clamp-1 w-full lg:w-1/3 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center`}
                >
                  {ticketRow?.registrationTicket?.selectedUnitsCount ?? 0}
                </p>
                <div className="lg:hidden w-full mb-1 font-medium text-[12px] mt-2">
                  Price Per Unit
                </div>

                <p
                  className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor line-clamp-1 w-full lg:w-1/3 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center`}
                >
                  {ticketRow.registrationTicket?.paymentType ??
                    getFloatPrice(`${ticketRow.ticketPrice}`)}
                </p>
              </div>
            )
        )}

        {/* Total */}
        <div className="flex items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor text-center">
          <p className="flex-grow p-4 text-left mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-1/3">
            {props.isManage && 'New'} Total
          </p>

          <p className="flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-1/3">
            {selectedUnits.totalItems}
          </p>

          <p className="flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-1/3">
            ${selectedUnits.totalPrice ?? 0}
          </p>
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventTicketList
