import React, { FC, useContext, useMemo, useState } from 'react'

import { useParams } from 'react-router'

import InfiniteScrollDataTable from '../../../../../../../components/common/tables/InfiniteScrollDataTable'

import { getColumnsScoring } from './data/columns'
import { useEventTrainers } from '../../../../../../../hooks/useEventTrainers'
import { convertTime } from '../../../../../../../helpers/time'
import { onRank } from './data/scoring-utils'

import { IBreakRegistrationByDay } from '../../../../../../../models/events/event.interface'
import { RegistrationFeesType } from '../../../../../../../models/event-fees/event-fees.interface'
import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'

import { IRegistrationByDayEx, RegistrationFeesTypeEx } from '../../ManageClinicNOtherOperationsTab'

import { DUMMY_REGISTRATION_FEES_STATS } from '../../data/operations-tab-utils'
import { MODAL_CONSTS } from '../../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'

interface ScoringTableByClassProps {
  loading: boolean
  registrationsByDayByClass: IBreakRegistrationByDay[]
  updateFeesKeys: (key: object, uuid: string) => void
  fee: RegistrationFeesType | null
  selectedClass: string
  increment: string
  getRegistrationsByDay: () => Promise<IRegistrationByDayInterface[]>
  registrationsByDay: IRegistrationByDayInterface[] | null
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  onChangeJudge: (
    row: RegistrationFeesTypeEx,
    values: { label: string; value: string }[]
  ) => Promise<void>
}
export const ScoringTableByClass: FC<ScoringTableByClassProps> = ({
  loading,
  registrationsByDayByClass,
  updateFeesKeys,
  fee,
  selectedClass,
  increment,
  getRegistrationsByDay,
  setRegistrationsByDay,
  registrationsByDay,
  onChangeJudge,
}) => {
  const { eventId } = useParams<{ eventId: string }>()

  const { eventTrainers } = useEventTrainers(eventId)

  const handleModalContext = useContext(HandleModalContext)

  const [scoringLoading, setScoringLoading] = useState(false)

  const registrationsToDisplay = useMemo(() => {
    return [...registrationsByDayByClass]
  }, [registrationsByDayByClass])

  const onSortScore = () => {
    updateFeesKeys({ isAsc: !fee?.isAsc }, selectedClass)
  }

  const onScore = (row: IRegistrationByDayEx) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.SCORING, {
      rider: row,
      onSaveScoring: () => getRegistrationsByDay(),
      editable: true,
    })
  }

  const getTime = ({ index }: { index: number }) => {
    if (increment) {
      return convertTime(
        `${fee?.startTimeHours}:${fee?.startTimeMinutes} ${fee?.startTimeFormat}`,
        increment,
        index
      )
    } else {
      return '00:00:00 AM'
    }
  }

  return (
    <InfiniteScrollDataTable
      hasMore={false}
      className="exhibitorListTable"
      columns={getColumnsScoring({
        type: 'class',
        eventTrainers,
        registrationsByDay,
        setRegistrationsByDay,
        scoringLoading,
        getTime,
        onSortScore,
        onScore,
        onRank: (row, fee) =>
          onRank({
            row,
            fee,
            setScoringLoading,
            getRegistrationsByDay,
            handleModalContext,
            onChangeJudge,
          }),
      })}
      data={loading ? DUMMY_REGISTRATION_FEES_STATS : registrationsToDisplay}
    />
  )
}
