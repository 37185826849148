import React, { FC } from 'react'

import Select from 'react-select'

import { IconMessaging } from '../../../../components/icons/IconMessaging'
import { IconSearch } from '../../../../components/icons/IconSearch'

import { formatOptionLabel } from '../../../../helpers/select'
import { getConvertedData } from '../../../../models/interface.helper'

import FirestoreService from '../../../../services/firestoreService'

import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'

import { CONST } from '../../../../const/const'
import { IRider } from '../../../../types/entryRider'
import { useChatClient } from '../../../messages/components/withStreamClient'
import { ALL_CLASSES, ALL_STATUSES, EStatuses } from './hooks/useManageClinicNOtherAdminTabNew'

import { getUserFullName } from '../../../../helpers/helpers'
import { PAYMENT_STATUS } from '../../../../types/payment'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const statuses = [
  ALL_STATUSES,
  { value: EStatuses.good, label: EStatuses.good },
  { value: EStatuses.bad, label: EStatuses.bad },
  { value: EStatuses.scratched, label: EStatuses.scratched },
  { value: EStatuses.draft, label: EStatuses.draft },
]

interface ManageEntriesActionsProps {
  activeStatus: { value: string; label: string }
  setActiveStatus: (value: { value: string; label: string }) => void
  setRiders: (value: IRider[]) => void
  frozenRiders: IRider[]
  setSearchNameValue: (value: string) => void
  setActiveClass: (value: { value: string; label: string }) => void
  activeClass: { value: string; label: string }
  searchNameValue: string
  ridersClasses: { value: string; label: string }[]
  riders: IRider[]
  setDraftRiders: (value: IRider[]) => void
  frozenDraftRiders: IRider[]
  eventId: string
}

export const ManageEntriesActions: FC<ManageEntriesActionsProps> = ({
  activeStatus,
  setActiveStatus,
  setRiders,
  activeClass,
  setActiveClass,
  searchNameValue,
  setSearchNameValue,
  frozenRiders,
  ridersClasses,
  riders,
  setDraftRiders,
  frozenDraftRiders,
  eventId,
}) => {
  const { createChatEvent } = useChatClient()

  const statusSelectHandler = (itm: typeof ALL_STATUSES) => {
    setActiveStatus(itm)
    setActiveClass({
      value: ALL_CLASSES.value,
      label: `${ALL_CLASSES.label}  <span class="text-[#122B4680]">• ${frozenRiders.length}  entries</span>`,
    })
    setSearchNameValue('')
    if (itm.value === ALL_STATUSES.value) {
      return setRiders(frozenRiders)
    }

    if (EStatuses.scratched === itm.value) {
      const newRiders = frozenRiders.filter((rider) => rider.isScratched)
      return setRiders(newRiders)
    }
    if (EStatuses.good === itm.value) {
      const newRiders = frozenRiders.filter((rider) => {
        const isUnSigned = rider.documents?.some((d) => d.status !== 'Signed')
        return rider.paymentStatus === PAYMENT_STATUS.PAID && !isUnSigned && rider.classes.length
      })

      return setRiders(newRiders)
    }
    if (EStatuses.bad === itm.value) {
      const newRiders = frozenRiders.filter((rider) => {
        const isUnSigned = rider.documents?.some((d) => d.status !== 'Signed')

        return (
          rider.paymentStatus !== PAYMENT_STATUS.PAID || isUnSigned || rider.classes.length === 0
        )
      })
      return setRiders(newRiders)
    }
  }
  const selectClassHandler = (itm: typeof ALL_CLASSES) => {
    setActiveClass(itm)
    setActiveStatus(ALL_STATUSES)
    setSearchNameValue('')
    if (itm.value === ALL_CLASSES.value) {
      return setRiders(frozenRiders)
    }

    const newRiders = frozenRiders.filter((rider) => rider.classes.find((cl) => cl === itm.value))
    setRiders(newRiders)
  }

  const onchangeSearchRiderName = (value: string) => {
    setSearchNameValue(value)
    if (activeClass.value !== ALL_CLASSES.value) {
      setActiveClass({
        value: ALL_CLASSES.value,
        label: `${ALL_CLASSES.label}  <span class="text-[#122B4680]">• ${frozenRiders.length}  entries</span>`,
      })
    }

    if (activeStatus.value !== ALL_STATUSES.value) {
      setActiveStatus(ALL_STATUSES)
    }

    if (value.length < 1) {
      setDraftRiders(frozenDraftRiders)
      return setRiders(frozenRiders)
    }
    const newRiders = frozenRiders.filter(
      (rider) =>
        rider.riderName?.toLowerCase().includes(value.toLowerCase()) ||
        rider.horseName?.toLowerCase().includes(value.toLowerCase())
    )
    const newDraftRiders = frozenDraftRiders.filter(
      (rider) =>
        rider.riderName?.toLowerCase().includes(value.toLowerCase()) ||
        rider.horseName?.toLowerCase().includes(value.toLowerCase())
    )

    setDraftRiders(newDraftRiders)

    return setRiders(newRiders)
  }

  return (
    <div className={'flex flex-wrap flex-col md:flex-row gap-4'}>
      <div className="relative w-full md:w-[272px]">
        <div className="relative h-[42px] rounded-lg border border-[#d1d5db] hover:border-[#9ca3af] transition-colors">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <IconSearch />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full bg-transparent p-0 h-[42px] pl-10 text-sm border-0 text-[#374151] focus:ring-0 focus:outline-none rounded-lg placeholder:text-[15px]"
            placeholder="Search name"
            value={searchNameValue}
            onChange={(event) => onchangeSearchRiderName(event.target.value)}
            onFocus={() => null}
            onBlur={() => null}
          />
        </div>
      </div>
      <Select
        value={activeClass}
        defaultValue={activeClass}
        options={ridersClasses}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '8px',
            border: '1px solid #d1d5db',
            boxShadow: 'none',
            height: '42px',
            '&:hover': { borderColor: '#9ca3af' }, // Slightly darker on hover
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: '#4B5563',
            '& svg': {
              fill: '#4B5563',
            },
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#4b5563' : state.isFocused ? '#e5e7eb' : '#ffffff',
            color: state.isSelected ? '#ffffff' : '#374151',
            '&:hover': {
              backgroundColor: state.isSelected ? '#4b5563' : '#e5e7eb',
            },
            // styles to target the spans inside the options
            '& span': {
              color: state.isSelected ? '#ffffff' : 'inherit',
            },
            // Target any HTML element with a class inside the option
            '& .text-SeabiscuitDark200ThemeColor/50': {
              color: state.isSelected ? '#ffffff !important' : 'inherit',
            },
          }),
        }}
        className={'w-full md:w-[20vw]'}
        onChange={(selectedOption) => selectClassHandler(selectedOption as typeof ALL_CLASSES)}
      />
      <Select
        placeholder="All"
        menuShouldScrollIntoView
        value={activeStatus}
        options={statuses}
        className={'w-full md:w-[123px]'}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '8px',
            border: '1px solid #d1d5db',
            boxShadow: 'none',
            height: '42px',
            '&:hover': { borderColor: '#9ca3af' }, // Slightly darker on hover
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: '#4B5563',
            '& svg': {
              fill: '#4B5563',
            },
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#4b5563' : state.isFocused ? '#e5e7eb' : '#ffffff',
            color: state.isSelected ? '#ffffff' : '#374151',
            '&:hover': {
              backgroundColor: state.isSelected ? '#4b5563' : '#e5e7eb',
            },
            // styles to target the spans inside the options
            '& span': {
              color: state.isSelected ? '#ffffff' : 'inherit',
            },
            // Target any HTML element with a class inside the option
            '& .text-SeabiscuitDark200ThemeColor/50': {
              color: state.isSelected ? '#ffffff !important' : 'inherit',
            },
          }),
        }}
        onChange={(selectedOption: any) => statusSelectHandler(selectedOption)}
      />
      {riders && riders[0] && (
        <div className="flex items-start">
          <button
            type="button"
            className="border flex items-center rounded-lg justify-center border-solid border-[#D3DAEE] w-[42px] h-[42px] hover:opacity-50"
            onClick={async () => {
              const trainersIds: string[] = []

              riders.forEach((rider) => {
                if (rider.horse && rider.riderId) {
                  trainersIds.push(rider.riderId)
                }
              })
              const usersList = [...new Set(trainersIds)]

              let usersSnaps = await FirestoreService.getItemsUsingIds(
                COLLECTIONS.USERS.NAME,
                usersList
              )
              const usersData: IUserInterface[] = []
              usersSnaps.forEach((currSnap) => {
                usersData.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
              })

              const users = usersData.map((user) => ({
                ...user,
                name: getUserFullName(user),
                role: 'Rider',
                vertical: 'exhibitors',
              }))
              await createChatEvent(users, eventId, { eventId: eventId }).then(() => {
                window.location.href = `${window.location.href}/messages`
              })
            }}
          >
            <IconMessaging />
          </button>
        </div>
      )}
    </div>
  )
}
