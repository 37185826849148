import React, { FC, useMemo } from 'react'

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, type DragEndEvent } from '@dnd-kit/core'

import { DraggableRow } from './components/DraggableRow'
import { OrderOfGoTableHeader } from './components/OrderOfGoTableHeader'
import { AddBreak } from './components/AddBreak'

import FirestoreService from '../../../../../../../services/firestoreService'
import useToasterHelper from '../../../../../../../helpers/ToasterHelper'
import { useEventTrainers } from '../../../../../../../hooks/useEventTrainers'
import { handleDragEnd, onScratch } from './data/order-of-go-utils'
import { getColumns } from './data/columns'

import { selectedEvent, setSelectedEventKey } from '../../../../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks'

import { RegistrationByDayModel } from '../../../../../../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { IBreak, IBreakRegistrationByDay } from '../../../../../../../models/events/event.interface'

import { CONST } from '../../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'

interface OrderOfGoTableByRiderProps {
  selectedDay: string
  eventId: string
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  registrationsByDay: IRegistrationByDayInterface[] | null
  registrationsGroupedByDay: { [key: string]: IBreakRegistrationByDay[] }
  setRegistrationsGroupedByDay: (value: { [key: string]: IBreakRegistrationByDay[] }) => void
  increment: string
}

export const OrderOfGoTableByRider: FC<OrderOfGoTableByRiderProps> = ({
  selectedDay,
  setRegistrationsGroupedByDay,
  eventId,
  setRegistrationsByDay,
  registrationsByDay,
  registrationsGroupedByDay,
  increment,
}) => {
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const { eventTrainers } = useEventTrainers(eventId)

  const currentEvent = useAppSelector(selectedEvent)

  const registrationsToDisplay = useMemo(() => {
    return selectedDay ? registrationsGroupedByDay[selectedDay] || [] : []
  }, [selectedDay, registrationsGroupedByDay])

  const updateBreaks = async (breaks: IBreak[], eventId: string) => {
    dispatch(
      setSelectedEventKey({
        key: 'Event',
        value: { ...currentEvent.Event, breaks },
      })
    )
    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
      eventId,
      {
        breaks,
      }
    )
  }

  const handleScratch = async (row: IBreakRegistrationByDay, state: boolean) => {
    try {
      await onScratch({
        registrationsToDisplay,
        row,
        state,
        registrationsByDay,
        onUpdate: async (newData, breaks, newRegistrationsByDay) => {
          await updateBreaks(breaks, eventId)
          setRegistrationsGroupedByDay({
            ...registrationsGroupedByDay,
            [selectedDay]: newData,
          })
          setRegistrationsByDay(newRegistrationsByDay)
        },
      })

      toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCRATCHED_UPDATED })
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({
        message: MESSAGES_CONST.EVENT_SCRATCHED_NOT_UPDATED,
      })
    }
  }

  const onDragEnd = async (event: DragEndEvent) => {
    try {
      await handleDragEnd({
        event,
        registrationsToDisplay,
        registrationsByDay,
        onUpdate: async (newArray, breaks, newRegistrationsByDay) => {
          setRegistrationsGroupedByDay({
            ...registrationsGroupedByDay,
            [selectedDay]: newArray,
          })
          setRegistrationsByDay(newRegistrationsByDay)
          await updateBreaks(breaks, eventId)
        },
      })
      toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const saveHandlerBreak = async (h: string, m: string, a: string, id: string) => {
    const newBreakTime = `${h}:${m}:${a}` // New break duration
    const newData = [...registrationsToDisplay]
    const breakIndex = newData.findIndex((e) => e.id === id)

    if (breakIndex < 0) {
      console.error(breakIndex, 'breakIndex')
      return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }

    // Update break duration in state
    newData[breakIndex].time = newBreakTime

    //  Convert new break duration (H:M:S) to numeric values
    let [breakHours, breakMinutes, breakSeconds] = newBreakTime.split(':').map(Number)

    //  Find affected riders (all riders after this break)
    let affectedRiders = newData.slice(breakIndex + 1).filter((r) => !r.isBreakDrag)

    //  Iterate over affected riders and push back their rideTimes by the new break duration
    affectedRiders.forEach((rider) => {
      if (!rider.rideTime) return

      let [riderHours, riderMinutes, riderSeconds] = rider.rideTime.split(':').map(Number)

      //  Directly add break duration to the existing rideTime
      riderHours += breakHours
      riderMinutes += breakMinutes
      riderSeconds += breakSeconds

      if (riderSeconds >= 60) {
        riderMinutes += Math.floor(riderSeconds / 60)
        riderSeconds %= 60
      }

      if (riderMinutes >= 60) {
        riderHours += Math.floor(riderMinutes / 60)
        riderMinutes %= 60
      }

      riderHours = riderHours % 24

      rider.rideTime = `${riderHours.toString().padStart(2, '0')}:${riderMinutes
        .toString()
        .padStart(2, '0')}:${riderSeconds.toString().padStart(2, '0')}`
    })

    const breaks = newData.filter((itm) => itm.isBreakDrag)

    try {
      await updateBreaks(breaks, eventId)
      setRegistrationsGroupedByDay({
        ...registrationsGroupedByDay,
        [selectedDay]: newData,
      })

      toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
    } catch (error) {
      console.error('error', error)
      toastFunctions.success({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const saveHandlerRider = async (newTime: string, id: string) => {
    const newData = [...registrationsToDisplay]
    const index = newData.findIndex((e) => e.id === id)

    if (index < 0) return

    newData[index] = { ...newData[index], rideTime: newTime }

    setRegistrationsGroupedByDay({
      ...registrationsGroupedByDay,
      [selectedDay]: newData,
    })

    try {
      const updatedItem = new RegistrationByDayModel(newData[index] as IRegistrationByDayInterface)
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        updatedItem.id,
        updatedItem.toFirestore()
      )

      // update registrationsByDay
      setRegistrationsByDay(
        registrationsByDay?.map((registrationByDay) => {
          const newRegistrationByDay = newData.find(
            (current) => current.id === registrationByDay.id
          )
          if (newRegistrationByDay) {
            return newRegistrationByDay as IRegistrationByDayInterface
          } else {
            return registrationByDay as IRegistrationByDayInterface
          }
        }) ?? []
      )

      toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
    } catch (error) {
      console.error(error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const columns = React.useMemo<ColumnDef<IBreakRegistrationByDay>[]>(() => {
    return getColumns({
      eventTrainers,
      saveHandlerBreak,
      saveHandlerRider,
      registrationsToDisplay,
      increment,
      type: 'rider',
    })
  }, [eventTrainers, registrationsToDisplay, selectedDay, increment])

  const table = useReactTable({
    data: registrationsToDisplay,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id, // required because row indexes will change
  })

  const addBreak = async () => {
    try {
      const breaks = registrationsToDisplay?.filter((itm) => itm.isBreakDrag) || []

      const dataToCreate = {
        id: `${new Date().getTime()}`,
        order: (registrationsToDisplay?.length || 0) + 1,
        time: '00:00:00',
        isBreakDrag: true,
        orderOfGoScratched: false,
        groupKey: selectedDay,
      }

      await updateBreaks([...breaks, dataToCreate], eventId)
      const newData = [...registrationsToDisplay, dataToCreate]

      setRegistrationsGroupedByDay({
        ...registrationsGroupedByDay,
        [selectedDay]: newData as IBreakRegistrationByDay[],
      })
    } catch (error) {
      console.error('error', error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  return (
    <DndContext onDragEnd={onDragEnd}>
      <table className={'w-full'}>
        <thead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <OrderOfGoTableHeader key={index} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody>
          <SortableContext
            items={registrationsToDisplay?.map(({ id }) => id)}
            strategy={verticalListSortingStrategy}
          >
            {registrationsToDisplay.map((row, rowIndex) => (
              <DraggableRow
                key={row.id}
                row={row}
                tableRow={table.getRowModel().rows[rowIndex]}
                rowIndex={rowIndex}
                isLastChild={rowIndex === registrationsToDisplay.length - 1}
                registrationsToDisplay={registrationsToDisplay}
                handleScratch={handleScratch}
                type="rider"
              />
            ))}
          </SortableContext>
          <AddBreak addBreak={addBreak} />
        </tbody>
      </table>
    </DndContext>
  )
}
