/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'

// Component imports
import WrapperWithHeader from '../../../components/common/wrappers/WrapperWithHeader'

// Types
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import {
  IGetMyEventsFilteredDataItem,
  IMyEvent,
  ITicketBoughtItem,
  TEventRegisteredUsers,
} from '../../../models/event-registered-users/event-registered-users.interface'

import { IEventRegisteredUserInterfaceTypes } from '../../../models/event-registered-users/event-registered-users.interface'

// Helpers
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { getConvertedData } from '../../../models/interface.helper'

// Firebase
import { documentId, where } from 'firebase/firestore'

// Services
import FirestoreService from '../../../services/firestoreService'

// Constants
import { CONST } from '../../../const/const'

// Models
import { EventDetailsModel } from '../../../models/event-details/event-details.model'

// Redux
import clsx from 'clsx'
import helpers from '../../../commonHelpers/helpers'
import InfiniteScrollDataTable from '../../../components/common/tables/InfiniteScrollDataTable'
import { AssignTicketsModal } from '../../../models/assigned-tickets/assigned-tickets.model'
import { IEventInterface } from '../../../models/events/event.interface'
import { EventModel } from '../../../models/events/event.model'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'

import { useAppSelector } from '../../../store/hooks'
import { selectUserId, selectUseremail } from '../../../store/user/userSlice'

import { cloneDeep } from 'lodash'
import DataNotAvailable from '../../../components/common/alerts/data-not-available/DataNotAvailable'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import { ITicketBuyer } from '../../../models/ticket-buyers/ticket-buyers.interface'
import { TicketBuyersModel } from '../../../models/ticket-buyers/ticket-buyers.model'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IEventFees } from '../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../models/event-fees/event-fees.model'
import { getUserEvents } from '../data/eventData'
import { selectAllUsers } from '../../../store/users/usersSlice'
import { getRegisteredRiders } from '../../../helpers/riders'

// Types
type ICol = { name?: string; cell: (row: IMyEvent) => JSX.Element }
type ISelectedTabUS = { tabTitle: string; tab: number; subTitle: string }
type IMyEventsDataTablesProps = { selectedTab: ISelectedTabUS; cols: ICol[] }

type IEventDetailsHolder = {
  event: IEventInterface
  eventId: string | null
  eventLogo: string | null
}

// Constants
const NO_DRAFTS_SAVED = 'You have no draft registrations'
const EMPTY_WAITLIST = 'You are not wait listed for any events'
const NO_BOOKMARKED_EVENTS = 'You have not bookmarked any events'
const NO_TICKET_BOUGHT = 'You have not bought tickets for any event'
const NO_TICKETS_ASSIGNED = 'No tickets have assigned to you yet'
const NO_EVENTS = 'You have not registered for any upcoming events'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS
const EVENT_REGISTERED_USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS
const TICKET_BUYERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.TICKET_BUYERS
const ASSIGNED_REGISTERED_TICKETS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.ASSIGNED_TICKETS
const NO_EVENTS_IMAGE_BLANK = 'assets/placeholders/noRegister.svg'
const EMPTY_WAITLIST_IMAGE_BLANK = NO_EVENTS_IMAGE_BLANK
const NO_DRAFTS_SAVED_IMAGE_BLANK = NO_EVENTS_IMAGE_BLANK
const NO_BOOKMARKED_EVENTS_IMAGE_BLANK = NO_EVENTS_IMAGE_BLANK

const MyEventsDataTables = ({ selectedTab, cols }: IMyEventsDataTablesProps) => {
  // Hooks and vars
  const userId: string = useAppSelector(selectUserId) ?? ''
  const users = useAppSelector(selectAllUsers)

  const toastFunction = useToasterHelper()

  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState<string>('')
  const [imageBlank, setImageBlank] = useState<string>('')
  const [assignedTickets, setAssignedTickets] = useState<ITicketBoughtItem[]>([])
  const [waitListEvents, setWaitlistEvents] = useState<IMyEvent[]>([])
  const [registeredEvents, setRegisteredEvents] = useState<IGetMyEventsFilteredDataItem[]>([])
  const [draftEvents, setDraftEvents] = useState<IGetMyEventsFilteredDataItem[]>([])
  const [bookmarkedEvents, setBookmarkedEvents] = useState<IGetMyEventsFilteredDataItem[]>([])
  const [ticketsData, setTicketsData] = useState<ITicketBoughtItem[]>([])
  const [riders, setRiders] = useState<IRegistrationByDayInterface[]>([])
  const [eventFees, setEventFees] = useState<IEventFees[]>([])
  const [eventDetails, setEventDetails] = useState<IEventDetails[]>([])
  const [events, setEvents] = useState<IEventInterface[]>([])
  const [generalInformationLoaded, setGeneralInformationLoaded] = useState(false)

  const ticketsDataRef = useRef<ITicketBoughtItem[]>([])

  const userEmail = useAppSelector(selectUseremail)
  const assignedRef = useRef<ITicketBoughtItem[]>([])
  const assignedLst = useRef()

  const setAssignedLst = (data: any) => {
    assignedLst.current = data
  }

  const emptyDataList = async (tab: number) => {
    switch (tab) {
      case 1:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 2:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 3:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 4:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 5:
        setAssignedTickets([])
        break
      case 6:
        setTicketsData([])
        break
    }

    setAssignedLst('')
    assignedRef.current = []
  }

  const getEventTabs = async () => {
    setLoading(true)
    switch (true) {
      case selectedTab.tab === 1:
        await emptyDataList(1)
        const registeredEvents_ = await getUserEvents({
          userId,
          eventFees,
          eventDetails,
          events,
          riders,
          users,
          type: 'registered',
        })
        setRegisteredEvents(registeredEvents_)
        setMessage(NO_EVENTS)
        setImageBlank(NO_EVENTS_IMAGE_BLANK)
        break

      case selectedTab.tab === 2:
        await emptyDataList(2)
        await getWaitlistUserList()
        setMessage(EMPTY_WAITLIST)
        setImageBlank(EMPTY_WAITLIST_IMAGE_BLANK)
        break

      case selectedTab.tab === 3:
        await emptyDataList(3)
        const draftEvents_ = await getUserEvents({
          userId,
          eventFees,
          eventDetails,
          events,
          riders,
          users,
          type: 'draft',
        })
        setDraftEvents(draftEvents_)
        setMessage(NO_DRAFTS_SAVED)
        setImageBlank(NO_DRAFTS_SAVED_IMAGE_BLANK)
        break

      case selectedTab.tab === 4:
        await emptyDataList(4)
        const bookmarkedEvents_ = await getUserEvents({
          userId,
          eventFees,
          eventDetails,
          events,
          riders,
          users,
          type: 'bookmarked',
        })
        setBookmarkedEvents(bookmarkedEvents_)
        setMessage(NO_BOOKMARKED_EVENTS)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break

      case selectedTab.tab === 5:
        await emptyDataList(5)
        await getTicketsList()
        setMessage(NO_TICKET_BOUGHT)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break

      case selectedTab.tab === 6:
        await emptyDataList(6)
        await getAssignedTicketsList()
        setMessage(NO_TICKETS_ASSIGNED)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break
    }
    setLoading(false)
  }

  useEffect(() => {
    if (generalInformationLoaded) getEventTabs().then()
  }, [generalInformationLoaded, selectedTab, eventFees, eventDetails, events])

  useEffect(() => {
    const getGeneralInformation = async () => {
      const eventFees_: IEventFees[] = []
      const events_: IEventInterface[] = []
      const eventIds: string[] = []
      const eventDetails_: IEventDetails[] = []

      const eventDetailsSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENT_DETAILS.NAME)
      eventDetailsSnaps.docs.forEach((currDoc) => {
        const current = getConvertedData(EventDetailsModel.fromFirestoreDoc(currDoc).toObject())
        if (current.id) eventDetails_.push(current)
      })

      const eventFeesSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENT_FEES.NAME)
      eventFeesSnaps.docs.forEach((currDoc) => {
        const current = getConvertedData(EventFeesModel.fromFirestoreDoc(currDoc).toObject())
        if (current) eventFees_.push(current)
      })

      const eventSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENTS.NAME)
      eventSnaps.docs.forEach((currDoc) => {
        const current = getConvertedData(EventModel.fromFirestoreDoc(currDoc).toObject())
        if (current) events_.push(current)
      })

      events_.forEach((event) => event?.id && eventIds.push(event.id))
      const registeredRiders = await getRegisteredRiders(eventIds)

      setRiders(registeredRiders)
      setEvents(events_)
      setEventFees(eventFees_)
      setEventDetails(eventDetails_)
    }
    getGeneralInformation()
      .then(() => setGeneralInformationLoaded(true))
      .catch(() => setGeneralInformationLoaded(true))
  }, [])

  useEffect(() => {
    ticketsDataRef.current = ticketsData
  }, [ticketsData])

  const decrementTicketCountFromTableRow = (tableRowIndex: number) => {
    let ticketsData_ = cloneDeep(ticketsDataRef.current)
    let remainingTicketsCount = 0

    if (!ticketsData_?.[tableRowIndex]) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `ticketsData_?.[tableRowIndex] is ${ticketsData_?.[tableRowIndex]}`,
          fileName: 'MyEventsDataTables',
          moduleName: 'decrementTicketCountFromTableRow',
        }),
      })
      return
    }

    remainingTicketsCount = ticketsData_[tableRowIndex].remainingTicketsCount

    ticketsData_.splice(tableRowIndex, 1, {
      ...ticketsData_[tableRowIndex],
      remainingTicketsCount: remainingTicketsCount > 1 ? remainingTicketsCount - 1 : 0,
    })

    setTicketsData(ticketsData_)
  }

  /**
   * @info Fetches the tickets list from db
   */
  const groupTicketsList: any = async (assignedTickets_: IAssignedTicket[]) => {
    let tableRowIndex = -1
    let eventId: string | null = null
    let datatableRows: ITicketBoughtItem[] = []
    let registration: TEventRegisteredUsers | null = null
    let currAssignedTicket: IAssignedTicket | null = null
    let currentEvent: IEventInterface | null = null

    await helpers.asyncWhileLoop({
      loopCount: assignedTickets_.length,
      functionToFire: async (index) => {
        currAssignedTicket = assignedTickets_[index]

        tableRowIndex = datatableRows.findIndex((currDataTableRow) => {
          return currDataTableRow.registration?.id === currAssignedTicket?.registrationDocId
        })

        registration = null

        if (tableRowIndex === -1) {
          if (currAssignedTicket.registrationDocId) {
            const registeredEventSnapshot = await FirestoreService.getItem(
              COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
              currAssignedTicket.registrationDocId
            )

            if (registeredEventSnapshot.exists())
              registration = getConvertedData(
                EventRegisteredUsersModel.fromFirestoreDoc(registeredEventSnapshot).toObject()
              )
          }

          if (!!currAssignedTicket.eventDocId || !!registration?.eventId) {
            eventId = (currAssignedTicket.eventDocId ?? registration?.eventId)!
            const eventsnap = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
            currentEvent = EventModel.fromFirestoreDoc(eventsnap).toObject()
          }

          datatableRows.push({
            assignedTickets: [currAssignedTicket],
            eventId: currAssignedTicket.eventDocId ?? null,
            userId,
            tableRowIndex: -1,
            registration,
            eventDetails: currentEvent,
            tickets: [],
            assignedTicket: null,
            registrationTicket: null,
            remainingTicketsCount: 1,
            assignedTicketsModal: true,
          })
        } else
          datatableRows[tableRowIndex] = {
            ...datatableRows[tableRowIndex],
            assignedTickets: [...datatableRows[tableRowIndex].assignedTickets, currAssignedTicket],
            remainingTicketsCount: datatableRows[tableRowIndex].remainingTicketsCount + 1,
          }
      },
    })

    setAssignedTickets(datatableRows)
    setLoading(false)
  }

  /**
   * @info Fetches the tickets list from db
   */
  const getAssignedTicketsList = async () => {
    try {
      const ORDER_DIRECTION = 'desc'
      let assignedTickets_: IAssignedTicket[] = []
      const ORDER_BY = ASSIGNED_REGISTERED_TICKETS.FIELDS.REGISTRATION_DOC_ID

      const assigned_ticketsData_db = await FirestoreService.filterItems(
        ASSIGNED_REGISTERED_TICKETS.NAME,
        [
          where(ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_HOLDER_EMAIL_ID, '==', userEmail),
          where(
            ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_TYPE.KEY,
            '==',
            ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_TYPE.VALUE.REGISTERED
          ),
        ],
        500,
        ORDER_BY,
        ORDER_DIRECTION,
        assignedLst?.current ? assignedLst?.current || '' : null
      )

      if (assigned_ticketsData_db?.size === 0) {
        return setLoading(false)
      }

      if (assigned_ticketsData_db?.size) {
        assigned_ticketsData_db?.forEach((currDoc) => {
          let main_data = AssignTicketsModal.fromFirestoreDoc(currDoc).toObject()

          if (main_data) {
            assignedTickets_.push(getConvertedData(main_data))
          }
        })

        await groupTicketsList(assignedTickets_)
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const getEventLogos = async (eventIds: string[]): Promise<IEventDetailsHolder[]> => {
    let logos: IEventDetailsHolder[] = []
    let eventList: IEventInterface[] = []
    let user: IUserInterface | null = null
    let event: IEventInterface | null = null

    const ownerIdsList: string[] = []
    const usersList: IUserInterface[] = []

    if (!eventIds.length) return logos

    // Fetches events
    const eventSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENTS.NAME, [
      where(documentId(), 'in', eventIds),
    ])

    eventSnaps.forEach((c) => {
      event = EventModel.fromFirestoreDoc(c).toObject()
      if (event.owner) {
        eventList.push(event)
        ownerIdsList.push(event.owner)
      }
    })

    if (!ownerIdsList.length) return logos

    // Fetches users
    const usersSnaps = await FirestoreService.filterItems(USERS.NAME, [
      where(documentId(), 'in', ownerIdsList),
    ])

    usersSnaps.forEach((c) => {
      user = UserModel.fromFirestoreDoc(c).toObject()
      usersList.push(user)
    })

    // Combines
    eventList.forEach((currEvent) => {
      user = usersList.find((cu) => cu.id === currEvent.owner) ?? null
      if (!user) return
      logos.push({
        event: currEvent,
        eventId: currEvent.id,
        eventLogo: user?.userProfilePicture ?? null,
      })
    })

    return logos
  }

  /**
   * @info Fetches the tickets list from db
   */
  const getTicketsList = async () => {
    let remainingTicketsCount = 0
    let eventId: string | null = null
    let tickets: IRegistrationTicketInterface[] = []
    let tickectsList: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem'][] = []
    let ticket_Buyers: ITicketBuyer[] = []
    let upgradedBuyers: any[] = []
    const ORDER_DIRECTION = 'desc'
    const ORDER_BY = EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY

    const ticketBuyersnaps = await FirestoreService.filterItems(
      TICKET_BUYERS.NAME,
      [where(TICKET_BUYERS.FIELDS.USER_ID.KEY, '==', userId)],
      500,
      ORDER_BY,
      ORDER_DIRECTION,
      ticketsData.length ? ticketsData[ticketsData.length - 1]?.registration?.id : null
    )

    if (!ticketBuyersnaps?.size) {
      setLoading(false)
      return
    }

    ticketBuyersnaps.forEach((c) => {
      ticket_Buyers.push(getConvertedData(TicketBuyersModel.fromFirestoreDoc(c).toObject()))
    })

    await helpers.asyncWhileLoop({
      loopCount: ticket_Buyers.length,
      functionToFire: async (index) => {
        let currentEvent: IEventInterface | null = null
        eventId = ticket_Buyers[index].eventId ?? null
        const currentBuyer = ticket_Buyers[index]

        tickets = []

        if (!eventId) {
          helpers.logger({
            message: CustomError.somethingWentWrong({
              message: `registration with id:${ticket_Buyers[index]} don't have event id in it`,
            }),
          })
          return 0
        }

        const ticketsSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.REGISTRATION_TICKET.NAME,
          [
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
              '==',
              ticket_Buyers[index].registrationDocId
            ),
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.PAYMENT_STATUS.KEY,
              '==',
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.PAYMENT_STATUS.VALUES.PAID
            ),
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.IS_SCRATCHED.KEY,
              '==',
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.IS_SCRATCHED.VALUES.NO
            ),
          ]
        )

        let ticket: IRegistrationTicketInterface
        remainingTicketsCount = 0

        ticketsSnapshots.forEach((currTicketSnapshot) => {
          ticket = RegistrationTicketModel.fromFirestoreDoc(currTicketSnapshot).toObject()
          tickets.push(ticket)
        })

        const eventIds = ticket_Buyers
          .map((buyer) => buyer.eventId)
          .filter((id): id is string => id !== null)
        const logos = await getEventLogos(eventIds)

        const eventsnap = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
        currentEvent = EventModel.fromFirestoreDoc(eventsnap).toObject()
        const eventLogo =
          logos.find((logo) => logo.eventId === eventId)?.eventLogo ??
          currentEvent.eventCardCoverImageUrl
        const { category, state, tags, eventName, registrationOpenDate } = currentEvent

        remainingTicketsCount = currentBuyer.unusedTicketCount

        let buyerData = {
          category,
          eventId,
          eventName,
          discipline: tags,
          eventState: state,
          registrationOpenDate,
          remainingTicketsCount,
          userId: currentBuyer.userId,
          eventLogo: eventLogo,
          ticket_Buyers: ticket_Buyers[index],
          type: 'spectator',
        }

        upgradedBuyers.push(buyerData)

        tickectsList.push(
          getConvertedData({
            eventId: ticket_Buyers[index].eventId ?? null,
            registration: upgradedBuyers[index] as any,
            tickets,
            userId,
            eventDetails: currentEvent,
            tableRowIndex: -1,
            assignedTicketsModal: false,
            assignedTicket: null,
            registrationTicket: null,
            remainingTicketsCount,
            assignedTickets: [],
            decrementTicketCountFromTableRow,
          })
        )
      },
    })

    setTicketsData(tickectsList)
    setLoading(false)
  }

  /**
   * @info Fetches the wailist from db
   */
  const getWaitlistUserList = async () => {
    try {
      const myEventIdArray: string[] = []
      const waitListedData: any[] = []

      const result = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST.NAME,
        [where('userId', '==', userId)]
      )

      if (!myEventIdArray.length) return setLoading(false)

      if (result.size) {
        result?.forEach((doc) => myEventIdArray.push(doc.data().eventId))
      }

      const myWaitlistEvent = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_DETAILS.NAME,
        [where(documentId(), 'in', myEventIdArray)]
      )

      if (myWaitlistEvent.size) {
        myWaitlistEvent?.forEach((doc) => {
          const dataTable = EventDetailsModel.fromFirestoreDoc(doc).toObject()
          waitListedData.push(
            getConvertedData({
              eventId: dataTable.id,
              eventName: dataTable.competitionName,
              eventLogo: dataTable.competitionEventImage[0] ?? '',
              eventCountry: dataTable.competitionCity,
              eventCategory: dataTable.category,
              eventState: dataTable.competitionState,
              eventCity: dataTable.competitionCity,
              discipline: dataTable.tags,
              registrationDate: dataTable.competitionRegistrationOpenDate,
              registeredUsers: dataTable.waitlistCount ? dataTable.waitlistCount : 0,
            })
          )
        })
      }

      setWaitlistEvents(waitListedData)
    } catch (error) {
      console.log(error, 'error')
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  /**
   * @info Gets the events in which the user is registered in,
   * and gets the count of the users,
   * that have registered in the event
   */

  const getDataList = () => {
    return (() => {
      switch (selectedTab.tab) {
        case 1:
          return registeredEvents.slice(0, 50)
        case 2:
          return waitListEvents.slice(0, 50)
        case 3:
          return draftEvents.slice(0, 50)
        case 4:
          return bookmarkedEvents.slice(0, 50)
        case 5:
          return ticketsData.slice(0, 50)
        case 6:
          return assignedTickets.slice(0, 50)
      }
    })() as IMyEvent[]
  }

  return (
    <WrapperWithHeader
      title={selectedTab.tabTitle}
      minHeight="250px"
      dividerStyles={{
        marginBottom: '8px',
      }}
      description={selectedTab.subTitle}
    >
      <InfiniteScrollDataTable
        fixedHeader={true}
        columns={!getDataList() || getDataList().length === 0 ? [] : cols}
        hasMore={false}
        data={getDataList()}
        progressPending={loading}
        progressComponent={<ViewsLoader size="md" color="red" className="pt-4 pb-4" />}
        noDataComponent={
          <DataNotAvailable
            mode="graphic"
            imageAlt="banner"
            bottomTextLink={CONST.ROUTES.HOME.URL}
            bottomText="Search for events >"
            imageSource={imageBlank}
            upperText={message}
          />
        }
        className={clsx(
          'transition-all',
          getDataList()?.length &&
            'h-full !max-h-[100%] md:max-h-[100vh] md:h-[65vh]  2xl:h-[65vh] !overflow-visible md:!overflow-auto 3333'
        )}
        noHeader={true}
      />
    </WrapperWithHeader>
  )
}

export default MyEventsDataTables
