import { useEffect, useState } from 'react'

import clsx from 'clsx'
import { size, sumBy, uniqBy } from 'lodash'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Redux
import { useAppSelector } from '../../../../../../store/hooks'
import { selectHorses } from '../../../../../../store/horses/horseSlice'
import { selectRegisterTabData } from '../../../../../../store/registration/registrationSlice'

import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'

import { CONST } from '../../../../../../const/const'
import { getFloatPrice } from '../../../../../../helpers/price'

type SelectedEventListProps = {
  registrationPrice: number | null
  activeTab: {
    tab: string
    step: number
  }
  setEventTab?: any
  manageInfo: IManageInfo
  isManage?: boolean
}

// Constants
const UNKNOWN = 'Unknown'
const NO_HORSES_SELECTED = "You may have selected members, but haven't selected horses"
const FILE_NAME = 'SelectedEventList'

const SelectedEventList = (props: SelectedEventListProps) => {
  // Hooks and vars
  const registerTabData = useAppSelector(selectRegisterTabData)
  const horses = useAppSelector(selectHorses)
  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
    uniqueHorseCount: number
    uniqueMemberCount: number
  }>({ totalItems: 0, totalPrice: 0, uniqueHorseCount: 0, uniqueMemberCount: 0 })
  const [registrationsByDay_, setRegistrationsByDay_] = useState<IRegistrationByDayInterface[]>([])

  let heading = [
    { title: 'Class', tooltipText: 'The events you entered' },
    { title: 'Registered horse', tooltipText: 'Horses you registered for each event' },
    { title: 'Registered rider', tooltipText: 'Riders you registered for each horse' },
    { title: 'Price', tooltipText: 'Cost per registration' },
  ]

  useEffect(() => {
    const registrationsByDayData: IRegistrationByDayInterface[] = []
    const registers = props.isManage ? props.manageInfo.register : registerTabData

    registers.forEach((register) => {
      register.registrationsByDay.forEach((registrationByDay) => {
        registrationsByDayData.push(registrationByDay)
      })

      register.children?.forEach((children) => {
        children.registrationsByDay.forEach((registrationByDay) => {
          registrationsByDayData.push(registrationByDay)
        })
      })
    })

    let qualifyFee = 0
    registrationsByDayData.forEach((register) => {
      if (register.isQualify) qualifyFee += Number(register.qualifyFee)
    })

    const totalPrice = parseFloat(sumBy(registrationsByDayData, 'registrationPrice').toFixed(2))
    setSelectedUnits({
      totalItems: registrationsByDayData?.length,
      totalPrice: totalPrice + qualifyFee,
      uniqueHorseCount: size(uniqBy(registrationsByDayData, 'horseId')),
      uniqueMemberCount: size(uniqBy(registrationsByDayData, 'riderId')),
    })

    const registrationsByDay__: IRegistrationByDayInterface[] = []

    ;[...registerTabData, ...props.manageInfo.register].forEach((data) => {
      data.registrationsByDay.forEach((registrationByDay) => {
        registrationsByDay__.push(registrationByDay)
      })
      data.children?.forEach((children) => {
        children.registrationsByDay.forEach((registrationByDay) => {
          registrationsByDay__.push(registrationByDay)
        })
      })
    })

    setRegistrationsByDay_(registrationsByDay__)
  }, [props.isManage, props.manageInfo.register, registerTabData])

  const uniqueHorses: string[] = []
  return (
    <ConfirmationCompsWrapper
      title="Registrations"
      id={FILE_NAME}
      cols={heading}
      activeTab={props.activeTab}
      accordion={{ open, setOpen }}
      redirectTab={CONST.UI.REGISTER.TABS.REGISTER}
      showList={!!registrationsByDay_.length}
      emptyListMessage={NO_HORSES_SELECTED}
      setEventTab={props.setEventTab}
      colsClassName={null}
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Registrations' : 'Registration'}, ${getFloatPrice(`${selectedUnits.totalPrice}`)}`}
    >
      <>
        {registrationsByDay_.map((currRegistrationByDay, currRegistrationByDayIndex) => {
          const riderHorses = horses
            .filter((h) => currRegistrationByDay.horseIds?.includes(h.id || ''))
            .map((h) => h.horseName)

          riderHorses.forEach((h) => {
            if (h && !uniqueHorses.includes(h)) {
              uniqueHorses.push(h)
            }
          })
          return (
            <div
              key={currRegistrationByDayIndex}
              className="flex flex-col lg:flex-row items-start justify-start w-full mb-2"
            >
              <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Class</div>

              <p
                className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize`}
              >
                <span className="line-clamp-1">{currRegistrationByDay?.registrationByDayName}</span>
              </p>

              <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Registered Horse</div>

              <p
                className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize relative px-12 lg:text-center`}
                title={!currRegistrationByDay.horseId ? 'Horse not selected' : ''}
              >
                <img
                  src="/assets/og_icons/YearofHorse-1.svg"
                  alt="horseIcon"
                  className="absolute left-4"
                />
                <span className="line-clamp-1">
                  {!riderHorses[0] ? 'No Horse' : riderHorses.join(', ')}
                </span>
              </p>
              <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Registered Rider</div>

              <p
                className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize relative px-12 lg:text-center`}
              >
                <img src="/assets/img/dark/User.svg" alt="horseIcon" className="absolute left-4" />
                <span className="line-clamp-1">{currRegistrationByDay.riderName ?? UNKNOWN}</span>
              </p>
              <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Price</div>
              <p
                className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/4 p-4 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize relative lg:justify-center items-center flex`}
              >
                <span>
                  {currRegistrationByDay.paymentType ?? (
                    <span>
                      {getFloatPrice(`${currRegistrationByDay.registrationPrice}`)}{' '}
                      {currRegistrationByDay.isQualify && (
                        <span className="normal-case">
                          {' '}
                          • {getFloatPrice(`${currRegistrationByDay.qualifyFee}`)}{' '}
                          {currRegistrationByDay.qualifyFeeName || 'qual'}
                        </span>
                      )}
                    </span>
                  )}
                </span>
              </p>
            </div>
          )
        })}

        {/* Total */}
        <div className="flex flex-col lg:flex-row items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor  text-center">
          <p className="p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor text-left w-full lg:w-1/4">
            <span className="line-clamp-1">
              {props.isManage && 'New'} Total{' '}
              <span className="pl-1">{`${selectedUnits.totalItems} ${selectedUnits.totalItems > 1 ? 'Registrations' : 'Registration'}`}</span>
            </span>
          </p>

          <p className="p-4 mr-2 rounded-md text-left lg:text-center bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-full lg:w-1/4">
            <span className="line-clamp-1">
              {`${uniqueHorses.length} ${uniqueHorses.length > 1 ? 'horses' : 'horse'}`}
            </span>
          </p>

          <p className="p-4 mr-2 rounded-md text-left lg:text-center bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-full lg:w-1/4">
            <span className="line-clamp-1">
              {`${selectedUnits.uniqueMemberCount} ${selectedUnits.uniqueMemberCount > 1 ? 'riders' : 'rider'}`}
            </span>
          </p>

          <p
            className={clsx(
              `p-4 rounded-md text-left lg:text-center w-full bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              'lg:w-1/4'
            )}
          >
            <span className="line-clamp-1">{getFloatPrice(`${selectedUnits.totalPrice}`)}</span>
          </p>
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventList
