// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'
import './SwitchElement.css'
import clsx from 'clsx'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SwitchElementUpdated: React.FC<{
  on: boolean
  onSwitch: () => void
  onText?: string
  offText?: string
  width?: number
  text_size?: string
}> = ({ on, onSwitch, onText, offText, width, text_size }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <button
      onClick={onSwitch}
      className={
        'relative tw-button-sponsors ' +
        (on ? 'bg-SeabiscuitGreenThemeColor yes' : 'bg-gray-200 no')
      }
      value={on ? onText || 'Yes' : offText || 'No'}
      style={{ width }}
    >
      <span className="bg-SeabiscuitWhiteThemeColor"></span>
      <div
        className={clsx(
          'btn-text',
          text_size && on ? `${text_size} !left-[2px]` : text_size ? text_size : 'text-base',
          'text-SeabiscuitWhiteThemeColor',
          on ? 'yes' : 'no'
        )}
      >
        {on ? onText || 'Yes' : offText || 'No'}
      </div>
    </button>
  )
}

export default SwitchElementUpdated
