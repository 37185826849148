import { QueryFieldFilterConstraint, where } from 'firebase/firestore'

import { IRegistrationFeesInterface } from '../models/registration-fees/registrationFees.interface'
import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationTicketInterface } from '../models/registration-tickets/registrationTicket.interface'
import { IEventReviewPublish } from '../models/event-review-publish/event-review-publish.interface'

import { CONST } from '../const/const'
import FirestoreService from '../services/firestoreService'
import { RegistrationByDayModel } from '../models/registrations-by-day/registrationByDay.model'
import { getConvertedData } from '../models/interface.helper'
import { EventReviewPublishModel } from '../models/event-review-publish/event-review-publish.model'
import { RegistrationFeesType } from '../models/event-fees/event-fees.interface'
import { RegistrationTicketModel } from '../models/registration-tickets/registrationTicket.model'
import { RegistrationFeesModel } from '../models/registration-fees/registrationFees.model'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export const getRegistrationData = async (
  userId: string,
  registrationId: string,
  eventId: string
) => {
  let registrationsFees: IRegistrationFeesInterface[] = []
  let registrationsByDay: IRegistrationByDayInterface[] = []
  let registrationsTickets: IRegistrationTicketInterface[] = []
  let publishedEventInDb: IEventReviewPublish | null = null

  let commonQueries = [
    where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId),
    where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId),
  ]

  commonQueries.push(where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY, '==', userId))

  let queryConstraint = {
    Fees: {
      query: [] as QueryFieldFilterConstraint[],
      collection: COLLECTIONS.REGISTRATION_FEES.NAME,
    },
    Tickets: {
      query: [] as QueryFieldFilterConstraint[],
      collection: COLLECTIONS.REGISTRATION_TICKET.NAME,
    },
    Classes: {
      query: [] as QueryFieldFilterConstraint[],
      collection: COLLECTIONS.REGISTRATION_BY_DAY.NAME,
    },
  }

  queryConstraint.Classes.query = commonQueries
  queryConstraint.Fees.query = commonQueries
  queryConstraint.Tickets.query = commonQueries

  if (queryConstraint.Classes.query.length) {
    const commonSnaps = await FirestoreService.filterItems(
      queryConstraint.Classes.collection,
      queryConstraint.Classes.query
    )

    commonSnaps.docs.forEach((currDoc) => {
      const current = getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      registrationsByDay.push(current)
    })
  }

  const publishedEventInDbSnapShot = await FirestoreService.getItem(
    COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
    eventId
  )

  if (publishedEventInDbSnapShot.exists()) {
    publishedEventInDb = getConvertedData(
      EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
    )

    const { EventFees: { registrationFees } = {} } = publishedEventInDb
    registrationsByDay = registrationsByDay.map((registrationByDay) => {
      const filtered = (registrationFees as RegistrationFeesType[]).filter(
        (fee) => fee.uuid === registrationByDay.uuid
      )
      if (filtered.length > 0) {
        return { ...registrationByDay, registrationByDayName: filtered[0].name ?? null }
      } else {
        return registrationByDay
      }
    })
  }

  if (queryConstraint.Tickets.query.length) {
    const commonSnaps = await FirestoreService.filterItems(
      queryConstraint.Tickets.collection,
      queryConstraint.Tickets.query
    )

    commonSnaps.docs.forEach((currDoc) => {
      registrationsTickets.push(
        getConvertedData(RegistrationTicketModel.fromFirestoreDoc(currDoc).toObject())
      )
    })
  }

  if (queryConstraint.Fees.query.length) {
    const commonSnaps = await FirestoreService.filterItems(
      queryConstraint.Fees.collection,
      queryConstraint.Fees.query
    )

    commonSnaps.docs.forEach((currDoc) => {
      const registrationFee = getConvertedData(
        RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject()
      )
      if (registrationFee.selectedUnitsCount > 0) registrationsFees.push(registrationFee)
    })
  }

  return { registrationsByDay, registrationsFees, registrationsTickets, publishedEventInDb }
}
