import React, { FC, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useIonRouter } from '@ionic/react'
import { where } from 'firebase/firestore'
import clsx from 'clsx'

import FirestoreService from '../../../../../services/firestoreService'

import ViewsLoader from '../../../../loader/ViewsLoader'
import MainModal from '../../../../modals/common/MainModal'
import Accordion from '../../../../accordion/common/Accordion'

import helpers from '../../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import customImageComponent from '../../../../common/CustomImageComponent'

import { selectBasicEventDetails } from '../../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  setRegisterExhibitor,
  setRegisterId,
  setWaitlistExhibitor,
  setWaitlistId,
} from '../../../../../store/registerExhibitor/registerExhibitorSlice'

import { EventWailistModel } from '../../../../../models/event-waitlist/eventWaitlist.model'
import { CustomError, getUserFullName } from '../../../../../helpers/helpers'
import { getConvertedData } from '../../../../../models/interface.helper'
import { UserModel } from '../../../../../models/users/user.model'
import { IEventWaitlistInterface } from '../../../../../models/event-waitlist/eventWaitlist.interface'

import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { DATA_FIRESTORE_V01_CONST } from '../../../../../const/data/v01/firestore-v01-const'
import { selectAllUsers } from '../../../../../store/users/usersSlice'
import { IRiderTeamMember } from '../../../../../models/users/user.interface'
import { getRegisteredRiders } from '../../../../../helpers/riders'

// Constants
const EVENT_WAITLIST = CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST

// Types
type IRiderToRender = {
  horseName?: string | null
  horseId?: string | null
  horseProfilePicture?: string | null
  registrationByDayName: string | null
}

type IRiderModified = {
  riderId: string
  riderName: string
  riderProfilePicture: string
  children: IRiderToRender[]
}

const TabSection: FC<{ currentTab: string; onChange: (e: string) => void }> = ({
  currentTab,
  onChange,
}) => {
  // Functions
  const activeTabHandle = (active: string) => {
    if (currentTab === active) {
      return {
        color: '#F70763',
        background: '#F707630D',
      }
    } else {
      return {
        color: '#122B46',
      }
    }
  }

  return (
    <div className="flex mt-4 gap-2 bg-gray-100 md:bg-transparent rounded-full md:rounded-none p-1 md:p-0 mb-3">
      <button
        onClick={() => onChange('1')}
        className="md:flex-none flex-1 bg-transparent border-none outline-none uppercase md:normal-case py-2 px-3 text-[14px] mr-1 rounded-lg"
        style={{
          ...activeTabHandle('1'),
        }}
      >
        Registered
      </button>
      <button
        onClick={() => onChange('2')}
        className="md:flex-none flex-1 bg-transparent border-none outline-none uppercase md:normal-case text-[14px] py-2 px-3 mr-1 rounded-lg"
        style={{
          ...activeTabHandle('2'),
        }}
      >
        Wait List
      </button>
    </div>
  )
}

const handleProfile = async (
  id: any,
  router: any,
  history: any,
  dispatch: any,
  handleModal: any,
  isRider?: boolean
) => {
  const usersSnaps = await FirestoreService.getItem(
    DATA_FIRESTORE_V01_CONST.COLLECTIONS.USERS.NAME,
    id
  )
  const userData = getConvertedData(UserModel.fromFirestoreDoc(usersSnaps).toObject())
  if (isRider) {
    dispatch(setRegisterExhibitor(userData))
    dispatch(setRegisterId(id))
    history.push(`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/${id}`)
  } else {
    dispatch(setWaitlistExhibitor(userData))
    dispatch(setWaitlistId(id))
    history.push(`${CONST.ROUTES.USER_PROFILE.URL}/${id}`)
  }
  handleModal?.(false, MODAL_CONSTS.REGISTER_EXHIBITORS)
}

const Cart: FC<{
  isRider?: boolean
  name?: string | null
  id?: string | null
  profilePicture?: string | null
  description?: string | null
}> = ({ description, isRider, name, id, profilePicture }) => {
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  return (
    <div className="flex gap-2 items-center">
      <div
        className={clsx(id && 'cursor-pointer hover:opacity-70 transition-all')}
        onClick={() => id && handleProfile(id, router, history, dispatch, handleModal, isRider)}
      >
        {customImageComponent(
          profilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER,
          name,
          'w-[45px] !h-[45px] rounded-full shrink-0 object-cover'
        )}
      </div>
      <div>
        <h3 className="m-0 text-[14px] font-regular text-SeabiscuitDark200ThemeColor">
          {name ?? 'N/A'}
        </h3>
        {description && (
          <p className="mt-0.5 text-[14px] font-regular text-SeabiscuitDark200ThemeColor/50">
            {description}
          </p>
        )}
      </div>
    </div>
  )
}

const EventDetailViewComponentExhibitorsModal: FC<{
  show: boolean
  dataToPassOn?: {
    id: {
      tab: number
      tabTitle: string
      subTitle: string
    }
    eventClicked: {
      eventId: string
    }
  }
}> = ({ show, dataToPassOn }) => {
  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const router = useIonRouter()
  const history = useHistory()

  const toastFunctions = useToasterHelper()
  const basicEventDetails = useAppSelector(selectBasicEventDetails)
  const users = useAppSelector(selectAllUsers)

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [waitlist, setWaitlist] = useState<IRiderTeamMember[]>([])
  const [registeredRiders, setRegisteredRiders] = useState<IRiderModified[]>([])

  const eventId = dataToPassOn?.eventClicked?.eventId ?? basicEventDetails.id

  useEffect(() => {
    if (!eventId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'useEffect',
          fileName: 'EventDetailViewComponentExhibitorsModal',
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `eventId is ${eventId}`,
        }),
      })
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return
    }

    if (currentTab === '2') getWaitlistUserList().then()
    else getRiders().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  async function getWaitlistUserList() {
    const EVENT_ID_KEY = 'eventId'

    try {
      setLoading(true)

      const result = await FirestoreService.filterItems(EVENT_WAITLIST.NAME, [
        where(EVENT_ID_KEY, '==', eventId),
      ])

      if (result.size) {
        const events: IEventWaitlistInterface[] = []
        result?.forEach((doc) => events.push(EventWailistModel.fromFirestoreDoc(doc).toObject()))
        const data = events.reduce((acc: any, current) => {
          current.waitlist.forEach((item) => {
            acc.push(item)
          })
          return acc
        }, [])

        setWaitlist(data)
      }

      setLoading(false)
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
    }
  }

  async function getRiders() {
    setLoading(true)
    try {
      if (!eventId) return
      let registeredRiders = await getRegisteredRiders([eventId])

      const grouped = registeredRiders.reduce<Record<string, IRiderModified>>((acc, item) => {
        const { riderId } = item

        if (riderId) {
          const user = users.find((currUser) => currUser.id === riderId) ?? null

          if (!acc[riderId]) {
            acc[riderId] = {
              riderId,
              riderName: user ? getUserFullName(user) : '',
              riderProfilePicture: user?.userProfilePicture ?? '',
              children: [],
            }
          }
          acc[riderId].children.push(item)
        }
        return acc
      }, {})

      setRegisteredRiders(Object.values(grouped))
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      size="md"
      show={show}
      loading={loading}
      type="REGISTER_EXHIBITORS"
      title="Going"
      titleClassName="text-SeabiscuitDark200ThemeColor"
      customTitle={
        <>
          <TabSection currentTab={currentTab} onChange={(e) => setCurrentTab(e)} />
        </>
      }
      buttons={[
        {
          label: 'CLOSE',
          fullWidth: true,
          bgClass: 'bg-SeabiscuitDark100ThemeColor/10',
          borderClass: 'border-SeabiscuitDark100ThemeColor/50',
          textClass: 'text-SeabiscuitDark100ThemeColor/50',
          onClick: () => handleModal?.(false, MODAL_CONSTS.REGISTER_EXHIBITORS),
        },
      ]}
    >
      <>
        {currentTab === '1' ? (
          loading ? null : (
            <>
              {registeredRiders && registeredRiders.length ? (
                registeredRiders.map((item, index) => (
                  <Accordion
                    key={index}
                    initialState={false}
                    className="border-none"
                    header={
                      <div className="flex items-center gap-2.5">
                        <div
                          className="cursor-pointer transition-all hover:opacity-70"
                          onClick={() => {
                            handleModal?.(false, MODAL_CONSTS.REGISTER_EXHIBITORS)
                            router.push(`${CONST.ROUTES.USER_PROFILE.URL}/${item.riderId}`)
                            history.push(`${CONST.ROUTES.USER_PROFILE.URL}/${item.riderId}`)
                          }}
                        >
                          {customImageComponent(
                            item.riderProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER,
                            item.riderName,
                            'w-[45px] !h-[45px] rounded-full shrink-0 object-cover border'
                          )}
                        </div>
                        <div className="text-SeabiscuitDark200ThemeColor">
                          <h6 className="text-[14px]">{item.riderName}</h6>
                          <p className="font-normal text-[12px] opacity-70">
                            {item.children.length} entries
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <div className="flex flex-col mt-2 gap-4">
                      {item.children.map((children) => (
                        <Cart
                          isRider
                          id={children.horseId}
                          name={children.horseName}
                          profilePicture={children.horseProfilePicture}
                          description={children.registrationByDayName}
                        />
                      ))}
                    </div>
                  </Accordion>
                ))
              ) : (
                <div className="text-SeabiscuitDark200ThemeColor text-nr h-[200px] flex justify-center items-center w-full">
                  No users have registered yet.
                </div>
              )}
            </>
          )
        ) : null}

        {currentTab === '2' ? (
          <>
            {loading ? (
              <div className="h-[200px] flex justify-center items-center w-full">
                <ViewsLoader size="xl" color="red" />
              </div>
            ) : (
              <div className="flex flex-col mt-2 gap-4">
                {waitlist && waitlist.length ? (
                  waitlist.map((item, index) => (
                    <Cart
                      key={index}
                      id={item.memberId}
                      name={item.memberName}
                      profilePicture={item.memberProfilePicture}
                      description={item.memberEmail}
                    />
                  ))
                ) : (
                  <div className="text-SeabiscuitDark200ThemeColor text-nr h-[200px] flex justify-center items-center w-full">
                    No one is on the waitlist yet
                  </div>
                )}
              </div>
            )}
          </>
        ) : null}
      </>
    </MainModal>
  )
}

export default EventDetailViewComponentExhibitorsModal
