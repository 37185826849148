// ############################################################
/**
 * @todo Document this
 */
// ############################################################
import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import { useAppSelector } from '../store/hooks'
import { RootState } from '../store/store'

import { capitalize } from '../helpers/helpers'
import FirebaseApp from '../services/firebaseApp'
import { httpService } from '../services/httpService'
import { selectDisplayName } from '../store/user/userSlice'
import MainLayout from './mainlayout/MainLayout'

const AppLayout: React.FC = () => {
  let displayName = useAppSelector(selectDisplayName)
  const userReducer = useSelector((state: RootState) => state.user)

  const { update, shutdown, boot } = useIntercom()

  const uid = FirebaseApp.auth.currentUser?.uid ?? null
  const email = FirebaseApp.auth.currentUser?.email ?? null
  const photoUrl = FirebaseApp.auth.currentUser?.photoURL ?? null
  const displayName_ = FirebaseApp.auth.currentUser?.displayName ?? null

  const capitalizeUserType = (userType: string | undefined) => {
    if (!userType) return ''
    return userType.charAt(0).toUpperCase() + userType.slice(1)
  }
  const fetchIntercomData = async () => {
    if (uid) {
      boot()

      type CustomAttributes = {
        account_type: string
        affiliate_link?: string
      }

      // Initialize default update parameters
      const updateParams = {
        userId: uid,
        email: email ?? undefined,
        name: capitalize(displayName_ ?? displayName ?? '', {
          capitalizeAll: false,
        }),
        avatar: {
          type: 'avatar' as const,
          imageUrl: photoUrl ?? undefined,
        },
        customAttributes: {
          account_type: capitalizeUserType(userReducer?.userType),
        } as CustomAttributes,
      }

      try {
        const result = await httpService({
          url: `get_affiliate_object?email=${encodeURIComponent(email || '')}`,
          method: 'GET',
        })

        // If success - add the affiliate link to the update params
        if (result?.data?.[0]?.links?.[0]?.url) {
          updateParams.customAttributes.affiliate_link = result.data[0].links[0].url
        }
      } catch (error) {
        // Log the error but continue without the affiliate data
        console.error('Could not fetch affiliate data:', error)
      }

      // Update Intercom with whatever data we have
      update(updateParams)
    } else {
      boot()
    }
  }

  useEffect(() => {
    if (email) fetchIntercomData().then()
  }, [boot, update, shutdown, uid, displayName, displayName_, email, photoUrl])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return <MainLayout />
}

export default AppLayout
