export const DATA_FIRESTORE_V01_EVENT_REGISTERED_USERS = {
  NAME: 'v01_event_registered_users',

  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
    },

    USER_EMAIL: {
      KEY: 'userEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    IS_REGISTERED: {
      KEY: 'isRegistered',
    },

    HORSES_IDS: {
      KEY: 'horsesIds',
      VALUES: {
        DEFAULT: [],
      },
    },

    MEMEBER_IDS: {
      KEY: 'memberIds',
      VALUES: {
        DEFAULT: [],
      },
    },

    IS_SCRATCHED: {
      KEY: 'isScratched',
      VALUES: {
        DEFAULT: false,
        TRUE: true,
        FALSE: false,
      },
    },

    USER_NAME_N_GRAMS: {
      KEY: 'userNameNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    EVENT_REGISTRATION_STATUS: {
      KEY: 'eventRegistrationStatus',
      VALUES: {
        DRAFT: 'draft',
        REGISTERED: 'registered',
      },
    },

    REGISTRATION_DATE: {
      KEY: 'registrationDate',
      VALUES: {
        DEFAULT: null,
      },
    },

    DRAFT_ID: {
      KEY: 'draftId',
    },

    USER_ID: {
      KEY: 'userId',
    },

    EVENT_ID: {
      KEY: 'eventId',
    },

    STATUS: {
      KEY: 'status',
    },

    USER_TYPE: {
      KEY: 'userType',
      VALUES: {
        COMPETITOR: {
          KEY: 'competitor',
        },
        EXHIBITOR: {
          KEY: 'exhibitor',
        },
        SPONSOR: {
          KEY: 'sponsor',
        },
      },
    },

    CREATED: {
      KEY: 'created',
    },

    MODIFIED: {
      KEY: 'modified',
    },
  },
}
