import React, { useEffect, useRef } from 'react'

// Third party
import { FieldValues, RegisterOptions, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { IBasicInputProps } from './Input'

// css
import './AmountInput.css'

type IAmountInputProps = {
  name: string
  onFocus?: () => void
  onBlur?: () => void
  title?: string
  clear?: boolean
  prefix?: string
  postFix?: string
  onChange?: (value: string) => void
  disable?: boolean
  required?: boolean
  readonly?: boolean
  className?: string
  postFixIcon?: string
  childWidth?: number
  preFixIcon?: string
  placeholder?: string
  value: string | number
  inputClassName?: string
  showPreFixIcon?: boolean
  showPostFixIcon?: boolean
  postFixIconClassName?: string
  preFixIconClassName?: string
  textColorOnDisabled?: boolean
  setValue?: UseFormSetValue<any>
  register?: UseFormRegister<any>
  childrenToAppend?: React.ReactNode
  rules?: RegisterOptions<FieldValues, string>
  withDecimals?: boolean
} & IBasicInputProps

const AmountInput = ({
  withDecimals = true,
  prefix,
  postFix,
  value,
  clear,
  onChange,
  disable,
  readonly,
  placeholder,
  inputClassName,
  className,
  preFixIcon,
  postFixIcon,
  showPreFixIcon,
  showPostFixIcon,
  postFixIconClassName,
  preFixIconClassName,
  title,
  register,
  required,
  rules,
  childrenToAppend,
  childWidth,
  valid,
  validValueClassName,
  invalidValueClassName,
  name,
  onFocus,
  onBlur,
  textColorOnDisabled,
}: IAmountInputProps) => {
  const regex = withDecimals ? /[^\d.]/g : /\D/g

  const showIcon = (postFixIcon && showPostFixIcon) || (preFixIcon && showPreFixIcon)
  const inputRef = useRef<string>('0')
  const inputParentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    inputRef.current = transpile(value)
  }, [value, prefix, postFix])

  // Functions

  const transpile = (value: string | number, cameValueHandler?: boolean): string => {
    if (clear && !cameValueHandler) return ''

    let valueToSet = value

    valueToSet = (`${valueToSet}` ?? '').trim() ?? ''
    if (prefix || postFix) valueToSet = valueToSet.replace(regex, '')

    const parts = valueToSet.split('.')
    if (parts.length > 2) {
      valueToSet = parts[0] + '.' + parts.slice(1).join('').replace(/\./g, '')
    }

    if (prefix) {
      if (valueToSet.length > 0) valueToSet = `${prefix}${valueToSet}`
      else valueToSet = `${prefix}${0}`
    }

    if (postFix) {
      if (valueToSet.length > 0) valueToSet = `${valueToSet}${postFix}`
      else valueToSet = `${0}${postFix}`
    }

    if (!prefix && !postFix) {
      if (valueToSet.length === 0) valueToSet = `0`
      valueToSet = valueToSet.replace(regex, '')
    }

    if (prefix && valueToSet.length > 2 && valueToSet[1] === '0' && valueToSet[2] !== '.') {
      valueToSet = valueToSet.substring(2)
    }

    if (postFix && valueToSet.length > 2 && valueToSet[0] === '0' && valueToSet[1] !== '.') {
      valueToSet = valueToSet.substring(1)
    }
    if (postFix) {
      const valueLength = valueToSet.length
      if (valueLength > 1) {
        inputParentRef.current
          ?.querySelector('input')
          ?.setSelectionRange(valueLength - 1, valueLength - 1)
      }
    }

    return valueToSet
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef.current = transpile(e.target.value, true)
    e.target.value = transpile(e.target.value, true)
  }

  const focusInput = () => {
    let ref = inputParentRef.current?.children.namedItem(name)
    if (ref) (ref as any).focus()
  }

  const inputProps = {
    name: name,
    value: transpile(value),
    placeholder: placeholder,
    onChange: function (e: React.ChangeEvent<HTMLInputElement>) {
      onChangeHandler(e)
      onChange?.(e.target.value)
    },
    onFocus: () => {
      if (typeof onFocus === 'function') {
        onFocus()
      }
    },
    onBlur: () => {
      if (typeof onBlur === 'function') {
        onBlur()
      }
    },
    ...(register && {
      ...register(name, {
        required: required,
        ...(rules && rules),
      }),
      onChange: async function (e: any) {
        if (typeof onChange === 'function') {
          onChange(e.target.value)
        }
        onChangeHandler(e)
        register &&
          (await register(name, {
            required: required,
          }).onChange(e))
      },
      onFocus: () => {
        if (typeof onFocus === 'function') {
          onFocus()
        }
      },
      onBlur: () => {
        if (typeof onBlur === 'function') {
          onBlur()
        }
      },
    }),
    className: twMerge(
      clsx(
        'w-full outline-0 ring-0 rounded-md focus:outline-0 focus:ring-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate border-none bg-transparent h-full',
        inputClassName,
        showIcon && 'px-2',
        disable && (textColorOnDisabled ?? 'text-SeabiscuitDark200ThemeColor')
      )
    ),
    title: title,
    readOnly: readonly,
    disabled: disable ?? false,
  }

  const parentProps = {
    title: placeholder,
    className: clsx(
      'relative overflow-hidden rounded-md',
      className,
      valid ? validValueClassName : invalidValueClassName
    ),
  }

  return (
    <div {...parentProps} onClick={focusInput}>
      <span
        className={clsx(
          showIcon
            ? 'px-3 flex h-full items-center'
            : `${childrenToAppend && valid ? `w-[calc(100%-${childWidth ?? 24}px)]` : 'w-full'} relative flex items-center h-full`
        )}
        ref={inputParentRef}
        onClick={focusInput}
        style={{
          width: `${childrenToAppend && valid ? `calc(100% - ${childWidth ?? 24}px)` : '100%'} `,
        }}
      >
        {preFixIcon && showPreFixIcon && (
          <img src={preFixIcon} alt="icon" className={clsx('w-6 h-6', preFixIconClassName)} />
        )}

        <input type="text" {...inputProps} />

        {postFixIcon && showPostFixIcon && (
          <img src={postFixIcon} alt="icon" className={clsx('w-6 h-6', postFixIconClassName)} />
        )}
      </span>
      {valid && childrenToAppend}
    </div>
  )
}

export default AmountInput
