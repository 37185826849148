import React from 'react'

interface PropsEntryCard {
  icon?: React.ReactNode
  title: string
  subtitle: string
}
export const EntryCard: React.FC<PropsEntryCard> = ({ icon, title, subtitle }) => {
  return (
    <div className="border border-SeabiscuitGray500ThemeColor rounded-[6px] p-[6px] pe-[23px] flex items-center justify-between gap-5">
      <div className="flex items-center gap-2.5">
        <div
          className={
            'bg-SeabiscuitGrayThemeColor rounded-[6px] flex items-center justify-center w-[45px] h-[45px]'
          }
        >
          {icon}
        </div>

        <p className="text-[14px]">{title}</p>
      </div>
      <p className="text-SeabiscuitDark200ThemeColor/50 text-[14px]">{subtitle}</p>
    </div>
  )
}
