import { cloneDeep } from 'lodash'

import { IRiderTeamMemberInterface } from '../models/rider-team-member/riderTeamMember.interface'
import { IRegistrationTabs } from '../models/event-registered-users/event-registered-users.interface'

export function mergeRidersTeamMembersWithPaperworkTabData(
  paperworkTabData: IRegistrationTabs['IPaperworkTab'][],
  ridersTeamMembers: IRiderTeamMemberInterface[]
) {
  let paperworkTabData_ = cloneDeep(paperworkTabData)
  let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

  paperworkTabData_ = paperworkTabData_.map((currPaperworkRow) => {
    const foundRiderTeamMembers = ridersTeamMembers_.filter(
      (currRidersTeamMember) =>
        currRidersTeamMember.registrationByDayUniqId === currPaperworkRow.registrationByDayUniqId
    )

    return {
      ...currPaperworkRow,
      ridersTeamMembers: foundRiderTeamMembers,
    }
  })

  return paperworkTabData_
}
