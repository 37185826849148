// Component imports
import ModalComponent from '../events/views/details/global/modalComponent'

// Constants
import { useEffect, useState } from 'react'
import { CONST } from '../../const/const'
import { MODAL_CONSTS } from '../../const/modal-const'
import { EventModel } from '../../models/events/event.model'
import { ILegalClauseInterface } from '../../models/user-legal-clauses/user-legal-clauses.interface'
import FirestoreService from '../../services/firestoreService'
import ViewsLoader from '../loader/ViewsLoader'
import { UserModel } from '../../models/users/user.model'
import { getAllClauses } from '../../helpers/clauses'
import { getUserFullName } from '../../helpers/helpers'

const AnnexCard = ({
  icon,
  heading,
  content,
}: {
  icon: string
  heading: string
  content: string | any
}) => {
  return (
    <>
      <div className="border border-[#D3DAEE] rounded-xl flex p-4 my-2 mr-2">
        <div className="w-8 h-8 px-1 py-1 shrink-0">
          <img className="w-full object-cover" src={icon} alt="icon" />
        </div>
        <div className="text-SeabiscuitDark200ThemeColor pl-4 leading-7">
          <span className="font-semibold mr-1">{heading} :</span>
          {content}
        </div>
      </div>
    </>
  )
}

// Types
type IReviewAnnexProps = {
  show: boolean
  dataToPassOn: { data: { eventId: string; eventName: string } }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const ReviewAnnex = ({ show, dataToPassOn, handleModal }: IReviewAnnexProps) => {
  // Hooks and vars
  const [allClauses, setAllClauses] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [ownerName, setOwnerName] = useState<string>('')

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.REVIEW_ANNEX)
  }

  const handleOwnerInfo = async (eventId: string) => {
    setLoading(true)
    const getownerdoc = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
      eventId
    )
    const getowner = EventModel.fromFirestoreDoc(getownerdoc).toObject()

    if (!getowner.owner) return

    const getownerinfo = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
      getowner.owner
    )
    const getownerdata = UserModel.fromFirestoreDoc(getownerinfo).toObject()
    setOwnerName(getUserFullName(getownerdata))

    if (getowner) {
      getAllClauses(getowner?.owner as string)
        .then((res) => {
          setLoading(false)
          setAllClauses(res ?? [])
        })
        .catch((error) => {
          console.error(error, 'error')
        })
    }
  }

  useEffect(() => {
    if (dataToPassOn?.data?.eventId) handleOwnerInfo(dataToPassOn?.data?.eventId).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn])

  return (
    <ModalComponent
      title={`Annex for ${dataToPassOn?.data?.eventName ?? 'Unknown'}`}
      show={show}
      fullScreen={true}
      onHide={closeModal}
      type={MODAL_CONSTS.REVIEW_ANNEX}
      size="md"
    >
      <div className="h-[650px] overflow-y-auto">
        <p className="text-SeabiscuitDark200ThemeColor leading-7">
          Pegasus generates a General Liability Waiver for all events. Event organizers add clauses
          to this liability waiver that are unique to their event and/or property. These unique
          clauses will be appended to the end of the General Liability Waiver as an annex.
        </p>
        <p className="text-SeabiscuitDark200ThemeColor mb-4 leading-7 mt-4">
          You can review these unique clauses below.
        </p>
        {allClauses && allClauses.length > 0 ? (
          allClauses.map((clause: ILegalClauseInterface, index) => {
            return (
              <AnnexCard
                key={`${JSON.stringify(clause)}${index}`}
                icon="/assets/og_icons/JudgeB.svg"
                heading={clause.title}
                content={clause.description}
              />
            )
          })
        ) : (
          <>
            {loading ? (
              <ViewsLoader
                className="flex items-center w-full justify-center min-h-[30vh] mt-3"
                size="lg"
                color="red"
              />
            ) : (
              <span className="text-SeabiscuitDark200ThemeColor opacity-50 w-[100%] mt-4 flex items-center justify-center ">
                No annex is added by an {ownerName}
              </span>
            )}
          </>
        )}
      </div>
      <div className="flex justify-center">
        <div
          onClick={closeModal}
          className="w-60 bg-[#1F41731A] cursor-pointer text-[#1F417380] p-4 uppercase mx-auto mt-6 rounded-xl text-center"
        >
          Close
        </div>
      </div>
    </ModalComponent>
  )
}

export default ReviewAnnex
