import { selectObjToString } from '../../../models/interface.helper'

type IEvaluatedFeeItem = {
  units?: number
  cost: number
  sold: number
  note: string
  name: string
  status: boolean
  duration: string
  category: string
  available: number
  costAlias: string
  uuid: string
} | null

type ISaveAllTabsFn = (args: ISaveAllTabsFnArgs) => Promise<any>

type ISaveAllTabsFnArgs = ISaveAllTabsFnPaidArgs

type ISaveAllTabsFnPaidArgs = {
  isManage?: boolean
  isPayByCash?: boolean
  isPaid?: boolean
  cardNo?: string
  invoiceId?: string
  invoiceUrl?: string
}

type IEventRegistrationContext = {
  saveAllTabs: ISaveAllTabsFn
} | null

export type ICompetitorEventRegisterTypes = {
  IEvaluatedFeeItem: IEvaluatedFeeItem
  IEventRegistrationContext: IEventRegistrationContext
  ISaveAllTabsFn: ISaveAllTabsFn
  ISaveAllTabsFnArgs: ISaveAllTabsFnArgs
}

const getEvaluatedFeeItem = (item: any): IEvaluatedFeeItem => {
  let cost: number = 0
  let sold: number = 0
  let name: string = ''
  let note: string = ''
  let uuid: string = ''
  let duration: string = ''
  let category: string = ''
  let costAlias: string = ''
  let available: number = 0
  let status: boolean = false
  let costMatchesWithAlias: boolean = false

  if (!item) return null

  if (item.hasOwnProperty('category') && typeof item.category === 'string' && !!item.category)
    category = item.category

  if (item.hasOwnProperty('uuid') && typeof item.uuid === 'string' && !!item.uuid) uuid = item.uuid

  if (item.hasOwnProperty('available') && typeof item.available === 'number')
    available = item.available
  else available = 0

  if (item.hasOwnProperty('sold') && typeof item.sold === 'number') sold = item.sold
  else sold = 0

  if (item.hasOwnProperty('status') && typeof item.status === 'boolean') status = item.status

  if (
    (item.hasOwnProperty('name') && typeof item.name === 'string' && !!item.name) ||
    (item.hasOwnProperty('title') && typeof item.title === 'string' && !!item.title)
  ) {
    name = item.name ?? item.title
  } else {
    name = 'N/A'
  }

  if (item.hasOwnProperty('cost') && typeof item.cost === 'number' && !!item.cost) cost = item.cost

  if (item.hasOwnProperty('costAlias') && typeof item.costAlias === 'string')
    costAlias = item.costAlias
  else costAlias = '$0'

  if (item.hasOwnProperty('note') && typeof item.note === 'string' && !!item.note) note = item.note
  else note = ''

  if (item.hasOwnProperty('duration') && !!item.duration) {
    if (typeof item?.duration === 'object') duration = selectObjToString(item.duration)
    else duration = item.duration
  } else duration = 'N/A'

  if (typeof cost === 'number' && !!costAlias)
    costMatchesWithAlias = cost === Number(costAlias.replace(/[^0-9\..]+/gi, ''))

  if (!(!!category && costMatchesWithAlias && status && !!uuid)) {
    return null
  }

  return {
    cost,
    name,
    sold,
    note,
    uuid,
    status,
    category,
    duration,
    costAlias,
    available: available - sold > available ? 0 : available - sold,
  }
}

export const competitorEventRegisterHelper = {
  getEvaluatedFeeItem,
}

export { getEvaluatedFeeItem }
