import clsx from 'clsx'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import Select, { components } from 'react-select'

import { IconBack } from '../../../../../../../../components/icons/IconBack'
import { formatOptionLabel } from '../../../../../../../../helpers/select'
import { IReports } from '../../ManageClinicReportsRoot'

const initialOptions = [
  {
    label: 'Event Reports <span class="text-[#122B4680]">• 3 reports available</span>',
    value: 'Event Reports',
  },
  {
    label: 'Event Results <span class="text-[#122B4680]">• 2 reports available</span>',
    value: 'Event Results',
  },
]

interface ReportsTabWrapperProps {
  children: ReactNode
  activeOption: IReports
  setActiveOption: (value: IReports) => void
  title: string
  description: string
  onBack?: () => void
  onExport?: () => void
  isReadyToExport?: boolean
  filledRows?: number
  additional?: ReactNode
}

export const ReportsTabWrapper: FC<ReportsTabWrapperProps> = ({
  children,
  setActiveOption,
  activeOption,
  title,
  description,
  onBack,
  onExport,
  isReadyToExport,
  filledRows,
  additional,
}) => {
  const [options, setOptions] = useState(initialOptions)

  useEffect(() => {
    if (filledRows) {
      setOptions([
        {
          label: 'Event Reports <span class="text-[#122B4680]">• 3 reports available</span>',
          value: 'Event Reports',
        },
        {
          label: `Event Results <span class="text-[#122B4680]">• ${filledRows} classes unmatched</span>`,
          value: 'Event Results',
        },
      ])
    } else {
      setOptions(initialOptions)
    }
  }, [filledRows])

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-full">
      <div className="flex flex-wrap items-center justify-between gap-2 mb-8">
        <div className="flex items-center gap-2">
          {onBack && (
            <button type="button" className="w-5 hover:opacity-70 transition-all" onClick={onBack}>
              <IconBack />
            </button>
          )}
          <div>
            <h2 className="text-SeabiscuitMainThemeColor font-bold text-[25px]">{title}</h2>
            <p className="text-SeabiscuitDark200ThemeColor">{description}</p>
          </div>
        </div>
        <div className="flex gap-2">
          {additional}
          <Select
            defaultValue={options.find((current) => current.value === activeOption)}
            value={options.find((current) => current.value === activeOption)}
            options={options}
            isSearchable={false}
            formatOptionLabel={formatOptionLabel}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: '8px',
                border: '1px solid #d1d5db',
                boxShadow: 'none',
                height: '42px',
                '&:hover': { borderColor: '#9ca3af' }, // Slightly darker on hover
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: '#4B5563',
                '& svg': {
                  fill: '#4B5563',
                },
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              menu: (provided) => ({
                ...provided,
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? '#4b5563'
                  : state.isFocused
                    ? '#e5e7eb'
                    : '#ffffff',
                color: state.isSelected ? '#ffffff' : '#374151',
                '&:hover': {
                  backgroundColor: state.isSelected ? '#4b5563' : '#e5e7eb',
                },
                // styles to target the spans inside the options
                '& span': {
                  color: state.isSelected ? '#ffffff' : 'inherit',
                },
                // Target any HTML element with a class inside the option
                '& .text-SeabiscuitDark200ThemeColor/50': {
                  color: state.isSelected ? '#ffffff !important' : 'inherit',
                },
              }),
            }}
            onChange={(value) => {
              setActiveOption(value?.value as IReports)
            }}
            components={{
              DropdownIndicator: (props_) => {
                return (
                  <components.DropdownIndicator
                    {...props_}
                    className="[&>svg>path]:fill-[#122B46]"
                  />
                )
              },
              IndicatorSeparator: () => null,
            }}
          />
          {onExport && (
            <button
              type="button"
              className={clsx(
                'p-1.5 min-w-[104px] text-base rounded-lg hover:text-SeabiscuitMainThemeColor hover:bg-[#fef5f6] transition-all',
                isReadyToExport
                  ? 'bg-SeabiscuitGreenThemeColor/10 text-SeabiscuitGreenThemeColor'
                  : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
              )}
              onClick={onExport}
            >
              Export
            </button>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}
