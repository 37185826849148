import React, { FC, useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import { Tooltip } from '@mui/material'

import ViewsLoader from '../../../components/loader/ViewsLoader'
import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'

import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import FirestoreService from '../../../services/firestoreService'
import useToasterHelper from '../../../helpers/ToasterHelper'
import useGetEventData from '../../../hooks/users/common/useGetEventData'
import { getConvertedData, getReactPickerDate } from '../../../models/interface.helper'
import { createMailLog, generatePaperworkDocument } from '../../../helpers/sign'

import { useAppSelector } from '../../../store/hooks'
import { selectUserId } from '../../../store/user/userSlice'

import { ILightboxProps } from '../../../types/competitor_types'
import { IUserDocument } from '../../../models/user-documents/user-documents.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { UserModel } from '../../../models/users/user.model'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

import fakeDocumentList, { DOCUMENT_TYPES } from '../../../fakeData/fakeDocumentList'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CONST } from '../../../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

interface PaperworkItemProps {
  doc: IUserDocument
  allDocs: IUserDocument[]
  setAllDocs: (value: IUserDocument[]) => void
  setLightBox: (value: ILightboxProps) => void
  registrationByDay?: IRegistrationByDayInterface
}
export const PaperworkItem: FC<PaperworkItemProps> = ({
  doc,
  allDocs,
  setAllDocs,
  setLightBox,
  registrationByDay,
}) => {
  const handleModalContext = useContext(HandleModalContext)
  const toastFunctions = useToasterHelper()
  const { getAllData } = useGetEventData()

  const userId = useAppSelector(selectUserId)

  const [loading, setLoading] = useState(false)
  const [dateOfSignature, setDateOfSignature] = useState<Date | null>(null)

  const onViewDocument = () => {
    let paperworkImages = fakeDocument?.document_image ?? []

    if (!Array.isArray(paperworkImages)) paperworkImages = [paperworkImages]

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({
        src: current,
        title: fakeDocument?.document_name,
      })
      return acc
    }, [])

    setLightBox({
      visible: true,
      slides,
    })
  }

  const onViewAnnex = (doc: IUserDocument) => {
    handleModalContext?.handleModal?.(true, MODAL_CONSTS.REVIEW_ANNEX, {
      data: {
        eventId: doc.eventId,
        eventName: doc?.eventName,
      },
    })
  }

  const onSign = async (row: IUserDocument) => {
    setLoading(true)
    try {
      const eventDetails = await getAllData(row.eventId ?? '', ['v01_event_details'])

      if (!eventDetails || !eventDetails.v01_event_details) {
        return toastFunctions.error({
          message: 'Error getting v01_event_details',
        })
      }

      const riderTeamMembers: IRiderTeamMemberInterface[] = []
      const riderTeamMemberSnaps = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            row?.registrationDocId ?? ''
          ),
        ]
      )

      riderTeamMemberSnaps.forEach((currDoc) => {
        riderTeamMembers.push(
          getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      const currRidersTeamMember = riderTeamMembers.find(
        (member) => member.id === row.riderTeamMemberDocId
      )

      if (!currRidersTeamMember) {
        return toastFunctions.error({
          message: 'Error get Rider Team Member',
        })
      }

      const paperwork = fakeDocumentList.find(
        (data) => data.document_name === doc.documentOriginalName
      )

      if (!paperwork) {
        return toastFunctions.error({
          message: 'Error getting paperwork',
        })
      }

      if (!registrationByDay) {
        return toastFunctions.error({
          message: 'Error registration by day not found',
        })
      }

      const userSnaps = await FirestoreService.getItem(
        COLLECTIONS.USERS.NAME,
        row.competitorId ?? ''
      )
      const competitor = UserModel.fromFirestoreDoc(userSnaps).toObject()

      if (!competitor) {
        return toastFunctions.error({
          message: 'Error competitor not found',
        })
      }

      await generatePaperworkDocument({
        paperwork,
        currRidersTeamMember,
        registrationByDay,
        userData: competitor,
        eventId: row.eventId ?? '',
        EventDetails: eventDetails.v01_event_details,
        dateOfSignature: dateOfSignature ?? new Date(),
      })

      const teamMembersWithMailLog = await createMailLog({
        ridersTeamMembers: [currRidersTeamMember],
        teamMemberId: currRidersTeamMember.teamMemberId ?? '',
        paperworkDocument: paperwork,
      })

      if (!teamMembersWithMailLog) {
        toastFunctions.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
        return console.error('Error get Rider Team Member')
      }

      await FirestoreService.updateItem(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        teamMembersWithMailLog[0].id,
        new RiderTeamMemberModel(teamMembersWithMailLog[0]).toFirestore()
      )

      const usersDocumentsSnaps = await FirestoreService.getItem(
        COLLECTIONS.USERS_DOCUMENTS.NAME,
        row.id ?? ''
      )

      const newRow = getConvertedData(
        UserDocumentModel.fromFirestoreDoc(usersDocumentsSnaps).toObject()
      )

      const newDocs = allDocs.map((doc) => (doc.id === row.id ? newRow : doc))
      setAllDocs([...newDocs])
      setDateOfSignature(null)
      toastFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })
    } catch (error) {
      console.error('error', error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  const fakeDocument = fakeDocumentList.find(
    (data) => data.document_name === doc.documentOriginalName
  )

  useEffect(() => {
    if (doc.status === 'Signed' && doc.dateOfSignature) {
      setDateOfSignature(new Date(doc.dateOfSignature))
    }
  }, [doc])

  return (
    <div className="relative z-10 w-full md:w-[49%] xl:w-[32.5%] rounded-lg border border-SeabiscuitLightThemeColorD3 py-3 px-4">
      <div className="flex items-center gap-4">
        <img className="w-[45px] h-[45px]" src={fakeDocument?.document_logo ?? ''} alt="" />
        <div>
          <p className="text-[14px]">{doc.documentOriginalName}</p>
          <div className="text-[12px]">
            <span
              className="opacity-50 underline cursor-pointer hover:no-underline"
              onClick={onViewDocument}
            >
              View document
            </span>{' '}
            {fakeDocument?.document_type === DOCUMENT_TYPES.GENERAL && (
              <>
                <span className="opacity-50">I</span>{' '}
                <span
                  className="opacity-50 underline cursor-pointer hover:no-underline"
                  onClick={() => onViewAnnex(doc)}
                >
                  View Annex
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <p className="leading-[2] text-[12px] border-t border-t-SeabiscuitLightThemeColorD3 pt-2.5 mt-4">
        {fakeDocument?.document_type === DOCUMENT_TYPES.GENERAL
          ? 'By clicking sign, you agree you have read and agree to the terms and conditions in this document and the attached annex.'
          : 'By clicking sign, you agree you have read and agree to the terms and conditions in this document.'}
      </p>
      <ul
        id="fieldContainer"
        className="flex flex-col gap-3 mt-3 text-[14px] rounded-lg border border-SeabiscuitLightThemeColorD3 py-3 px-2 ms:px-5"
      >
        <li className="flex flex-wrap items-center gap-1 justify-between">
          <span className="flex items-center gap-1 md:gap-3">
            <img className="w-4 md:w-6" src="/assets/og_icons/Quill Pen-1.svg" alt="" />
            Signing as:
          </span>{' '}
          <span>
            {doc.signatoryDefaultRole}, {registrationByDay?.horseName ?? 'No horse'}
          </span>
        </li>
        <li className="flex flex-wrap items-center gap-1 justify-between">
          <span className="flex items-center gap-1 md:gap-3">
            <img className="w-4 md:w-6" src="/assets/og_icons/People-1.svg" alt="" />
            Sent by:
          </span>
          <span>{doc.competitorId === userId ? 'You' : doc.activityUser}</span>
        </li>
        <li className="relative z-10 flex flex-wrap items-center gap-1 justify-between">
          <div className="flex items-center gap-1 md:gap-3">
            <img className="w-4 md:w-6" src="/assets/og_icons/Tear-Off%20Calendar-1.svg" alt="" />
            <div className="flex items-center gap-1">
              Effective as of:
              <Tooltip
                title={
                  <p className="tooltip_title">
                    Select date prior to event start date to comply with regulations
                  </p>
                }
                placement="top"
                arrow
              >
                <img
                  className="w-[14px] cursor-pointer opacity-50 hover:opacity-100 transition-all"
                  src="/assets/cp_icons/Info-1.svg"
                  alt=""
                />
              </Tooltip>
            </div>
          </div>
          <div className="relative z-20">
            <CustomDatePicker
              disabled={doc.status === 'Signed'}
              onChange={(date) => setDateOfSignature(date)}
              selected={getReactPickerDate(dateOfSignature)}
              placeholderText={doc.status === 'Signed' ? 'N/A' : 'Select Date'}
              className={clsx(
                'ml-auto underline !opacity-100',
                doc.status === 'Signed'
                  ? 'text-SeabiscuitDark200ThemeColor pointer-events-none'
                  : !dateOfSignature && 'text-SeabiscuitMainThemeColor'
              )}
              portalId="root"
              dateFormat="dd MMM, Y"
            />
          </div>
        </li>
      </ul>
      <button
        type="button"
        className={clsx(
          'mt-4 rounded-lg w-full text-center p-3 text-[14px] disabled:bg-[#F1F1F1] disabled:text-SeabiscuitDark200ThemeColor/70 transition-all',
          loading
            ? 'text-SeabiscuitDark200ThemeColor/70'
            : doc.status === 'Signed'
              ? 'bg-SeabiscuitGreenThemeColor/15 text-SeabiscuitGreenThemeColor group hover:bg-SeabiscuitGreenThemeColor/20'
              : 'bg-SeabiscuitMainThemeColor text-white hover:bg-SeabiscuitMainThemeColorDark'
        )}
        disabled={loading || (!dateOfSignature && doc.status !== 'Signed')}
        onClick={async () => {
          if (doc.status !== 'Signed') await onSign(doc)
          else window.open(doc.documentUrl, '_blank')
        }}
      >
        {doc.status === 'Signed' ? (
          <p>
            Signed • <span className="underline group-hover:no-underline">View</span>
          </p>
        ) : (
          <p>Sign</p>
        )}
      </button>
      {loading && (
        <div className={'absolute top-0 left-0 w-full h-full bg-white/70 rounded-lg'}>
          <ViewsLoader size={'sm'} />
        </div>
      )}
    </div>
  )
}
