import React, { useEffect, useState } from 'react'

// Libs
import { uniq } from 'lodash'

// Components
import MainModal from './common/MainModal'
import { IconTeam } from '../icons/IconTeam'
import { EntriesSection } from './components/EntriesSection'
import { ScratchModalHeader } from './components/ScratchModalHeader'
import ViewsLoader from '../loader/ViewsLoader'

// Redux
import { useAppSelector } from '../../store/hooks'
import { selectExhibitorDetails } from '../../store/exhibitor/exhibitorSlice'

// Helpers
import { getFloatPrice, convertPrice } from '../../helpers/price'
import { generateName } from '../../helpers/helpers'
import { handleImageError } from '../../helpers/handleImageError'
import { getRegistrationData } from '../../helpers/registration'

// Models

// Interfaces
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'

// Constants
import { CONST } from '../../const/const'
import { FEES_CATEGORY_CONST } from '../events/views/details/EventDetailsViewComponentFees'
import { MODAL_CONSTS } from '../../const/modal-const'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface IGroupedEntries extends IRegistrationByDayInterface {
  children: IRegistrationByDayInterface[]
  horses: string[]
}
interface IData {
  entries: {
    total: number
    count: number
    items: IRegistrationByDayInterface[]
    groupedItems: IGroupedEntries[]
  }
  fees: { total: number; count: number; items: IRegistrationFeesInterface[] }
  mandatory: { total: number; count: number; items: IRegistrationFeesInterface[] }
  tickets: { total: number; count: number; items: IRegistrationTicketInterface[] }
}

interface ScratchFullEntryProps {
  show: boolean
  handleModal: any
  dataToPassOn: {
    eventId: string
    userId: string
    registrationId: string
    isChargeSalesTax: string
  }
}

export const ScratchFullEntryModal: React.FC<ScratchFullEntryProps> = ({
  show,
  handleModal,
  dataToPassOn,
}) => {
  const exhibitor = useAppSelector(selectExhibitorDetails)

  const [data, setData] = useState<IData>({
    entries: { total: 0, count: 0, items: [], groupedItems: [] },
    fees: { total: 0, count: 0, items: [] },
    mandatory: { total: 0, count: 0, items: [] },
    tickets: { total: 0, count: 0, items: [] },
  })
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)

    let { registrationsByDay, registrationsFees, registrationsTickets } = await getRegistrationData(
      dataToPassOn.userId,
      dataToPassOn.registrationId,
      dataToPassOn.eventId
    )

    let countEntries = 0
    let qualifyFeeEntries = 0
    let paidEntries = 0
    let itemsEntries: IRegistrationByDayInterface[] = []
    registrationsByDay.forEach((entry) => {
      if (
        !entry.isScratched &&
        entry.paymentStatus !==
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.REFUNDED
      ) {
        countEntries += 1
        itemsEntries.push(entry)
        if (entry.registrationPrice) paidEntries += entry.registrationPrice
        if (entry.isQualify) qualifyFeeEntries += Number(entry?.qualifyFee) ?? 0
      }
    })
    const totalEntries = paidEntries + qualifyFeeEntries

    const groupedRegistrationsByDay = itemsEntries.reduce<Record<string, IGroupedEntries>>(
      (acc, item) => {
        const horses: string[] = []
        registrationsByDay.forEach((entry) => {
          if (entry.riderId === item.riderId && entry.horseIds?.[0]) {
            horses.push(...entry.horseIds)
          }
        })

        if (item.riderId) {
          if (!acc[item.riderId]) {
            acc[item.riderId] = {
              ...item,
              children: [],
              horses: uniq(horses),
            }
          }
          acc[item.riderId].children.push(item)
        }
        return { ...acc }
      },
      {}
    )

    let totalFees = 0
    let countFees = 0
    let itemsFees: IRegistrationFeesInterface[] = []
    let totalGovernance = 0
    let countGovernance = 0
    let itemsGovernance: IRegistrationFeesInterface[] = []
    registrationsFees.forEach((fee) => {
      if (
        !fee.isScratched &&
        fee.paymentStatus !== COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.REFUNDED
      ) {
        if (fee.feesItemCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
          countGovernance += 1
          itemsGovernance.push(fee)
          totalGovernance += fee.feesPrice * fee.selectedUnitsCount
        } else {
          countFees += 1
          itemsFees.push(fee)
          totalFees += fee.feesPrice * fee.selectedUnitsCount
        }
      }
    })

    let totalTickets = 0
    let countTickets = 0
    let itemsTickets: IRegistrationTicketInterface[] = []
    registrationsTickets.forEach((ticket) => {
      if (
        !ticket.isScratched &&
        ticket.paymentStatus !==
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.REFUNDED
      ) {
        countTickets += 1
        itemsTickets.push(ticket)
        totalTickets += ticket.ticketPrice * ticket.selectedUnitsCount
      }
    })
    const paidTotal = convertPrice(totalEntries + totalFees + totalGovernance + totalTickets)

    setTotal(paidTotal)
    setData({
      entries: {
        total: totalEntries,
        count: countEntries,
        items: itemsEntries,
        groupedItems: Object.values(groupedRegistrationsByDay),
      },
      fees: { total: totalFees, count: countFees, items: itemsFees },
      mandatory: {
        total: totalGovernance,
        count: countGovernance,
        items: itemsGovernance,
      },
      tickets: { total: totalTickets, count: countTickets, items: itemsTickets },
    })

    setLoading(false)
  }

  useEffect(() => {
    getData().then()
  }, [])

  const handleNextClick = () => {
    handleModal(false, MODAL_CONSTS.SCRATCH_FULL_ENTRY)
    handleModal(true, MODAL_CONSTS.CONFIRM_SCRATCH_FULL_ENTRY, {
      exhibitor,
      totalCost: total,
      eventId: dataToPassOn.eventId,
      registrations: data.entries.items,
      fees: [...data.fees.items, ...data.mandatory.items],
      tickets: data.tickets.items,
    })
  }

  const entriesData = React.useMemo(() => {
    return data.entries.groupedItems
      .map((entry) => {
        return {
          icon: (
            <img
              src={entry?.riderProfilePicture || ''}
              alt="icons"
              className="object-cover w-[45px] h-[45px] rounded-[6px]"
              onError={(e) => handleImageError(e, generateName(entry?.riderName || ''))}
            />
          ),
          name: entry?.riderName || 'N/A',
          details: `${entry.children?.length} class${entry.children?.length === 1 ? '' : 'es'} • ${entry.horses.length || 0} horse${entry.horses.length === 1 ? '' : 's'}`,
        }
      })
      .filter(Boolean)
  }, [data.entries])

  return (
    <MainModal
      customTitle={
        <div className={`text-SeabiscuitDark200ThemeColor text-[25px] font-bold`}>
          Scratch full entry
        </div>
      }
      size={'lg'}
      show={show}
      onHide={() => handleModal(false, MODAL_CONSTS.SCRATCH_FULL_ENTRY)}
      type={'SCRATCH_FULL_ENTRY'}
      buttons={[
        {
          label: 'Next >',
          loading: false,
          disabled: total === 0,
          className: '!w-full',
          bgClass: '!bg-SeabiscuitMainThemeColor h-[58px]',
          onClick: handleNextClick,
          textClass: '!text-white uppercase',
          onHoverClass: 'hover:!bg-SeabiscuitMainThemeColorDark',
        },
        {
          label: 'CANCEL',
          loading: false,
          disabled: false,
          className: '!w-full',
          bgClass: '!bg-SeabiscuitIconThemeColor/10 border-0 !mt-2 h-[58px]',
          onClick: () => handleModal(false, MODAL_CONSTS.SCRATCH_FULL_ENTRY),
          textClass: '!text-[#8597b3] uppercase',
          onHoverClass: 'hover:!bg-SeabiscuitIconThemeColor/20',
        },
      ]}
    >
      {!loading && exhibitor?.id ? (
        <div
          className={
            'flex flex-1 h-full flex-col gap-[22px] pt-5 w-full text-SeabiscuitDark200ThemeColor'
          }
        >
          <ScratchModalHeader
            exhibitor={exhibitor}
            totalEntries={data.entries.count}
            totalCost={total}
          />
          {entriesData.length > 0 && <EntriesSection title="Entries" entries={entriesData as []} />}
          {total > 0 && (
            <EntriesSection
              title="Other"
              entries={[
                {
                  icon: <IconTeam />,
                  name: 'Fees',
                  details: `${data.fees.count} fees • ${getFloatPrice(`${data.fees.total}`)}`,
                },
                {
                  icon: <IconTeam />,
                  name: 'Mandatory fees',
                  details: `${data.mandatory.count} fees • ${getFloatPrice(`${data.mandatory.total}`)}`,
                },
                {
                  icon: <IconTeam />,
                  name: 'Tickets',
                  details: `${data.tickets.count} tickets • ${getFloatPrice(`${data.tickets.total}`)}`,
                },
              ]}
            />
          )}
          {total === 0 && (
            <div className={'flex justify-center items-center w-full flex-col flex-1 text-center'}>
              <h2 className={'text-SeabiscuitGray200ThemeColor'}>All entries are scratched</h2>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full  p-4 flex justify-center items-center">
          <ViewsLoader />
        </div>
      )}
    </MainModal>
  )
}
