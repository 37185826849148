import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { StreamChat, ChannelData } from 'stream-chat'
import FirebaseApp from '../../../services/firebaseApp'
import { httpsCallable } from 'firebase/functions'
import { selectProfileData, selectUserProfileImageUrl } from '../../../store/user/userSlice'
import { useAppSelector } from '../../../store/hooks'
import { Channel } from 'stream-chat'
import { DefaultStreamChatGenerics } from 'stream-chat-react'
import { v4 as uuidv4 } from 'uuid'
import { IUserInterface } from '../../../models/users/user.interface'
import { getUserFullName } from '../../../helpers/helpers'

export type IUsersChat = {
  id: IUserInterface['id']
  name: string
  role: string
  vertical: string
}

interface ChatContextProps {
  client: StreamChat | null
  eventsChannel: Channel<DefaultStreamChatGenerics> | undefined
  messagesChannel: Channel<DefaultStreamChatGenerics> | undefined
  setEventsChannel: React.Dispatch<
    React.SetStateAction<Channel<DefaultStreamChatGenerics> | undefined>
  >
  setMessagesChannel: React.Dispatch<
    React.SetStateAction<Channel<DefaultStreamChatGenerics> | undefined>
  >
  createChat: (
    users: { id: string; name: string }[],
    action: 'new_chat' | 'add_member',
    activeChannel?: Channel<DefaultStreamChatGenerics> | undefined
  ) => Promise<void>
  createChatEvent: (
    users: IUsersChat[],
    eventId: string,
    channelData?: ChannelData
  ) => Promise<void>
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined)

export const useChatClient = () => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChatClient must be used within a ChatProvider')
  }
  return context
}

interface ChatProviderProps {
  children: ReactNode
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [client, setClient] = useState<StreamChat | null>(null)
  const [eventsChannel, setEventsChannel] = useState<
    Channel<DefaultStreamChatGenerics> | undefined
  >(undefined)
  const [messagesChannel, setMessagesChannel] = useState<
    Channel<DefaultStreamChatGenerics> | undefined
  >(undefined)
  const profileData = useAppSelector(selectProfileData)
  const userProfileImageUrl = useAppSelector(selectUserProfileImageUrl)
  const userData = {
    id: profileData.id,
    name: getUserFullName(profileData).split(' ')[0],
    image: userProfileImageUrl,
  }

  useEffect(() => {
    if (!userData.id) return
    const chatClient = new StreamChat(process.env.REACT_APP_GETSTREAM_KEY!)
    let didUserConnectInterrupt = false

    const getStreamUserToken = httpsCallable(
      FirebaseApp.functions,
      'ext-auth-chat-getStreamUserToken'
    )

    const connectClient = async () => {
      try {
        const token = await getStreamUserToken().then((result) => result.data as string) // Asume una función para obtener el token
        await chatClient.connectUser(userData, token)
        if (!didUserConnectInterrupt) {
          setClient(chatClient)
        }
      } catch (error) {
        console.error('Error connecting to Stream Chat:', error)
      }
    }

    if (userData.id) {
      connectClient()

      return () => {
        didUserConnectInterrupt = true
        chatClient.disconnectUser().then(() => {
          console.log(`Disconnected user "${userData.id}"`)
        })
      }
    }
  }, [userData.id])

  async function createChat(
    users: { id: string; name: string }[],
    action: 'new_chat' | 'add_member',
    activeChannel: any
  ) {
    if (!client) {
      throw new Error('Client not connected')
    }

    const otherUserIds = [...new Set(users.map((user) => user.id))].filter(
      (id) => id !== profileData.id
    )
    if (action === 'new_chat') {
      let channel
      const channelData: ChannelData = {}
      try {
        if (users.length === 1) {
          channel = client.channel('messaging', {
            members: [...otherUserIds, profileData.id],
            ...channelData,
          })
        } else if (users.length > 1) {
          const channelName =
            users.map((user) => user.name.split(' ')[0]).join(', ') +
            `, ${getUserFullName(profileData).split(' ')[0]}`

          const channelId = uuidv4()
          channel = client.channel('messaging', channelId, {
            name: channelName,
            ...channelData,
          })
          await channel.create()
          await channel.addMembers([...otherUserIds, profileData.id])
        }

        await channel?.watch()
        await channel?.show()
        setMessagesChannel(channel)
      } catch (error) {
        console.error(`Error creating chat: ${error}`)
      }
    } else if (action === 'add_member') {
      try {
        await activeChannel?.addMembers(otherUserIds)
      } catch (error) {
        console.error(`Error adding members to chat: ${error}`)
      }
    } else {
      throw new Error('Invalid type of modal')
    }
  }

  async function createChatEvent(users: IUsersChat[], eventId: string, channelData?: ChannelData) {
    if (!client) {
      throw new Error('Client not connected')
    }

    const otherUserIds = [...new Set(users.map((user) => user.id))].filter(
      (id) => id !== profileData.id
    )
    let channel

    try {
      const channelName =
        users.map((user) => user.name.split(' ')[0]).join(', ') +
        `, ${getUserFullName(profileData).split(' ')[0]}`

      if (users.length === 1) {
        const channelId = await getSHA256Hash(
          [...otherUserIds, profileData.id, eventId, users[0].vertical, users[0].role]
            .sort()
            .join('')
        )
        const extraData = {
          ...channelData,
          vertical: users[0].vertical,
          user_role: users[0].role,
        }
        channel = client.channel('messaging', channelId, {
          name: channelName,
          ...extraData,
        })
      } else if (users.length > 1) {
        const channelId = uuidv4()
        channel = client.channel('messaging', channelId, {
          name: channelName,
          ...channelData,
        })
      }

      await channel?.create()
      await channel?.addMembers([...otherUserIds, profileData.id])

      await channel?.watch()
      await channel?.show()
      setEventsChannel(channel)
    } catch (error) {
      // TO DO: improve log error
      console.error(`Error creating chat: ${error}`)
    }
  }

  return (
    <ChatContext.Provider
      value={{
        client,
        eventsChannel,
        messagesChannel,
        setEventsChannel,
        setMessagesChannel,
        createChat,
        createChatEvent,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
  return hash
}
