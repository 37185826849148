import React, { useCallback } from 'react'
import { IconList } from '../../icons/IconList'
import { handleImageError } from '../../../helpers/handleImageError'
import { IUserInterface } from '../../../models/users/user.interface'
import { getUserFullName } from '../../../helpers/helpers'
import {  getFloatPrice } from '../../../helpers/price'

interface ScratchModalHeaderProps {
  exhibitor: IUserInterface
  totalEntries: number
  totalCost: number
}

export const ScratchModalHeader: React.FC<ScratchModalHeaderProps> = ({
  exhibitor,
  totalEntries,
  totalCost,
}) => {
  const handleImgError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => handleImageError(e, getUserFullName(exhibitor)),
    [exhibitor]
  )

  return (
    <div className="flex gap-5 justify-between items-center border-b border-SeabiscuitGray500ThemeColor pb-[22px]">
      <div className="flex items-center gap-2.5">
        <img
          src={exhibitor.userProfilePicture}
          alt={getUserFullName(exhibitor)}
          className="object-cover w-[45px] h-[45px] rounded-[6px]"
          onError={handleImgError}
        />
        <div>
          <p className="text-SeabiscuitDark200ThemeColor/50 text-[12px]">Registered by</p>
          <p>{getUserFullName(exhibitor)}</p>
        </div>
      </div>
      <div className="flex items-center gap-2.5">
        <div className="text-right">
          <p className="text-SeabiscuitDark200ThemeColor/50 text-[12px]">Total</p>
          <p>
            {totalEntries} entries • {getFloatPrice(`${totalCost}`)}
          </p>
        </div>
        <div className="bg-SeabiscuitMainThemeColor/5 w-[45px] h-[45px] rounded-[6px] flex items-center justify-center">
          <IconList />
        </div>
      </div>
    </div>
  )
}
