import { useContext, useEffect, useRef, useState } from 'react'

// Constants
import { MODAL_CONSTS } from '../../const/modal-const'

// Third party
import { useHistory } from 'react-router-dom'

// Components
import BillDetailsFeesData from './accordionData/BillDetailsFeesData'
import BillDetailsGrandTotalData from './accordionData/BillDetailsGrandTotalData'
import BillDetailsTicketsData from './accordionData/BillDetailsTicketsData'
import BillDetailsRegistrationData from './accordionData/BillDetailsRegistrationData'

// Redux
import { useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import domToImage from 'dom-to-image'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { StandardFonts } from 'pdf-lib'
import helpers from '../../commonHelpers/helpers'
import { CONST } from '../../const/const'
import { IMAGE_CONSTS } from '../../const/image-const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { ROUTES_CONST } from '../../const/routes-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { CustomError, capitalize, createString, getUserFullName } from '../../helpers/helpers'
import { RecipientModel } from '../../models/recipients/recipients'
import { IRecipientInterface } from '../../models/recipients/recipients.interface'
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IUserCards, IUserInterface } from '../../models/users/user.interface'
import { UserModel } from '../../models/users/user.model'
import ExhibitorCommentAccordion from '../../pages/organizer/exhibitorProfile/accordions/ExhibitorCommentAccordion'
import ExhibitorProfileRefundAccordion from '../../pages/organizer/exhibitorProfile/accordions/ExhibitorProfileRefundAccordion'
import EventService from '../../services/eventService'
import FirestoreService from '../../services/firestoreService'
import { httpService } from '../../services/httpService'
import PdfService from '../../services/pdfService'
import UserService from '../../services/userService'
import {
  selectBillRecipient,
  selectCountDetails,
  selectUsers,
  setSelectedEvent,
  setSelectedEventFees,
  setSelectedRecipient,
} from '../../store/bills/billsSlice'
import { selectedEvent as selectedEventGetter } from '../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserId, selectUserStripeId, selectUserType } from '../../store/user/userSlice'
import { FEES_CATEGORY_CONST } from '../events/views/details/EventDetailsViewComponentFees'
import ViewsLoader from '../loader/ViewsLoader'
import MainModal from './common/MainModal'
import useGetEventData from '../../hooks/users/common/useGetEventData'
import { EventRegistrationContext } from '../../pages/competitor/event-registration-tabs/EventRegistrationTabs'
import { IUserInterfaceExtended } from '../../store/storeHelpers/userSlice/types'
import { SelectPaymentMethod } from '../payment/SelectPaymentMethod/SelectPaymentMethod'
import { getTotalPrices, ITotalPrices } from '../../helpers/payment'
import { getRegistrationData } from '../../helpers/registration'
import { convertPrice, getFloatPrice } from '../../helpers/price'

// Types
type IStepTab = 'paymentDetails' | 'viewReceipt'
type ICustomRecipient = IRecipientInterface & {
  isrecipient: boolean
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const FILE_NAME = 'BillDetailsModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const defaultTotal = {
  paid: 0,
  unpaid: 0,
  refund: 0,
  scratch: 0,
}

const BillDetailsModal = ({
  show,
  handleModal,
  dataToPassOn,
}: {
  show: boolean
  handleModal: any
  dataToPassOn: {
    bill: ICustomRecipient
  } & Record<any, any>
}) => {
  // Hooks and vars
  const { getAllData } = useGetEventData()

  const saveAllTabs = useContext(EventRegistrationContext)?.saveAllTabs ?? (() => {})
  const { memberName, currentUser } = dataToPassOn
  const [bill, setBill] = useState<ICustomRecipient>(dataToPassOn.bill)

  const { amountScratched } = dataToPassOn.bill
  const showRefund = amountScratched > 0
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsers)
  const toasterFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const router = useIonRouter()
  const history = useHistory()
  const userType = useAppSelector(selectUserType)

  const stripeId = useAppSelector(selectUserStripeId)
  const selectedEvent = useAppSelector(selectedEventGetter)
  const countDetails = useAppSelector(selectCountDetails)
  const selectedRecipient = useAppSelector(selectBillRecipient)

  const [registrationsData, setRegistrationsData] = useState<IRegistrationByDayInterface[]>([])
  const [feesData, setFeesData] = useState<IRegistrationFeesInterface[]>([])
  const [ticketData, setTicketData] = useState<IRegistrationTicketInterface[]>([])

  const [loading, setLoading] = useState(false)
  const [recieptLoading, setRecieptLoading] = useState(false)
  const [stripeRes, setStripeRes] = useState<any>(null)
  const [eventLoading] = useState(false)
  const [eventLogo, setEventLogo] = useState<string>('')
  const [userProfilePicture, setUserProfilePicture] = useState<string>('')
  const [openedMenu, setOpenedMenu] = useState<string[]>([])
  const [eventLocation, setEventLocation] = useState<string>('')
  const [step, setStep] = useState<IStepTab>('paymentDetails')
  const [selectedCard, setSelectedCard] = useState<IUserCards | null>(null)
  const [feeTotalState, setFeeTotalState] = useState<ITotalPrices>(defaultTotal)
  const [governanceTotalState, setGovernanceTotalState] = useState<ITotalPrices>(defaultTotal)
  const [ticketTotalState, setTicketTotalState] = useState<ITotalPrices>(defaultTotal)
  const [registrationTotalState, setRegistrationTotalState] = useState<ITotalPrices>(defaultTotal)
  const [taxTotalState, setTaxTotalState] = useState<number>(0)
  const [currentUnpaidTotal, setCurrentUnpaidTotal] = useState(0)
  const [currentPaidTotal, setCurrentPaidTotal] = useState(0)
  const [modalThemeColor, setModalThemeColor] = useState<`#${string}`>('#FFD96440')
  const [userData, setUserData] = useState<IUserInterfaceExtended | null>(null)
  const [organiserData, setOrganiserData] = useState<IUserInterfaceExtended | null>(null)

  const usersRef = useRef<IUserInterface[]>([])

  let title = 'Receipt'

  useEffect(() => {
    usersRef.current = users
  }, [users])

  useEffect(() => {
    if (bill) {
      if (bill.refundStatus === 'pending') {
        setModalThemeColor('#FFD96440')
      } else {
        setModalThemeColor('#00B6AA26')
      }
      setRecieptLoading(true)
      getEventDetails().then()
    }
  }, [bill])

  useEffect(() => {
    setBill(dataToPassOn.bill)

    if (!!dataToPassOn.bill.eventId) {
      getAllData(dataToPassOn.bill.eventId, ['v01_event_payments_settings']).then()
    }
  }, [dataToPassOn.bill, getAllData])

  useEffect(() => {
    const { Event, EventFees } = selectedEvent

    if (!Event || !EventFees) return

    dispatch(setSelectedEvent(Event))
    dispatch(setSelectedEventFees(EventFees))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent.Event, selectedEvent.EventFees])

  useEffect(() => {
    if (bill.id) {
      dispatch(setSelectedRecipient(bill))
    }
  }, [bill])

  const loadRegistrationData = async (userId: string, registrationId: string, eventId: string) => {
    const { registrationsByDay, registrationsFees, registrationsTickets } =
      await getRegistrationData(userId, registrationId, eventId)

    setRegistrationsData(registrationsByDay)
    setFeesData(registrationsFees)
    setTicketData(registrationsTickets)

    const {
      totalRegistrations,
      totalFees,
      totalGovernance,
      totalTickets,
      totalTax,
      unpaidTotal,
      paidTotal,
    } = getTotalPrices({
      registrationsEntries: registrationsByDay,
      registrationsFees: registrationsFees,
      registrationsTickets: registrationsTickets,
      isChargeSalesTax: selectedEvent?.EventPaymentSettings?.chargeSalesTax ?? false,
    })

    setFeeTotalState(totalFees)
    setGovernanceTotalState(totalGovernance)
    setTicketTotalState(totalTickets)
    setRegistrationTotalState(totalRegistrations)
    setTaxTotalState(Math.floor(totalTax * 100) / 100)
    setCurrentUnpaidTotal(unpaidTotal)
    setCurrentPaidTotal(paidTotal)
  }

  useEffect(() => {
    const bill = dataToPassOn.bill
    if (bill.recipientId && bill.registrationDocId && bill.eventId) {
      loadRegistrationData(bill.recipientId, bill.registrationDocId, bill.eventId).then()
    }
  }, [dataToPassOn.bill, selectedEvent.EventPaymentSettings])

  // Functions
  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.BILL_BRIEFE)
  }

  const onRefundButtonClick = () => {
    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      'bill.eventId': bill.eventId,
      'bill.registrationDocId': bill.registrationDocId,
    })

    if (emptyVarName) {
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return console.error(`${emptyVarName} is ${emptyVarValue}`)
    }

    router.push(
      `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${bill.eventId}/${bill.registrationDocId}`
    )
    history.push(
      `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${bill.eventId}/${bill.registrationDocId}`
    )
    closeModal()
  }

  const handleToggle = (value?: any) => {
    const exist = openedMenu.includes(value)

    if (exist) {
      setOpenedMenu(() => {
        return openedMenu.filter((current: any) => {
          return current !== value
        })
      })
    } else {
      setOpenedMenu((prevState: any) => [...prevState, value])
    }
  }

  const downloadReciept = async () => {
    let openMenu_ = [...cloneDeep(openedMenu)]
    let imageWidth = 0
    let imageHeight = 0

    setRecieptLoading(true)
    setOpenedMenu(['1', '2', '3', '4', '5'])

    await new Promise((resolve) => {
      setTimeout(resolve, 500)
    })

    try {
      let receiptContainer = document.querySelector('#receipt')

      if (!receiptContainer)
        throw CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `receiptContainer is ${receiptContainer}`,
        })

      const base64Code = await domToImage.toPng(receiptContainer, {
        bgcolor: 'white',
      })
      const img = new Image()
      img.src = base64Code

      await new Promise((resolve) => {
        img.onload = function () {
          imageWidth = img.width
          imageHeight = img.height
          resolve(true)
        }
      })

      const pdfService = new PdfService({
        pageWidth: imageWidth + 100,
        pageHeight: imageHeight + 100,
        distanceBetweenLines: 20,
        pageVerticalStartPoint: imageWidth,
        pageHorizontalStartPoint: 45,
        pageHorizontalEndPoint: imageWidth,
        pageVerticalEndPoint: 80,
      })

      await pdfService.embedFont(StandardFonts.TimesRomanBold)
      const pdfPage = await pdfService.addNewPdfPage()
      const png = await pdfService.embedPng(base64Code)

      pdfPage.drawImage(png, {
        x: 50,
        y: 50,
        width: imageWidth,
        height: imageHeight,
      })

      const pdfBytes = await pdfService.generatedPdf
      const blob_ = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })
      const blobUrl = URL.createObjectURL(blob_)
      const downloadLink = document.createElement('a')

      downloadLink.href = blobUrl
      downloadLink.download = `Pegasus reciept-${selectedRecipient?.id}.pdf`
      downloadLink.click()

      document.body.appendChild(img)
      document.body.removeChild(img)
    } catch (error) {
      console.error(error, 'error')
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setOpenedMenu(openMenu_)
      setRecieptLoading(false)
    }
  }

  const getEventDetails = async () => {
    try {
      if (!bill.eventId)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage: `selectedRecipient?.eventId is ${bill?.eventId}`,
        })

      const eventService = new EventService(bill?.eventId)

      const eventLocation = await eventService.getEventLocation

      const eventOwner = await eventService.getPublishedEvent

      const ownerSnapshot = await FirestoreService.getItem(
        COLLECTIONS.USERS.NAME,
        eventOwner?.owner ?? ''
      )
      const owner = UserModel.fromFirestoreDoc(ownerSnapshot).toObject()

      const userSnapshot = await UserService.getUserInfoById(bill.recipientId)
      const user = UserModel.fromFirestoreDoc(userSnapshot).toObject()

      let eventLogo_ = owner?.userProfilePicture
        ? owner.userProfilePicture
        : '/assets/placeholders/Emptyuser.png'

      const userProfilePicture_ = user.userProfilePicture
        ? user.userProfilePicture
        : '/assets/placeholders/Emptyuser.png'

      setEventLogo(eventLogo_)
      setUserProfilePicture(userProfilePicture_)
      setEventLocation(eventLocation.combinedLocation)
      setUserData(user)
      setOrganiserData(owner)
    } catch (error) {
      console.error(error, 'error')
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setRecieptLoading(false)
    }
  }

  const onMarkAsPaid = async () => {
    handleModal(false, MODAL_CONSTS.BILL_BRIEFE)
    handleModal(true, MODAL_CONSTS.ORGANIZER_MARK_PAID, {
      saveAllTabs,
      bill,
      registrationsByDay: registrationsData,
      registrationsFees: feesData,
      registrationsTickets: ticketData,
      selectedEvent,
    })
  }

  const payByCard = async (eventId: string, registrationId: string) => {
    let createChargeRes: {
      paymentIntent?: {
        recipient: IRecipientInterface
        id: string
        amount: number
        receipt_url: string
        transactionDocId: string
      }
    }

    createChargeRes = await httpService({
      url: 'create_payment_intents',
      method: 'POST',
      data: {
        userId,
        amount: currentUnpaidTotal ?? 0,
        pmId: selectedCard?.pmId ?? '',
        customerId: stripeId,
        paymentType: 'pay_registration_bill',
        description: 'Event registration',
        eventId: eventId,
        recipientDocId: bill.id,
        userEmail: userData?.userEmail,
        userName: userData ? getUserFullName(userData) : '',
        userPhone: userData?.userPhoneNumber,
        userStripeAccountId: organiserData?.userStripeAccountId,
      },
    })

    if ('paymentIntent' in createChargeRes) {
      setStripeRes(createChargeRes.paymentIntent)

      const updatedDocRecipient = new RecipientModel({
        ...bill,
        cardNo: selectedCard?.cardNumber,
      }).toFirestore()

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.NAME,
        bill.id,
        updatedDocRecipient
      )

      await httpService({
        url: 'update_recipient',
        method: 'PUT',
        data: {
          registrationDocId: registrationId,
          recipientDocId: bill.recipientId,
          paymentStatus: 'paid',
        },
      })

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        registrationId,
        { paymentStatus: COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.PAID }
      )
    } else {
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG_PAYMENT })
    }
  }

  const onPayAmount = async () => {
    setLoading(true)

    try {
      if (!selectedCard) {
        return toasterFunctions.info({ message: MESSAGES_CONST.PLEASE_SELECT_CARD })
      }

      if (bill.eventId && bill.registrationDocId && userId) {
        await payByCard(bill.eventId, bill.registrationDocId)
        setStep('viewReceipt')
        toasterFunctions.success({ message: MESSAGES_CONST.PAYMENT_DONE })
      } else {
        return toasterFunctions.info({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      }
    } catch (error) {
      console.error(error, 'error')
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  const onViewReceiptClick = () => {
    const registrationId = bill.id

    if (!registrationId) {
      console.error('"registrationId" is wrong')
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return
    }

    setBill({
      ...bill,
    })
    setStep('paymentDetails')
  }

  const openAddNewCardModal = () => {
    handleModal(false, MODAL_CONSTS.BILL_BRIEFE)
    handleModal(true, MODAL_CONSTS.CARD, {
      add_card: true,
      re_open_modal: true,
      modal_name: MODAL_CONSTS.BILL_BRIEFE,
      ...dataToPassOn,
      organizerId: organiserData?.id,
    })
  }

  if (step === 'viewReceipt' && stripeRes) title = 'Success!'

  return (
    <MainModal
      show={show}
      title={title}
      type="BILL_BRIEFE"
      titleClassName="text-base pl-2 pb-2"
      className={`relative${recieptLoading ? ' pointer-events-none' : ''}`}
      setHeightAsPerContent={true}
      size={step === 'viewReceipt' ? 'sm' : '4xl'}
      buttons={[
        ...(step === 'paymentDetails' &&
        currentUnpaidTotal > 0 &&
        userType === CONST.USE_MODE.COMPETITOR
          ? [
              {
                loading,
                label: 'Pay $' + currentUnpaidTotal,
                fullWidth: true,
                disabled: loading,
                onClick: onPayAmount,
                bgClass: '!bg-SeabiscuitMainThemeColor',
                textClass: '!text-SeabiscuitWhiteThemeColor',
              },
              {
                label: 'Close',
                fullWidth: true,
                bgClass: '!bg-SeabiscuitLightThemeColor',
                borderClass: 'border border-transparent',
                textClass: '!text-SeabiscuitLightTextColor',
                onClick: closeModal,
              },
            ]
          : []),
        ...(step === 'viewReceipt' && stripeRes && userType === CONST.USE_MODE.COMPETITOR
          ? [
              {
                label: 'View Receipt',
                fullWidth: true,
                bgClass: 'bg-transparent',
                borderClass: 'border border-SeabiscuitMainThemeColor',
                textClass: 'text-SeabiscuitMainThemeColor',
                onClick: onViewReceiptClick,
              },
              {
                label: 'Close',
                fullWidth: true,
                bgClass: '!bg-SeabiscuitLightThemeColor',
                borderClass: 'border border-transparent',
                textClass: '!text-SeabiscuitLightTextColor',
                onClick: closeModal,
              },
            ]
          : []),
      ]}
    >
      {recieptLoading || eventLoading ? (
        <div className="absolute w-full h-full bg-white z-10 flex justify-center items-center">
          <ViewsLoader className="flex items-center w-full justify-center" size="xl" color="red" />
        </div>
      ) : null}
      {!eventLoading ? (
        <div className="text-SeabiscuitDark200ThemeColor">
          {selectedRecipient?.id && step === 'paymentDetails' ? (
            <div className={clsx('', recieptLoading && 'py-5')} id="receipt">
              {recieptLoading ? (
                <>
                  <div className="relative h-[70px] flex items-center opacity-[.8]">
                    <div className="mb-2 text-lg text-gray-400 absolute top-0">
                      Pegasus App Inc.
                    </div>
                    <img
                      src="/assets/Pegasus_app_logo.png"
                      alt="Pegasus app logo"
                      className="w-[70px] h-[70px] absolute right-0"
                    />
                    <hr className="bg-SeabiscuitMainThemeColor w-full h-[2px]" />
                  </div>

                  <div className="h-[120px]">
                    <h1 className="font-extrabold text-3xl mb-1">Receipt from Pegasus</h1>
                    <div className="text-xl">
                      Receipt ID:{' '}
                      {capitalize(createString(selectedRecipient.id), {
                        fullyCapitalize: true,
                      })}
                      . {moment(selectedRecipient?.created).format('MMMM Do, YYYY')}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="flex flex-col md:flex-row md:justify-between pb-2">
                <div>
                  <div className="flex items-center my-3">
                    <img
                      className="rounded-md mr-4 h-9 w-9 object-cover"
                      src={eventLogo ? eventLogo : IMAGE_CONSTS.PLACEHOLDERS.EVENT}
                      alt="eventImage"
                    />

                    <p className="text-md font-semibold text-SeabiscuitDark200ThemeColor">
                      {' '}
                      <div className="opacity-50 text-sm font-normal">Event</div>
                      {selectedRecipient?.eventName}
                    </p>
                  </div>
                  <div className="flex items-center my-3">
                    <img
                      className="rounded-md mr-4 h-9 w-9 object-cover"
                      src={userProfilePicture}
                      alt="userIcon"
                    />
                    <p className="text-md text-SeabiscuitDark200ThemeColor capitalize gap-1">
                      <div className="opacity-50 text-xs">From </div>{' '}
                      {memberName ?? selectedRecipient?.userName}
                    </p>
                  </div>
                  <div className="flex items-center my-3">
                    <span className="mr-2 flex items-center">
                      <img
                        className="bg-[#fef5f6] rounded-lg p-[0.4rem]"
                        src="/assets/cp_icons/Location-2.svg"
                        alt="locationIcon"
                      />
                    </span>
                    <p className="text-md text-SeabiscuitDark200ThemeColor ml-2 gap-1">
                      <div className="opacity-50 text-xs">
                        {moment(selectedRecipient?.created).format('MMM Do, YYYY')}{' '}
                      </div>{' '}
                      {eventLocation}
                    </p>
                  </div>
                </div>

                <div className="mb-4 flex md:justify-end">
                  <div className="flex-col md:justify-end">
                    {currentUnpaidTotal > 0 && (
                      <p className="text-lg text-SeabiscuitMainThemeColor font-bold rounded-md flex md:justify-end">
                        ${currentUnpaidTotal} owing
                      </p>
                    )}
                    <div className="flex my-1 mb-3 md:justify-end flex-wrap gap-1">
                      <p className="text-SeabiscuitDark200ThemeColor">
                        {getFloatPrice(`${currentPaidTotal}`) ?? 0} paid,
                      </p>
                      <p className="text-SeabiscuitDark200ThemeColor">
                        {getFloatPrice(`${selectedRecipient?.amountScratched || '$0'}`)} scratched,
                      </p>
                      <p className="text-SeabiscuitDark200ThemeColor">
                        {getFloatPrice(`${selectedRecipient?.amountRefunded || '$0'}`)} refunded
                      </p>
                    </div>
                    <div className="-ml-2 md:ml-0 md:-mr-2 flex flex-wrap divide-x divide-solid divide-SeabiscuitDark200ThemeColor/50 leading-none gap-y-2 md:justify-end">
                      {!recieptLoading ? (
                        <button
                          type="button"
                          onClick={downloadReciept}
                          className="text-SeabiscuitDark200ThemeColor opacity-50 hover:opacity-100 underline text-sm px-2"
                        >
                          Download Receipt
                        </button>
                      ) : null}
                      {userType === CONST.USE_MODE.COMPETITOR && currentPaidTotal > 0 ? (
                        <button
                          type="button"
                          onClick={onRefundButtonClick}
                          className="text-SeabiscuitDark200ThemeColor opacity-50 hover:opacity-100 underline text-sm pr-2 px-2"
                        >
                          Request Refund
                        </button>
                      ) : null}
                      {userType === CONST.USE_MODE.ORGANIZER && currentUnpaidTotal > 0 && (
                        <button
                          type="button"
                          onClick={onMarkAsPaid}
                          className="text-SeabiscuitDark200ThemeColor opacity-50 hover:opacity-100 underline text-sm px-2"
                        >
                          Mark As Paid
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-[1px] bg-[#D3DAEE] my-2 mb-6"></div>

              {/* Registration accordion */}
              {
                <div
                  className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('1') ? 'activeAccordion' : ''}`}
                >
                  <div
                    className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('1') ? 'font-semibold' : 'font-normal'}`}
                  >
                    Classes
                    {!recieptLoading ? (
                      <div className="flex flex-col md:flex-row md:items-center">
                        <div
                          className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                          onClick={() => handleToggle('1')}
                        >
                          {openedMenu.includes('1') ? (
                            <div className={'add_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                            </div>
                          ) : (
                            <div className={'remove_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={`faqs-content ${openedMenu.includes('1') ? 'activeTab' : ''}`}>
                    <div className="faqs-content-inside mt-4">
                      <BillDetailsRegistrationData
                        countDetails={countDetails}
                        modalThemeColor={modalThemeColor}
                        currentUser={currentUser}
                        registrationsByDay={registrationsData ?? []}
                        refundregistrationTotalState={registrationTotalState.refund}
                        showRefund={showRefund}
                      />
                    </div>
                  </div>
                </div>
              }

              {/* Fees accordion */}
              <div
                className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('2') ? 'activeAccordion' : ''}`}
              >
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('2') ? 'font-semibold' : 'font-normal'}`}
                >
                  Fees
                  {!recieptLoading ? (
                    <div className="flex items-center">
                      <div
                        className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                        onClick={() => handleToggle('2')}
                      >
                        {openedMenu.includes('2') ? (
                          <div className={'add_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                          </div>
                        ) : (
                          <div className={'remove_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={`faqs-content ${openedMenu.includes('2') ? 'activeTab' : ''}`}>
                  <div className="faqs-content-inside mt-4">
                    <BillDetailsFeesData
                      data={
                        feesData.length
                          ? feesData.filter(
                              (data) => data.feesItemCategory !== FEES_CATEGORY_CONST.GOVERNANCE
                            )
                          : []
                      }
                      modalThemeColor={modalThemeColor}
                      showRefund={showRefund}
                      refundFeeTotalState={feeTotalState.refund}
                    />
                  </div>
                </div>
              </div>
              {/* Mandatory accordion */}
              <div
                className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('5') ? 'activeAccordion' : ''}`}
              >
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('5') ? 'font-semibold' : 'font-normal'}`}
                >
                  Mandatory Fees
                  {!recieptLoading ? (
                    <div className="flex items-center">
                      <div
                        className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                        onClick={() => handleToggle('5')}
                      >
                        {openedMenu.includes('5') ? (
                          <div className={'add_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                          </div>
                        ) : (
                          <div className={'remove_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={`faqs-content ${openedMenu.includes('5') ? 'activeTab' : ''}`}>
                  <div className="faqs-content-inside mt-4">
                    <BillDetailsFeesData
                      modalThemeColor={modalThemeColor}
                      data={
                        feesData.length
                          ? feesData.filter(
                              (data) => data.feesItemCategory === FEES_CATEGORY_CONST.GOVERNANCE
                            )
                          : []
                      }
                      showRefund={showRefund}
                      refundFeeTotalState={governanceTotalState.refund}
                    />
                  </div>
                </div>
              </div>

              {/* Tickets accordion */}
              <div
                className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('3') ? 'activeAccordion' : ''}`}
              >
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('3') ? 'font-semibold' : 'font-normal'}`}
                >
                  Tickets
                  {!recieptLoading ? (
                    <div className="flex items-center">
                      <div
                        className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                        onClick={() => handleToggle('3')}
                      >
                        {openedMenu.includes('3') ? (
                          <div className={'add_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                          </div>
                        ) : (
                          <div className={'remove_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={`faqs-content ${openedMenu.includes('3') ? 'activeTab' : ''}`}>
                  <div className="faqs-content-inside mt-4">
                    <BillDetailsTicketsData
                      data={ticketData}
                      modalThemeColor={modalThemeColor}
                      showRefund={showRefund}
                      refundgovernanceTotalState={ticketTotalState.refund}
                    />
                  </div>
                </div>
              </div>

              {/* Grand Total accordion */}
              {bill.amountScratched < 1 && (
                <div
                  className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('4') ? 'activeAccordion' : ''}`}
                >
                  <div
                    className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('4') ? 'font-semibold' : 'font-normal'}`}
                  >
                    Grand Total
                    {!recieptLoading ? (
                      <div className="flex items-center">
                        <div
                          className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                          onClick={() => handleToggle('4')}
                        >
                          {openedMenu.includes('4') ? (
                            <div className={'add_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                            </div>
                          ) : (
                            <div className={'remove_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={`faqs-content ${openedMenu.includes('4') ? 'activeTab' : ''}`}>
                    <div className="faqs-content-inside mt-4">
                      <BillDetailsGrandTotalData
                        modalThemeColor={modalThemeColor}
                        totalPayments={{
                          tax: taxTotalState || 0,
                          fees: feeTotalState.paid || 0,
                          governance: governanceTotalState.paid ?? 0,
                          tickets: ticketTotalState.paid ?? 0,
                          registrations: registrationTotalState.paid ?? 0,
                        }}
                        grandTotal={currentPaidTotal}
                      />
                    </div>
                  </div>
                </div>
              )}

              {userType === CONST.USE_MODE.COMPETITOR ? (
                <>
                  {currentUnpaidTotal > 0 && (
                    <SelectPaymentMethod
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                      openAddNewCardModal={openAddNewCardModal}
                      userProfile={userData}
                      organiserProfile={organiserData}
                    />
                  )}

                  {currentPaidTotal > 0 && bill.amountScratched < 1 && bill.modified && (
                    <div className="mt-2 border border-[#D3DAEE] py-3.5 rounded-lg p-2 flex items-center mb-2">
                      <img
                        src="/assets/cp_icons/Calendar-1.svg"
                        className="w-6 h-6 mr-4"
                        alt="card"
                      />
                      <span className="text-SeabiscuitDark200ThemeColor">
                        Paid: {moment(bill.modified).format('MMM DD, YYYY')}
                      </span>
                    </div>
                  )}
                  {currentPaidTotal > 0 &&
                    bill.amountScratched < 1 &&
                    bill.amountRefunded === 0 &&
                    bill.cardNo && (
                      <div className="border py-3.5 border-[#D3DAEE] rounded-lg p-2 flex items-center">
                        <img
                          src="/assets/cp_icons/MagneticCard-1.svg"
                          className="w-6 h-6 mr-4"
                          alt="card"
                        />
                        <span className="text-SeabiscuitDark200ThemeColor">
                          XXXX-XXXX-XXXX-
                          {bill.cardNo ?? 'XXXX'}
                        </span>
                      </div>
                    )}

                  {bill.amountScratched > 0 && bill.comments.length ? (
                    <>
                      <div className="mt-2 border-[#D3DAEE] border p-4 rounded-lg ">
                        {bill.amountScratched && (
                          <>
                            <ExhibitorProfileRefundAccordion
                              total={currentPaidTotal}
                              refundTotal={bill.amountRefunded}
                            />
                          </>
                        )}
                      </div>
                      <div className="mt-2">
                        <ExhibitorCommentAccordion
                          hide={false}
                          commenttext={bill.comments.find((data) => data.comment)}
                        />
                      </div>

                      <hr />
                      <div id="container" className="mt-2 border-[#d3daee] border p-4 rounded-lg">
                        <h1 className="text-SeabiscuitDark200ThemeColor font-semibold w-3/4 mt-2 mb-3">
                          Refunded to
                        </h1>
                        <div className="border border-[white] bg-SeabiscuitGrayThemeColor rounded-lg p-2 flex items-center">
                          <img
                            src="/assets/cp_icons/MagneticCard-1.svg"
                            className="w-6 h-6 mr-4"
                            alt="card"
                          />
                          <span className="text-SeabiscuitDark200ThemeColor">
                            XXXX-XXXX-XXXX-
                            {bill.cardNo ?? 'XXXX'}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : null}
          {userType === CONST.USE_MODE.COMPETITOR && step === 'viewReceipt' && stripeRes ? (
            <p className="text-SeabiscuitDark200ThemeColor py-6">Your bill has been paid.</p>
          ) : null}
        </div>
      ) : null}
    </MainModal>
  )
}

export default BillDetailsModal
