import { FC } from 'react'
import clsx from 'clsx'

export enum ESwitchScreen {
  addAccount,
  yourAccounts,
  whoHasAccess,
  confirmRemoveAccount,
}

interface SwitchAccountTabsProps {
  clickHandler: (tab: ESwitchScreen) => void
  activeTab: ESwitchScreen
}

export const SwitchAccountTabs: FC<SwitchAccountTabsProps> = ({ activeTab, clickHandler }) => {
  const getTabClasses = (tab: ESwitchScreen) =>
    clsx(
      'cursor-pointer text-[14px] flex-1 text-center pb-2 border-b hover:text-SeabiscuitRedThemeColor transition-all mb-5',
      tab === activeTab
        ? 'border-SeabiscuitRedThemeColor text-SeabiscuitRedThemeColor'
        : 'border-SeabiscuitLightThemeColorD3 text-black'
    )

  return (
    <div className={'flex'}>
      <div
        role="button"
        onClick={() => clickHandler(ESwitchScreen.yourAccounts)}
        className={getTabClasses(ESwitchScreen.yourAccounts)}
      >
        Your accounts
      </div>
      <div
        role="button"
        onClick={() => clickHandler(ESwitchScreen.whoHasAccess)}
        className={getTabClasses(ESwitchScreen.whoHasAccess)}
      >
        Who has access to you?
      </div>
    </div>
  )
}
