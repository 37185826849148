import clsx from 'clsx'
import { handleImageError } from '../../helpers/handleImageError'

const generateName = (fullName: string | undefined) => {
  return fullName
    ? fullName
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase()
    : ''
}

const customImageComponent = (
  src: string | undefined | null,
  name: string | undefined | null,
  className?: string,
  lazyLoad?: boolean
) => {
  return lazyLoad ? (
    <img
      alt=""
      onError={(e) => handleImageError(e, generateName(name ?? 'N/A'))}
      data-src={
        src
          ? src
          : `https://ui-avatars.com/api/?name=${generateName(name ?? 'N/A')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`
      }
      loading="lazy"
      className={clsx(className, 'object-cover h-ful')}
    />
  ) : (
    <img
      alt=""
      onError={(e) => handleImageError(e, generateName(name ?? 'N/A'))}
      src={
        src
          ? src
          : `https://ui-avatars.com/api/?name=${generateName(name ?? 'N/A')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`
      }
      className={clsx(className, 'object-cover h-full')}
    />
  )
}

export default customImageComponent
