import { Skeleton } from '@mui/material'

const CardsLoader = () => {
  return (
    <div className="p-4 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {[...Array(8)].map((_, index) => {
        return (
          <div
            className="inline-block col-span-1 rounded-lg px-3 h-min-[430px] mb-4 sm:mb-6"
            key={index}
          >
            <div className="flex flex-col flex-wrap items-left justify-start">
              <Skeleton variant="rounded" width="100%" height={270} className="!rounded-3xl mb-5" />
              <Skeleton variant="rounded" width="80%" height={15} className="ml-1 mb-4" />
              <Skeleton variant="rounded" width="50%" height={15} className="ml-1 mb-2" />
              <Skeleton variant="rounded" width="50%" height={15} className="ml-1 mb-2" />
              <Skeleton variant="rounded" width="60%" height={15} className="ml-1 mb-2" />
              <Skeleton variant="rounded" width="60%" height={15} className="ml-1 mb-2" />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CardsLoader
