import React from 'react'

export const IconTeam: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none" viewBox="0 0 20 18">
      <path
        fill="#122B46"
        d="M10 0c-.937 0-1.736.379-2.248.955C7.239 1.532 7 2.271 7 3c0 .73.24 1.468.752 2.045C8.264 5.62 9.062 6 10 6s1.736-.379 2.248-.955C12.761 4.468 13 3.729 13 3c0-.73-.24-1.468-.752-2.045C11.736.38 10.938 0 10 0M3.5 1c-.77 0-1.444.316-1.873.799A2.55 2.55 0 0 0 1 3.5c0 .604.198 1.218.627 1.701C2.057 5.684 2.729 6 3.5 6s1.444-.316 1.873-.799S6 4.104 6 3.5s-.198-1.218-.627-1.701C4.943 1.316 4.271 1 3.5 1m13 0c-.77 0-1.444.316-1.873.799A2.55 2.55 0 0 0 14 3.5c0 .604.198 1.218.627 1.701.43.483 1.102.799 1.873.799s1.444-.316 1.873-.799S19 4.104 19 3.5s-.198-1.218-.627-1.701C17.943 1.316 17.271 1 16.5 1m-6.5.5c.563 0 .89.184 1.127.451S11.5 2.604 11.5 3s-.136.782-.373 1.049c-.238.267-.565.451-1.127.451s-.89-.184-1.127-.451S8.5 3.396 8.5 3s.136-.782.373-1.049C9.111 1.684 9.438 1.5 10 1.5m-6.5 1c.396 0 .598.121.752.295.154.173.248.434.248.705s-.094.532-.248.705c-.154.174-.356.295-.752.295s-.598-.121-.752-.295A1.08 1.08 0 0 1 2.5 3.5c0-.27.094-.532.248-.705.154-.174.356-.295.752-.295m13 0c.396 0 .598.121.752.295.154.173.248.434.248.705s-.094.532-.248.705c-.154.174-.356.295-.752.295s-.598-.121-.752-.295A1.08 1.08 0 0 1 15.5 3.5c0-.27.094-.532.248-.705.154-.174.356-.295.752-.295M1.75 7C.785 7 0 7.785 0 8.75V12a4.005 4.005 0 0 0 5.03 3.865c-.21-.45-.365-.93-.45-1.435q-.279.069-.58.07A2.5 2.5 0 0 1 1.5 12V8.75c0-.14.11-.25.25-.25h2.765c.05-.57.27-1.085.615-1.5zm5.5 0c-.957 0-1.75.793-1.75 1.75v4.75c0 2.476 2.024 4.5 4.5 4.5s4.5-2.024 4.5-4.5V8.75c0-.957-.793-1.75-1.75-1.75zm7.62 0c.345.415.565.93.615 1.5h2.765c.14 0 .25.11.25.25V12a2.5 2.5 0 0 1-3.08 2.43c-.085.505-.24.985-.45 1.435Q15.466 16 16 16c2.205 0 4-1.795 4-4V8.75C20 7.785 19.215 7 18.25 7zM7.25 8.5h5.5c.148 0 .25.102.25.25v4.75c0 1.666-1.334 3-3 3s-3-1.334-3-3V8.75c0-.148.102-.25.25-.25"
      />
    </svg>
  )
}
