import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import React, { FC, useState } from 'react'
import Select from 'react-select'

import InfiniteScrollDataTable from '../../../../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../../../../components/loader/ViewsLoader'

import { IEventFees } from '../../../../../../../models/event-fees/event-fees.interface'
import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationFeesTypeEx } from '../../ManageClinicNOtherOperationsTab'

import { sheets } from '../../../data/sheets'
import { DUMMY_REGISTRATION_FEES_STATS } from '../../data/operations-tab-utils'

const scoreCardOptions = [
  { label: 'Default', value: 'Default' },
  ...sheets.map((sheet) => ({
    label: sheet.name,
    value: sheet.id,
  })),
]

interface JudgingTableProps {
  loading: boolean
  onChangeJudge: (
    row: RegistrationFeesTypeEx,
    values: { label: string; value: string }[]
  ) => Promise<void>
  getRegistrationsByDay: () => Promise<IRegistrationByDayInterface[]>
  updateRegistrationByDayName: (
    feeName: string,
    sheetId: string,
    values?: { value: string; label: string }[]
  ) => Promise<void>
  updateFeesKeys: (keys: object, uuid: string) => void
  registrationFees: IEventFees | null
  judgesOptions: { label: string; value: string }[] | null
}

export const JudgingTable: FC<JudgingTableProps> = ({
  loading,
  onChangeJudge,
  getRegistrationsByDay,
  updateRegistrationByDayName,
  updateFeesKeys,
  registrationFees,
  judgesOptions,
}) => {
  const [currentLoading, currentSetLoading] = useState(false)

  const onChangeScoreCard = async (
    row: RegistrationFeesTypeEx,
    value: { value: string; label: string }
  ) => {
    updateFeesKeys(
      {
        scoreCard: value.value,
        judges: null,
      },
      row.uuid
    )

    await updateRegistrationByDayName(row.name ?? '', value.value)
    await getRegistrationsByDay()
  }

  const columnsJudging = [
    {
      name: (
        <span className="text-gray-400 font-normal text-[0.875rem]">
          <span className="whitespace-nowrap">Class</span>
        </span>
      ),
      cell: (row: RegistrationFeesTypeEx) =>
        row.loading ? (
          <div className="p-2 w-full">
            <Skeleton
              variant="rounded"
              width="98%"
              height={56}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </div>
        ) : (
          <div
            className={clsx(
              'my-2 p-2 min-h-[56px] w-full rounded-md flex items-center border border-SeabiscuitLightThemeColorD3',
              row.judges && row.judges.length > 0 && row.scoreCard && 'bg-SeabiscuitGrayThemeColor'
            )}
          >
            {row.name}
          </div>
        ),
      width: '33.3%',
    },
    {
      name: (
        <span className="text-gray-400 font-normal text-[0.875rem]">
          <span className="whitespace-nowrap">Score cards</span>
        </span>
      ),
      cell: (row: RegistrationFeesTypeEx) =>
        row.loading ? (
          <div className="p-2 w-full">
            <Skeleton
              variant="rounded"
              width="98%"
              height={56}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </div>
        ) : (
          <div className="flex items-center w-full pl-2">
            <Select
              className="w-full"
              classNamePrefix="clinic-select"
              isClearable={false}
              isSearchable={true}
              placeholder="Select score card"
              menuPlacement="auto"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: '#D3DAEE',
                  backgroundColor:
                    row.judges && row.judges.length > 0 && row.scoreCard ? '#F6F7FB' : '#fff',
                }),
              }}
              value={
                row.scoreCard && {
                  value:
                    scoreCardOptions.find((sheet) => sheet.value === row.scoreCard)?.value ?? '',
                  label:
                    scoreCardOptions.find((sheet) => sheet.value === row.scoreCard)?.label ?? '',
                }
              }
              options={scoreCardOptions}
              onChange={async (value) => {
                currentSetLoading(true)
                await onChangeScoreCard(row, value as { value: string; label: string })
                currentSetLoading(false)
              }}
            />
          </div>
        ),
      width: '33.3%',
    },
    {
      name: (
        <span className="text-gray-400 font-normal text-[0.875rem]">
          <span className="whitespace-nowrap">Judges</span>
        </span>
      ),
      cell: (row: RegistrationFeesTypeEx) =>
        row.loading ? (
          <div className="p-2 w-full">
            <Skeleton
              variant="rounded"
              width="100%"
              height={56}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </div>
        ) : (
          <div className="flex items-center w-full pl-2">
            <Select
              className="w-full"
              classNamePrefix="clinic-select"
              isClearable={false}
              isSearchable={false}
              isMulti={true}
              placeholder="Select judges"
              menuPlacement="auto"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: '#D3DAEE',
                  backgroundColor:
                    row.judges && row.judges.length > 0 && row.scoreCard ? '#F6F7FB' : '#fff',
                }),
              }}
              value={
                row.judges && row.judges.length > 0 && judgesOptions && judgesOptions.length > 0
                  ? row.judges.map((judge) => ({
                      value: judge,
                      label:
                        judgesOptions && judgesOptions.length > 0
                          ? judgesOptions.find((sheet) => sheet.value === judge)?.label
                          : '',
                    }))
                  : []
              }
              options={judgesOptions ?? []}
              onChange={async (values) => {
                currentSetLoading(true)
                await onChangeJudge(row, values as { label: string; value: string }[])
                currentSetLoading(false)
              }}
            />
          </div>
        ),
      width: '33.3%',
    },
  ]

  return (
    <div className="mt-8">
      {currentLoading && (
        <div className="w-full bg-white/30 h-full fixed z-30 left-0 top-0 flex justify-center items-center">
          <ViewsLoader size="lg" color="#F70763" />
        </div>
      )}
      <InfiniteScrollDataTable
        hasMore={false}
        className="exhibitorListTable"
        columns={columnsJudging}
        data={
          loading ? DUMMY_REGISTRATION_FEES_STATS : [...(registrationFees?.registrationFees ?? [])]
        }
      />
    </div>
  )
}
