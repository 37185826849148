import React, { CSSProperties, FC } from 'react'

import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { flexRender, Row } from '@tanstack/react-table'

import { IBreakRegistrationByDay } from '../../../../../../../../models/events/event.interface'
import { MANAGE_OPERATIONS_CONSTS } from '../../../../data/operations.data.const'

interface DraggableRowProps {
  tableRow: Row<IBreakRegistrationByDay>
  row: IBreakRegistrationByDay
  isLastChild: boolean
  rowIndex: number
  registrationsToDisplay: IBreakRegistrationByDay[]
  handleScratch: (row: IBreakRegistrationByDay, isScratched: boolean) => Promise<void>
  type: 'rider' | 'class'
}

export const DraggableRow: FC<DraggableRowProps> = ({
  tableRow,
  row,
  isLastChild,
  rowIndex,
  registrationsToDisplay,
  handleScratch,
  type,
}) => {
  const { transform, transition, setNodeRef, attributes, listeners, isDragging } = useSortable({
    id: row.id,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? registrationsToDisplay.length : registrationsToDisplay.length - rowIndex,
    position: 'relative',
    borderBottom: !isLastChild ? '1px solid rgb(233, 236, 239)' : 'none',
    minHeight: '72px',
    height: '70px',
    overflow: 'auto',
  }

  return (
    <tr ref={setNodeRef} style={style}>
      {tableRow.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          style={{
            width: MANAGE_OPERATIONS_CONSTS.COLUMNS_WIDTH[cell.id]?.[type],
            paddingTop: '8px',
            paddingBottom: '8px',
            verticalAlign: 'middle',
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
      <td
        style={{
          width: MANAGE_OPERATIONS_CONSTS.COLUMNS_WIDTH['drag']?.[type],
          paddingTop: '8px',
          paddingBottom: '8px',
          verticalAlign: 'middle',
        }}
      >
        <div className="flex justify-end gap-2.5">
          {row.orderOfGoScratched ? (
            <button
              className="ml-auto flex border border-solid border-SeabiscuitGray500ThemeColor hover:bg-SeabiscuitGrayThemeColor transition-all rounded-lg p-2"
              onClick={async () => await handleScratch(row, false)}
            >
              <img src="/assets/cp_icons/undo.svg" alt="moveIcon" className="w-5" />
            </button>
          ) : (
            <>
              <button
                className="flex border border-solid border-SeabiscuitGray500ThemeColor hover:bg-SeabiscuitGrayThemeColor transition-all rounded-lg p-2"
                onClick={async () => await handleScratch(row, true)}
              >
                <img
                  src="/assets/cp_icons/delete.svg"
                  alt="moveIcon"
                  className="w-5 min-w-[20px]"
                />
              </button>
              <div className="justify-center flex" {...attributes} {...listeners}>
                <button className="border border-solid border-SeabiscuitGray500ThemeColor hover:bg-SeabiscuitGrayThemeColor transition-all rounded-lg gap-2 p-2">
                  <img
                    src="/assets/cp_icons/Move.svg"
                    alt="moveIcon"
                    className="w-5 min-w-[20px]"
                  />
                </button>
              </div>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}
