export type IPaymentStatus = 'paid' | 'refunded' | 'scratched' | null
export type IPaymentType = 'cash' | 'check' | 'card' | null

export const PAYMENT_STATUS_VALUES = {
  PAID: 'paid',
  SCRATCHED: 'scratched',
  REFUNDED: 'refunded',
  DEFAULT: null,
}
export const PAYMENT_STATUS = {
  PAID: 'paid' as IPaymentStatus,
  SCRATCHED: 'scratched' as IPaymentStatus,
  REFUNDED: 'refunded' as IPaymentStatus,
  DEFAULT: null as IPaymentStatus,
  // we need it to support old events
  PENDING: 'pending' as IPaymentStatus,
}

export const PAYMENT_TYPE = {
  CASH: 'cash' as IPaymentType,
  CHECK: 'check' as IPaymentType,
  CARD: 'card' as IPaymentType,
  DEFAULT: null as IPaymentType,
}
