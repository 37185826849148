// Component imports
import EventClinicConfirmationTab from './EventClinicConfirmationTab'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

type Props = {
  step?: number
  title?: string
  description?: string
  activeTab: {
    tab: string
    step: number
  }
  setEventTab: any
  manageInfo: IManageInfo
  isManage?: boolean
}

const ClinicNOtherEventRegisterConfirmationTab = (props: Props) => {
  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <EventClinicConfirmationTab
        activeTab={props.activeTab}
        setEventTab={props.setEventTab}
        isManage={props.isManage}
        manageInfo={props.manageInfo}
      />
    </CompetitorEventRegisterWrapper>
  )
}

export default ClinicNOtherEventRegisterConfirmationTab
