import TimeLib from '../../../../lib/Time'
import { ISignedStatuses } from '../../../../models/rider-team-member/riderTeamMember.interface'

export const signedStatuses: { [key: string]: ISignedStatuses } = {
  SIGNED: 'Signed',
  NOT_SIGNED: 'Not Signed',
  REMOVED: 'Removed',
  DEFAULT: 'Not sent',
  NOT_SENT: 'Not sent',
  MAIL_SENT: 'Mail sent',
}

export const DATA_FIRESTORE_V01_RIDER_TEAM_MEMBER = {
  NAME: 'v01_rider_team_member',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },
    UUID: {
      KEY: 'uuid',
      VALUES: {
        DEFAULT: null,
      },
    },
    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_BY_DAY_DOC_ID: {
      KEY: 'registrationByDayDocId',
      VALUES: {
        DEFAULT: null,
      },
    },
    REGISTRATION_BY_DAY_UNIQ_ID: {
      KEY: 'registrationByDayUniqId',
      VALUES: {
        DEFAULT: '',
      },
    },
    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    SIGNED_STATUS: {
      KEY: 'signedStatus',
      VALUES: signedStatuses,
    },

    TEAM_MEMBER_ID: {
      KEY: 'teamMemberId',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_NAME: {
      KEY: 'teamMemberName',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_EMAIL: {
      KEY: 'teamMemberEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_COUNTRY: {
      KEY: 'teamMemberCountry',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_DOB: {
      KEY: 'teamMemberDob',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_ROLE: {
      KEY: 'teamMemberRole',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_ID: {
      KEY: 'riderId',
      VALUES: {
        DEFAULT: null,
      },
    },

    MAIL_LOG: {
      KEY: 'mailLog',
      VALUES: {
        DEFAULT: [],
      },
    },

    RIDER_NAME_N_GRAM: {
      KEY: 'riderNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    RIDER_DOB: {
      KEY: 'riderDob',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_NAME: {
      KEY: 'riderName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_EMAIL: {
      KEY: 'riderEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_PROFILE_PICTURE: {
      KEY: 'riderProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_PROFILE_PICTURE: {
      KEY: 'teamMemberProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
