// Components
import SingleNotificationRow from './SingleNotificationRow'

// Types

// Contexts

// Constants

import NoDataFoundSkeleton from '../../components/common/banners/NoDataFoundSkeleton'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IProps = {
  type: 'All' | 'Unread' | 'Settings'
  data: any
  onToggle: (key: string, on: boolean) => void
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 * @TODO todo
 */
const PaperworkListing = (props: IProps) => {
  // Hooks and vars

  // Functions

  return (
    <>
      {props?.data?.length ? (
        props.data.map((currDoc: object, currDocIndex: number) => {
          return (
            <SingleNotificationRow
              key={`${JSON.stringify(currDoc)}${currDocIndex}`}
              currDoc={currDoc}
              type={props?.type}
              onToggle={props?.onToggle}
            />
          )
        })
      ) : (
        <NoDataFoundSkeleton
          message={
            props?.type === 'All' ? 'You have no notifications' : 'You have no unread notifications'
          }
        />
      )}
    </>
  )
}

export default PaperworkListing
