import React from 'react'
import { generateName } from './helpers'

const getDefaultAvatarUrl = (name: string) =>
  `https://ui-avatars.com/api/?name=${generateName(name)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`

export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>, name: string) => {
  const target = e.target as HTMLImageElement

  if (!target) return

  target.style.border = '1px solid rgba(0, 0, 0, 0.1)'
  target.src = getDefaultAvatarUrl(name)
}
