import { InfoOutlined as TooltipIcon } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import React from 'react'

interface TooltipTextToggleProps {
  on: boolean
  onSwitch: () => void
  label?: string
  className?: string
  tooltip?: string
}

const TooltipTextToggle: React.FC<TooltipTextToggleProps> = ({
  on,
  onSwitch,
  label,
  className,
  tooltip,
}) => {
  return (
    <div className={clsx('flex items-center gap-2', className)}>
      {label && <span className="text-[14px]">{label}</span>}

      <Tooltip
        title={tooltip ? <h6 className="tooltip_title">{tooltip}</h6> : ''}
        placement="top"
        arrow
      >
        <TooltipIcon
          style={{ width: '16px', height: '16px' }}
          className="text-gray-500 hover:text-gray-700 cursor-pointer"
        />
      </Tooltip>

      <label className="inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={on} onChange={onSwitch} className="sr-only peer" />
        <div
          className={clsx(
            'relative w-11 h-6 rounded-full transition-all',
            on ? 'bg-SeabiscuitGreenThemeColor' : 'bg-gray-200'
          )}
        >
          <div
            className={clsx(
              'absolute top-0.5 left-[2px] h-5 w-5 rounded-full bg-white border border-gray-300 transition-all',
              on ? 'translate-x-full peer-checked:border-white' : 'dark:border-gray-600'
            )}
          />
        </div>
      </label>
    </div>
  )
}

export default TooltipTextToggle
