import { useEffect, useRef, useState } from 'react'

// Third party
import { motion } from 'framer-motion'
import { Link, useHistory } from 'react-router-dom'
import { KeyboardBackspace } from '@mui/icons-material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { useParams } from 'react-router'
import { useIonRouter } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { collection, documentId, query, where } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'

// Redux
import { selectedEvent, setSelectedEvent } from '../../../store/events/eventsSlice'

// Components
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import EventRegistrationTabs from '../event-registration-tabs/EventRegistrationTabs'

// Helpers
import { getConvertedData, getPublishedDetailsOfEvent } from '../../../models/interface.helper'
import helpers from '../../../commonHelpers/helpers'
import { CustomError } from '../../../helpers/helpers'

// Services
import FirebaseApp from '../../../services/firebaseApp'
import FirestoreService from '../../../services/firestoreService'

// Models
import { EventReviewPublishModel } from '../../../models/event-review-publish/event-review-publish.model'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'

// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import ITypes from '../event-registration-tabs/hooks/useEventRegistrationTabs.types'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string) => void
  isManage?: boolean
}

const maxTagsToShow = 2
const CompetitorEventRegisterRootPage = (props: Props) => {
  const { eventId, userId } = useParams<ITypes['IParams']>()

  const dispatch = useDispatch()
  const router = useIonRouter()
  const history = useHistory()

  const event = useSelector(selectedEvent)
  const eventTitleNodeRef = useRef<HTMLSpanElement>(null)
  const [pageLoading, setPageLoading] = useState(true)

  const { EventDetails } = event
  const eventTags =
    (EventDetails?.tags && EventDetails?.tags?.length > maxTagsToShow
      ? [...EventDetails?.tags]?.splice(0, 3)
      : EventDetails?.tags) ?? []

  let elemWidth = `md:max-w-[calc(100vw-${(eventTitleNodeRef?.current?.clientWidth ?? 0) + 10 + 200}px)]`
  // let elemWidth = `md:max-w-[calc(100vw-${(eventTitleNodeRef?.current?.clientWidth ?? 0) + 10 + 200}px)] max-w-[calc(100vw-120px)]`

  const EventFirebaseQueries: any[] = (function (): any {
    let queries: any = []
    if (eventId) {
      queries.push(where(documentId(), '==', eventId))
    }
    return queries
  })()

  const [publishedEventSnapshot, loading] = useCollection(
    query(
      collection(
        FirebaseApp.firestore,
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME
      ),
      ...EventFirebaseQueries
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  const getRegistered = async () => {
    let registeredUsers: TEventRegisteredUsers[] = []

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      registeredUsers.push(
        getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    return { registeredUsers }
  }

  useEffect(() => {
    if (event) {
      getRegistered().then(({ registeredUsers }) => {
        if (registeredUsers[0]?.isRegistered) {
          router.push(`${CONST.ROUTES.MANAGE_REGISTER_EVENT.URL}/${eventId}/${userId}`)
          history.push(`${CONST.ROUTES.MANAGE_REGISTER_EVENT.URL}/${eventId}/${userId}`)
        } else {
          router.push(`${CONST.ROUTES.REGISTER_EVENT.URL}/${eventId}/${userId}`)
          history.push(`${CONST.ROUTES.REGISTER_EVENT.URL}/${eventId}/${userId}`)
        }
      })
    }
  }, [event, eventId, userId])

  useEffect(() => {
    const getEvents = function () {
      if (!publishedEventSnapshot?.size && !loading)
        return helpers.logger({
          message: CustomError.somethingWentWrong({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
            devMessage: `published event doc not found with id: ${eventId}`,
            fileName: 'CompetitorEventRegisterRootPage',
            moduleName: 'getEvents',
          }),
        })

      const publishedEvent = EventReviewPublishModel.fromFirestoreDoc(
        publishedEventSnapshot?.docs[0]
      ).toObject()
      let eventDetails = publishedEvent?.EventDetails ?? null

      if (!eventDetails)
        return helpers.logger({
          message: CustomError.somethingWentWrong({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
            devMessage: `eventDetails is ${eventDetails}`,
            fileName: 'CompetitorEventRegisterRootPage',
            moduleName: 'getEvents',
          }),
        })
    }

    if (!loading) getEvents()
  }, [event, loading])

  // Functions
  const getDetailSelectedEvent = async () => {
    const publishedDetails = await getPublishedDetailsOfEvent(eventId)
    if (publishedDetails) dispatch(setSelectedEvent(publishedDetails))
    setPageLoading(false)
  }

  useEffect(() => {
    if (eventId) getDetailSelectedEvent()
  }, [eventId])

  const getCustomHeader = (): ReactJSXElement => {
    return (
      <div className={clsx('event_comp', { 'opacity-0': pageLoading })}>
        <div className="flex flex-wrap items-center gap-2 w-full">
          <div className="flex items-center gap-2">
            <Link to={`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`} className={'flex'}>
              <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
            </Link>
            <span ref={eventTitleNodeRef} className="mr-0 lg:mr-4 xl:text-[22px] 2xl:text-2xl">
              {props.isManage ? 'add to ' : 'Register for '}
              <h1 className="font-bold inline-block capitalize">
                {event?.EventDetails?.competitionName ? event?.EventDetails?.competitionName : ''}
              </h1>
            </span>
          </div>
          <div
            className={clsx(
              'lg:overflow-x-auto flex flex-wrap lg:flex-nowrap items-start pt-1 pb-2 ',
              elemWidth
            )}
          >
            <div className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-1  lg:mr-4 inline-block mt-1 text-nr md:text-base">
              {EventDetails?.category}
            </div>
            {eventTags && eventTags.length
              ? eventTags.map((currentItem: string, index: number) => {
                  return (
                    <div
                      key={`${currentItem}${index}`}
                      className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-1 lg:mr-4 inline-block whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis mt-1 min-w-fit md:min-w-[unset] text-nr md:text-base"
                    >
                      {currentItem}
                    </div>
                  )
                })
              : null}
            {eventTags && eventTags?.length < (EventDetails?.tags ?? [])?.length ? (
              <div className="h-fit">...</div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <WrapperContainer
      noBg={true}
      removeShadow={true}
      customHeader={getCustomHeader()}
      removePadding={true}
    >
      <motion.div
        className="w-full mx-auto flex flex-col flex-1"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.1,
        }}
      >
        <EventRegistrationTabs handleModal={props?.handleModal} isManage={props.isManage} />
      </motion.div>
    </WrapperContainer>
  )
}

export default CompetitorEventRegisterRootPage
