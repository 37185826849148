import { TEventPaperwork } from '../models/event-review-publish/event-review-publish.interface'
import fakeDocumentList from '../fakeData/fakeDocumentList'
import helpers from '../commonHelpers/helpers'
import { CustomError } from './helpers'

import { MESSAGES_CONST } from '../const/messages-const'

const getFilteredPaperwork = (EventPaperwork: TEventPaperwork | null) => {
  let fakePaperworkFoundOnIndex = -1
  let fakePaperworkDocumentsList_ = fakeDocumentList ?? []
  let filteredPaperworkDocuments: typeof fakeDocumentList = []

  try {
    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      EventPaperwork,
    })

    if (emptyVarName)
      throw CustomError.somethingWentWrong({
        fileName: 'documents.tsx',
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        moduleName: 'getFilteredPaperworks',
        devMessage: `${emptyVarName} is [${emptyVarValue}]`,
      })

    Object.entries(EventPaperwork ?? {}).forEach(([key, value]) => {
      fakePaperworkFoundOnIndex = -1

      if (value)
        fakePaperworkFoundOnIndex = fakePaperworkDocumentsList_.findIndex((currFakeDoc) => {
          return currFakeDoc.key === key
        })

      if (fakePaperworkFoundOnIndex !== -1)
        filteredPaperworkDocuments.push(fakePaperworkDocumentsList_[fakePaperworkFoundOnIndex])
    })
    return filteredPaperworkDocuments
  } catch (error) {
    helpers.logger({
      message: error,
    })
    return []
  }
}

export { getFilteredPaperwork }
