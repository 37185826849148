import { httpService } from '../services/httpService'

import { IPaymentMethod } from '../store/storeHelpers/userSlice/types'

import { IUserInterface } from '../models/users/user.interface'
import { IRegistrationFeesInterface } from '../models/registration-fees/registrationFees.interface'
import { FEES_CATEGORY_CONST } from '../components/events/views/details/EventDetailsViewComponentFees'
import { ModelBaseModel } from '../models/model-base/model-base.model'
import { PAYMENT_STATUS } from '../types/payment'
import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationTicketInterface } from '../models/registration-tickets/registrationTicket.interface'

export const setPaymentMethods = async (
  userProfile: IUserInterface,
  organiserProfile?: IUserInterface
) => {
  let data
  let payments = []
  try {
    if (organiserProfile?.userStripeAccountId && userProfile?.userStripeId) {
      const response = await httpService({
        url: `get_payment_methods/${userProfile?.userEmail}/${organiserProfile?.userStripeAccountId}`,
      })
      data = response.data
    } else if (userProfile?.userStripeId) {
    }

    if (data && data.length > 0) {
      payments = data?.map((payment: IPaymentMethod) => ({
        cardNumber: payment.card.last4,
        cardType: payment.card.brand,
        pmId: payment.id,
        expiryMonth: payment.card.exp_month,
        expiryYear: payment.card.exp_year,
      }))
    }
    return payments
  } catch (error) {
    console.log(error, 'error')
    return []
  }
}

export interface ITotalPrices {
  paid: number
  unpaid: number
  refund: number
  scratch: number
}

const feesTotal = (fees: IRegistrationFeesInterface[]) => {
  let paid = 0
  let unpaid = 0
  let refund = 0
  let scratch = 0

  fees.forEach((fee) => {
    if (fee.feesItemCategory !== FEES_CATEGORY_CONST.GOVERNANCE) {
      if (fee.paymentStatus === PAYMENT_STATUS.PAID) {
        paid += fee.feesPrice * fee.selectedUnitsCount
      }
      if (fee.paymentStatus === PAYMENT_STATUS.DEFAULT) {
        unpaid += fee.feesPrice * fee.selectedUnitsCount
      }
      if (fee.paymentStatus === PAYMENT_STATUS.REFUNDED) {
        refund += fee.amountRefunded
      }
      if (fee.paymentStatus === PAYMENT_STATUS.SCRATCHED) {
        scratch += fee.feesPrice * fee.selectedUnitsCount
      }
    }
  })

  return {
    paid: new ModelBaseModel().getNum(paid),
    unpaid: new ModelBaseModel().getNum(unpaid),
    refund: new ModelBaseModel().getNum(refund),
    scratch: new ModelBaseModel().getNum(scratch),
  }
}

const governanceTotal = (fees: IRegistrationFeesInterface[]) => {
  let paid = 0
  let unpaid = 0
  let refund = 0
  let scratch = 0

  fees.forEach((fee) => {
    if (fee.feesItemCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
      if (fee.paymentStatus === PAYMENT_STATUS.PAID) {
        paid += fee.feesPrice * fee.selectedUnitsCount
      }
      if (fee.paymentStatus === PAYMENT_STATUS.DEFAULT) {
        unpaid += fee.feesPrice * fee.selectedUnitsCount
      }
      if (fee.paymentStatus === PAYMENT_STATUS.REFUNDED) {
        refund += fee.amountRefunded
      }
      if (fee.paymentStatus === PAYMENT_STATUS.SCRATCHED) {
        scratch += fee.feesPrice * fee.selectedUnitsCount
      }
    }
  })

  return {
    paid: new ModelBaseModel().getNum(paid),
    unpaid: new ModelBaseModel().getNum(unpaid),
    refund: new ModelBaseModel().getNum(refund),
    scratch: new ModelBaseModel().getNum(scratch),
  }
}

const ticketsTotal = (ticketsData: IRegistrationTicketInterface[]) => {
  let paid = 0
  let unpaid = 0
  let refund = 0
  let scratch = 0

  ticketsData.forEach((ticket) => {
    if (ticket.paymentStatus === PAYMENT_STATUS.PAID) {
      paid += ticket.ticketPrice * ticket.selectedUnitsCount
    }
    if (ticket.paymentStatus === PAYMENT_STATUS.DEFAULT) {
      unpaid += ticket.ticketPrice * ticket.selectedUnitsCount
    }
    if (ticket.paymentStatus === PAYMENT_STATUS.REFUNDED) {
      refund += ticket.amountRefunded
    }
    if (ticket.paymentStatus === PAYMENT_STATUS.SCRATCHED) {
      scratch += ticket.ticketPrice * ticket.selectedUnitsCount
    }
  })

  return {
    paid: new ModelBaseModel().getNum(paid),
    unpaid: new ModelBaseModel().getNum(unpaid),
    refund: new ModelBaseModel().getNum(refund),
    scratch: new ModelBaseModel().getNum(scratch),
  }
}

const registrationsTotal = (registrationsData: IRegistrationByDayInterface[]) => {
  let paid = 0
  let unpaid = 0
  let refund = 0
  let scratch = 0
  let qualifyFeePaid = 0
  let qualifyFeeUnpaid = 0
  let qualifyFeeRefund = 0
  let qualifyFeeScratch = 0

  registrationsData.forEach((registration) => {
    if (registration.paymentStatus === PAYMENT_STATUS.PAID) {
      if (registration.registrationPrice) paid += registration.registrationPrice
      if (registration.isQualify) qualifyFeePaid += Number(registration?.qualifyFee) ?? 0
    }
    if (registration.paymentStatus === PAYMENT_STATUS.DEFAULT) {
      if (registration.registrationPrice) unpaid += registration.registrationPrice
      if (registration.isQualify) qualifyFeeUnpaid += Number(registration?.qualifyFee) ?? 0
    }
    if (registration.paymentStatus === PAYMENT_STATUS.REFUNDED) {
      if (registration.registrationPrice) refund += registration.registrationPrice
      if (registration.isQualify) qualifyFeeRefund += Number(registration?.qualifyFee) ?? 0
    }

    if (registration.paymentStatus === PAYMENT_STATUS.SCRATCHED) {
      if (registration.registrationPrice) scratch += registration.registrationPrice
      if (registration.isQualify) qualifyFeeScratch += Number(registration?.qualifyFee) ?? 0
    }
  })

  return {
    paid: new ModelBaseModel().getNum(paid + qualifyFeePaid),
    unpaid: new ModelBaseModel().getNum(unpaid + qualifyFeeUnpaid),
    refund: new ModelBaseModel().getNum(refund + qualifyFeeRefund),
    scratch: new ModelBaseModel().getNum(scratch + qualifyFeeScratch),
  }
}

const salesTotal = (
  registrationTotal: number,
  ticketTotal: number,
  feesTotal: number,
  totalGovernance: number,
  isChargeSalesTax: boolean
) => {
  let salesTotal = registrationTotal + ticketTotal + feesTotal + totalGovernance
  salesTotal = salesTotal * (isChargeSalesTax ? 0.02 : 0)
  return salesTotal
}

type IGetUnpaidTotal = {
  registrationsEntries?: IRegistrationByDayInterface[]
  registrationsFees?: IRegistrationFeesInterface[]
  registrationsTickets?: IRegistrationTicketInterface[]
  isChargeSalesTax: boolean
}
export const getTotalPrices = ({
  registrationsEntries,
  registrationsFees,
  registrationsTickets,
  isChargeSalesTax,
}: IGetUnpaidTotal) => {
  let totalRegistrations = registrationsTotal(registrationsEntries ?? [])
  let totalFees = feesTotal(registrationsFees ?? [])
  let totalGovernance = governanceTotal(registrationsFees ?? [])
  let totalTickets = ticketsTotal(registrationsTickets ?? [])

  let totalTax = salesTotal(
    totalRegistrations.paid,
    totalTickets.paid,
    totalFees.paid,
    totalGovernance.paid,
    isChargeSalesTax
  )

  let unpaidTotalTax = salesTotal(
    totalRegistrations.unpaid,
    totalTickets.unpaid,
    totalFees.unpaid,
    totalGovernance.unpaid,
    isChargeSalesTax
  )

  const unpaidTotal = parseFloat(
    (
      totalRegistrations.unpaid +
      totalFees.unpaid +
      totalGovernance.unpaid +
      totalTickets.unpaid +
      unpaidTotalTax
    ).toFixed(2)
  )

  const refundTotal = parseFloat(
    (
      totalRegistrations.refund +
      totalFees.refund +
      totalGovernance.refund +
      totalTickets.refund
    ).toFixed(2)
  )
  const scratchTotal = parseFloat(
    (
      totalRegistrations.scratch +
      totalFees.scratch +
      totalGovernance.scratch +
      totalTickets.scratch
    ).toFixed(2)
  )

  const paidTotal = parseFloat(
    (
      totalRegistrations.paid +
      totalFees.paid +
      totalGovernance.paid +
      totalTickets.paid +
      totalTax
    ).toFixed(2)
  )

  return {
    totalRegistrations,
    totalFees,
    totalGovernance,
    totalTickets,
    totalTax,
    unpaidTotal,
    paidTotal,
    refundTotal,
    scratchTotal,
  }
}
