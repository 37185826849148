import { useEffect, useState } from 'react'
import { PillButtonElement } from '../../../../components/elements/buttons/pill/PillButtonElement'
import { IEventInterface } from '../../../../models/events/event.interface'
import { EventCardList } from '../../../../components/events/cards/post-horizontal-card/EventCardList'

const TabData = [
  { id: 1, title: 'New events' },
  { id: 2, title: 'Past events' },
]

type IProps = {
  pastEvents: IEventInterface[]
  newEvents: IEventInterface[]
}

const HorseCompetetionDetailsCard = ({ pastEvents, newEvents }: IProps) => {
  const [activeTab, setActiveTab] = useState(1)
  const [events, setEvents] = useState<IEventInterface[]>([])

  useEffect(() => {
    setEvents(activeTab === 1 ? newEvents : pastEvents)
  }, [activeTab])

  return (
    <>
      <div className="flex-1 flex flex-col bg-SeabiscuitWhiteThemeColor pt-6 pb-2 px-6">
        <div className="max-w-full overflow-y-auto mb-4 flex w-fit pb-3">
          {TabData.map((item, index) => {
            return (
              <PillButtonElement
                key={`${JSON.stringify(item)}${index}`}
                text={item.title}
                className="h-[45px] w-[200px] border-none text-SeabiscuitDark200ThemeColor"
                onButtonClicked={() => setActiveTab(item.id)}
                value={item.id}
                selected={activeTab === item.id}
              />
            )
          })}
        </div>
        <EventCardList events={events} />
      </div>
    </>
  )
}

export default HorseCompetetionDetailsCard
