// Third party
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { useState } from 'react'
import { sumBy } from 'lodash'

// Components

// Types
import Accordion from '../../../components/accordion/common/Accordion'
import {
  selectExhibitorRefundTotals,
  selectRecipientR,
} from '../../../store/exhibitor/exhibitorSlice'
import { useAppSelector } from '../../../store/hooks'
import ExhibitorProfileRefundAccordion from './accordions/ExhibitorProfileRefundAccordion'
import { toFixed } from '../../../helpers/helpers'
import { getFloatPrice } from '../../../helpers/price'

const ExhibitorProfileRefundTab = () => {
  // Hooks and vars
  const refundTotals = useAppSelector(selectExhibitorRefundTotals)
  const recipientR = useAppSelector(selectRecipientR)

  const refundTotal =
    sumBy(refundTotals.eventsRefundPriceTotal, 'amountRefunded') +
    sumBy(refundTotals.feesRefundPriceTotal, 'amountRefunded') +
    sumBy(refundTotals.ticketsRefundPriceTotal, 'amountRefunded')

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion
      icon={<AddCircleOutline className="add_icon text-[red]" />}
      initialState={true}
      className="mb-2"
      header={
        <div
          className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
        >
          Refund total
          <div className="flex items-center details">
            <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
              {`Refund total: ${getFloatPrice(`${refundTotal}`)}`}
            </div>
          </div>
        </div>
      }
      headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
    >
      <div className="faqs-content-inside mt-1">
        <ExhibitorProfileRefundAccordion
          total={recipientR.data?.amountPaid ?? 0}
          refundTotal={refundTotal}
        />
      </div>
    </Accordion>
  )
}

export default ExhibitorProfileRefundTab
