import { useEffect, useState } from 'react'

// Libs
import { sumBy } from 'lodash'

// Components
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

import { useAppSelector } from '../../../../../../store/hooks'
import {
  selectFeesTabDataR,
  selectRegistrationFeesR,
} from '../../../../../../store/registration/registrationSlice'

import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'

import { CONST } from '../../../../../../const/const'
import { FEES_CATEGORY_CONST } from '../../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { IFeesTab } from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { getFloatPrice } from '../../../../../../helpers/price'

type SelectedEventFeeListProps = {
  setEventTab?: any
  manageInfo: IManageInfo
  isManage?: boolean
}

const SelectedEventFeeList = (props: SelectedEventFeeListProps) => {
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registrationFees = useAppSelector(selectRegistrationFeesR)

  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
  }>({ totalItems: 0, totalPrice: 0 })
  const [feesItems, setFeesItems] = useState<IFeesTab[]>([])

  let heading = [
    { title: 'Line item', tooltipText: 'Fee line items you added during registration' },
    { title: 'Number of units', tooltipText: 'Number of units per line item' },
    { title: 'Price per unit', tooltipText: 'Price per individual line item' },
  ]

  useEffect(() => {
    let feesItems_: IFeesTab[] = []
    const fees = props.isManage ? props.manageInfo.fees : feesTabData

    fees.forEach((currFeesTabRow) => {
      if (currFeesTabRow.feesCategory !== FEES_CATEGORY_CONST.GOVERNANCE)
        feesItems_.push(currFeesTabRow)
    })

    setFeesItems(feesItems_)
  }, [feesTabData, props.isManage, props.manageInfo.fees])

  useEffect(() => {
    const totalItems = parseFloat(
      sumBy(feesItems, (fee) => fee.registrationFees?.selectedUnitsCount ?? 0).toFixed(2)
    )

    const totalPrice = parseFloat(
      sumBy(
        feesItems,
        (fee) =>
          (fee.registrationFees?.selectedUnitsCount ?? 0) * (fee.registrationFees?.feesPrice ?? 0)
      ).toFixed(2)
    )
    setSelectedUnits({
      totalItems,
      totalPrice,
    })
  }, [feesItems])

  return (
    <ConfirmationCompsWrapper
      title="Fees"
      setEventTab={props.setEventTab}
      redirectTab={CONST.UI.REGISTER.TABS.FEES}
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Fees' : 'Fee'}, ${getFloatPrice(`${selectedUnits.totalPrice}`)}`}
      accordion={{ open, setOpen }}
      showList={!!registrationFees.length}
      colsClassName={{
        0: '!w-1/3 flex items-center',
        1: '!w-1/3 flex items-center',
        2: '!w-1/3 flex items-center',
      }}
      cols={heading}
    >
      <>
        {[...feesTabData, ...props.manageInfo.fees]?.map(
          (currRow, index) =>
            currRow.registrationFees &&
            currRow.feesCategory !== FEES_CATEGORY_CONST.GOVERNANCE && (
              <div
                key={`${JSON.stringify(currRow)}${index}FeesList`}
                className="flex flex-col lg:flex-row items-start justify-start w-full mb-2"
              >
                <div className="lg:hidden mb-1 font-medium text-[12px]">Line Item</div>

                <p className="w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize lg:w-1/3">
                  {currRow.feesTitle}
                </p>
                <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Number Of Units</div>

                <p className="w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize lg:text-center lg:w-1/3">
                  {currRow?.registrationFees?.selectedUnitsCount ?? 0}
                </p>
                <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Price Per Unit</div>

                <p className="w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize lg:text-center lg:w-1/3">
                  {currRow.registrationFees?.paymentType ?? getFloatPrice(`${currRow.feesPrice}`)}
                </p>
              </div>
            )
        )}

        {/* Total */}
        <div className="flex flex-row items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor text-center">
          <p className="w-full flex-grow p-4 text-left mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor lg:w-1/3">
            {props.isManage && 'New'} Total
          </p>

          <p className="w-full flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor lg:w-1/3">
            {selectedUnits.totalItems}
          </p>

          <p className="w-full  flex-grow p-4 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor lg:w-1/3">
            {selectedUnits.totalPrice ? getFloatPrice(`${selectedUnits.totalPrice}`) : `$0`}
          </p>
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventFeeList
