import { useEffect, useState, useRef } from 'react'

// Data
import { earnCommissionDTTabs } from '../data/earnCommissionDTTabs'
import WrapperWithHeader from '../../../components/common/wrappers/WrapperWithHeader'
import { VideoModal } from '../modals/VideoModal'
import clsx from 'clsx'
import { httpService } from '../../../services/httpService'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { useSelector } from 'react-redux'

// Component imports

import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import { PillButtonElement } from '../../../components/elements/buttons/pill/PillButtonElement'
import DataNotAvailable from '../../../components/common/alerts/data-not-available/DataNotAvailable'
import InfiniteScrollDataTable from '../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { selectUseremail } from '../../../store/user/userSlice'

type ISelectedTabUS = { tabTitle: string; tab: number; subTitle: string }
type ISelectTab = (tabDetails: ISelectedTabUS) => void

const SELECTED_TAB_INI_VALUES = {
  ...earnCommissionDTTabs[0],
}

const IconComponent = ({
  children,
  icon,
  title,
  subtitle,
}: {
  children: any
  icon: string
  title: string
  subtitle: string
}) => {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center w-96">
        <img className="mr-6 w-6 h-6" src={icon} alt="User_Groups-1" />
        <div>
          <div className="text-SeabiscuitDark200ThemeColor text-[16px]">{title}</div>
          <div className="text-SeabiscuitDark200ThemeColor text-[14px] opacity-50">{subtitle}</div>
        </div>
      </div>

      {children}
    </div>
  )
}

const EarnCommission = () => {
  const [openVideoModal, setOpenVideoModal] = useState(false)

  const [selectedTab, setSelectedTab] = useState<ISelectedTabUS>({ ...SELECTED_TAB_INI_VALUES })
  const [affiliateLink, setAffiliateLink] = useState('')
  const userEmail = useSelector(selectUseremail)
  const toastFunctions = useToasterHelper()
  const [paypalEmail, setPaypalEmail] = useState('')
  const paypalEmailRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const fetchAffiliateData = async () => {
      try {
        const result = await httpService({
          url: `get_affiliate_object?email=${encodeURIComponent(userEmail || '')}`,
          method: 'GET',
        })

        console.log('Affiliate data:', result.data)
        setAffiliateLink(result.data[0].links[0].url)
      } catch (error) {
        console.error('Error fetching affiliate data:', error)
      }
    }

    const fetchPaypalEmail = async () => {
      try {
        const response = await httpService({
          url: `get_affiliate_object?email=${encodeURIComponent(userEmail || '')}`,
          method: 'GET',
        })
        if (response.data[0].paypal_email) {
          setPaypalEmail(response.data[0].paypal_email)
        }
      } catch (error) {
        console.error('Error fetching PayPal email:', error)
      }
    }

    fetchAffiliateData()
    fetchPaypalEmail()
  }, [userEmail])

  const fetchAffiliateIDAndSetPaypalEmail = async () => {
    try {
      // Fetch Affiliate ID
      const affiliateIdResponse = await httpService({
        url: `get_affiliate_id?email=${encodeURIComponent(userEmail || '')}`,
        method: 'GET',
      })
      const affiliateID = affiliateIdResponse.data[0].id

      const paypalEmail = paypalEmailRef.current?.value || ''

      // Set PayPal Email with the fetched Affiliate ID
      await httpService({
        url: `set_paypal_email?id=${affiliateID}&paypal_email=${encodeURIComponent(paypalEmail)}`,
        method: 'PUT',
      })

      console.log('PayPal email set successfully')
      toastFunctions.success({
        message: 'PayPal email updated successfully',
      })
    } catch (error) {
      console.error('Error setting PayPal email:', error)
      // Handle error (e.g., show an error message)
    }
  }

  const selectTab: ISelectTab = (tabDetails) => {
    setSelectedTab(tabDetails)
  }

  const [columns] = useState<any[]>([
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">Event</span>
        </span>
      ),
      cell: () => (
        <div className="flex  text-SeabiscuitDark200ThemeColor font-semibold items-center"></div>
      ),
      width: '34%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">Dates</span>
        </span>
      ),
      cell: () => <div className="flex w-full items-center"></div>,
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">Registered</span>
        </span>
      ),
      cell: () => <div className="flex w-full items-center"></div>,
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">Tickets Sold</span>
        </span>
      ),
      cell: () => <div className="flex w-full items-center"></div>,
      width: '12%',
    },

    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">Sponsors</span>
        </span>
      ),
      cell: () => <div className="flex w-full items-center"></div>,
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex ml-1">
          <span className="whitespace-nowrap">Vendors</span>
        </span>
      ),
      cell: () => <div className="flex w-full items-center"></div>,
      width: '12%',
    },

    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">Commission</span>
        </span>
      ),
      cell: () => <span className="flex w-full justify-center cursor-pointer"></span>,
      width: '6%',
    },
  ])

  const placeholderdata = 'No data available'
  const imgSrc = '/assets/placeholders/NoBillsFound.svg'

  if (1) return <></>

  return (
    <WrapperContainer title="Affiliate Program">
      <>
        <div className="mb-[30px] 22">
          {earnCommissionDTTabs.map((item, itemIndex) => {
            return (
              <PillButtonElement
                key={`${JSON.stringify(item)}${itemIndex}`}
                value={item.tab}
                text={item.tabTitle}
                className={`h-[45px] border-none inline-block text-SeabiscuitDark200ThemeColor ${item?.tab === 5 ? 'w-[130px]' : item?.tab === 6 ? 'w-[145px] ' : 'whitespace-nowrap w-[120]'}`}
                onButtonClicked={() => selectTab(item)}
                selected={selectedTab.tab === item.tab}
              />
            )
          })}
        </div>

        {selectedTab.tab === 1 && (
          <WrapperWithHeader
            title="Affiliate Account"
            description="Share and save your details to earn commissions from new users"
          >
            <>
              <hr className="mb-4"></hr>
              <IconComponent
                icon="/assets/cp_icons/User_Groups-1.svg"
                title="Affiliate Program"
                subtitle="Get paid to share Pegasus"
              >
                <a
                  href="/"
                  className="border border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-between rounded-xl p-4 w-[11rem] mr-2 cursor-pointer"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenVideoModal(true)
                  }}
                >
                  <span>Play Video</span>
                  <img className="w-5 h-5" src="/assets/cp_icons/video.svg" alt="editIcon" />
                </a>
              </IconComponent>
              <hr className="my-4"></hr>
              <IconComponent
                icon="/assets/cp_icons/link.svg"
                title="Your affiliate link"
                subtitle="Share this link to track commissions"
              >
                <div className="border border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-between rounded-xl p-1 w-[calc(26.50rem)] mr-2">
                  <span id="copyText" className="ml-2">
                    {affiliateLink || 'Contact Support To Set Up Affiliate Link'}
                  </span>
                  <div
                    className={`bg-[#F6F7FB] rounded-lg p-3 px-6 cursor-pointer ${affiliateLink ? 'hover:bg-opacity-90' : 'opacity-50 cursor-not-allowed'}`}
                    onClick={() => {
                      if (!affiliateLink) return // Prevent the function from executing if affiliateLink is empty
                      const copyTextElement = document.getElementById('copyText')
                      if (copyTextElement) {
                        navigator.clipboard.writeText(copyTextElement.innerText).then(
                          () => {
                            // Show success toast message after successfully copying
                            toastFunctions.success({
                              message: 'Link copied successfully!',
                            })
                          },
                          (err) => {
                            // Handle error case, optionally show an error toast
                            console.error('Could not copy text: ', err)
                          }
                        )
                      }
                    }}
                  >
                    Copy
                  </div>
                </div>
              </IconComponent>
              <hr className="my-4"></hr>
              <IconComponent
                icon="/assets/cp_icons/paypal.svg"
                title="PayPal account"
                subtitle="Save your account to receive commissions"
              >
                <div className="border border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-between rounded-xl p-1 w-[calc(26.50rem)] mr-2">
                  <input
                    ref={paypalEmailRef} // Use the ref here
                    className="w-full border-none focus:outline-none"
                    type="text"
                    placeholder="Enter your PayPal link to receive commissions"
                    defaultValue={paypalEmail || ''}
                  />
                  <div
                    className="bg-[#F6F7FB] rounded-lg p-3 px-6 cursor-pointer hover:bg-[#fff5f6]"
                    onClick={fetchAffiliateIDAndSetPaypalEmail}
                  >
                    Save
                  </div>
                </div>
              </IconComponent>
              {openVideoModal && (
                <VideoModal
                  open={openVideoModal}
                  videoURL={
                    'https://firebasestorage.googleapis.com/v0/b/pegasus-ems.appspot.com/o/Website%20Videos%2Faffiliate-video.mp4?alt=media&token=8b32dc23-b9d0-48b8-8b68-256dffbdd91b'
                  }
                  onClose={() => setOpenVideoModal(false)}
                />
              )}
            </>
          </WrapperWithHeader>
        )}

        {selectedTab.tab === 2 && (
          <WrapperWithHeader
            title="Account Activity"
            description="Track events created by organizers using your affiliate link"
          >
            <InfiniteScrollDataTable
              data={[]}
              hasMore={false}
              columns={columns}
              fixedHeader={true}
              className={clsx('!rounded-none')}
              noDataComponent={
                <DataNotAvailable
                  mode="graphic"
                  imageAlt="no bills"
                  upperText={placeholderdata}
                  imageSource={imgSrc}
                />
              }
              progressComponent={
                <ViewsLoader
                  className="flex items-center w-full justify-center my-10"
                  size="md"
                  color="#F70763"
                />
              }
            />
          </WrapperWithHeader>
        )}
      </>
    </WrapperContainer>
  )
}

export default EarnCommission
