import { useContext, useEffect, useRef, useState } from 'react'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'

// Types
import {
  IRiderTeamMember,
  ITeamMember,
  IUserInterface,
} from '../../../../models/users/user.interface'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectUserId,
  setProfileDetails,
  setUserProfileComplete,
} from '../../../../store/user/userSlice'
import { RootState } from '../../../../store/store'
import { selectAllUsers, setAllUsers } from '../../../../store/users/usersSlice'

import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../helpers/helpers'
import { getAllUsers } from '../../../../helpers/users'

import useHorses from '../../../../hooks/users/competitor/profile/useHorses'

import { getConvertedData } from '../../../../models/interface.helper'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import { ProfileCard } from './components/ProfileCard'
import { IProfileItem } from './components/ProfileItem'

// Constants
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { CONST } from '../../../../const/const'
import { ITEAMMEMBER_MODAL_TYPES } from '../../../home/modals/UserProfileTeamTabAddTeamMember'
import { formatConnectedDate } from '../../../../helpers/time'

// Types
type Props = {
  title?: string
  description?: string
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}
interface IUserExtendedWithStatus extends IUserInterface {
  status: string
  connectedDate: Date | string | null
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const ProfileTeamTabCompetitor = (props: Props) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const dispatch = useAppDispatch()
  const userId = useAppSelector<string | null>(selectUserId) ?? ''
  const users = useAppSelector(selectAllUsers)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const { getMapping } = useHorses()

  // const [eventTab, setEventTab] = useState(CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE)
  const usersHolderRef = useRef<IUserInterface[]>([])
  const [loading, setLoading] = useState(true)
  const [teamMembers, setTeamMembers] = useState<IProfileItem[]>([])
  const [usersWHoAddedYou, setUsersWHoAddedYou] = useState<IUserExtendedWithStatus[]>([])

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })

  const getTeamMemberDetails = async () => {
    let owner: ITeamMember | null
    let userIdsList: string[] = []
    let memberName: string | null = null
    let usersList: IUserInterface[] = []
    let currUser: IUserInterface | null = null
    let teamMembersListLocal: ITeamMember[]
    let usersSnaps: QueryDocumentSnapshot<DocumentData>[]

    const ACTIVE = '1'
    owner = (() => {
      memberName = profileDetails ? getUserFullName(profileDetails) : ''

      return {
        memberStatus: ACTIVE,
        memberName: memberName,
        memberShipActive: false,
        defaultRole: COLLECTIONS.USERS.FIELDS.USER_DEFAULT_ROLE.VALUES.OWNER,
        memberprofileSynced: false,
        memberSafeSupportTraining: false,
        memberId: profileDetails.id ?? '',
        memberEmail: profileDetails?.userEmail ?? '',
        memberAddress: profileDetails?.userAddress ?? '',
        memberCountry: profileDetails?.userNationality ?? '',
        memberPhoneNumber: profileDetails?.userPhoneNumber ?? '',
        memberProfilePicture: profileDetails?.userProfilePicture ?? '',
        memberDob: profileDetails?.userDOB ? getConvertedData(profileDetails.userDOB) : '',
      }
    })()

    teamMembersListLocal = [owner, ...(profileDetails?.userTeamMembers ?? [])]
    userIdsList = []

    teamMembersListLocal.forEach((currMember) => {
      if (currMember.memberId) {
        userIdsList.push(currMember.memberId)
      }
    })

    userIdsList = [...new Set(userIdsList)]
    userIdsList = userIdsList.reduce((acc: string[], curr) => {
      if (!usersHolderRef.current.find((c) => c.id === curr)) acc.push(curr)
      return acc
    }, [])

    usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsList)
    usersSnaps.forEach((curr) => {
      currUser = getConvertedData(UserModel.fromFirestoreDoc(curr).toObject())
      usersList.push(currUser)
      usersHolderRef.current.push(currUser)
    })

    teamMembersListLocal = teamMembersListLocal.map((currMember) => {
      currUser = usersHolderRef.current.find((c) => c.id === currMember.memberId) ?? null
      if (!currUser)
        currUser = usersList.find((currUser_) => currUser_?.id === currMember.memberId) ?? null
      if (!currUser) return currMember
      else {
        return {
          ...currMember,
          memberName: getUserFullName(currUser),
          memberEmail: currUser?.userEmail,
          memberId: currUser?.id,
          memberProfilePicture: currUser?.userProfilePicture,
          memberUserName: currUser.userName,
        }
      }
    })

    return teamMembersListLocal
  }

  const getAllData = async () => {
    setLoading(true)

    const listHorseTeamMembers: IProfileItem[] = []

    const { horsesTeamsMappings } = await getMapping(userId)
    const teamMembersList = await getTeamMemberDetails()

    const listTeam = teamMembersList.map((member) => ({
      id: member.memberId || '',
      name: member.memberName || '',
      profilePicture: member.memberProfilePicture || '',
      userDesc: `${member?.memberUserName ? `@${member.memberUserName}` : 'N/A'}`,
      role: member.memberRole || '',
    }))

    horsesTeamsMappings.forEach((itm) => {
      Array.isArray(itm.teamMembers) &&
        itm.teamMembers.forEach((member) => {
          const user = users.find((u) => u.id === member.memberId)
          listHorseTeamMembers.push({
            id: member.memberId || '',
            name: member.memberName || '',
            profilePicture: member.memberProfilePicture || '',
            userDesc: `${user?.userName ? `@${user.userName}` : 'N/A'} • ${itm.horseName}`,
            role: member.memberRole || '',
            horseId: member.id,
          })
        })
    })
    setTeamMembers([...listTeam, ...listHorseTeamMembers])
    setLoading(false)
  }

  useEffect(() => {
    if (profileDetails) getAllData().then()
  }, [profileDetails.userTeamMembers, users])

  useEffect(() => {
    const filteredUsers: IUserExtendedWithStatus[] = []
    if (users.length > 0) {
      users.forEach((user) => {
        user.userTeamMembers?.forEach((currentMember: ITeamMember) => {
          if (currentMember.memberId === userId) {
            filteredUsers.push({
              ...user,
              status: currentMember.memberAuthorized === '1' ? 'Authorized' : 'Unauthorized',
              connectedDate: currentMember.connectedDate ?? null,
            })
          }
        })
      })
      setUsersWHoAddedYou(filteredUsers)
    }
  }, [users])

  const handleTeamInvite = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
      type: 5,
      maxWidth: 430,
    })

    return dispatch(setUserProfileComplete(false))
  }

  const removeUserFromTeamMembers = async (
    userTeamMembers: IUserInterface['userTeamMembers'],
    id: string
  ) => {
    try {
      await FirestoreService.updateItem(COLLECTIONS.USERS.NAME, id, {
        userTeamMembers,
      })

      const allUsers = await getAllUsers()
      dispatch(setAllUsers(allUsers))

      toastFunctions.success({
        message: MESSAGES_CONST.TEAM_MEMBERS_LIST_UPDATED,
      })
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const onRemoveHorseTeamMember = async (id: string, horseId: string) => {
    const newTeamMembers = cloneDeep(teamMembers)

    const removedIndex = newTeamMembers.findIndex((u) => u.id === id && horseId === u.horseId)

    if (removedIndex !== -1) {
      console.error(`Can't find removedIndex`)
      return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }

    const removedItm = newTeamMembers.splice(removedIndex, 1)

    try {
      await FirestoreService.deleteItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_TEAM.NAME,
        removedItm[0]?.horseId || ''
      )
      setTeamMembers(newTeamMembers)
      toastFunctions.success({
        message: MESSAGES_CONST.HORSE_TEAM_MEMBER_REMOVED,
      })
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const removeTeamMemberHandler = (memberId: string, memberHorseId: string | null) => {
    if (memberHorseId) {
      return props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: onRemoveHorseTeamMember,
        alertText: (
          <div>
            <h5 className="font-bold -mt-5 text-xl text-SeabiscuitDark200ThemeColor">
              Remove horse team member
            </h5>
            <p className="mt-5">This team member was add in the horses profile settings.</p>
            <p className="mb-5 mt-2">
              Removing this person here, will also remove them from the horses profile.
            </p>
          </div>
        ),
      })
    } else {
      return props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: async () => {
          const userTeamMembers = profileDetails.userTeamMembers?.filter(
            (member) => member.memberId !== memberId
          )
          await removeUserFromTeamMembers(userTeamMembers, profileDetails.id)
          setTeamMembers((prevUsers) => prevUsers.filter((user) => user.id !== memberId))
          dispatch(setProfileDetails({ ...profileDetails, userTeamMembers }))
        },
        alertText: (
          <div>
            <h5 className="font-bold -mt-5 text-xl text-SeabiscuitDark200ThemeColor">
              Remove team member
            </h5>
            <p className="mt-5">Are you sure you would like to remove this team member?</p>
          </div>
        ),
      })
    }
  }

  const removeUserWHoAddedYou = async (id: string) => {
    const usersWhoAddedYouCloned = cloneDeep(usersWHoAddedYou)

    // Find index of the user to remove
    const removedIndex = usersWhoAddedYouCloned.findIndex((user) => user.id === id)

    if (removedIndex !== -1) {
      const removedObject = usersWhoAddedYouCloned[removedIndex]

      // Ensure userId is defined
      if (!userId) {
        console.error('Error: userId is not defined.')
        return
      }

      const userTeamMembers = removedObject.userTeamMembers?.filter(
        (member) => member.memberId !== userId
      )

      await removeUserFromTeamMembers(userTeamMembers, removedObject.id)
      setUsersWHoAddedYou((prevUsers) => prevUsers.filter((user) => user.id !== userId))
    }
  }

  const onRemovePeople = (id: string) => {
    props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: async () => await removeUserWHoAddedYou(id),
      alertText: (
        <div>
          <h5 className="font-bold -mt-5 text-xl text-SeabiscuitDark200ThemeColor">
            Remove connection
          </h5>
          <p className={'my-5'}>Are you sure you would like to remove this connection?</p>
        </div>
      ),
    })
  }

  return (
    <>
      <FormHeader
        title="Team Settings"
        description={
          'Add team members that you will select when registering for events. Eg. Riders, Trainers, Owners, Guardians, etc.'
        }
        removeBreakLine={true}
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              type="submit"
              onClick={handleTeamInvite}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              Invite friends
            </button>
          </div>
        }
      />

      <div className={clsx('flex-1 flex flex-col', formStyles.className)} style={formStyles.styles}>
        <div className={'flex flex-1 flex-col lg:flex-row gap-6 mt-4 lg:mt-0'}>
          <ProfileCard
            subTitle={'Team members'}
            teamMembers={teamMembers}
            loading={loading}
            removeHandler={({ memberId, memberHorseId }) =>
              removeTeamMemberHandler(memberId, memberHorseId)
            }
            title={'People you add'}
            desc={'People you add to your team'}
            addTeamMemberHandler={() => {
              props.handleModal(true, MODAL_CONSTS.TEAM, {
                modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
                saveHandler: async (userTeamMembers: IRiderTeamMember[]) => {
                  await FirestoreService.updateItem(COLLECTIONS.USERS.NAME, profileDetails.id, {
                    userTeamMembers,
                  })
                },
              })
            }}
          />
          <ProfileCard
            loading={false}
            title={'People who added you'}
            subTitle={'Connections'}
            desc={'People who added you to their team'}
            teamMembers={usersWHoAddedYou.map((user) => ({
              id: user.id,
              name: getUserFullName(user),
              userDesc: `@${user.userName} • connected ${user.connectedDate ? formatConnectedDate(new Date(user.connectedDate)) : 'N/A'}`,
              role: '',
              profilePicture: user.userProfilePicture || '',
            }))}
            removeHandler={({ memberId }) => onRemovePeople(memberId)}
            isConnections
          />
        </div>
      </div>
    </>
  )
}

export default ProfileTeamTabCompetitor
