// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventStaffFormComponent from '../../../components/events/forms/staff/EventStaffFormComponent'
import { useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventStaffModel } from '../../../models/event-staff/event-staff.model'
import { selectUserId } from '../../../store/user/userSlice'
import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'

interface ShowsDetailPageEventStaffFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
  nextEventTab: string
  eventTab?: string
}

const ShowsDetailPageEventStaffFormSubSection: React.FC<
  ShowsDetailPageEventStaffFormSubSectionProps
> = (props) => {
  const router = useIonRouter()
  const history = useHistory()

  const userId = useAppSelector(selectUserId)
  const selectedEventR = useAppSelector(selectedEvent)

  const eventId = selectedEventR.Event?.id ?? ''

  const [data, setData] = useState<any>(null)

  const validFormHandler = async (data: { eventStaff: any[]; otherStaff: any[] }) => {
    let updated = true

    const updated_data = new EventStaffModel({
      eventStaff: data.eventStaff,
      otherStaff: data.otherStaff,
      id: eventId,
      owner: userId,
    })

    await new Promise((resolve, reject) => {
      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_STAFF.NAME,
        eventId,
        updated_data.toFirestore()
      )
        .then((_) => {
          setData(updated_data.toObject())
          resolve(true)
        })
        .catch((reason) => {
          updated = false
          console.error('validFormHandler:' + reason)
          reject()
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab:
        props.nextEventTab === '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
      onSetNextEventTab: props.onSetNextEventTab(''),
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndExitFormHandler = async (data: any) => {
    const valueToReturn = await validFormHandler(data)
    return valueToReturn
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    console.log({ data }, 'Invalid')
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndExitFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_STAFF.NAME,
          eventId
        )
        if (doc) {
          setData(EventStaffModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventStaffFormComponent
        data={data}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        onValidAnExit={validAndExitFormHandler}
        onInvalidAndExit={invalidAndExitFormHandler}
        handleModal={props.handleModal}
        dataToPassOn={props.dataToPassOn}
        nextEventTab={props.nextEventTab}
        onSetNextEventTab={props.onSetNextEventTab}
        eventTab={props.eventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventStaffFormSubSection
