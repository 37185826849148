import React from 'react'

export const IconList: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="none" viewBox="0 0 16 20">
      <path
        fill="#F7074F"
        d="M6.25 0C5.102 0 4.153.883 4.025 2H2.25A2.26 2.26 0 0 0 0 4.25v13.5A2.26 2.26 0 0 0 2.25 20h11.5A2.26 2.26 0 0 0 16 17.75V4.25A2.26 2.26 0 0 0 13.75 2h-1.775C11.847.883 10.898 0 9.75 0zm0 1.5h3.5c.423 0 .75.327.75.75s-.327.75-.75.75h-3.5a.74.74 0 0 1-.75-.75c0-.423.327-.75.75-.75m-4 2h2.135c.406.601 1.092 1 1.865 1h3.5c.773 0 1.46-.399 1.865-1h2.135c.423 0 .75.327.75.75v13.5c0 .423-.327.75-.75.75H2.25a.74.74 0 0 1-.75-.75V4.25c0-.423.327-.75.75-.75M4.741 5a.5.5 0 0 0-.112.015l-1 .25a.5.5 0 0 0 .242.97l.379-.094V8.5A.5.5 0 1 0 5.251 8.5v-3a.5.5 0 0 0-.51-.5M8 7a.75.75 0 1 0 0 1.5h3.75a.75.75 0 1 0 0-1.5zM4.75 9.5c-.822 0-1.293.483-1.442.768a.493.493 0 0 0 .2.659.51.51 0 0 0 .678-.182c.015-.025.162-.245.564-.245a.5.5 0 0 1 .5.5c0 .242-.472.506-.85.718-.54.3-1.15.64-1.15 1.282a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 0-1h-.702c.572-.325 1.202-.742 1.202-1.5 0-.827-.673-1.5-1.5-1.5M8 11a.751.751 0 1 0 0 1.5h3.75a.751.751 0 1 0 0-1.5zm-3.312 3c-.78 0-1.252.478-1.303.532a.497.497 0 0 0 .02.698c.196.19.51.185.704-.007A.86.86 0 0 1 4.688 15c.163 0 .437.033.437.25 0 .1 0 .25-.375.25a.5.5 0 0 0 0 1c.083 0 .5.012.5.25s-.417.25-.5.25c-.442 0-.708-.157-.728-.17a.5.5 0 0 0-.55.836c.052.034.523.334 1.278.334.897 0 1.5-.503 1.5-1.25 0-.325-.114-.604-.316-.815.123-.191.191-.423.191-.685 0-.736-.591-1.25-1.437-1.25M8 15a.751.751 0 1 0 0 1.5h3.75a.751.751 0 1 0 0-1.5z"
      />
    </svg>
  )
}
