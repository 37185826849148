// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useRef, useState } from 'react'

import { IonContent, IonPage } from '@ionic/react'

import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import UserSignupViewComponent from '../../components/user/views/sign-up/UserSignupViewComponent'
import { CONST } from '../../const/const'
import { CompetitorProfileModel } from '../../models/competitor-profile/competitor-profile.model'
import { UserModel } from '../../models/users/user.model'
import FirebaseApp from '../../services/firebaseApp'
import firestoreService from '../../services/firestoreService'
import UserService from '../../services/userService'
import { useAppDispatch } from '../../store/hooks'
import {
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setStripeId,
  setUserId,
  setUserProfileComplete,
  setUserProfileData,
  setUserType,
  storeIsFirstTime,
  storeUserId,
} from '../../store/user/userSlice'
import { storeDisplayName } from '../../store/user/userThunk'

// Redux
import FIREBASE_CONST from '../../const/firebase-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { CustomError, authHelpers, getUserFullName } from '../../helpers/helpers'
import { getConvertedData } from '../../models/interface.helper'
import { httpService } from '../../services/httpService'
import { storeUseMode } from '../../store/system/systemThunk'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SignUpPage = () => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const processStatus = useRef<'running' | null>(null)

  const [loading, setLoading] = useState(false)

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo send email
   */
  const sendEmailtoTeam = async (data: { name: string; email: string }) => {
    try {
      const res: any = await httpService({
        url: 'send_emails',
        method: 'POST',
        data: {
          mail_type: 'account_create',
          mail_data: {
            reciever_data: {
              receiverName: data.name,
              receiverEmail: data.email,
            },
          },
        },
      })

      if (res?.status) {
        return true
      }
    } catch (err) {
      return false
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Document this
   */
  const onSignUpButtonPressedHandler = async (data: any, userType: string) => {
    const result_ = await authHelpers.isUsernameUnique(data.username)

    if (result_ instanceof CustomError) {
      setLoading(false)
      processStatus.current = null
      return toastFunctions.error({ message: result_.message })
    }
    try {
      if (processStatus.current === 'running') return 0

      processStatus.current = 'running'
      setLoading(true)

      const result = await createUserWithEmailAndPassword(
        FirebaseApp.auth,
        data?.email?.toLowerCase(),
        data?.newPassword
      )

      await updateProfile(result.user, {
        displayName: data?.fullname,
      })

      await httpService({
        url: `create_user`,
        method: 'POST',
        data: {
          uuid: result.user.uid,
          userName: data?.username ?? '',
          email: `${result.user.email}`.toLowerCase(),
          userType,
        },
      })

      dispatch(setEmail(`${result.user.email}`.toLowerCase()))
      dispatch(setUserId(result.user.uid))
      dispatch(storeIsFirstTime(true))
      dispatch(setUserProfileComplete(true))

      let user_data_doc = null
      let document_exists = false

      while (!document_exists) {
        user_data_doc = await UserService.getUserInfoById(result.user.uid)
        document_exists = user_data_doc.exists()
      }

      let userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

      if ('username' in data) {
        userData.userName = data?.username
      } else {
        userData.userName = data?.userName
        userData.userBusinessName = data?.userName
      }
      if ('website' in data) {
        userData.websiteUrl = data?.website
      }

      if ('profilePic' in data) {
        userData.userProfilePicture = data.profilePic
        userData.userPhoneNumber = data.userPhoneNumber
        userData.userCategory = data.userCategory
        userData.userAddress = data.userAddress
        userData.vendorname = data.vendorname
      }

      userData.userFullName = data?.fullname || ''

      if (!userData.userStripeId && data?.fullname && userData?.userEmail) {
        const customerData = await httpService({
          url: `create_stripe_customer`,
          method: 'POST',
          data: {
            userEmail: userData?.userEmail.toLowerCase(),
            userName: getUserFullName(userData),
            userStripeAccountId: userData.userStripeAccountId,
          },
        })

        userData.userStripeId = (customerData as any).customer

        if (userData.userStripeId) {
          dispatch(setStripeId(userData.userStripeId))
        }
      }

      if (userType) {
        userData.userType = userType
        if (userType === 'competitor') {
          let CompetitorSubRole = sessionStorage.getItem('competitorRoles')
          const competitorSubRole = CompetitorSubRole?.split(',')
          const userProfile = new CompetitorProfileModel({
            competitorOwnerId: result.user.uid,
          }).toFirestore()

          userData.userSubRoles = competitorSubRole || []
          await UserService.createCompetitorProfile(result.user.uid, userProfile)
        }
      }

      if ('nationality' in data || 'userNationality' in data) {
        userData.userNationality = data.nationality.value
      }

      let userData_ = new UserModel(userData)

      await firestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        result.user.uid,
        userData_.toObject()
      )

      const data_email: {
        name: string
        email: string
      } = {
        name: userData_ ? getUserFullName(userData_) : 'N/A',
        email: userData_?.userEmail || '',
      }

      const [firstName, ...lastName] = data.fullname.split(' ')

      const affiliateData = {
        email: data.email,
        first_name: firstName,
        last_name: lastName.join(' '),
        stripe_customer_id: data.userStripeId,
      }

      try {
        const result = await httpService({
          url: 'create_rewardful_affiliate',
          method: 'POST',
          data: affiliateData,
        })

        console.log('Affiliate created:', result.data)
      } catch (error) {
        console.error('Error creating affiliate:', error)
      }

      await sendEmailtoTeam(data_email)
      dispatch(setIsLoggedIn(true))
      dispatch(setUserProfileData(getConvertedData(userData_.toObject())))
      dispatch(storeUserId(result.user.uid))
      dispatch(setDisplayName(data?.username))
      dispatch(storeDisplayName(data?.username))
      dispatch(storeUseMode(userType))
      dispatch(setUserType(userType))
      setLoading(false)
      processStatus.current = null
    } catch (error: any) {
      console.error(error, 'error')
      setLoading(false)
      processStatus.current = null

      if (error.code === FIREBASE_CONST.EMAIL_ALREADY_EXISTS)
        return toastFunctions.error({ message: 'Email already exists' })

      toastFunctions.error({ message: 'Something went wrong' })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonPage>
      <IonContent>
        <UserSignupViewComponent
          onSignUpButtonPressed={onSignUpButtonPressedHandler}
          loading={loading}
        />
      </IonContent>
    </IonPage>
  )
}

SignUpPage.isauthpage = true

export default SignUpPage
