import clsx from 'clsx'
import React, { FC, useState } from 'react'

interface ReportItemProps {
  title: string
  description: string
  onClick: () => Promise<void>
  isAvailable?: boolean
}

export const EventReportItem: FC<ReportItemProps> = ({
  title,
  description,
  onClick,
  isAvailable,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <div className="flex flex-col w-full sm:w-[49%] md:w-[32%] xl:w-[18.4%] rounded-xl shadow-md p-5 border border-SeabiscuitLightThemeColorD3/50  transition-all h-[211px] bg-white">
      <h4 className="font-normal text-SeabiscuitDark200ThemeColor text-lg">{title}</h4>
      <p className="text-SeabiscuitDark200ThemeColor/60 text-base mt-1.5">{description}</p>

      <div className="mt-auto w-full px-2">
        <button
          type="button"
          disabled={!isAvailable || loading}
          className={`
            w-full flex items-center ring-0 justify-center gap-2.5 px-4 py-2.5 rounded-lg 
            ${
              isAvailable
                ? 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor cursor-pointer'
                : 'bg-SeabiscuitGrayThemeColor/50 text-SeabiscuitDark200ThemeColor/40 cursor-default'
            }
          `}
          onClick={async () => {
            if (isAvailable && !loading) {
              setLoading(true)
              await onClick()
              setLoading(false)
            }
          }}
        >
          {loading ? (
            <>
              <img
                className="animate-spin w-5 h-5"
                src={'assets/og_icons/Synchronize-1.svg'}
                alt=""
              />
              <span>Loading...</span>
            </>
          ) : (
            <>
              <svg
                width="20"
                height="14"
                viewBox="0 0 30 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="group-hover:fill-SeabiscuitMainThemeColor transition-all"
              >
                <path
                  className={clsx(
                    'fill-current',
                    isAvailable && 'group-hover:fill-SeabiscuitMainThemeColor'
                  )}
                  d="M15 0.375C10.3188 0.375 6.4563 3.94375 5.98755 8.5H5.625C2.525 8.5 0 11.025 0 14.125C0 17.225 2.525 19.75 5.625 19.75H11.8811L9.87549 17.875H5.625C3.55625 17.875 1.875 16.1938 1.875 14.125C1.875 12.0562 3.55625 10.375 5.625 10.375H6.875C7.39375 10.375 7.8125 9.95625 7.8125 9.4375C7.8125 5.475 11.0375 2.25 15 2.25C18.9625 2.25 22.1875 5.475 22.1875 9.4375C22.1875 9.95625 22.6063 10.375 23.125 10.375H24.375C26.4438 10.375 28.125 12.0562 28.125 14.125C28.125 16.1938 26.4438 17.875 24.375 17.875H20.1245L18.1189 19.75H24.375C27.475 19.75 30 17.225 30 14.125C30 11.025 27.475 8.5 24.375 8.5H24.0125C23.5437 3.94375 19.6812 0.375 15 0.375ZM14.9854 7.23657C14.7369 7.24045 14.5002 7.34278 14.3272 7.52108C14.1541 7.69938 14.0589 7.93907 14.0625 8.1875V16.6543L10.9521 13.7527C10.8617 13.6657 10.7547 13.5977 10.6375 13.5529C10.5202 13.508 10.3952 13.4871 10.2698 13.4915C10.0835 13.4981 9.90347 13.5602 9.75263 13.6697C9.6018 13.7792 9.48702 13.9311 9.42296 14.1062C9.3589 14.2812 9.34845 14.4714 9.39296 14.6523C9.43747 14.8333 9.53492 14.997 9.67285 15.1223L14.3604 19.4973C14.534 19.6593 14.7626 19.7494 15 19.7494C15.2374 19.7494 15.466 19.6593 15.6396 19.4973L20.3271 15.1223C20.4218 15.0396 20.4989 14.9386 20.5538 14.8255C20.6086 14.7124 20.6402 14.5894 20.6465 14.4638C20.6529 14.3382 20.6339 14.2126 20.5908 14.0945C20.5476 13.9764 20.4812 13.8682 20.3953 13.7763C20.3095 13.6845 20.2061 13.6108 20.0912 13.5597C19.9763 13.5086 19.8523 13.4811 19.7266 13.4789C19.6009 13.4767 19.476 13.4998 19.3594 13.5468C19.2428 13.5939 19.1369 13.6639 19.0479 13.7527L15.9375 16.6543V8.1875C15.9393 8.06202 15.9159 7.93746 15.8687 7.82119C15.8214 7.70493 15.7513 7.59933 15.6626 7.51065C15.5738 7.42197 15.4681 7.35202 15.3517 7.30494C15.2354 7.25786 15.1108 7.23461 14.9854 7.23657Z"
                />
              </svg>
              <p className="text-[15px]">{isAvailable ? 'Download' : 'Coming Soon'}</p>
            </>
          )}
        </button>
      </div>
    </div>
  )
}
