import { useEffect, useState, useRef } from 'react'
import MainModal from '../../../components/modals/common/MainModal'
import { useQuery } from '@tanstack/react-query'
import FirestoreService from '../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { CONST } from '../../../const/const'
import { UserModel } from '../../../models/users/user.model'
import { getConvertedData } from '../../../models/interface.helper'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { UserCard } from './MembersModal'
import { getUserFullName } from '../../../helpers/helpers'
import { CustomError } from '../../../helpers/helpers'
import { EventStaffModel } from '../../../models/event-staff/event-staff.model'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { ChannelData } from 'stream-chat'
import { useChatClient, IUsersChat } from '../components/withStreamClient'
import { IUserInterface } from '../../../models/users/user.interface'

type UserWithRole = IUserInterface & IUsersChat

type Vertical = 'all' | 'exhibitors' | 'staff'

const options: {
  id: string
  vertical: Vertical
  sub_vertical: string
  order: number
  name: string
}[] = [
  { id: 'all', vertical: 'all', sub_vertical: '', order: 0, name: 'All' },
  { id: 'staff', vertical: 'staff', sub_vertical: '', order: 2, name: 'Staff' },
  { id: 'judges', vertical: 'staff', sub_vertical: 'Judges', order: 3, name: 'Judges' },
  {
    id: 'show_manager',
    vertical: 'staff',
    sub_vertical: 'Show manager',
    order: 4,
    name: 'Show manager',
  },
  { id: 'exhibitors', vertical: 'exhibitors', sub_vertical: '', order: 1, name: 'Exhibitors' },
  { id: 'riders', vertical: 'exhibitors', sub_vertical: 'Rider', order: 5, name: 'Riders' },
  { id: 'trainers', vertical: 'exhibitors', sub_vertical: 'Trainer', order: 6, name: 'Trainers' },
  { id: 'owners', vertical: 'exhibitors', sub_vertical: 'Owner', order: 7, name: 'Owners' },
  { id: 'coaches', vertical: 'exhibitors', sub_vertical: 'Coach', order: 8, name: 'Coaches' },
  { id: 'grooms', vertical: 'exhibitors', sub_vertical: 'Groom', order: 9, name: 'Grooms' },
  {
    id: 'guardians',
    vertical: 'exhibitors',
    sub_vertical: 'Guardian',
    order: 10,
    name: 'Guardians',
  },
]

export const useEventRidersUsers = ({
  eventId,
  search,
  select,
  enabled = true,
}: {
  eventId: string
  search: string
  select?: (data: UserWithRole[]) => UserWithRole[]
  enabled?: boolean
}) => {
  return useQuery<UserWithRole[]>({
    queryKey: ['event', eventId, 'team', search],
    queryFn: async () => {
      const filters = [
        where(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY,
          '==',
          eventId
        ),
      ]
      if (search) {
        filters.push(
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.RIDER_NAME_N_GRAM.KEY,
            'array-contains',
            search
          )
        )
      }

      // Get rider team members from firestore
      const ridersSnapshots = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        filters
      )
      let riderTeamMembers: IRiderTeamMemberInterface[] = []
      ridersSnapshots.docs.forEach((currDoc) => {
        riderTeamMembers.push(
          getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      // Get unique user ids
      const userIdsSet = new Set<string>()
      riderTeamMembers.forEach((rider) => {
        if (rider.userId) {
          userIdsSet.add(rider.userId)
        }
      })
      const userIdsList: string[] = Array.from(userIdsSet)

      // Get users from firestore
      let usersSnaps = await FirestoreService.getItemsUsingIds(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        userIdsList
      )
      const users: IUserInterface[] = []
      usersSnaps.forEach((currSnap) =>
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      )

      // Merge users with their roles
      const users_with_roles: UserWithRole[] = []
      const uniqueUsersMap = new Map<string, UserWithRole>()

      riderTeamMembers.forEach((member) => {
        const user = users.find((u) => u.id === member.userId)
        if (user) {
          const key = `${user.id}-${member.teamMemberRole}`
          if (!uniqueUsersMap.has(key) && member.teamMemberRole) {
            const userWithRole = {
              ...user,
              role: member.teamMemberRole,
              vertical: 'exhibitors',
              name: getUserFullName(user),
            }
            uniqueUsersMap.set(key, userWithRole)
            users_with_roles.push(userWithRole)
          }
        }
      })

      return users_with_roles
    },
    select: select,
    initialData: [],
    enabled: !!eventId && enabled,
  })
}

export const useEventStaffUsers = ({
  eventId,
  search,
  enabled = true,
  select,
}: {
  eventId: string
  search: string
  enabled?: boolean
  select?: (data: UserWithRole[]) => UserWithRole[]
}) => {
  return useQuery<UserWithRole[]>({
    queryKey: ['event', eventId, 'staff', search],
    queryFn: async () => {
      const staffSnap = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_STAFF.NAME,
        eventId
      )

      if (!staffSnap.exists)
        throw CustomError.somethingWentWrong({
          lineNumber: 84,
          fileName: 'useEventStaffUsers',
          message: 'no event staff found',
          devMessage: `Could not find event staff for event ${eventId}`,
        })

      const staffs = EventStaffModel.fromFirestoreDoc(staffSnap).toObject()

      const userIdsList: string[] = []
      const userIdsWithRole: { userId: string; role: string }[] = []

      staffs.eventStaff?.forEach((user_staff) => {
        user_staff.value.forEach((currentId) => {
          if (typeof currentId === 'string') {
            userIdsList.push(currentId)
            userIdsWithRole.push({ userId: currentId, role: user_staff.title })
          }
        })
      })
      let usersSnaps = await FirestoreService.getItemsUsingIds(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        userIdsList
      )
      const users: IUserInterface[] = []
      usersSnaps.forEach((currSnap) =>
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      )

      const users_with_roles: UserWithRole[] = []
      users.forEach((user) => {
        const member = userIdsWithRole.find((m) => m.userId === user.id)

        if (member) {
          users_with_roles.push({
            ...user,
            role: member.role,
            vertical: 'staff',
            name: getUserFullName(user),
          })
        }
      })

      return users_with_roles
    },
    select,
    initialData: [],
    enabled: !!eventId && enabled,
  })
}

const useEventUserStats = (eventId: string) => {
  const { data: team_members } = useEventRidersUsers({
    eventId: eventId ?? '',
    search: '',
  })
  const { data: staff_members } = useEventStaffUsers({
    eventId: eventId ?? '',
    search: '',
  })
  const [stats, setStats] = useState({
    exhibitors: 0,
    riders: 0,
    trainers: 0,
    owners: 0,
    coaches: 0,
    grooms: 0,
    guardians: 0,
    staff: 0,
    judges: 0,
    show_manager: 0,
    all: 0,
  })

  useEffect(() => {
    // Exhibitors
    const exhibitors = team_members.length
    const riders = team_members.filter((m) => m.role === 'Rider').length
    const trainers = team_members.filter((m) => m.role === 'Trainer').length
    const owners = team_members.filter((m) => m.role === 'Owner').length
    const coaches = team_members.filter((m) => m.role === 'Coach').length
    const grooms = team_members.filter((m) => m.role === 'Groom').length
    const guardians = team_members.filter((m) => m.role === 'Guardian').length
    //Staff
    const staff = staff_members.length
    const judges = staff_members.filter((s) => s.role === 'Judges').length
    const show_manager = staff_members.filter((s) => s.role === 'Show manager').length
    // All
    const all = exhibitors + staff

    setStats({
      exhibitors,
      riders,
      trainers,
      owners,
      coaches,
      grooms,
      guardians,
      staff,
      judges,
      show_manager,
      all,
    })
  }, [eventId, team_members, staff_members])

  return { stats }
}

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    eventId: string
  }
}

export default function MembersEventModal(props: Props) {
  const [openMenu, setOpenMenu] = useState(false)
  const openMenuRef = useRef<HTMLDivElement>(null)
  const [selectedUsers, setSelectedUsers] = useState<IUsersChat[]>([])
  const [selectedOption, setSelectedOption] = useState('all')
  const [filter, setFilter] = useState<{
    vertical: Vertical
    sub_vertical: string
  }>({ vertical: 'all', sub_vertical: '' })
  const [searchTerm, setSearchTerm] = useState('')
  const { createChatEvent } = useChatClient()
  const { data: team_members } = useEventRidersUsers({
    eventId: props.dataToPassOn.eventId ?? '',
    search: searchTerm,
    enabled: filter.vertical === 'all' || filter.vertical === 'exhibitors',
    select: filter.sub_vertical
      ? (data) => data.filter((m) => m.role === filter.sub_vertical)
      : undefined,
  })
  const { data: staff_members } = useEventStaffUsers({
    eventId: props.dataToPassOn.eventId ?? '',
    search: searchTerm,
    enabled: filter.vertical === 'all' || filter.vertical === 'staff',
    select: filter.sub_vertical
      ? (data) => data.filter((m) => m.role === filter.sub_vertical)
      : undefined,
  })
  const { stats } = useEventUserStats(props.dataToPassOn.eventId ?? '')

  const users =
    filter.vertical === 'all'
      ? [...team_members, ...staff_members]
      : filter.vertical === 'staff'
        ? staff_members
        : team_members

  const toggleUserSelection = (user: IUsersChat) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.some((u) => u.id === user.id)
        ? prevSelectedUsers.filter((u) => u.id !== user.id)
        : [...prevSelectedUsers, user]
    )
  }

  const selecAllUsers = (users: IUsersChat[]) => {
    setSelectedUsers(users)
  }

  const onSubmit = async (users: IUsersChat[], channelData?: ChannelData) => {
    //newChat({ users: [otherUserId, userId], dispatch })
    await createChatEvent(users, props.dataToPassOn.eventId, channelData)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openMenuRef.current && !openMenuRef.current.contains(event.target as Node)) {
        setOpenMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <MainModal
      size="md"
      title={'New Message'}
      show={props.show}
      type="MEMBERS_EVENT_MODAL"
      buttons={[
        {
          label: 'New Message',
          onClick: async () => {
            if (!selectedUsers.length) return
            onSubmit(selectedUsers, { eventId: props.dataToPassOn.eventId })
            setSelectedUsers([])
            props.handleModal(false, MODAL_CONSTS.MEMBERS_EVENT_MODAL)
          },
          textClass: !selectedUsers.length ? 'text-[#F7074F]' : 'text-white',
          bgClass: !selectedUsers.length ? 'bg-white' : 'bg-SeabiscuitMainThemeColor',
          borderClass: !selectedUsers.length
            ? 'border-solid border border-[#F7074F]'
            : 'border-transparent',
          className: 'py-3 md:my-1 w-full rounded-xl md:w-[250px]',
        },
        {
          label: 'Cancel',
          onClick: () => {
            setSelectedUsers([])
            props.handleModal(false, MODAL_CONSTS.MEMBERS_EVENT_MODAL)
          },
          className: 'py-3 md:my-1 w-full md:w-[250px]',
          bgClass: 'bg-[#1F41731A]/[.1]',
          textClass: 'text-[#1F417380]/[0.5]',
          borderClass: 'border-transparent rounded-xl',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="bg-white rounded-lg w-full space-y-4 h-full flex flex-col mt-2">
        <div className="flex flex-row space-x-2 rounded-full border border-[#D3DAEE]">
          <input
            type="text"
            placeholder="Search people..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border-none p-2 rounded bg-transparent placeholder-[#122B4680]/[0.5] ml-4 text-[#122B46] flex-1 min-w-0"
          />
          <div className="w-[1px] h-[30px] bg-[#D3DAEE] my-1 flex-none" />

          <div className="rounded bg-transparent h-full relative flex-shrink-0">
            <button
              className="flex flex-row justify-center items-center h-full"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <p className="text-center text-[14px] text-[#122B46] text-nowrap mx-4">
                {options.find((option) => option.id === selectedOption)?.name}
                <span className="text-[#3C4B71B2]/[.7]">
                  {' '}
                  • {stats?.[selectedOption as keyof typeof stats]} people
                </span>
              </p>
            </button>
            {openMenu && (
              <div
                ref={openMenuRef}
                className="flex flex-col absolute top-12 right-0 bg-white rounded-lg border border-[#D3DAEE] z-10 w-[12.5rem]"
              >
                {options
                  .sort((a, b) => a.order - b.order)
                  .map((option) => (
                    <>
                      <button
                        key={option.id}
                        className="w-full flex flex-row-reverse py-3 hover:bg-[#F1F3F8]"
                        onClick={() => {
                          setSelectedOption(option.id)
                          setFilter({
                            vertical: option.vertical,
                            sub_vertical: option.sub_vertical,
                          })
                          setOpenMenu(false)
                        }}
                      >
                        <span className="text-right text-[14px] text-[#122B46] w-full pr-2">
                          {option.name}
                          <span className="text-[#3C4B71B2]/[.7]">
                            {' '}
                            • {stats?.[option.id as keyof typeof stats]} people
                          </span>
                        </span>
                      </button>
                      {(option.id === 'staff' || option.id === 'show manager') && (
                        <div className="w-[calc(100%-1rem)] h-[1px] bg-[#D3DAEE] my-1 mx-2" />
                      )}
                    </>
                  ))}
              </div>
            )}
          </div>
        </div>

        <div className="text-sm flex flex-row justify-between text-[#122B46]">
          <div>
            {selectedUsers.length} selected
            {selectedUsers.length > 0 && (
              <button onClick={() => setSelectedUsers([])} className="ml-2 text-blue-500 underline">
                Clear all
              </button>
            )}
          </div>
          <button onClick={() => selecAllUsers(users)}>Select all</button>
        </div>

        <div className="w-full overflow-auto flex flex-1 grow flex-col">
          <div className="w-full relative">
            {users?.map((user) => (
              <button
                className="w-full"
                key={user.id + user.role}
                onClick={() => toggleUserSelection(user)}
              >
                <UserCard
                  className="h-full"
                  selected={selectedUsers.some((u) => u.id === user.id && u.role === user.role)}
                  userProfilePicture={user?.userProfilePicture}
                  name={getUserFullName(user)}
                  description={`${user.vertical[0].toUpperCase()}${user.vertical.slice(1)} • ${user.role}`}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </MainModal>
  )
}
