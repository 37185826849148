import { yupResolver } from '@hookform/resolvers/yup'
import { useClickOutside } from '@react-hookz/web'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import GlobalInputComponent from '../../../../../../../components/events/forms/detail/Components/GlobalInputComponent'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import { Hours, Minutes, Seconds } from '../../../../../../../fakeData/TimeList'
import { useOutsideClick } from '../../../../../../../hooks/useOutsideClick'
import { EventSchedulingModel } from '../../../../../../../models/operations-tab/operations-tab.model'
import { DateEnum } from '../../../data/operations.data.const'

interface IProps {
  h: string
  m: string
  s: string
  saveHandler: (h: string, m: string, s: string) => void
}
const eventSchedulingFormValidationSchema = EventSchedulingModel.validationSchema()

export const TimeSelector: React.FC<IProps> = ({ saveHandler, s, m, h }) => {
  const [incrementHours, setIncrementHours] = useState(h || Hours[0].value)
  const [incrementMinutes, setIncrementMinutes] = useState(m || Minutes[0].value)
  const [incrementSeconds, setIncrementSeconds] = useState(s || Seconds[0].value)

  const { control } = useForm({
    defaultValues: { title: '' },
    resolver: yupResolver(eventSchedulingFormValidationSchema),
    mode: 'onSubmit',
  })
  const [editMode, setEditMode] = useState(false)
  const ref = useOutsideClick(() => setEditMode(false))

  const onchangeIncrementHandler = async (value: string, type: DateEnum) => {
    let time

    try {
      switch (type) {
        case DateEnum.h:
          setIncrementHours(value)
          time = `${value}:${incrementMinutes}:${incrementSeconds}`
          break
        case DateEnum.m:
          setIncrementMinutes(value)
          time = `${incrementHours}:${value}:${incrementSeconds}`
          break
        default:
          setIncrementSeconds(value)
          time = `${incrementHours}:${incrementMinutes}:${value}`
      }

      console.log('=>(TimeSelector.tsx:37) time', time)
      // setIncrement(time)
      // updateFeesKeys({ increment: time }, selectedClass.value)
      // toastFunctions.success({ message: MESSAGES_CONST.EVENT_INCREMENT_UPDATED })
    } catch (error) {
      console.error(error)
      // toastFunctions.error({
      //     message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      // })
    }
  }

  if (!editMode) {
    return (
      <div className={'underline min-w-[100px] text-center'} onClick={() => setEditMode(true)}>
        {incrementHours}:{incrementMinutes}:{incrementSeconds}
      </div>
    )
  }
  return (
    <div className={'flex min-w-[265px] items-center text-center'}>
      <GlobalInputComponent
        type="select"
        className="flex-1 px-[0!important] h-[40px] bg-[#f0f0f0] flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:outline-0 no-underline rounded-l-[4px]"
        placeholder="00"
        defaultValue={incrementHours}
        onChange={(value) => onchangeIncrementHandler(value, DateEnum.h)}
        value={Hours}
        control={control}
        isTimeSelector={true}
        isdisableInput={true}
      />
      <GlobalInputComponent
        type="select"
        className="flex-1 px-[0!important] h-[40px] border-none flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-[#f0f0f0] !rounded-none"
        placeholder="00"
        defaultValue={incrementMinutes}
        value={Minutes}
        onChange={(value) => onchangeIncrementHandler(value, DateEnum.m)}
        isTimeSelector={true}
        isdisableInput={true}
        control={control}
      />
      <GlobalInputComponent
        type="select"
        className="flex-1 px-[0!important] h-[40px] outline-0 flex items-center text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-[#f0f0f0] rounded-r-[4px]"
        placeholder="00"
        defaultValue={incrementSeconds}
        value={Seconds}
        onChange={(value) => onchangeIncrementHandler(value, DateEnum.s)}
        isTimeSelector={true}
        isdisableInput={true}
        control={control}
      />
      <button
        className={'ms-3 text-[12px]'}
        onClick={() => {
          setEditMode(false)
        }}
      >
        Cancel
      </button>
      <button
        className={'ms-3 me-5 text-SeabiscuitMainThemeColor text-[12px]'}
        onClick={() => {
          setEditMode(false)
          saveHandler(incrementHours, incrementMinutes, incrementSeconds)
        }}
      >
        Save
      </button>
    </div>
  )
}
