import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { IoMdInformationCircleOutline } from 'react-icons/io'

import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import GlobalInputComponent from '../../../../../../../components/events/forms/detail/Components/GlobalInputComponent'

import useToasterHelper from '../../../../../../../helpers/ToasterHelper'

import { EventFeesModel } from '../../../../../../../models/event-fees/event-fees.model'
import { IBreakRegistrationByDay } from '../../../../../../../models/events/event.interface'
import { EventSchedulingModel } from '../../../../../../../models/operations-tab/operations-tab.model'
import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../../../models/registrations-by-day/registrationByDay.model'

import { Hours, Minutes, Seconds } from '../../../../../../../fakeData/TimeList'
import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'
import {
  IEventFees,
  RegistrationFeesType,
} from '../../../../../../../models/event-fees/event-fees.interface'
import FirestoreService from '../../../../../../../services/firestoreService'
import { DateEnum, MANAGE_OPERATIONS_CONSTS } from '../../../data/operations.data.const'
import { IOperationsTabs } from '../../ManageClinicNOtherOperationsTab'

import { Tooltip } from '@mui/material'
import { CONST } from '../../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../../../const/modal-const'
import TooltipIcon from '../../../../../../../helpers/TooltipIcon'
import { formatOptionLabel } from '../../../../../../../helpers/select'

interface OperationsTabsProps {
  activeTab: IOperationsTabs
  setActiveTab: (value: IOperationsTabs) => void
  selectedClass: string
  setSelectedClass: (value: string) => void
  classOptions: { label: string; value: string }[]
  dayOptions: { label: string; value: string }[]
  selectedDay: string
  setSelectedDay: (value: string) => void
  registrationFees: any
  setIncrement: (value: string) => void
  updateFeesKeys: (keys: object, uuid: string) => void
  fee: RegistrationFeesType | null
  eventId: string
  scheduleBy: 'class' | 'rider'
  registrationsGroupedByDay: Record<string, any[]>
  setRegistrationsGroupedByDay: (value: { [key: string]: IBreakRegistrationByDay[] }) => void
  setRegistrationFees: (value: IEventFees | null) => void
}

export const OperationsHeader: FC<OperationsTabsProps> = ({
  activeTab,
  setActiveTab,
  selectedClass,
  setSelectedClass,
  classOptions,
  selectedDay,
  setSelectedDay,
  dayOptions,
  registrationFees,
  updateFeesKeys,
  setIncrement,
  registrationsGroupedByDay,
  eventId,
  fee,
  scheduleBy,
  setRegistrationsGroupedByDay,
  setRegistrationFees,
}) => {
  const history = useHistory()
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

  const eventSchedulingFormValidationSchema = EventSchedulingModel.validationSchema()

  const isClassDropdown = activeTab === 'prize-money' || scheduleBy === 'class'

  const { control } = useForm({
    defaultValues: { title: '' },
    resolver: yupResolver(eventSchedulingFormValidationSchema),
    mode: 'onSubmit',
  })

  const [incrementHours, setIncrementHours] = useState(Hours[0].value)
  const [incrementMinutes, setIncrementMinutes] = useState(Minutes[0].value)
  const [incrementSeconds, setIncrementSeconds] = useState(Seconds[0].value)
  const [isPublish, setIsPublish] = useState<{
    'order-of-go': boolean
    scoring: boolean
    'prize-money': boolean
  }>({
    'order-of-go': false,
    scoring: false,
    'prize-money': false,
  })
  const [prizeMoney, setPrizeMoney] = useState(0)
  const [editLoading, setEditLoading] = useState(false)

  // This function will handle updating the local state of registrationsGroupedByDay as well as updating firestore for each rider to support the rideTime value used for scheduling riders by the day. This function is called by the OperationsHeader when the increment changes.
  const recalculateRideTimes = async (selectedDayValue: string, increment: string) => {
    try {
      let registrationsForDay = registrationsGroupedByDay[selectedDayValue] || []

      registrationsForDay = registrationsForDay.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))

      // 🔹 Find the earliest existing rideTime (excluding breaks)
      let firstRider = registrationsForDay.find(
        (reg) => 'rideTime' in reg && typeof reg.rideTime === 'string'
      )

      let startTime = firstRider?.rideTime || '08:00:00' // Default if no valid rideTime found

      // Parse start time and increment
      let [startHours, startMinutes, startSeconds] = startTime.split(':').map(Number)
      let [incHours, incMinutes, incSeconds] = increment.split(':').map(Number)

      let currentTime = new Date(1970, 0, 1, startHours, startMinutes, startSeconds)

      // Assign new ride times
      const updatedRegistrations = registrationsForDay.map((registration) => {
        if ('isBreakDrag' in registration && registration.isBreakDrag) {
          // 🔹 Handle breaks: Adjust the schedule but don't assign rideTime
          let [breakHours, breakMinutes, breakSeconds] =
            'time' in registration && typeof registration.time === 'string'
              ? registration.time.split(':').map(Number)
              : [0, 0, 0] // Default values to prevent errors

          currentTime.setHours(currentTime.getHours() + breakHours)
          currentTime.setMinutes(currentTime.getMinutes() + breakMinutes)
          currentTime.setSeconds(currentTime.getSeconds() + breakSeconds)

          return { ...registration } // No rideTime for breaks
        }

        let rideTime = new Date(currentTime) // Copy current time
        let formattedRideTime = rideTime.toTimeString().substring(0, 8)

        // Update time for next rider
        currentTime.setMinutes(currentTime.getMinutes() + incHours * 60 + incMinutes)
        currentTime.setSeconds(currentTime.getSeconds() + incSeconds)

        return { ...registration, rideTime: formattedRideTime }
      })

      // Filter out the break items from Firestore updates
      const registrationsToUpdate = updatedRegistrations.filter(
        (reg) => !('isBreakDrag' in reg && reg.isBreakDrag)
      )

      // Update Firestore only for actual registrations (not breaks)
      const updatePromises = registrationsToUpdate.map(async (registration) => {
        try {
          const updatedData = new RegistrationByDayModel({
            ...(registration as IRegistrationByDayInterface),
          })
          await FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
            registration.id,
            updatedData.toFirestore()
          )
        } catch (error) {
          console.error(`error`, error)
        }
      })

      await Promise.all(updatePromises)

      // Update local state
      setRegistrationsGroupedByDay({
        ...registrationsGroupedByDay,
        [selectedDayValue]: updatedRegistrations,
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  const batchUpdateFeesKeys = async (updates: {
    [uuid: string]: Partial<RegistrationFeesType>
  }) => {
    let registrationFees_ = cloneDeep(registrationFees)

    // Ensure registrationFees_ has a valid array
    if (!registrationFees_ || !Array.isArray(registrationFees_.registrationFees)) {
      console.error('Error: `registrationFees_` is missing or not an array', registrationFees_)
      return
    }

    Object.entries(updates).forEach(([uuid, changes]) => {
      const indexToUpdate = registrationFees_?.registrationFees?.findIndex(
        (item: RegistrationFeesType) => item.uuid === uuid
      )

      if (indexToUpdate === -1) {
        return // Skip this iteration if no match
      }

      // Apply changes
      registrationFees_!.registrationFees![indexToUpdate!] = {
        ...registrationFees_!.registrationFees![indexToUpdate!],
        ...changes,
      }
    })

    setRegistrationFees(registrationFees_)

    const docToStore = new EventFeesModel(registrationFees_).toFirestore()

    await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, eventId, {
      EventFees: docToStore,
    })

    await FirestoreService.updateItem(COLLECTIONS.EVENT_FEES.NAME, eventId, {
      ...docToStore,
      id: eventId,
    })
  }

  const updateRiderGroupPublishStatus = async (groupKey: string, publishOrder: boolean) => {
    try {
      // Fetch the current event review publish document
      const eventReviewPublishSnap = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      const currentData = eventReviewPublishSnap.data() || {}

      // Retrieve or initialize riderGroupPublish
      const currentRiderGroupPublish = currentData.RiderGroupPublish || {}

      //  Preserve existing data - updating only the selected groupKey
      currentRiderGroupPublish[groupKey] = {
        ...currentRiderGroupPublish[groupKey], // Keep existing data for the group
        publishOrder, // Update the publish order status
      }

      await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, eventId, {
        ...currentData,
        RiderGroupPublish: currentRiderGroupPublish,
      })

      // Update local state to reflect the change, add flag for rider mode order publishing
      setRegistrationsGroupedByDay({
        ...registrationsGroupedByDay,
        [groupKey]: registrationsGroupedByDay[groupKey].map((registration) => ({
          ...registration,
          isPublishedRiderOrder: publishOrder,
        })),
      })
    } catch (error) {
      console.error('Error updating rider group publish status:', error)
    }
  }

  const onSavePublish = async (currentIsPublish: { [p: string]: boolean }) => {
    try {
      if (scheduleBy === 'class') {
        updateFeesKeys(
          {
            publishOrder: currentIsPublish['order-of-go'],
            publishScore: currentIsPublish['scoring'],
            publishPrize: currentIsPublish['prize-money'],
          },
          selectedClass
        )
      } else if (scheduleBy === 'rider' && selectedDay) {
        const registrationsForDay = registrationsGroupedByDay[selectedDay] || []
        // set of classes (fees) that are included in the current registrations for group key
        const uniqueClasses = new Set<string>()

        // aggregate the class uuid's into the set for an update
        registrationsForDay.forEach((riderEntry) => {
          if (riderEntry.fee?.uuid) {
            uniqueClasses.add(riderEntry.fee.uuid)
          }
        })

        const updatesObject: { [uuid: string]: Partial<RegistrationFeesType> } = {}

        // build an object with key of the class uuid and value of the updated field for that class object, in this case it is the publishScore boolean
        Array.from(uniqueClasses).forEach((classId) => {
          updatesObject[classId] = { publishScore: currentIsPublish['scoring'] }
        })

        await batchUpdateFeesKeys(updatesObject)

        await updateRiderGroupPublishStatus(selectedDay, currentIsPublish['order-of-go'])

        await fetchPublishStatus()
      }

      toastFunctions.success({ message: MESSAGES_CONST.VISIBILITY_UPDATED })
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.EVENT_INCREMENT_NOT_UPDATED,
      })
    }
  }

  const onChangeMoneyPrize = (event: any) => {
    try {
      updateFeesKeys({ prizeMoney: event.target.value }, selectedClass)
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.EVENT_INCREMENT_NOT_UPDATED,
      })
    }
  }

  const onchangeIncrementHandler = async (value: string, type: DateEnum) => {
    let time

    try {
      switch (type) {
        case DateEnum.h:
          setIncrementHours(value)
          time = `${value}:${incrementMinutes}:${incrementSeconds}`
          break
        case DateEnum.m:
          setIncrementMinutes(value)
          time = `${incrementHours}:${value}:${incrementSeconds}`
          break
        default:
          setIncrementSeconds(value)
          time = `${incrementHours}:${incrementMinutes}:${value}`
      }

      setIncrement(time)

      if (scheduleBy === 'class') {
        updateFeesKeys({ increment: time }, selectedClass)
        toastFunctions.success({ message: MESSAGES_CONST.EVENT_INCREMENT_UPDATED })
      } else if (scheduleBy === 'rider' && selectedDay) {
        handleModalContext?.handleModal(true, MODAL_CONSTS.RESCHEDULE_RIDE_TIMES, {
          dayKey: selectedDay,
          currentRegistrations: registrationsGroupedByDay[selectedDay],
          increment: time,
          recalculateRideTimes,
        })
      }
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const fetchPublishStatus = useCallback(async () => {
    try {
      const eventReviewPublishSnap = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      const publishData = eventReviewPublishSnap.data() || {}

      //setting local state of publish keys for rider scheduling mode

      if (scheduleBy === 'rider' && selectedDay) {
        const riderGroupPublish = publishData?.RiderGroupPublish || {}

        const publishedRegistrationsFees = publishData?.EventFees?.registrationFees

        const registrationsForDay = registrationsGroupedByDay[selectedDay] || []
        const uniqueClasses = new Set<string>()

        // getting all unique classes (fee) in set
        registrationsForDay.forEach((riderEntry) => {
          if (riderEntry.fee?.uuid) {
            uniqueClasses.add(riderEntry.fee.uuid)
          }
        })

        // loop through unique classes in the data set of registrations by the group key for rider scheduling, find the unique classes in the published data from firestore and return to the arr their publishScore status
        const classPublishStatus = Array.from(uniqueClasses).map((classId) => {
          const matchedClass = publishedRegistrationsFees.find(
            (classFee: IRegistrationByDayInterface) => classFee.uuid === classId
          )
          return matchedClass ? matchedClass.publishScore : false
        })

        // variable that checks if there is any false items in the arr above where we are checking each unique fee to see if the publish status is false - if any are false then not all classes are published and need to show this on the toggle
        const isAnyClassUnpublished = classPublishStatus.includes(false)

        setIsPublish({
          'order-of-go': riderGroupPublish[selectedDay]?.publishOrder ?? false,
          scoring: !isAnyClassUnpublished,
          'prize-money': false,
        })
      }
    } catch (error) {
      console.error('Error fetching publish status:', error)
    }
  }, [
    eventId,
    scheduleBy,
    selectedDay,
    registrationsGroupedByDay,
    COLLECTIONS.EVENT_REVIEW_PUBLISH,
  ])

  useEffect(() => {
    if (selectedClass && registrationFees && scheduleBy === 'class') {
      let registrationFees_
      const indexToUpdateFees = registrationFees.registrationFees.findIndex(
        (item: any) => item.uuid === selectedClass
      )

      if (indexToUpdateFees !== -1) {
        registrationFees_ = registrationFees.registrationFees[indexToUpdateFees]
      }

      const incrementTime =
        registrationFees_.increment.length === 8 ? registrationFees_.increment : '00:00:00'

      const [incrementBaseHours, incrementBaseMinutes, incrementBaseSeconds] =
        incrementTime.split(/[:\s]/)
      setIncrementHours(
        incrementBaseHours?.length === 2 && incrementBaseHours > 0 ? incrementBaseHours : '00'
      )
      setIncrementMinutes(
        incrementBaseMinutes?.length === 2 && incrementBaseMinutes > 0 ? incrementBaseMinutes : '00'
      )
      setIncrementSeconds(
        incrementBaseSeconds?.length === 2 && incrementBaseSeconds > 0 ? incrementBaseSeconds : '00'
      )

      setIncrement(incrementTime)

      setIsPublish({
        'order-of-go': registrationFees_[MANAGE_OPERATIONS_CONSTS.PUBLISH_KEYS['order-of-go']],
        scoring: registrationFees_[MANAGE_OPERATIONS_CONSTS.PUBLISH_KEYS['scoring']],
        'prize-money': registrationFees_[MANAGE_OPERATIONS_CONSTS.PUBLISH_KEYS['prize-money']],
      })
    }
  }, [registrationFees, selectedClass, activeTab])

  useEffect(() => {
    if (registrationFees)
      setPrizeMoney(
        [...registrationFees?.registrationFees].find(
          (registrationFee: RegistrationFeesType) => registrationFee.uuid === selectedClass
        )?.prizeMoney
      )
  }, [registrationFees, selectedClass])

  useEffect(() => {
    // If the tab is prize money OR the scheduling mode is class, treat it as class mode for dropdown logic.
    const isClassMode =
      activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value || scheduleBy === 'class'

    // If we are in class mode and we have class options but no selected class, pick the first class.
    if (isClassMode && classOptions.length > 0 && !selectedClass) {
      setSelectedClass(classOptions[0].value)
    }
    // if the user is in rider scheduling mode and we have day options but no selected day, pick the first day.
    else if (scheduleBy === 'rider' && dayOptions.length > 0 && !selectedDay) {
      setSelectedDay(dayOptions[0].value)
    }
  }, [activeTab, scheduleBy, dayOptions, classOptions, selectedDay, selectedClass])

  useEffect(() => {
    if (eventId && scheduleBy === 'rider') fetchPublishStatus().then()
  }, [eventId, scheduleBy, selectedDay, activeTab])

  return (
    <div className="flex flex-wrap gap-2 items-center justify-between">
      <div className="flex flex-wrap min-h-[42px] items-center">
        {MANAGE_OPERATIONS_CONSTS.TABS.map((currentItem: any, index: number) => {
          return (
            <div
              key={`${currentItem}b${index}`}
              onClick={() => setActiveTab(currentItem.id)}
              className={`py-2 px-4 text-[14px] cursor-pointer mr-2 rounded-xl ${
                activeTab === currentItem.id
                  ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
                  : 'text-SeabiscuitDark200ThemeColor'
              }`}
            >
              {currentItem.tab}
            </div>
          )
        })}
      </div>
      <div className="relative z-20 flex gap-2  ml-auto">
        {activeTab !== 'judging' && (
          <Controller
            name="title"
            control={control}
            render={() => {
              const dropDownOptions = isClassDropdown ? classOptions : dayOptions

              const dropdownPlaceholder = isClassDropdown ? 'Select Class' : 'Select Day'
              return (
                <Select
                  isClearable={false}
                  isSearchable={true}
                  placeholder={dropdownPlaceholder}
                  menuShouldScrollIntoView={true}
                  formatOptionLabel={formatOptionLabel}
                  value={
                    isClassDropdown
                      ? dropDownOptions.find((option) => option.value === selectedClass)
                      : dropDownOptions.find((option) => option.value === selectedDay)
                  }
                  options={dropDownOptions}
                  onChange={(newValue) => {
                    if (isClassDropdown) {
                      setSelectedClass(newValue?.value ?? '')
                    } else {
                      setSelectedDay(newValue?.value ?? '')
                    }
                  }}
                  className={clsx('min-w-[22vw]')}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                      border: '1px solid #d1d5db',
                      boxShadow: 'none',
                      height: '42px', //42 px to match the Increment components 40px height and 2px parent padding
                      '&:hover': { borderColor: '#9ca3af' }, // Slightly darker on hover
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#4B5563',
                      '& svg': {
                        fill: '#4B5563',
                      },
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px', //  border radius on dropdown
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? '#4b5563' // Keep the selected option dark
                        : state.isFocused
                          ? '#e5e7eb' // Light gray for hovered but non-selected options
                          : '#fff', // Default background for other options
                      color: state.isSelected ? '#fff' : '#374151', // Ensure selected option remains readable
                      '&:hover': {
                        backgroundColor: state.isSelected ? '#4b5563' : '#e5e7eb', // Prevent excessive gray on selected hover
                      },
                    }),
                  }}
                />
              )
            }}
          />
        )}
        {activeTab === 'order-of-go' && (selectedClass || selectedDay) && (
          <>
            {scheduleBy === 'class' && selectedClass && (
              <Controller
                name="title"
                control={control}
                render={() => (
                  <div className="flex gap-2 start-time-wrapper items-center border border-solid border-[#ccc] rounded-lg px-2">
                    <p>Class Start:</p>
                    <span>{`${fee?.startTimeHours}:${fee?.startTimeMinutes} ${fee?.startTimeFormat}`}</span>
                    <button
                      type="button"
                      className="w-4 transition-all hover:opacity-70"
                      onClick={async () => {
                        setEditLoading(true)
                        await FirestoreService.updateItem(
                          CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
                          eventId,
                          {
                            eventFormState: CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE,
                          }
                        )
                        history.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${eventId}`)
                        setEditLoading(false)
                      }}
                    >
                      {editLoading ? (
                        <img
                          className="w-full animate-spin"
                          src="/assets/og_icons/Synchronize.svg"
                          alt=""
                        />
                      ) : (
                        <img className="w-full" src="/assets/cp_icons/edit.svg" alt="" />
                      )}
                    </button>
                  </div>
                )}
              />
            )}

            {(scheduleBy === 'class' && selectedClass) ||
            (scheduleBy === 'rider' && selectedDay) ? (
              <Controller
                name="title"
                control={control}
                render={() => (
                  <div className="flex start-time-wrapper items-center border border-solid border-[#ccc] rounded-lg px-2">
                    <p className={'mr-2'}>Increment:</p>
                    <GlobalInputComponent
                      type="select"
                      className="flex-1 px-[0!important] h-[40px] flex items-center border-none outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-white"
                      placeholder="00"
                      defaultValue={incrementHours}
                      onChange={(value) => onchangeIncrementHandler(value, DateEnum.h)}
                      value={Hours}
                      control={control}
                      isTimeSelector={true}
                      isdisableInput={true}
                    />
                    :
                    <GlobalInputComponent
                      type="select"
                      className="flex-1 px-[0!important] h-[40px] border-none flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-white !rounded-none"
                      placeholder="00"
                      defaultValue={incrementMinutes}
                      value={Minutes}
                      onChange={(value) => onchangeIncrementHandler(value, DateEnum.m)}
                      isTimeSelector={true}
                      isdisableInput={true}
                      control={control}
                    />
                    :
                    <GlobalInputComponent
                      type="select"
                      className="flex-1 px-[0!important] h-[40px] outline-0 flex items-center text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-white !rounded-r-[4px] !rounded-l-non"
                      placeholder="00"
                      defaultValue={incrementSeconds}
                      value={Seconds}
                      onChange={(value) => onchangeIncrementHandler(value, DateEnum.s)}
                      isTimeSelector={true}
                      isdisableInput={true}
                      control={control}
                    />
                  </div>
                )}
              />
            ) : null}
          </>
        )}
        {activeTab === 'prize-money' && (
          <div className="flex gap-2 items-center rounded-md border border-solid border-SeabiscuitGray500ThemeColor px-2.5 py-0.5">
            <Tooltip
              title={
                <h1 className="tooltip_title max-w-[100px] text-center py-3">
                  Enter the total amount you wish to award in this class.
                  <br />
                  <br />
                  Class entry fees collected:
                  <br />
                  <b className={'text-[18px]'}>${prizeMoney || '0'}</b>
                </h1>
              }
              placement="top"
              arrow
            >
              <button>
                <IoMdInformationCircleOutline />
                {/* <img className="w-3 h-3 ml-1" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
              </button>
            </Tooltip>
            Prize money:
            <input
              value={prizeMoney > 0 ? prizeMoney : ''}
              type="number"
              className="p-0 ml-2 w-[100px] outline-0 border-0 text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all"
              placeholder="$0"
              onChange={(event) => setPrizeMoney(Number(event.target.value))}
              onBlur={onChangeMoneyPrize}
            />
          </div>
        )}
        {(selectedClass || selectedDay) && activeTab !== 'judging' && (
          <button
            type="button"
            className={clsx(
              'p-1.5 min-w-[104px] text-base rounded-lg hover:text-SeabiscuitMainThemeColor hover:bg-[#fef5f6] transition-all bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
            )}
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.PUBLISH_OPERATIONS_MODAL, {
                onSavePublish,
                isPublish,
                selectedDay,
                activeTab,
                scheduleBy,
                selectedClass,
              })
            }}
          >
            Publish
          </button>
        )}
      </div>
    </div>
  )
}
