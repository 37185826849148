import { useEffect, useRef } from 'react'

import { cloneDeep } from 'lodash'

// Redux
import { IEventsRegisterData, selectRegisterEventData } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectHolder, setUsersHolder } from '../../../../store/holders/holdersSlice'
import { holdersSliceInitialState } from '../../../../store/holders/holdersSlice.data'
import { selectUserId } from '../../../../store/user/userSlice'

// Custom components
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import SelectedEventGrandTotal from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventGrandTotal'

import { getUserFullName } from '../../../../helpers/helpers'
import { getCategorizedEventDetails, getConvertedData } from '../../../../models/interface.helper'

import { ITeamMember, IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

import FirestoreService from '../../../../services/firestoreService'

// Constants
import { CONST } from '../../../../const/const'

const USERS_COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS

// Types
type Props = {
  title?: string
  step?: number
  description?: string
  activeTab: {
    tab: string
    step: number
  }
  registerFormData: IEventsRegisterData
  isManage?: boolean
  manageInfo: IManageInfo
}

type IPayer = { teamMember: ITeamMember; payment: number; unit_name: string }
export type IHandlePaymentDivided = (payer: IPayer) => void

const CompetitorEventRegisterPayTab = (props: Props) => {
  // Hooks and vars

  const holder = useAppSelector(selectHolder)
  const userId = useAppSelector(selectUserId)
  const eventData = useAppSelector(selectRegisterEventData)

  const dispatch = useAppDispatch()

  const holderRef = useRef(holdersSliceInitialState)

  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  useEffect(() => {
    const categorizedEventDetails = getCategorizedEventDetails(eventData.events)
    const members = categorizedEventDetails.members.filter((member) => member.memberId !== userId)
    getSyncedMembers(members).then()
  }, [eventData.events])

  //Function
  async function getSyncedMembers(members: ITeamMember[]) {
    let members_ = cloneDeep(members)

    let memberIds: string[] = []
    let users: IUserInterface[] = []
    let user: IUserInterface | null = null
    let alreadyFetchedUsers = holderRef.current.usersHolder.data

    // Remove already fetched users
    members.forEach((currentMember) => {
      if (!alreadyFetchedUsers.find((currentUser) => currentUser.id === currentMember.memberId))
        if (currentMember.memberId) memberIds.push(currentMember.memberId)
    })

    // Remove duplicate ids
    const uniqueMemberIds = [...new Set(memberIds)]

    const userSnaps = await FirestoreService.getItemsUsingIds(
      USERS_COLLECTIONS.NAME,
      uniqueMemberIds
    )

    userSnaps.forEach((currUserDoc) => {
      users.push(getConvertedData(UserModel.fromFirestoreDoc(currUserDoc).toObject()))
    })

    alreadyFetchedUsers = [...alreadyFetchedUsers, ...users]

    members_ = members_.map((currMember) => {
      user = alreadyFetchedUsers.find((currUser) => currUser.id === currMember.memberId) ?? null
      if (user)
        return {
          ...currMember,
          memberDob: user.userDOB,
          memberName: getUserFullName(user),
        }
      else return currMember
    })

    if (users.length) dispatch(setUsersHolder(alreadyFetchedUsers))

    return members_
  }

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <SelectedEventGrandTotal isManage={props.isManage} manageInfo={props.manageInfo} />
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterPayTab
