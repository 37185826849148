import React, { useEffect, useState } from 'react'

// Third party
import { where } from 'firebase/firestore'

// Components
import ViewsLoader from '../../../components/loader/ViewsLoader'
import CompetitorAllBills from './tabs/CompetitorAllBills'

// Redux
import { useAppSelector } from '../../../store/hooks'
import { selectUserId } from '../../../store/user/userSlice'

import { getConvertedData } from '../../../models/interface.helper'

import FirestoreService from '../../../services/firestoreService'

import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'

// Constants
import { CONST } from '../../../const/const'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { RecipientModel } from '../../../models/recipients/recipients'
import { PAYMENT_STATUS } from '../../../types/payment'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

// Types
export interface IDataTableItem extends IRecipientInterface {
  eventLogo: string
}

type CompetitorBillDisplayTabsProps = {
  selectedTab?: 'all' | 'unpaid' | 'paid'
  handleModal?: any
  sortBy: string
}

const CompetitorBillDisplayTabs = ({ sortBy, selectedTab }: CompetitorBillDisplayTabsProps) => {
  const userId = useAppSelector(selectUserId)

  const [loading, setLoading] = useState(false)
  const [paidBills, setPaidBills] = useState<IDataTableItem[]>([])
  const [unPaidBills, setUnPaidBills] = useState<IDataTableItem[]>([])
  const [filteredBills, setFilteredBills] = useState<IDataTableItem[] | null>(null)

  const getData = async () => {
    setLoading(true)

    let registrations: TEventRegisteredUsers[] = []
    let paidBillsDocs: IDataTableItem[] = []
    let unpaidBillsDocs: IDataTableItem[] = []
    let recipients: IRecipientInterface[] = []

    const eventRegisteredSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.IS_REGISTERED.KEY, '==', true),
      ]
    )
    eventRegisteredSnaps.forEach((currDoc) => {
      const current = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      )
      if (current.eventId) {
        registrations.push(current)
      }
    })

    const recipientsSnaps = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
      where(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
        '==',
        userId
      ),
    ])

    recipientsSnaps.forEach((currDoc) => {
      const current = getConvertedData(RecipientModel.fromFirestoreDoc(currDoc).toObject())
      recipients.push(current)
    })

    recipients.forEach((recipient) => {
      const registration = registrations.find((reg) => reg.eventId === recipient.eventId)

      if (recipient.paymentStatus === PAYMENT_STATUS.PAID) {
        unpaidBillsDocs.push({
          ...recipient,
          eventLogo: registration?.eventLogo ?? '',
        })
      } else {
        paidBillsDocs.push({
          ...recipient,
          eventLogo: registration?.eventLogo ?? '',
        })
      }
    })

    setPaidBills(paidBillsDocs)
    setUnPaidBills(unpaidBillsDocs)

    setLoading(false)
  }

  useEffect(() => {
    if (userId) getData().then()
  }, [userId])

  useEffect(() => {
    let bills: IDataTableItem[] | null = null
    if (selectedTab === 'all') {
      bills = [...paidBills, ...unPaidBills]
    } else if (selectedTab === 'unpaid') {
      bills = unPaidBills
    } else if (selectedTab === 'paid') {
      bills = paidBills
    }

    if (bills) {
      if (sortBy === 'Newest') {
        bills = bills.sort(
          (a, b) =>
            new Date(b.registrationDate as Date).getTime() -
            new Date(a.registrationDate as Date).getTime()
        )
      }
      if (sortBy === 'Oldest') {
        bills = bills.sort(
          (a, b) =>
            new Date(a.registrationDate as Date).getTime() -
            new Date(b.registrationDate as Date).getTime()
        )
      }
    }

    setFilteredBills(bills)
  }, [unPaidBills, paidBills, selectedTab, sortBy])

  return (
    <div className="overflow-auto max-h-[calc(100%-270px)]">
      {loading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F70763"
          />
        </div>
      ) : (
        <CompetitorAllBills bills={filteredBills} selectedTab={selectedTab} />
      )}
    </div>
  )
}

export default CompetitorBillDisplayTabs
