import { where } from 'firebase/firestore'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { CONST } from '../../../../const/const'
import { getConvertedData } from '../../../../models/interface.helper'
import { ITicketBuyer } from '../../../../models/ticket-buyers/ticket-buyers.interface'
import { TicketBuyersModel } from '../../../../models/ticket-buyers/ticket-buyers.model'
import FirestoreService from '../../../../services/firestoreService'
import NoDataAvailable from '../../../myEvent/component/NoDataAvailable'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { getFloatPrice } from '../../../../helpers/price'

const TICKET_BUYERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.TICKET_BUYERS

const TicketBuyersTab = ({ eventId }: { eventId: string | null }) => {
  const toastFunctions = useToasterHelper()

  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [ticketBuyers, setTicketBuyers] = useState<ITicketBuyer[]>([])

  const handleModalContext = useContext(HandleModalContext)

  const Cols = [
    {
      name: 'Ticket buyer',
      draggable: false,
      cell: (row: ITicketBuyer) => (
        <>
          {row?.isPlatformUser ? (
            <Link
              to={`${CONST.ROUTES.USER_PROFILE.URL}/${row.userId}`}
              className="flex gap-5 items-center"
            >
              <img
                src={row.ticketBuyerProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                alt="icon"
                className="rounded-full w-[45px] h-[45px] object-cover"
              />
              <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold capitalize">
                {row?.ticketBuyerName ?? 'N/A'}
              </span>
            </Link>
          ) : (
            <div className="flex items-center gap-5">
              <img
                src={row.ticketBuyerProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                alt="icon"
                className="rounded-full w-[45px] h-[45px] object-cover"
              />
              <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold capitalize">
                {row?.ticketBuyerName ?? 'N/A'}
              </span>
            </div>
          )}
        </>
      ),
      width: 'auto',
    },
    {
      name: 'Tickets',
      draggable: false,
      cell: (row: ITicketBuyer) => (
        <div
          className="text-SeabiscuitDark200ThemeColor cursor-pointer"
          onClick={() =>
            handleModalContext?.handleModal?.(true, MODAL_CONSTS.TICKET_BUYER_DETAILS, row)
          }
        >
          <div className="underline pointer-events-none flex gap-2.5 items-center">
            <img
              src={'assets/og_icons/singleTicket.svg'}
              alt="icon"
              className="w-[20px] h-[20px]"
            />
            {`${row.ticketBoughtCount} tickets`}
          </div>
        </div>
      ),
      width: '13%',
    },
    {
      name: 'Status',
      draggable: false,
      cell: (row: ITicketBuyer) => {
        console.log(row)
        return (
          <>
            <div className="flex gap-2.5 items-center">
              <img src={'assets/og_icons/Info.svg'} alt="icon" className="w-[20px] h-[20px]" />
              <div className="flex flex-col text-SeabiscuitDark200ThemeColor">
                <div>{row.usedTicketsCount} used</div>
                <div>{row.unusedTicketCount} available</div>
              </div>
            </div>
          </>
        )
      },
      width: '13%',
    },
    {
      name: 'Paid',
      draggable: false,
      cell: (row: ITicketBuyer) => (
        <div className="flex gap-2.5 items-center">
          <img src={'assets/og_icons/MagneticCard.svg'} alt="icon" className="w-[22px] h-[22px]" />
          <p className="text-SeabiscuitDark200ThemeColor capitalize font-normal">
            {getFloatPrice(`${row?.amountPaid || '$0'}`)}
          </p>
        </div>
      ),
      width: '13%',
    },
    {
      name: 'Purchase date',
      draggable: false,
      cell: (row: ITicketBuyer) => (
        <div className="flex gap-2.5 items-center">
          <img
            src={'assets/og_icons/Tear-Off Calendar.svg'}
            alt="icon"
            className="w-[22px] h-[22px]"
          />
          <p className="text-SeabiscuitDark200ThemeColor capitalize font-normal">
            {row?.purchasedOn ? moment(row?.purchasedOn).format('MMM DD, YYYY') : 'N/A'}
          </p>
        </div>
      ),
      width: '13%',
    },
    {
      name: 'Contact',
      draggable: false,
      cell: (row: ITicketBuyer) => (
        <div
          className="w-full flex cursor-pointer"
          onClick={() => {
            handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
              emailId: row.ticketBuyerEmailId,
              user_name: row.ticketBuyerName,
              profilePicture: row.ticketBuyerProfilePicture,
            })
          }}
        >
          <p className="bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor">
            Message
          </p>
        </div>
      ),
      width: '150px',
    },
  ]

  const handleTicketHolderData = async (eventId: string, ticketBuyers: ITicketBuyer[] = []) => {
    if (!ticketBuyers.length) {
      setLoading(true)
    }

    try {
      const ORDER_DIRECTION = 'asc'
      const ORDER_BY_FIELD = TICKET_BUYERS.FIELDS.CREATED.KEY
      let ticketBuyers_: ITicketBuyer[] = []

      const ticketBuyersSnapshot = await FirestoreService.filterItems(
        TICKET_BUYERS.NAME,
        [where(TICKET_BUYERS.FIELDS.EVENT_DOC_ID.KEY, '==', eventId)],
        20,
        ORDER_BY_FIELD,
        ORDER_DIRECTION,
        ticketBuyers?.at(-1)?.id ?? null
      )

      if (!ticketBuyersSnapshot.size) {
        setHasMore(false)
      } else {
        setHasMore(true)

        ticketBuyersSnapshot?.forEach((currDoc) => {
          let main_data = TicketBuyersModel.fromFirestoreDoc(currDoc).toObject()
          ticketBuyers_.push(getConvertedData(main_data))
        })

        setTicketBuyers([...ticketBuyers, ...ticketBuyers_])
      }
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (eventId) {
      handleTicketHolderData(eventId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const fetchMore = () => {
    if (eventId) {
      handleTicketHolderData(eventId, ticketBuyers)
    } else {
      console.log('eventId is empty')
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  return (
    <InfiniteScrollDataTable
      fetchMore={fetchMore}
      columns={Cols}
      hasMore={hasMore}
      fixedHeader={true}
      className="h-[60vh] min-h-[500px] transition-all"
      data={ticketBuyers}
      progressPending={loading}
      progressComponent={<ViewsLoader size="xl" color="red" />}
      noDataComponent={
        <NoDataAvailable
          imgSrc={'assets/placeholders/NoEventsFoundProfile.svg'}
          text="No tickets exists"
          bottomText={'See More >'}
          textColor="text-SeabiscuitDark200ThemeColor"
        />
      }
      noHeader={true}
    />
  )
}

export default TicketBuyersTab
