import React from 'react'

import { IconClock } from '../../../../../../../../components/icons/IconClock'
import RideTimeSelector from '../RideTimeSelector'
import { TimeSelector } from '../TimeSelector'

import { generateName } from '../../../../../../../../helpers/helpers'
import { convertTime } from '../../../../../../../../helpers/time'

import { RegistrationFeesType } from '../../../../../../../../models/event-fees/event-fees.interface'
import { IBreakRegistrationByDay } from '../../../../../../../../models/events/event.interface'
import { RiderTeamMemberModel } from '../../../../../../../../models/rider-team-member/riderTeamMember.model'

import { IMAGE_CONSTS } from '../../../../../../../../const/image-const'

const BREAK = 'isBreakDrag'

const getInvalidRiders = (riders: IBreakRegistrationByDay[]) => {
  const invalidRiders = new Map<string, { outOfOrder: boolean; duplicate: boolean }>()
  const rideTimeCounts = new Map<string, number>()

  riders.forEach((rider, index) => {
    if (!rider.rideTime) return

    let [riderHours, riderMinutes, riderSeconds] = rider.rideTime.split(':').map(Number)
    let riderTotalSeconds = riderHours * 3600 + riderMinutes * 60 + riderSeconds

    // track ride time occurrences
    const rideTimeKey = rider.rideTime
    rideTimeCounts.set(rideTimeKey, (rideTimeCounts.get(rideTimeKey) || 0) + 1)

    let outOfOrder = false
    let duplicate = false

    //use computed ride time for the curr index to total seconds and check left & right neighbors with arr out of bounds checks
    if (index > 0) {
      let prevRider = riders[index - 1]
      if (prevRider.rideTime) {
        let [prevHours, prevMinutes, prevSeconds] = prevRider.rideTime.split(':').map(Number)
        let prevTotalSeconds = prevHours * 3600 + prevMinutes * 60 + prevSeconds

        if (riderTotalSeconds < prevTotalSeconds) {
          outOfOrder = true
        }
      }
    }

    // make sure not to go out of bounds for next rider check
    if (index < riders.length - 1) {
      let nextRider = riders[index + 1]
      if (nextRider.rideTime) {
        let [nextHours, nextMinutes, nextSeconds] = nextRider.rideTime.split(':').map(Number)
        let nextTotalSeconds = nextHours * 3600 + nextMinutes * 60 + nextSeconds

        if (riderTotalSeconds > nextTotalSeconds) {
          outOfOrder = true
        }
      }
    }

    // check if ride time key exists more than once, if so its a duplicate
    if (rideTimeCounts.get(rideTimeKey)! > 1) {
      duplicate = true
    }

    // only add riders with issues
    if (outOfOrder || duplicate) {
      invalidRiders.set(rider.id, { outOfOrder, duplicate })
    }
  })
  return invalidRiders
}

export const getColumns = ({
  eventTrainers,
  saveHandlerBreak,
  saveHandlerRider,
  fee,
  increment,
  type,
  registrationsToDisplay,
}: {
  eventTrainers: RiderTeamMemberModel[]
  saveHandlerBreak: (h: string, m: string, a: string, id: string) => void
  fee?: RegistrationFeesType | null
  saveHandlerRider?: (value: string, id: string) => void
  registrationsToDisplay?: IBreakRegistrationByDay[]
  increment: string
  type: 'class' | 'rider'
}) => {
  return [
    {
      accessorFn: (row: IBreakRegistrationByDay) => {
        if (row.isBreakDrag) return BREAK
        return `${row.riderName != null ? row.riderName : ''}+${row?.riderProfilePicture != null ? row?.riderProfilePicture : ''}`
      },
      cell: (info: any) => {
        if (info.getValue() === BREAK) {
          return (
            <div className={'flex items-center gap-4'}>
              <span
                className={
                  'w-[44px] h-[44px] rounded-full flex items-center justify-center bg-[#F6F7FB]'
                }
              >
                <IconClock />
              </span>

              <span className="text-SeabiscuitDark200ThemeColor font-medium text-base flex items-center">
                Break{' '}
                <span className="text-SeabiscuitDark200ThemeColor/80 text-sm mx-1 inline-flex items-center">
                  /
                </span>{' '}
                Drag
              </span>
            </div>
          )
        }
        return (
          <div className="flex items-center gap-4">
            <span className="w-11 h-11 rounded-full block shrink-0 my-2 overflow-hidden">
              <img
                src={
                  info.getValue().split('+')[1].toLowerCase() !== ''
                    ? info.getValue().split('+')[1]
                    : IMAGE_CONSTS.PLACEHOLDERS.USER
                }
                alt="icons"
                className="object-cover w-full h-full rounded-full"
                onError={(e) =>
                  ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                    info.getValue() ?? ''
                  )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            </span>
            <div className="flex flex-col">
              <span className="text-SeabiscuitDark200ThemeColor text-base">
                {info.getValue().split('+')[0]}
              </span>
              <span className="text-SeabiscuitDark200ThemeColor/60 text-nr flex items-center">
                {Number(info.row.original.backNumber) > 0 ? (
                  <>{info.row.original.backNumber}</>
                ) : (
                  <span className=" inline-flex items-center text-SeabiscuitDark200ThemeColor/60 ">
                    No back number
                  </span>
                )}
              </span>
            </div>
          </div>
        )
      },
      id: 'rider',
      header: () => (
        <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
          <span className="whitespace-nowrap">Rider & Back Number</span>
        </span>
      ),
    },
    {
      accessorFn: (row: IBreakRegistrationByDay) => {
        if (row.isBreakDrag) return BREAK
        return `${row.horseName != null ? row.horseName : ''}+${row?.horseProfilePicture != null ? row?.horseProfilePicture : ''}`
      },
      cell: (info: any) => {
        if (info.getValue() === BREAK) {
          return <></>
        }

        const trainer = eventTrainers?.find(
          (eventTrainer) => eventTrainer.registrationByDayUniqId === info.row.original.uniqId
        )

        return (
          <div className="flex items-center gap-4">
            <span className="w-11 h-11 rounded-full block shrink-0 my-2 overflow-hidden">
              <img
                src={
                  info.getValue().split('+')[1].toLowerCase() !== ''
                    ? info.getValue().split('+')[1]
                    : IMAGE_CONSTS.PLACEHOLDERS.USER
                }
                alt="icons"
                className="object-cover w-full h-full rounded-full"
                onError={(e) =>
                  ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                    info.getValue().split('+')[0] ?? ''
                  )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            </span>
            <div className="flex flex-col">
              <span className="text-SeabiscuitDark200ThemeColor text-base">
                {info.getValue().split('+')[0] === '' ? 'No Horse' : info.getValue().split('+')[0]}
              </span>
              <span className="text-SeabiscuitDark200ThemeColor/60 text-nr flex items-center">
                {trainer ? (
                  <span>{trainer.teamMemberName}</span>
                ) : (
                  <span className=" inline-flex items-center text-SeabiscuitDark200ThemeColor/60">
                    No trainer
                  </span>
                )}
              </span>
            </div>
          </div>
        )
      },
      id: 'horse',
      header: () => (
        <span className="text-gray-400 font-normal text-[0.875rem] flex items-center">
          <span className="whitespace-nowrap">Horse & Trainer</span>
        </span>
      ),
    },
    ...(type === 'rider'
      ? [
          {
            id: 'class',
            accessorFn: (row: any) => row.registrationByDayName || '',
            header: () => (
              <span className="text-gray-400 font-normal  text-[0.875rem] flex items-center justify-start   ">
                <span className="whitespace-nowrap">Class</span>
              </span>
            ),
            cell: (info: any) => (
              <span className="text-SeabiscuitDark200ThemeColor text-sm whitespace-nowrap block pr-6 ">
                {info.getValue()}
              </span>
            ),
          },
        ]
      : []),
    {
      id: 'order',
      accessorFn: (row: IBreakRegistrationByDay) => {
        return row.isBreakDrag ? `${row.order}-${BREAK}` : row.order || ''
      },
      header: () => (
        <span className="text-gray-400 font-normal text-[0.875rem] flex justify-center items-center ">
          <span className="whitespace-nowrap">Order of Go</span>
        </span>
      ),
      cell: (info: any) => {
        const arr = `${info.getValue()}`.split('-')
        const num = arr[0]
        const isBreakDrag = arr[1]

        return (
          <div className="flex justify-center">
            {!info.row.original.orderOfGoScratched && (
              <span
                className={`rounded-full ${isBreakDrag ? 'bg-[#122B460D] text-[#122B46]' : 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'} text-base flex items-center justify-center w-10 h-10`}
              >
                {num}
              </span>
            )}
          </div>
        )
      },
    },
    {
      accessorFn: (row: IBreakRegistrationByDay, index: number) => {
        if (row.isBreakDrag) return `${BREAK}-${row.time}-${row.id}`
        return index
      },
      header: () => (
        <span className="text-gray-400 font-normal text-[0.875rem] flex items-center justify-center w-full">
          <span className="whitespace-nowrap">Ride Time</span>
        </span>
      ),
      id: 'rideTime',
      cell: (info: any) => {
        const arr = `${info.getValue()}`.split('-')
        const id = arr[2]
        const isBreak = arr[0]

        // Break time editing common for both schedule types
        if (isBreak === BREAK && arr[1] && id) {
          const timeArr = `${arr[1]}`.split(':')
          const h = timeArr[0]
          const m = timeArr[1]
          const s = timeArr[2]

          return (
            <div
              className={
                'text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex cursor-pointer'
              }
            >
              <TimeSelector
                h={h}
                m={m}
                s={s}
                saveHandler={(h, m, a) => saveHandlerBreak(h, m, a, id)}
              />
            </div>
          )
        }

        if (type === 'class') {
          return (
            <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex">
              {info.row.original.orderOfGoScratched ? (
                <span className="text-SeabiscuitDark200ThemeColor/50">Scratched</span>
              ) : (
                convertTime(
                  `${fee?.startTimeHours}:${fee?.startTimeMinutes} ${fee?.startTimeFormat}`,
                  increment,
                  info.getValue()
                )
              )}
            </div>
          )
        }

        if (type === 'rider' && saveHandlerRider && registrationsToDisplay) {
          const riderId = info.row.original.id
          const riderStatus = getInvalidRiders(registrationsToDisplay).get(riderId)
          const isOutOfOrder = riderStatus?.outOfOrder ?? false
          const isDuplicate = riderStatus?.duplicate ?? false

          return (
            <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex cursor-pointer">
              <RideTimeSelector
                isOutOfOrder={isOutOfOrder}
                isDuplicate={isDuplicate}
                initialTime={info.row.original.rideTime}
                saveHandler={(newTime) => saveHandlerRider(newTime, info.row.original.id)}
              />
            </div>
          )
        }
      },
    },
  ]
}
