import { IconCheck } from './IconCheck'
import { FC } from 'react'

interface IconCheckBlackProps {
  isActive: boolean
}

export const IconCheckBlack: FC<IconCheckBlackProps> = ({ isActive }) => {
  if (!isActive) {
    return <div className={'w-5 h-5 rounded-full border-black/20 border'} />
  }
  return (
    <div
      className={
        'text-white w-5 h-5 flex items-center justify-center border-black bg-black rounded-full'
      }
    >
      <IconCheck />
    </div>
  )
}
