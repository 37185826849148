// web/src/components/icons/NotificationIcon.tsx
import React from 'react'

const NotificationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8125 0.506104C13.3312 0.506104 10.8495 1.45021 8.96191 3.33813L5.68799 6.61206L2.18213 7.82544C1.3686 8.10666 0.756208 8.79059 0.563477 9.62842V9.62964L0.562256 9.63086C0.520744 9.81364 0.5 10.0004 0.5 10.1875C0.5 10.8431 0.758556 11.4812 1.23242 11.9551L4.72485 15.4475C4.04779 16.182 3.625 17.1582 3.625 18.2344C3.625 20.5104 5.49025 22.375 7.76562 22.375C8.84123 22.375 9.81761 21.9523 10.5525 21.2751L14.0437 24.7676C14.5176 25.2425 15.1576 25.5 15.8125 25.5C15.9996 25.5 16.1871 25.4786 16.3691 25.4377H16.3704L16.3728 25.4365C17.2106 25.2438 17.8946 24.6314 18.1758 23.8179V23.8167L19.3892 20.312L22.6631 17.0381C26.4376 13.2629 26.4379 7.11274 22.6619 3.33813C20.7743 1.45021 18.2938 0.506104 15.8125 0.506104ZM15.8125 2.3689C17.81 2.3689 19.8069 3.13424 21.3362 4.66382C24.3952 7.72171 24.3953 12.6539 21.3374 15.7124L17.7583 19.2903L16.4033 23.2051C16.3333 23.408 16.1638 23.559 15.9541 23.6079L15.9529 23.6091C15.9033 23.6199 15.8576 23.625 15.8125 23.625C15.6474 23.625 15.4892 23.5619 15.3706 23.4431V23.4419L8.96436 17.0356L2.55811 10.6294C2.43947 10.5108 2.375 10.3507 2.375 10.1875C2.375 10.1416 2.38026 10.0959 2.39087 10.0483C2.43931 9.83776 2.59144 9.66814 2.79492 9.5979L6.70972 8.24292L10.2876 4.66382C11.8169 3.13424 13.815 2.3689 15.8125 2.3689ZM6.04932 16.772L7.63867 18.3613L9.22681 19.9507C8.83448 20.2914 8.33019 20.5 7.76562 20.5C6.5035 20.5 5.5 19.4971 5.5 18.2344C5.5 17.6697 5.70823 17.1645 6.04932 16.772Z"
      fill="#8c95ac"
      className="group-hover:fill-SeabiscuitMainThemeColor group-hover:opacity-1"
    />
  </svg>
)

export default NotificationIcon
