import React from 'react'
import { CONST } from '../../../../../const/const'
import FirestoreService from '../../../../../services/firestoreService'
import { IRegisteredUser, IRider } from '../../../../../types/entryRider'

interface IProps {
  backNumber: number
  id: string
  cbUpdate: (value: number) => void
  riders: IRider[]
  allRiders: IRegisteredUser[]
}
export const BackNumber: React.FC<IProps> = ({ backNumber, id, cbUpdate, riders, allRiders }) => {
  const [back, setBack] = React.useState(0)
  const [editMode, setEditMode] = React.useState(false)
  const divRef = React.useRef<HTMLDivElement | null>(null)
  const [isExist, setIsExist] = React.useState(false)
  const updateNumberHandler = React.useCallback(async () => {
    const riderIndex = riders.findIndex((r) => r.id === id)
    if (!riders[riderIndex]) {
      return null
    }

    const promises: Promise<void>[] = []

    allRiders.forEach((user) => {
      if (
        user.riderId === riders[riderIndex].riderId &&
        user.userId === riders[riderIndex].userId &&
        user.horseId === riders[riderIndex].horseId
      ) {
        promises.push(
          FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
            user.id,
            {
              backNumber: +back,
            }
          )
        )
      }
    })

    setEditMode(false)
    if (backNumber === back) {
      return null
    }
    const hasBackNumber = riders.some((obj) => +obj.backNumber === +back)
    if (hasBackNumber) {
      setIsExist(true)
      return null
    }
    try {
      await Promise.all(promises)
      cbUpdate(back)
    } catch (e) {
      console.log('=>(BackNumber.tsx:34) e', e)
    }
  }, [backNumber, back, id, riders, cbUpdate])

  const handleClickOutside = React.useCallback(
    async (event: any) => {
      if (editMode && divRef.current && !divRef.current.contains(event.target)) {
        updateNumberHandler().then(() => null)
      }
    },
    [editMode, divRef, updateNumberHandler]
  )

  React.useEffect(() => {
    setBack(backNumber)
  }, [backNumber])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const onChangeHandler = async (value: string) => {
    const regex = /^[0-9\b]+$/

    if (value === '' || regex.test(value)) {
      setBack(+value || 0)
    }
  }

  return (
    <div ref={divRef} className={'flex items-center'}>
      <span className="md:hidden">Back #</span>
      {editMode ? (
        <input
          className={`w-[100%] outline-none rounded-sm border-[#D3DAEE] focus:border-[#D3DAEE]`}
          onChange={(e) => onChangeHandler(e.target.value)}
          value={back}
          type="text"
          onKeyDown={async (event) => {
            if (event.key === 'Enter') {
              updateNumberHandler().then(() => null)
            }
          }}
        />
      ) : (
        <span
          onClick={() => {
            setEditMode(true)
          }}
          className={` whitespace-nowrap cursor-pointer block text-center w-[40px] text-[16px] ${isExist ? 'text-SeabiscuitMainThemeColor font-normal' : back !== 0 ? 'text-SeabiscuitDark200ThemeColor font-normal' : 'text-SeabiscuitDark200ThemeColor/40 font-normal '}`}
        >
          {back}
          {isExist && <span className={'block text-[10px] font-light'}>Taken</span>}
        </span>
      )}
    </div>
  )
}
