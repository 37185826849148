import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import moment from 'moment/moment'

import { PaperworkItem } from './PaperworkItem'
import Accordion from '../../../components/accordion/common/Accordion'
import customImageComponent from '../../../components/common/CustomImageComponent'
import LightboxCommonComponent from '../../../components/common/dialogs/full-screen/LightboxCommonComponent'
import ViewsLoader from '../../../components/loader/ViewsLoader'

import { IUserDocument } from '../../../models/user-documents/user-documents.interface'
import { IEventInterface } from '../../../models/events/event.interface'
import { ILightboxProps } from '../../../types/competitor_types'
import { IPaperworkTab } from '../PaperworkRootPage'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

type IPaperworkDataListProps = {
  filteredDocs: { [key: string]: IUserDocument[] }
  allDocs: IUserDocument[]
  setAllDocs: (value: IUserDocument[]) => void
  events: IEventInterface[]
  activeTab: IPaperworkTab
  loading: boolean
  registrationsByDay: IRegistrationByDayInterface[]
}

const PaperworkDataList = ({
  allDocs,
  setAllDocs,
  filteredDocs,
  events,
  activeTab,
  loading,
  registrationsByDay,
}: IPaperworkDataListProps) => {
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })

  if (loading) {
    return (
      <div className="w-full min-h-[400px] flex justify-center items-center">
        <ViewsLoader size="lg" color="#F70763" />
      </div>
    )
  }

  return (
    <>
      {Object.keys(filteredDocs).length > 0 ? (
        Object.values(filteredDocs).map((docs, index) => {
          const event = events.find((event) => event.id === docs[0].eventId)
          return (
            <Accordion
              id={docs[0].eventId}
              key={index}
              initialState={false}
              headerWrapperClassName="flex-col md:flex-row !items-end md:!items-center !py-4 !px-2"
              headerTextClassName="w-full"
              className="w-full border-t-0 border-l-0 border-r-0 p-0 !shadow-none border-b-black/5 rounded-none"
              header={
                <div className="flex items-center gap-3 text-SeabiscuitDark200ThemeColor">
                  <Link
                    to={`/events-details/${docs[0].eventId}`}
                    className="transition-all hover:opacity-70"
                  >
                    {customImageComponent(
                      docs[0].eventLogo,
                      docs[0].eventName,
                      '!w-[45px] !min-w-[45px] !h-[45px] rounded-full shrink-0'
                    )}
                  </Link>
                  <div>
                    <p className="font-normal text-[13px]">{docs[0]?.eventName}</p>
                    <p className="font-normal text-[13px] opacity-50">
                      {event?.eventStartDate
                        ? moment(event.eventStartDate).format('DD MMM, Y')
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              }
              info={
                <div className="w-full ml-auto text-right text-SeabiscuitDark200ThemeColor text-[13px]">
                  <p>{docs.length} documents</p>
                  {activeTab === 'signed' ? (
                    <span className="opacity-50">
                      {docs.filter((doc) => doc.status === 'Signed').length} signed
                    </span>
                  ) : activeTab === 'unsigned' ? (
                    <span className="text-[#E80000]">
                      {docs.filter((doc) => doc.status !== 'Signed').length} unsigned
                    </span>
                  ) : docs.filter((doc) => doc.status !== 'Signed').length > 0 ? (
                    <div className="flex flex-wrap gap-1 justify-end">
                      <span className="opacity-50">
                        {docs.filter((doc) => doc.status === 'Signed').length} signed
                      </span>
                      <span className="opacity-50">•</span>
                      <span className="text-[#E80000]">
                        {docs.filter((doc) => doc.status !== 'Signed').length} unsigned
                      </span>
                    </div>
                  ) : (
                    <span className="opacity-50">All signed</span>
                  )}
                </div>
              }
            >
              <div className="flex flex-wrap gap-x-[1%] gap-y-4">
                {docs.map((doc, index) => {
                  const registrationByDay = registrationsByDay.find(
                    (rider) => rider.id === doc?.registrationByDayDocId
                  )
                  return (
                    <PaperworkItem
                      key={index}
                      doc={doc}
                      allDocs={allDocs}
                      setAllDocs={setAllDocs}
                      setLightBox={setLightBox}
                      registrationByDay={registrationByDay}
                    />
                  )
                })}
              </div>
            </Accordion>
          )
        })
      ) : (
        <p className="p-4 text-center opacity-50 w-full">No documents found</p>
      )}

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={() => setLightBox({ visible: false, slides: [] })}
        slides={lightBox.slides}
      />
    </>
  )
}

export default PaperworkDataList
