import { createSlice } from '@reduxjs/toolkit'
import { billsSliceInitialState } from './billsSlice.data'
import { IBillsSliceInitialStateInterface as ibsisi } from './billsSlice.types'
import { RootState } from '../store'

const billsSlice = createSlice({
  name: 'bills',
  initialState: billsSliceInitialState,
  reducers: {
    setUsers(state, { payload }: { payload: ibsisi['holder']['users'] }) {
      state.holder.users = payload
    },
    setEvents(state, { payload }: { payload: ibsisi['holder']['events'] }) {
      state.holder.events = payload
    },
    setSelectedEvent(state, { payload }: { payload: ibsisi['selected']['event'] }) {
      state.selected.event = payload
    },
    setSelectedEventFees(state, { payload }: { payload: ibsisi['selected']['eventFees'] }) {
      state.selected.eventFees = payload
    },
    setSelectedRecipient(state, { payload }: { payload: ibsisi['selected']['recipient'] }) {
      state.selected.recipient = payload
    },
    setRegistrationCountDetails(
      state,
      { payload }: { payload: ibsisi['selected']['countDetails'] }
    ) {
      state.selected.countDetails = payload
    },
  },
})

// Redux selectors
const selectUsers = (state: RootState) => state.bills.holder.users
const selectCountDetails = (state: RootState) => state.bills.selected.countDetails
const selectBillRecipient = (state: RootState) => state.bills.selected.recipient

export { selectUsers, selectCountDetails, selectBillRecipient }

export const { setUsers, setEvents, setSelectedEvent, setSelectedEventFees, setSelectedRecipient } =
  billsSlice.actions
export default billsSlice.reducer
