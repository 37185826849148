import EventDetailsViewComponentHeaderSection from './EventDetailsViewComponentHeaderSection'
import EventDetailViewComponentRegisterBox from './EventDetailViewComponentRegisterBox'
import PrimaryButton from './global/PrimaryButton'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { getConvertedData, getPublishedDetailsOfEvent } from '../../../../models/interface.helper'
import {
  selectedEvent,
  selectRegisteredUsersByDay,
  setRegisteredUsersByDay,
  setRegisterEventData,
  setRegisterHorseData,
  setSelectedEvent,
} from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectUseMode } from '../../../../store/system/systemSlice'
import ViewsLoader from '../../../loader/ViewsLoader'
import EventDetailTab from './EventDetailTab'
import EventScheduleTab from './EventScheduleTab'
import HorizantalDivider from './global/HorizantalDivider'
import EventDetailViewComponentAllStaffModal from './modals/EventDetailViewComponentAllStaffModal'

import clsx from 'clsx'
import { collection, documentId, query, where } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import helpers from '../../../../commonHelpers/helpers'
import { HandleModalContext, IHandleModal } from '../../../../layout/mainlayout/MainLayout'
import { IEventInterface } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import FirebaseApp from '../../../../services/firebaseApp'
import UserService from '../../../../services/userService'
import WrapperContainer from '../../../common/wrappers/WrapperContainer'
import { capitalize, getUserFullName } from '../../../../helpers/helpers'
import EventResults from './EventResults'
import EventOrderOfGo from './EventOrderOfGo'
import ITypes from '../../../../pages/competitor/event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { selectUserId } from '../../../../store/user/userSlice'
import { selectHorses } from '../../../../store/horses/horseSlice'
import { EventReviewPublishModel } from '../../../../models/event-review-publish/event-review-publish.model'
import { toastFunctionsObj } from '../../../../helpers/ToasterHelper'
import { getFullDate } from '../../../../helpers/time'

export interface EventDetailViewComponentComponentProps {
  onClose?: any
  step?: number
  isOpen?: boolean
  handleStep?: any
  dataToPassOn?: any
  isLoggedIn?: boolean
  handleSetEventData?: any
  handleModal?: IHandleModal['handleModal']
}

type IEventTab = {
  src: ReactJSXElement | string
  tabName: string
  alt: string
  link: string
  activeSrc: ReactJSXElement | string
}

const eventPageTabs: IEventTab[] = [
  {
    src: 'assets/og_icons/Regular Document-1.svg',
    activeSrc: 'assets/cp_icons/RegularDocument-2.svg',
    tabName: 'Event Details',
    alt: 'Event',
    link: 'www.google.com',
  },
  {
    src: 'assets/cp_icons/Clock-1.svg',
    activeSrc: 'assets/cp_icons/Clock-2.svg',
    tabName: 'Schedule',
    alt: 'Schedule',
    link: 'www.google.com',
  },
  {
    src: 'assets/og_icons/Clipboard-1.svg',
    activeSrc: 'assets/og_icons/Clipboard-3.svg',
    tabName: 'Classes',
    alt: 'Classes',
    link: 'www.google.com',
  },
  {
    src: 'assets/cp_icons/Prize-1.svg',
    activeSrc: 'assets/cp_icons/Prize-2.svg',
    tabName: 'Results',
    alt: 'Results',
    link: 'www.google.com',
  },
  {
    src: 'assets/cp_icons/Share Rounded-1.svg',
    activeSrc: 'assets/cp_icons/Share Rounded-2.svg',
    tabName: 'Share Event',
    alt: 'Share Event',
    link: 'www.google.com',
  },
]

const HOME_PAGE_ROUTE = CONST.ROUTES.HOME.URL

const EventDetailsViewComponent: React.FC<EventDetailViewComponentComponentProps> = (props) => {
  const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

  // Hooks and vars
  const handleModalContext = useContext(HandleModalContext)

  const historyHook = useHistory()
  const dispatch = useAppDispatch()
  const { eventId } = useParams<ITypes['IParams']>()
  const userMode = useAppSelector(selectUseMode)
  const horses = useAppSelector(selectHorses)
  const registeredUsersByDay = useAppSelector(selectRegisteredUsersByDay)
  const userId = useAppSelector(selectUserId)

  const setExhibitorsModalShow = useState(false)[1]
  const [activeTab, setActiveTab] = useState<number>(0)
  const [allStaffModalShow, setAllStaffModalShow] = useState(false)
  const [eventTableData, setEventTableData] = useState<IEventInterface | null>(null)
  const [organizerDetails, setOrganizerDetails] = useState<IUserInterface | null>(null)
  const [eventIsOpen, setEventIsOpen] = useState(true)
  const [eventIsClosed, setEventIsClosed] = useState(false)

  useEffect(() => {
    dispatch(
      setRegisterEventData({
        reset: true,
      })
    )

    dispatch(setRegisterHorseData(horses))

    return () => {
      dispatch(setRegisteredUsersByDay([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (typeof props.dataToPassOn === 'number' && eventTableData?.id) {
      setEventTableData({
        ...eventTableData,
        waitlistCount: Number(eventTableData.waitlistCount ?? 0) + props.dataToPassOn,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataToPassOn])

  const { EventDetails, EventStaff, EventFees, EventPolicies, EventPaperwork, EventTickets } =
    useAppSelector(selectedEvent)

  const [loading, setLoading] = useState<boolean>(false)

  const ownerId = (() => {
    return EventDetails?.owner
  })()

  const [competitonFull, setCompetitonFull] = useState<boolean>(false)

  useEffect(() => {
    if (EventDetails) {
      const openDate = getFullDate(
        `${EventDetails.competitionRegistrationOpenDate}`,
        EventDetails.competitionRegistrationOpenTime
      )
      const closedDate = getFullDate(
        `${EventDetails.competitionRegistrationCloseDate}`,
        EventDetails.competitionRegistrationCloseTime
      )

      const now = new Date()
      setEventIsOpen(openDate < now)
      setEventIsClosed(closedDate > now)
    }
  }, [EventDetails])

  useEffect(() => {
    setLoading(true)

    const getDetailSelectedEvent = async () => {
      if (!eventId) return 0

      const publishedDetails = await getPublishedDetailsOfEvent(eventId)

      if (publishedDetails) dispatch(setSelectedEvent(publishedDetails))

      setLoading(false)
    }

    if (eventId) getDetailSelectedEvent()
    else {
      helpers.logger({
        isError: true,
        message: `EventDetailsViewComponent::eventId is missing`,
      })
      setLoading(true)
    }
  }, [eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const EventFirebaseQueries: any[] = (function (): any {
    let queries: any = []
    if (eventId) {
      queries.push(where(documentId(), '==', eventId))
    }
    return queries
  })()

  useEffect(() => {
    if (ownerId) {
      const getOrganizerDetails = async function () {
        const user_data_doc = await UserService.getUserInfoById(ownerId)
        let userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()
        setOrganizerDetails(userData)
      }
      getOrganizerDetails()
    }
  }, [ownerId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const [event] = useCollection(
    query(collection(FirebaseApp.firestore, COLLECTIONS.EVENTS.NAME), ...EventFirebaseQueries),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  const [eventPublished] = useCollection(
    query(
      collection(FirebaseApp.firestore, COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME),
      ...EventFirebaseQueries
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    const getEvents = function () {
      const fetchedEvents =
        event?.docs.map((doc) => {
          let EventsData = EventModel.fromFirestoreDoc(doc).toObject()
          return getConvertedData(EventsData)
        }) ?? []

      const fetchedEventPublished =
        eventPublished?.docs.map((doc) => {
          let EventsData = EventReviewPublishModel.fromFirestoreDoc(doc).toObject()
          return getConvertedData(EventsData)
        }) ?? []

      if (fetchedEvents.length) {
        const eventDetailData = fetchedEvents[0]
        const eventDetailPublishedData = fetchedEventPublished[0]?.EventFees
        setEventTableData(eventDetailData)

        if (eventDetailPublishedData.registrationLimit?.isEnable) {
          if (
            registeredUsersByDay.length >=
            parseInt((eventDetailPublishedData?.registrationLimit?.spots ?? 0).toString())
          ) {
            setCompetitonFull(true)
          }
        }
      }
    }
    if (eventPublished && eventPublished.size > 0) getEvents()
  }, [event, eventPublished, registeredUsersByDay])

  const handleRegisterForCompetition = () => {
    if (typeof props.handleModal !== 'function') return

    if (!eventIsOpen) {
      return toastFunctionsObj.error({
        message: 'Registration is not open yet.',
      })
    }
    if (!eventIsClosed) {
      return toastFunctionsObj.error({
        message: 'Registration is already closed.',
      })
    }

    if (competitonFull) {
      props.handleModal(true, MODAL_CONSTS.REGISTER_FOR_COMPETETION, eventTableData)
    } else {
      historyHook.push(
        `${CONST.ROUTES.REGISTER_EVENT.URL}/${eventId}/${userId || new Date().getTime()}`
      )
    }
  }

  const handlemodalopen = (index: number) => {
    if (index === 4) {
      handleModalContext?.handleModal(true, MODAL_CONSTS.SHARE_DOCUMENT, { data: 'data is coming' })
    } else return {}
  }

  return (
    <WrapperContainer
      noBg={true}
      removeShadow={true}
      headLink={`${userMode === 'competitor' ? HOME_PAGE_ROUTE : CONST.ROUTES.SEARCH.URL}`}
      title="Search events"
      removePadding={true}
      headerStyles={{
        fontWeight: '400',
      }}
    >
      {loading ? (
        <div className="min-h-[500px] flex items-center justify-center">
          <ViewsLoader size="xl" color="red" />
        </div>
      ) : (
        <div className="relative bg-white pt-5 px-4 2xl:p-[45px] sm:pt-6 sm:px-6 shadow rounded-[20px] scroll-auto">
          <EventDetailViewComponentAllStaffModal
            show={allStaffModalShow}
            onHide={() => setAllStaffModalShow(false)}
          />

          <EventDetailsViewComponentHeaderSection
            ownerId={ownerId}
            organizerDetails={organizerDetails}
            coverImage={EventDetails?.eventCardCoverImageUrl}
          />

          <div>
            <div className="flex flex-wrap items-center mb-3 2xl:mb-4">
              <h1 className="text-SeabiscuitDark200ThemeColor font-bold text-2xl sm:text-[35px] 2xl:text-[45px] mr-3 2xl:mr-4">
                {capitalize(EventDetails?.competitionName, { capitalizeAll: false })}
              </h1>
              {eventTableData?.status === 'cancel' ? (
                <button
                  type="button"
                  disabled
                  className="!w-fit text-white bg-[#FC2804] px-2 rounded-2xl text-xs 2xl:text-nr 2xl:py-1.5 py-1 capitalize"
                >
                  Cancelled
                </button>
              ) : null}
            </div>
            <p className="text-SeabiscuitDark200ThemeColor text-base 2xl:text-[20px] mb-5 2xl:mb-[30px]">
              {capitalize(EventDetails?.summaryShort, {
                capitalizeAll: false,
              })}
            </p>

            {/* Tab list  */}
            <div className="flex flex-wrap">
              {eventPageTabs.map((eventTab: IEventTab, index: number) => {
                return (
                  <span
                    onClick={() => {
                      index !== 4 && setActiveTab(index)
                      handlemodalopen(index)
                    }}
                    key={`${JSON.stringify(eventTab)}${index}`}
                    className={`mr-2 rounded-lg px-3 2xl:px-4 py-2.5 2xl:py-3 ${activeTab === index ? 'text-SeabiscuitMainThemeColor bg-[#fef5f6]' : 'text-SeabiscuitDark200ThemeColor'} transition ease-in duration-200 flex items-center min-w-[120px] py-1 cursor-pointer`}
                  >
                    <span className="pr-1">
                      {typeof eventTab.activeSrc === 'string' &&
                      typeof eventTab.src === 'string' ? (
                        <img
                          width="24"
                          height="24"
                          src={activeTab === index ? eventTab.activeSrc : eventTab.src}
                          alt={eventTab.alt}
                        />
                      ) : activeTab === index ? (
                        eventTab.activeSrc
                      ) : (
                        eventTab.src
                      )}
                    </span>
                    <span className="text-nr 2xl:text-base">{eventTab.tabName}</span>
                  </span>
                )
              })}
            </div>
            {/* Tab list */}
          </div>

          <div className="flex flex-col-reverse lg:grid grid-cols-1 lg:grid-cols-[60%_calc(40%_-_24px)] xl:grid-cols-[68%_calc(32%_-_24px)] 2xl:grid-cols-[72%_calc(28%_-_24px)] gap-6">
            <div>
              <HorizantalDivider />
              {!activeTab ? (
                <EventDetailTab
                  handleModal={props.handleModal}
                  EventDetails={EventDetails}
                  EventTickets={EventTickets}
                  setExhibitorsModalShow={setExhibitorsModalShow}
                  EventFees={EventFees}
                  EventPolicies={EventPolicies}
                  EventStaff={EventStaff}
                  dataToPassOn={props.dataToPassOn}
                  EventPaperwork={EventPaperwork}
                />
              ) : activeTab === 1 ? (
                <>
                  <EventScheduleTab />
                </>
              ) : activeTab === 2 ? (
                <>
                  <EventOrderOfGo />
                </>
              ) : activeTab === 3 ? (
                <>
                  <EventResults />
                </>
              ) : (
                <p>No data</p>
              )}
            </div>
            <div className="">
              <div className="rounded-xl 2xl:mt-6 mt-4 shadow-[0_0_30px_rgba(0,0,0,0.12)] p-4 sticky top-4">
                <EventDetailViewComponentRegisterBox
                  EventTickets={EventTickets}
                  EventDetails={EventDetails}
                  ownerId={organizerDetails?.id}
                  handleModal={props.handleModal}
                  eventTableData={eventTableData}
                  companyLogo={organizerDetails?.userProfilePicture}
                  companyName={organizerDetails ? getUserFullName(organizerDetails) : ''}
                />
                <PrimaryButton
                  className={clsx('m-auto w-full block mt-4', {
                    'bg-[#FC2804] font-semibold': eventTableData?.status === 'cancel',
                    '!bg-SeabiscuitGrayLight100ThemeColor !text-SeabiscuitLightTextColor !cursor-default':
                      !eventIsOpen || !eventIsClosed,
                  })}
                  disabled={userMode === 'organizer'}
                  onClick={handleRegisterForCompetition}
                  caption={eventTableData?.status === 'cancel' ? 'CANCELLED' : 'REGISTER'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </WrapperContainer>
  )
}

export default EventDetailsViewComponent
