import { FC, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import MainModal from '../../../../../components/modals/common/MainModal'

import TogglesElement from '../../../../../components/elements/toggles/toggles/TogglesElement'

import { EventSchedulingModel } from '../../../../../models/operations-tab/operations-tab.model'
import { IEventScheduleInterface } from '../../../../../models/operations-tab/operations-tab.interface'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { MANAGE_OPERATIONS_CONSTS } from '../../clinic/data/operations.data.const'

interface PublishOperationsModalProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    isPublish: {
      [key: string]: boolean
    }
    selectedClass: { value: string; label: string }
    activeTab: string
    scheduleBy: 'rider' | 'class'
    selectedDay?: { value: string; label: string }
    onSavePublish: (value: { [key: string]: boolean }) => void
  }
}

interface IControlItem {
  title: string
  type: string
  icon: string
  control: Control<IEventScheduleInterface>
  isPublished: { [key: string]: boolean }
  setIsPublished: (value: { [key: string]: boolean }) => void
}

const ControlItem: FC<IControlItem> = ({
  title,
  type,
  icon,
  control,
  isPublished,
  setIsPublished,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <img className="w-6" src={icon} alt="icons" />
        <div>
          <h6 className="text-SeabiscuitDark200ThemeColor text-[14px]">{title}</h6>
          <p className="text-SeabiscuitDark200ThemeColor/50 text-[10px]">N/A</p>
        </div>
      </div>
      <Controller
        name="title"
        control={control}
        render={() => {
          return (
            <TogglesElement
              checkedMessage="Live"
              on={isPublished[type]}
              buttonClassName="h-[36px] w-[100px]"
              textClassName="h-[25px]"
              onToggle={() => setIsPublished({ ...isPublished, [type]: !isPublished[type] })}
            />
          )
        }}
      />
    </div>
  )
}

export const PublishOperationsModal: FC<PublishOperationsModalProps> = ({
  show,
  handleModal,
  dataToPassOn,
}) => {
  const eventSchedulingFormValidationSchema = EventSchedulingModel.validationSchema()

  const [isPublished, setIsPublished] = useState({
    [MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value]:
      dataToPassOn.isPublish[MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value],
    [MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value]:
      dataToPassOn.isPublish[MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value],
    [MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value]:
      dataToPassOn.isPublish[MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value],
  })

  const { control } = useForm<IEventScheduleInterface>({
    defaultValues: { title: '' },
    resolver: yupResolver(eventSchedulingFormValidationSchema),
    mode: 'onSubmit',
  })

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.PUBLISH_OPERATIONS_MODAL)
  }

  return (
    <MainModal
      size="md"
      title="Publish"
      show={show}
      onHide={closeModal}
      type="PUBLISH_OPERATIONS_MODAL"
      buttons={[
        {
          label: 'SAVE',
          onClick: () => {
            dataToPassOn.onSavePublish(isPublished)
            closeModal()
          },
          textClass: 'text-white',
        },
      ]}
    >
      <p className="text-SeabiscuitDark200ThemeColor text-base mb-3">
        Toggle settings to ‘Live’ to publish riders and public.
      </p>
      <p className="text-SeabiscuitDark200ThemeColor text-base mb-3">
        Toggle settings back to private to hide from the riders and public.
      </p>
      <div className="mt-6 flex flex-col gap-6 border-solid p-4 border-[#D3DAEE] border rounded-2xl">
        <div className="flex items-center gap-4 mb-2">
          <img className="w-6" src={'assets/og_icons/Prize-1.svg'} alt="icons" />
          <h6
            className="text-SeabiscuitDark200ThemeColor text-[18px] font-medium"
            dangerouslySetInnerHTML={{
              __html:
                dataToPassOn.scheduleBy === 'rider'
                  ? dataToPassOn.selectedDay?.label || 'No Day Selected'
                  : dataToPassOn.selectedClass.label || 'No Class Selected',
            }}
          />
        </div>
        {dataToPassOn.activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value ? (
          // If on Prize Money tab, show only the prize money toggle
          <ControlItem
            icon="assets/og_icons/Money Bag-1.svg"
            title="Publish Prize Money"
            type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value}
            isPublished={isPublished}
            setIsPublished={setIsPublished}
            control={control}
          />
        ) : (
          // On order of go tab or scoring tab, show both toggles
          <>
            {dataToPassOn.activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value && (
              <>
                <ControlItem
                  icon="assets/og_icons/Shortlist-1.svg"
                  title="Publish Order of Go"
                  type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value}
                  isPublished={isPublished}
                  setIsPublished={setIsPublished}
                  control={control}
                />
                <ControlItem
                  icon="assets/og_icons/Prize-1.svg"
                  title="Publish Scores"
                  type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value}
                  isPublished={isPublished}
                  setIsPublished={setIsPublished}
                  control={control}
                />
              </>
            )}
            {dataToPassOn.activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value && (
              <>
                <ControlItem
                  icon="assets/og_icons/Shortlist-1.svg"
                  title="Publish Order of Go"
                  type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value}
                  isPublished={isPublished}
                  setIsPublished={setIsPublished}
                  control={control}
                />
                <ControlItem
                  icon="assets/og_icons/Prize-1.svg"
                  title="Publish Scores"
                  type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value}
                  isPublished={isPublished}
                  setIsPublished={setIsPublished}
                  control={control}
                />
              </>
            )}
          </>
        )}
      </div>
    </MainModal>
  )
}
