import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'

import FirestoreService from '../../../../../../../../../services/firestoreService'
import { IRegistrationByDayEx } from '../../../../ManageClinicNOtherOperationsTab'

import { IRegistrationByDayInterface } from '../../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../../../../../models/registrations-by-day/registrationByDay.model'
import { RegistrationFeesType } from '../../../../../../../../../models/event-fees/event-fees.interface'

import { CONST } from '../../../../../../../../../const/const'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

interface TotalPointsInputProps {
  row: IRegistrationByDayEx
  fee: RegistrationFeesType | null
  setRegistrationsByDay: (value: IRegistrationByDayInterface[]) => void
  registrationsByDay: IRegistrationByDayInterface[] | null
}

export const TotalPointsInput: FC<TotalPointsInputProps> = ({
  row,
  setRegistrationsByDay,
  registrationsByDay,
  fee,
}) => {
  const [totalPoints, setTotalPoints] = useState('')

  const onBlurTotalPoints = (event: any, row: IRegistrationByDayInterface) => {
    const value = event.target.value
    const valueWithoutLeadingZeros = value === '0' ? value : value.replace(/^0+/, '')

    setTotalPoints(valueWithoutLeadingZeros)

    let registrationsByDay_: IRegistrationByDayInterface[] =
      registrationsByDay?.map((registrationByDay) => {
        if (registrationByDay.id === row.id) {
          return {
            ...registrationByDay,
            score: {
              ...registrationByDay.score,
              totalPoints: valueWithoutLeadingZeros,
              sheet: registrationByDay.score?.sheet ?? null,
              judges: registrationByDay.score?.judges ?? [],
            },
          }
        } else {
          return registrationByDay
        }
      }) ?? []

    const registrationsByDayByClass_ = registrationsByDay_
      ?.filter(
        (registrationByDay) =>
          registrationByDay.uuid === fee?.uuid &&
          registrationByDay.paymentStatus !==
            COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
      )
      ?.map((registrationByDay, index) => {
        return {
          ...registrationByDay,
          ...(registrationByDay.score && {
            score: { ...registrationByDay.score, rank: index + 1 },
          }),
        }
      })

    setRegistrationsByDay([...registrationsByDay_])

    // Update firestore collection
    registrationsByDayByClass_?.map(async (registrationByDay) => {
      const item = new RegistrationByDayModel(registrationByDay)

      await FirestoreService.updateItem(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        item.id,
        item.toFirestore()
      )
    })
  }

  useEffect(() => {
    setTotalPoints(String(row.score?.totalPoints) ?? '')
  }, [row.score?.totalPoints])

  return (
    <input
      style={{ boxShadow: 'none', outline: 'none' }}
      type="number"
      className={clsx(
        'border-0 outline-0 text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all cursor-pointer'
      )}
      placeholder="Enter number"
      value={Number(totalPoints) ? totalPoints : ''}
      onChange={(event) => setTotalPoints(event.target.value)}
      onBlur={(event) => onBlurTotalPoints(event, row)}
    />
  )
}
