import TimeLib from '../../../../lib/Time'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { PAYMENT_STATUS_VALUES, PAYMENT_TYPE } from '../../../../types/payment'

export const DATA_FIRESTORE_V01_REGISTRATIONS_BY_DAY = {
  NAME: 'v01_registrations_by_day',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_EMAIL_ID: {
      KEY: 'riderEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_ID: {
      KEY: 'invoiceId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_URL: {
      KEY: 'invoiceUrl',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_AMOUNT_ADDED: {
      KEY: 'refundAmountAdded',
      VALUES: {
        DEFAULT: false,
      },
    },

    SCRATCH_AMOUNT_ADDED: {
      KEY: 'scratchAmountAdded',
      VALUES: {
        DEFAULT: false,
      },
    },

    RECIPIENT_NAME_N_GRAM: {
      KEY: 'recipientNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    SCORE: {
      KEY: 'score',
      VALUES: {
        DEFAULT: null,
      },
    },

    ORDER: {
      KEY: 'order',
      VALUES: {
        DEFAULT: 0,
      },
    },

    ORDER_OF_GO_SCRATCHED: {
      KEY: 'orderOfGoScratched',
      VALUES: {
        DEFAULT: false,
      },
      DEFAULT: 0,
    },

    BACK_NUMBER: {
      KEY: 'backNumber',
      VALUES: {
        DEFAULT: 0,
      },
    },

    RIDER_DOB: {
      KEY: 'riderDob',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_NAME_N_GRAM: {
      KEY: 'riderNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_ID: {
      KEY: 'horseId',
      VALUES: {
        DEFAULT: null,
      },
    },
    HORSE_IDS: {
      KEY: 'horseIds',
      VALUES: {
        DEFAULT: [],
      },
    },

    USER_HORSE_MAPPING_DOC_ID: {
      KEY: 'userHorseMappingDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_PROFILE_PICTURE: {
      KEY: 'recipientProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    ORDER_GO: {
      KEY: 'orderOfGo',
      VALUES: {
        DEFAULT: 0,
      },
    },

    HORSE_OWNER_ID: {
      KEY: 'horseOwnerId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_ID: {
      KEY: 'riderId',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_DATE: {
      KEY: 'eventDate',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_NAME: {
      KEY: 'riderName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_ROLE: {
      KEY: 'riderRole',
      VALUES: {
        DEFAULT: null,
      },
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: PAYMENT_STATUS_VALUES,
    },

    PAYMENT_TYPE: {
      KEY: 'paymentType',
      VALUES: PAYMENT_TYPE,
    },

    IS_SCRATCHED: {
      KEY: 'isScratched',
      VALUES: {
        YES: true,
        NO: false,
        DEFAULT: false,
      },
    },

    AMOUNT_REFUNDED: {
      KEY: 'amountRefunded',
      VALUES: {
        DEFAULT: 0,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      VALUES: {
        DEFAULT: null,
        REFUNDED: 'refunded' as IRegistrationByDayInterface['refundStatus'],
      },
    },

    HORSE_NAME: {
      KEY: 'horseName',
      VALUES: {
        DEFAULT: null,
      },
    },

    IS_PAID_BY_OWNER: {
      KEY: 'isPaidByOwner',
      VALUES: {
        YES: true,
        NO: false,
        DEFAULT: true,
      },
    },

    RECIPIENT_NAME: {
      KEY: 'recipientName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_ID: {
      KEY: 'recipientId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_PROFILE_PICTURE: {
      KEY: 'riderProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_PROFILE_PICTURE: {
      KEY: 'horseProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_PRICE: {
      KEY: 'registrationPrice',
      VALUES: {
        DEFAULT: null,
      },
    },

    QUALIFY_FEE: {
      KEY: 'qualifyFee',
      VALUES: {
        DEFAULT: null,
      },
    },
    QUALIFY_FEE_NAME: {
      KEY: 'qualifyFeeName',
      VALUES: {
        DEFAULT: null,
      },
    },

    IS_QUALIFY: {
      KEY: 'isQualify',
      VALUES: {
        DEFAULT: null,
      },
    },

    BREAK_TIME: {
      KEY: 'breakTime',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_BY_DAY_NAME: {
      KEY: 'registrationByDayName',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_DOC_ID: {
      KEY: 'refundDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_ID: {
      KEY: 'refundId',
      VALUES: {
        DEFAULT: null,
      },
    },

    UUID: {
      KEY: 'uuid',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
