import React, { FC, useEffect, useState } from 'react'

import { where } from 'firebase/firestore'

import EventReportsReport from './components/EventReportsReport/EventReportsReport'
import { EventResultsReport } from './components/EventResultsReport/EventResultsReport'
import ViewsLoader from '../../../../../../components/loader/ViewsLoader'

import { ITeamMember } from '../../../../../../models/users/user.interface'
import { TEventRegisteredUsers } from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { getConvertedData } from '../../../../../../models/interface.helper'
import { EventModel } from '../../../../../../models/events/event.model'
import { HorseTeamModel } from '../../../../../../models/horse-team/horseTeam.model'
import { EventRegisteredUsersModel } from '../../../../../../models/event-registered-users/event-registered-users.model'
import { RegistrationByDayModel } from '../../../../../../models/registrations-by-day/registrationByDay.model'
import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'
import { IEventInterface } from '../../../../../../models/events/event.interface'
import { IHorseData } from '../../../../../../models/horse/horse.interface'
import { HorseModel } from '../../../../../../models/horse/horse.model'

import FirestoreService from '../../../../../../services/firestoreService'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'

import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { CONST } from '../../../../../../const/const'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectAllUsers } from '../../../../../../store/users/usersSlice'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export type IReports = 'Event Reports' | 'Event Results'

interface ManageClinicNOtherReportsTabProps {
  eventId: string
}

const ManageClinicReportsRoot: FC<ManageClinicNOtherReportsTabProps> = ({ eventId }) => {
  const toastFunctions = useToasterHelper()

  const [activeOption, setActiveOption] = useState<IReports>('Event Reports')
  const [event, setEvent] = useState<IEventInterface | null>(null)
  const [teamMembers, setTeamMembers] = useState<ITeamMember[] | null>(null)
  const [eventRegisteredUsers, setEventRegisteredUsers] = useState<TEventRegisteredUsers[] | null>(
    null
  )
  const [registeredUsersByDay, setRegisteredUsersByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)
  const [uniqueRegisteredUsersByDay, setUniqueRegisteredUsersByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)
  const [registrationFees, setRegistrationFees] = useState<any>(null)
  const [horses, setHorses] = useState<IHorseData[] | null>(null)
  const [loading, setLoading] = useState(false)
  const users = useAppSelector(selectAllUsers)
  const getAllData = async () => {
    setLoading(true)

    try {
      const teamMembers_: ITeamMember[] = []
      const eventRegisteredUsers_: TEventRegisteredUsers[] = []
      const registeredUsersByDay_: IRegistrationByDayInterface[] = []
      const uniqueRegisteredUsersByDay_: IRegistrationByDayInterface[] = []
      const horses_: IHorseData[] = []

      const eventsSnaps = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
      const event_ = getConvertedData(EventModel.fromFirestoreDoc(eventsSnaps).toObject())

      const teamMembersSnaps = await FirestoreService.listItems(COLLECTIONS.HORSE_TEAM.NAME)

      teamMembersSnaps.forEach((horse) => {
        teamMembers_.push(HorseTeamModel.fromFirestoreDoc(horse).toObject())
      })

      const eventRegisteredUsersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        [where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId)]
      )

      eventRegisteredUsersSnaps.forEach((currDoc) => {
        eventRegisteredUsers_.push(
          getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      const registeredUsersByDaySnaps = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [
          where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId),
          where(
            COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.KEY,
            '!=',
            COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
          ),
        ]
      )

      registeredUsersByDaySnaps.docs.forEach((currDoc) => {
        const current = getConvertedData(
          RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject()
        )

        const isExist = registeredUsersByDay_.find(
          (registered) =>
            registered.riderId === current.riderId && registered.horseId === current.horseId
        )

        if (!isExist?.id) uniqueRegisteredUsersByDay_.push(current)

        registeredUsersByDay_.push(current)
      })

      const eventFeesSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)

      const registrationFees_ = new EventFeesModel(eventFeesSnapShot.data()).toFirestore()

      const horsesSnaps = await FirestoreService.filterItems(COLLECTIONS.HORSES.NAME)

      horsesSnaps.forEach((currDoc) => {
        horses_.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })

      setEvent(event_)
      setTeamMembers(teamMembers_)
      setEventRegisteredUsers(eventRegisteredUsers_)
      setRegisteredUsersByDay(registeredUsersByDay_)
      setUniqueRegisteredUsersByDay(uniqueRegisteredUsersByDay_)
      setRegistrationFees(registrationFees_)
      setRegistrationFees(registrationFees_)
      setHorses(horses_)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.EVENT_DATA_NOT_AVAILABLE,
      })
    }
  }

  useEffect(() => {
    getAllData().then()
  }, [eventId])

  return loading ? (
    <div className="flex w-full justify-center items-center">
      <ViewsLoader />
    </div>
  ) : (
    <>
      {activeOption === 'Event Reports' && (
        <EventReportsReport
          event={event}
          eventRegisteredUsers={eventRegisteredUsers}
          uniqueRegisteredUsersByDay={uniqueRegisteredUsersByDay}
          registeredUsersByDay={registeredUsersByDay}
          registrationFees={registrationFees}
          teamMembers={teamMembers}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />
      )}
      {activeOption === 'Event Results' && (
        <EventResultsReport
          event={event}
          registrationFees={registrationFees}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
          registeredUsersByDay={registeredUsersByDay}
          horses={horses}
          users={users}
          teamMembers={teamMembers}
        />
      )}
    </>
  )
}

export default ManageClinicReportsRoot
