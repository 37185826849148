import React, { FC, useEffect, useState } from 'react'

import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { useParams } from 'react-router'

import { DaysTabs } from '../common/DaysTabs/DaysTabs'
import TogglesElement from '../../elements/toggles/toggles/TogglesElement'
import Accordion from '../../accordion/common/Accordion'
import ExhibitorOrderOfGoAccordion from './details/accordions/ExhibitorOrderOfGoAccordion'
import ViewsLoader from '../../loader/ViewsLoader'

import useHorse from '../../../pages/organizer/manage/common/table/HorsesManage/hooks/useHorse'
import { getRegisteredRiders } from '../../../helpers/riders'
import { getUserFullName } from '../../../helpers/helpers'
import { getConvertedData } from '../../../models/interface.helper'
import { daysOfWeek } from '../../../helpers/time'

import FirestoreService from '../../../services/firestoreService'

import { useAppSelector } from '../../../store/hooks'
import { selectAllUsers } from '../../../store/users/usersSlice'

import { IClassOption, IClassOptionRegistered } from './details/EventOrderOfGo'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { EventFeesModel } from '../../../models/event-fees/event-fees.model'
import { IBreak } from '../../../models/events/event.interface'
import { EventModel } from '../../../models/events/event.model'

import { CONST } from '../../../const/const'
import { getFloatPrice } from '../../../helpers/price'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface ScheduleByClassProps {
  activeDay: string
  setActiveDay: (value: string) => void
  days: string[]
  setDays: (value: string[]) => void
}

export const ScheduleByClass: FC<ScheduleByClassProps> = ({
  activeDay,
  setActiveDay,
  days,
  setDays,
}) => {
  const eventId = useParams<{ eventId: string }>().eventId ?? null
  const { horses } = useHorse(eventId)

  const users = useAppSelector(selectAllUsers)

  const [hideEmptyClasses, setHideEmptyClasses] = React.useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [breaks, setBreaks] = useState<IBreak[]>([])
  const [filteredClasses, setFilteredClasses] = useState<IClassOptionRegistered[] | null>(null)
  const [classes, setClasses] = useState<IClassOptionRegistered[] | null>(null)

  useEffect(() => {
    if (hideEmptyClasses)
      setFilteredClasses(classes?.filter((item) => item.registered && item.registered.length) ?? [])
    else setFilteredClasses(classes)
  }, [classes, hideEmptyClasses])

  const handleGetFeesData = async () => {
    setLoading(true)

    try {
      const riders = await getRegisteredRiders([eventId])

      const eventSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
      const eventData = getConvertedData(EventModel.fromFirestoreDoc(eventSnapShot).toObject())

      const eventFeesSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)
      const registrationFees = new EventFeesModel(eventFeesSnapShot.data()).toFirestore()

      const days_: string[] = []

      const classOption: IClassOption[] = Object.values(
        [...registrationFees?.registrationFees].reduce((acc, item) => {
          const day = daysOfWeek[new Date(item.startDate)?.getDay()]
          if (!days_.includes(day)) days_.push(day)

          acc[item.uuid] = {
            value: item.uuid,
            label: item.name,
            actualCostAlias: item.actualCostAlias,
            dayOfWeek: day,
            fullTime: `${item.startTimeHours}:${item.startTimeMinutes} ${item.startTimeFormat}`,
            increment: item.increment,
            publishOrder: item.publishOrder,
            location: item.location,
            order: item.order || '',
          }
          return acc
        }, {})
      )

      setDays(
        days_.sort((a, b) => {
          return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
        })
      )

      const combinedArray = classOption?.map((item1) => {
        const correspondingItem = riders.filter((item2) => item1.value === item2.uuid)

        const uniqueData = correspondingItem.reduce(
          (acc: IRegistrationByDayInterface[], currentItem) => {
            // Check if the riderId of the current item is already in the accumulator
            const isRiderIdExist = acc.some(
              (item) => item.riderId === currentItem.riderId && item.horseId === currentItem.horseId
            )

            // If riderId doesn't exist, add the current item to the accumulator
            if (!isRiderIdExist) {
              const newItem = {
                ...currentItem,
              }

              const riderAsUserObj = users.find((user) => user.id === currentItem.riderId)

              acc.push({
                ...newItem,
                riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : currentItem.riderName,
              })
            }
            return acc
          },
          []
        )

        return {
          ...item1,
          registered: uniqueData,
        }
      })
      setClasses(combinedArray as IClassOptionRegistered[])

      setBreaks(eventData?.breaks ?? [])
    } catch (error) {
      console.error(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetFeesData().then()
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[100px]">
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[30vh] mt-3"
          size="lg"
          color="#F70763"
        />
      </div>
    )
  }

  return filteredClasses && filteredClasses.length > 0 ? (
    <>
      <div className={'flex w-full justify-between items-center gap-3 flex-wrap'}>
        <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} />
        <div className={'flex gap-2 items-center mb-6'}>
          <span className={'text-sm'}>Hide empty classes</span>
          <TogglesElement
            checkedMessage=""
            uncheckedMessage=""
            buttonClassName="h-[27px] w-[50px] rounded-full"
            on={hideEmptyClasses}
            onToggle={() => setHideEmptyClasses((val) => !val)}
          />
        </div>
      </div>
      {filteredClasses?.map((item, index) => {
        const classBreaks = breaks.filter((b) => b.registrationByDayName === item.value)
        let registered = 0
        if (item.registered && Array.isArray(item.registered)) registered = item.registered.length

        if (item.dayOfWeek === activeDay || activeDay === 'All') {
          return (
            <Accordion
              key={index}
              icon={<AddCircleOutline className="add_icon text-[red]" />}
              initialState={false}
              status={!item.publishOrder ? 'Pending' : 'Available'}
              className="mb-2 w-full"
              header={
                <div className="flex flex-col xl:flex-row gap-2 text-[14px] justify-between pr-[10px]">
                  <div className="flex gap-2 items-center">
                    {item.label}
                    {item.order && (
                      <span className="text-[14px] text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5 rounded-full py-0.5 px-3">
                        {item.order}
                      </span>
                    )}
                  </div>

                  <div className={'text-[#122B4680] font-normal'}>
                    <span className={'customValue text-[#122B46]'}>
                      {getFloatPrice(`${item.actualCostAlias}`) || '$0'}
                    </span>{' '}
                    entry fee • <span className={'customValue text-[#122B46]'}>{registered}</span>{' '}
                    registered •{' '}
                    <span className={'customValue text-[#122B46]'}>{item.fullTime}</span>,{' '}
                    {item.dayOfWeek} • <span className="text-[#122B46]">{item.location}</span>
                  </div>
                </div>
              }
              headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
              activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
            >
              <ExhibitorOrderOfGoAccordion
                {...item}
                data={[...item.registered, ...classBreaks]}
                horses={horses}
                eventId={eventId}
                isRiderMode={false}
              />
            </Accordion>
          )
        } else {
          return null
        }
      })}
    </>
  ) : (
    <p className="text-SeabiscuitDark200ThemeColor/50">Classes not found</p>
  )
}
