import React, { FC, useEffect, useState } from 'react'
import moment from 'moment/moment'

import MainModal from './common/MainModal'

import { IRegisterTab } from '../../models/event-registered-users/event-registered-users.interface'

import { MODAL_CONSTS } from '../../const/modal-const'

import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { getRegisteredRiders } from '../../helpers/riders'

interface ClassMoreProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string) => void
  dataToPassOn: { row: IRegisterTab; eventId: string }
}
export const ClassMore: FC<ClassMoreProps> = ({ show, handleModal, dataToPassOn }) => {
  const [registeredRiders, setRegisteredRiders] = useState<IRegistrationByDayInterface[]>([])

  const getCurrentRiders = async () => {
    const registeredRiders_ = await getRegisteredRiders([dataToPassOn.eventId])

    const uniqueRegisteredRiders: IRegistrationByDayInterface[] = []
    registeredRiders_.forEach((rider) => {
      const isFind = uniqueRegisteredRiders.find(
        (unique) => unique.riderId === rider.riderId && unique.horseId === rider.horseId
      )

      if (!isFind?.id) {
        uniqueRegisteredRiders.push(rider)
      }
    })

    setRegisteredRiders(uniqueRegisteredRiders)
  }

  useEffect(() => {
    getCurrentRiders().then()
  }, [dataToPassOn.row])

  return (
    <MainModal
      title="More"
      show={show}
      type="CLASS_MORE"
      size="md"
      buttons={[
        {
          label: 'CLOSE',
          fullWidth: true,
          bgClass: 'bg-SeabiscuitDark100ThemeColor/10',
          borderClass: 'border-SeabiscuitDark100ThemeColor/50',
          textClass: 'text-SeabiscuitDark100ThemeColor/50',
          onClick: () => handleModal(false, MODAL_CONSTS.CLASS_MORE),
        },
      ]}
    >
      <div className="text-SeabiscuitDark200ThemeColor">
        <div className="flex flex-wrap gap-4 justify-between mb-6 pb-6 border-b border-SeabiscuitLightThemeColorD3">
          <div className="flex flex-col flex-1 gap-5 justify-between">
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center w-[45px] min-w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Clock-2.svg" className="w-6" alt="" />
              </div>
              <div>
                <div className="text-xs opacity-50">Date • Time</div>
                <span>
                  {moment(dataToPassOn.row.startDate as Date).format('dddd, D MMM ')} •{' '}
                  {dataToPassOn.row.startTimeHours}:{dataToPassOn.row.startTimeMinutes}{' '}
                  {dataToPassOn.row.startTimeFormat}
                </span>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex items-center justify-center w-[45px] min-w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Prize-2.svg" className="w-6" alt="" />
              </div>
              <div>
                <div className="text-xs opacity-50">Class • Number</div>
                <span>{dataToPassOn.row.registrationByDayName ?? 'N/A'}</span>
              </div>
            </div>
          </div>
          <div className="ml-auto flex flex-col gap-5  items-end text-right">
            <div className="flex items-center gap-2">
              <div>
                <div className="text-xs opacity-50">
                  Entry fee • {dataToPassOn.row.qualifyFeeName || 'Qual fee'}
                </div>
                <span>
                  ${dataToPassOn.row.price ?? 0} • ${dataToPassOn.row.qualifyFee ?? 0}
                </span>
              </div>
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Bill-2.svg" className="w-6" alt="" />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div>
                <div className="text-xs opacity-50">Registered</div>
                <span>{registeredRiders.length} rides</span>
              </div>
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/People-2.svg" className="w-6" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="border boder-SeabiscuitLightThemeColorD3 rounded-md p-4">
          {dataToPassOn.row.feesNote ?? 'No Description'}
        </div>
      </div>
    </MainModal>
  )
}
