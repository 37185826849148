// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'

// Components
import ExhibitorProfileEligabilityTab from './ExhibitorProfileEligabilityTab'
import ExhibitorProfileFeesTab from './ExhibitorProfileFeesTab'
import ExhibitorProfileTeamTab from './ExhibitorProfileTeamTab'
import ExhibitorProfileHorsesTab from './ExhibitorProfileHorsesTab'
import ExhibitorProfileRegistrationTab from './ExhibitorProfileRegistrationTab'
import ExhibitorProfilePaperworkTab from './ExhibitorProfilePaperworkTab'
import ExhibitorProfileTicketsTab from './ExhibitorProfileTicketsTab'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

// Models
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'

// Redux
import {
  IScratch,
  resetScratchItems,
  selectScratch,
  setScratchType,
} from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Helpers
import { useContext, useEffect, useState } from 'react'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { httpService } from '../../../services/httpService'
import { selectRecipientR } from '../../../store/exhibitor/exhibitorSlice'
import ExhibitorProfileRefundTab from './ExhibitorProfileRefundTab'
import RefundPageFooter from './footer/RefundPageFooter'
import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import { AutorenewRounded } from '@mui/icons-material'
import ExhibitorProfileRegistrationTabNew from './ExhibitorProfileRegistrationTabNew'
import { IUserInterface } from '../../../models/users/user.interface'
import { organizerSliceAcs } from '../../../store/organizer/organizerSlice'
import { ExhibitorProfileAnswersTab } from './ExhibitorProfileAnswersTab'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { getRegistrationData } from '../../../helpers/registration'

// Types
type IOnRefundAmountChangeFnArgs = {
  type: 'classes' | 'tickets' | 'fees'
  itemDetails: {
    itemId: string
    itemRefundAmount: number
  }
}

export type IOnRefundAmountChangeFn = (args: IOnRefundAmountChangeFnArgs) => void

type IScratchItem =
  | IRegistrationTicketInterface
  | IRegistrationFeesInterface
  | IRegistrationByDayInterface

type ExhibitorProfileDisplayTabProps = {
  eventTab: string
  isRecipient?: boolean
  isRefund?: boolean
  eventData?: IEventReviewPublish | null
  isCompetitorView?: boolean
  showScratchedView: boolean
  exhibitorAllData?: TEventRegisteredUsers | null
  handleScratchView: (visible: boolean) => void
  userId: string
  registrationId: string
  exhibitor: IUserInterface | null
  eventId: string
}

const ExhibitorProfileDisplayTab = ({
  isRefund,
  isRecipient,
  eventData,
  userId,
  registrationId,
  eventId,
  eventTab,
  exhibitor,
  exhibitorAllData,
  handleScratchView,
  showScratchedView,
}: ExhibitorProfileDisplayTabProps) => {
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const recipientR = useAppSelector(selectRecipientR)
  const scratchDetails = useAppSelector(selectScratch)

  const handleModal = useContext(HandleModalContext)?.handleModal

  const [uiLoading, setUiLoading] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [listToScratch, setListToScratch] = useState<string[]>([])
  const [resetState, setresetState] = useState<boolean>(false)
  const [sortedArray, setSortedArray] = useState<IRegistrationByDayInterface[][]>([])
  const [currentRegistrationsByDay, setCurrentRegistrationsByDay] = useState<
    IRegistrationByDayInterface[]
  >([])
  const [registrationfeesData, setRegistrationfeesData] = useState<IRegistrationFeesInterface[]>([])
  const [registrationticketData, setRegistrationTicketData] = useState<
    IRegistrationTicketInterface[]
  >([])

  useEffect(() => {
    if (currentRegistrationsByDay[0]) {
      const groupedByRiderId = currentRegistrationsByDay.reduce<
        Record<string, IRegistrationByDayInterface[]>
      >((acc: any, item) => {
        if (!item.riderId) return null
        if (!acc[item.riderId]) {
          acc[item.riderId] = []
        }
        acc[item.riderId].push(item)
        return acc
      }, {})
      const result = Object.values(groupedByRiderId)
      setSortedArray(result)
      dispatch(organizerSliceAcs.setCurrentEntries(result))
    }
  }, [currentRegistrationsByDay])

  const loadRegistrationData = async (userId: string, registrationId: string, eventId: string) => {
    setUiLoading(true)
    const { registrationsByDay, registrationsFees, registrationsTickets } =
      await getRegistrationData(userId, registrationId, eventId)

    setCurrentRegistrationsByDay(registrationsByDay)
    setRegistrationfeesData(registrationsFees)
    setRegistrationTicketData(registrationsTickets)

    setUiLoading(false)
  }

  useEffect(() => {
    if (userId && registrationId && eventId) {
      loadRegistrationData(userId, registrationId, eventId).then()
    }
  }, [userId, registrationId, eventId])

  const closeScratchView = () => {
    dispatch(resetScratchItems())
    return handleScratchView(false)
  }

  useEffect(() => {
    if (!scratchDetails.scratchViewVisibility) closeScratchView()
  }, [scratchDetails.scratchViewVisibility])

  // Functions
  const onSubmitScratch = async (scratchType: IScratch['scratchType']) => {
    setLoading(true)

    try {
      let items: IScratchItem[] = []

      let scratchedRegistrations: {
        scratchedItems: IRegistrationByDayInterface[]
        scratchTotal: number
      } = { scratchedItems: [], scratchTotal: 0 }
      let scratchedFees: {
        scratchedItems: IRegistrationFeesInterface[]
        scratchTotal: number
      } = { scratchedItems: [], scratchTotal: 0 }
      let scratchedTickets: {
        scratchedItems: IRegistrationTicketInterface[]
        scratchTotal: number
      } = { scratchedItems: [], scratchTotal: 0 }

      switch (eventTab) {
        case 'Classes':
          items = currentRegistrationsByDay
          break
        case 'Entries':
          items = currentRegistrationsByDay
          break
        case 'Fees':
          items = registrationfeesData
          break
        case 'Tickets':
          items = registrationticketData
          break
      }

      items.forEach((item) => {
        if (listToScratch.find((data) => data === item.id)) {
          if ('registrationPrice' in item) {
            scratchedRegistrations.scratchTotal += item.registrationPrice ?? 0
            scratchedRegistrations.scratchedItems.push({
              ...item,
              amountScratched: item.registrationPrice ? item.registrationPrice : 0,
              isScratched: true,
              scratchAmountAdded: true,
            })
          } else if ('ticketPrice' in item) {
            scratchedTickets.scratchTotal += (item.ticketPrice ?? 0) * item.selectedUnitsCount
            scratchedTickets.scratchedItems.push({
              ...item,
              amountScratched: item.ticketPrice,
              isScratched: true,
              scratchAmountAdded: true,
            })
          } else if ('feesPrice' in item) {
            scratchedFees.scratchTotal += (item.feesPrice ?? 0) * item.selectedUnitsCount
            scratchedFees.scratchedItems.push({
              ...item,
              amountScratched: item.feesPrice,
              isScratched: true,
              scratchAmountAdded: true,
            })
          }
        }
      })

      if (listToScratch.length < 1) {
        return toastFunction.info({
          message: MESSAGES_CONST.NO_ITEM_SELECTED_TO_SCRATCH,
        })
      }

      await httpService({
        url: 'scratch_items',
        method: 'POST',
        data: {
          userDocId: userId,
          recipientId: recipientR.data?.recipientId,
          registrationDocId: recipientR.data?.registrationDocId,
          scratchedRegistrationByDayItems: scratchedRegistrations.scratchedItems?.map(({ id }) => ({
            docId: id,
          })),
          scratchedFeesItems: scratchedFees.scratchedItems?.map(({ id }) => ({ docId: id })),
          scratchedTicketItems: scratchedTickets.scratchedItems?.map(({ id }) => ({ docId: id })),
        },
      })

      const totalScratchAmount =
        scratchedRegistrations.scratchTotal +
        scratchedFees.scratchTotal +
        scratchedTickets.scratchTotal

      setresetState(!resetState)
      dispatch(setScratchType(scratchType))
      handleScratchView(false)

      handleModal?.(true, MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL, {
        ...recipientR.data,
        scratchTotal: totalScratchAmount,
      })
    } catch (error: any) {
      console.error(error, 'error')
      toastFunction.error({
        message: error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  const onRefundAmountChange: IOnRefundAmountChangeFn = ({ itemDetails, type }) => {
    if (type === 'classes') {
      let registrationData_ = currentRegistrationsByDay.map((currentRegistrationByDay) => {
        if (currentRegistrationByDay.id === itemDetails.itemId) {
          return {
            ...currentRegistrationByDay,
            amountRefunded: itemDetails.itemRefundAmount,
          }
        } else {
          return currentRegistrationByDay
        }
      })

      setCurrentRegistrationsByDay(registrationData_)
    }

    if (type === 'fees') {
      let registrationfeesData_ = registrationfeesData.map((currRegistrationFees) => {
        if (currRegistrationFees.id === itemDetails.itemId) {
          return {
            ...currRegistrationFees,
            amountRefunded: itemDetails.itemRefundAmount,
          }
        } else {
          return currRegistrationFees
        }
      })

      setRegistrationfeesData(registrationfeesData_)
    }

    if (type === 'tickets') {
      let registrationticketData_ = registrationticketData.map((currRegistrationTicket) => {
        if (currRegistrationTicket.id === itemDetails.itemId) {
          return {
            ...currRegistrationTicket,
            amountRefunded: itemDetails.itemRefundAmount,
          }
        } else {
          return currRegistrationTicket
        }
      })
      setRegistrationTicketData(registrationticketData_)
    }
  }

  const getScratchView = (scratchType: IScratch['scratchType']): ReactJSXElement | null => {
    let disabled = false
    if (registrationfeesData.length) {
      let count = 0
      registrationfeesData.forEach((data) => {
        if (data.isScratched) {
          count++
        }
      })
      if (count === registrationfeesData.length) {
        disabled = true
      }
    } else if (registrationticketData.length) {
      let count = 0
      registrationticketData.forEach((data) => {
        if (data.isScratched) {
          count++
        }
      })
      if (count === registrationticketData.length) {
        disabled = true
      }
    } else if (currentRegistrationsByDay.length) {
      let count = 0
      currentRegistrationsByDay.forEach((data) => {
        if (data.isScratched) {
          count++
        }
      })
      if (count === currentRegistrationsByDay.length) {
        disabled = true
      }
    }

    if (!showScratchedView) return null

    return (
      <div className="w-full">
        <button
          onClick={() => onSubmitScratch(scratchType)}
          type="button"
          disabled={disabled}
          className={`${disabled ? 'opacity-40' : ''} w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor  mt-2 uppercase`}
        >
          {loading ? (
            <AutorenewRounded fontSize="small" className="animate-spin" />
          ) : (
            <>{eventData && eventData?.status === 'cancel' ? 'Submit' : 'Scratch'}</>
          )}
        </button>
        <button
          onClick={closeScratchView}
          type="button"
          className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor mt-2 uppercase"
        >
          Cancel
        </button>
      </div>
    )
  }

  const selectTab = (event_tab: string) => {
    switch (event_tab) {
      case CONST.UI.EXHIBITOR.TABS.PAPERWORK.VALUE:
        return (
          <ExhibitorProfilePaperworkTab
            exhibitorAllData={exhibitorAllData}
            isRecipient={isRecipient}
            registrationsByDay={currentRegistrationsByDay}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.FEES.VALUE:
        return (
          <ExhibitorProfileFeesTab
            isRecipient={isRecipient}
            getScratchView={getScratchView}
            exhibitorAllData={exhibitorAllData}
            showScratchedView={showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            registrationfeesData={registrationfeesData}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.TICKETS.VALUE:
        return (
          <ExhibitorProfileTicketsTab
            isRecipient={isRecipient}
            getScratchView={getScratchView}
            exhibitorAllData={exhibitorAllData}
            showScratchedView={showScratchedView}
            registrationticketData={registrationticketData}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.ELIGABILITY.VALUE:
        return (
          <ExhibitorProfileEligabilityTab
            isRecipient={isRecipient}
            exhibitorAllData={exhibitorAllData}
          />
        )
      case CONST.UI.EXHIBITOR.TABS.CLASSES.VALUE:
        return (
          <ExhibitorProfileRegistrationTab
            getScratchView={getScratchView}
            showScratchedView={showScratchedView}
            registrationsByDay={currentRegistrationsByDay}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            eventId={eventId}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.ENTRIES.VALUE:
        return sortedArray.map((registrationByDay, index) => (
          <ExhibitorProfileRegistrationTabNew
            key={index}
            registeredBy={exhibitor ? getUserFullName(exhibitor) : ''}
            getScratchView={getScratchView}
            showScratchedView={showScratchedView}
            registrationsByDay={registrationByDay}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            eventId={eventId}
          />
        ))

      case CONST.UI.EXHIBITOR.TABS.TEAM.VALUE:
        return (
          <ExhibitorProfileTeamTab isRecipient={isRecipient} exhibitorAllData={exhibitorAllData} />
        )

      case CONST.UI.EXHIBITOR.TABS.HORSES.VALUE:
        return (
          <ExhibitorProfileHorsesTab
            isRecipient={isRecipient}
            getScratchView={getScratchView}
            exhibitorAllData={exhibitorAllData}
            showScratchedView={showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            registrationfeesData={registrationfeesData}
          />
        )
      case CONST.UI.EXHIBITOR.TABS.ANSWERS.VALUE:
        return <ExhibitorProfileAnswersTab userId={userId} />
    }
  }

  if (uiLoading) {
    return (
      <div className="h-fit my-10 flex justify-center items-center">
        <ViewsLoader size="lg" color="#F70763" />
      </div>
    )
  }

  return isRefund ? (
    <>
      <ExhibitorProfileRegistrationTab
        isRefund
        isRecipient={isRecipient}
        registrationsByDay={currentRegistrationsByDay}
        getScratchView={getScratchView}
        onRefundAmountChange={onRefundAmountChange}
        showScratchedView={showScratchedView}
        setListToScratch={setListToScratch}
        listToScratch={listToScratch}
        eventId={eventId}
      />
      <ExhibitorProfileFeesTab
        isRefund
        isRecipient={isRecipient}
        getScratchView={getScratchView}
        exhibitorAllData={exhibitorAllData}
        showScratchedView={showScratchedView}
        setListToScratch={setListToScratch}
        onRefundAmountChange={onRefundAmountChange}
        listToScratch={listToScratch}
        registrationfeesData={registrationfeesData}
      />
      <ExhibitorProfileTicketsTab
        isRefund
        isRecipient={isRecipient}
        getScratchView={getScratchView}
        exhibitorAllData={exhibitorAllData}
        showScratchedView={showScratchedView}
        onRefundAmountChange={onRefundAmountChange}
        registrationticketData={registrationticketData}
        setListToScratch={setListToScratch}
        listToScratch={listToScratch}
      />
      <ExhibitorProfileRefundTab />
      <RefundPageFooter
        userDocId={userId}
        registrationId={registrationId}
        registrationData={currentRegistrationsByDay}
        setRegistrationData={setCurrentRegistrationsByDay}
        registrationfeesData={registrationfeesData}
        registrationticketData={registrationticketData}
        setRegistrationfeesData={setRegistrationfeesData}
        setRegistrationTicketData={setRegistrationTicketData}
      />
    </>
  ) : (
    <>{selectTab(eventTab)}</>
  )
}

export default ExhibitorProfileDisplayTab
