import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { useOutsideClick } from '../../../../../../../hooks/useOutsideClick'
import { convertTo12HourFormat, convertTo24HourFormat } from '../../../../../../../helpers/time'

// Custom styling for React Select
const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#f0f0f0',
    border: 'none',
    boxShadow: 'none',
    padding: '0px !important',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#4b5563',
  }),
  input: (styles: any) => ({
    ...styles,
    width: '30px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: '#f0f0f0',
    border: '1px solid #122B4680',
    fontSize: '14px',
    padding: '0px',
    boxShadow: 'none',
    borderRadius: '0px 0px 8px 8px',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#f0f0f0',
    zIndex: '9999',
    border: '1px solid #1f41731a',
    boxShadow: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: 10,
    ':hover': {
      background: '#F6F7FB',
      color: '#122B46',
    },
    backgroundColor: state.isSelected ? '#122B46' : '#f0f0f0',
    color: state.isSelected ? '#fff' : '#122B46',
    textTransform: 'capitalize',
    fontSize: 14,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
}

const Hours = Array.from({ length: 12 }, (_, i) => ({
  value: (i + 1).toString().padStart(2, '0'),
  label: (i + 1).toString().padStart(2, '0'),
}))

const Minutes = Array.from({ length: 60 }, (_, i) => ({
  value: i.toString().padStart(2, '0'),
  label: i.toString().padStart(2, '0'),
}))

const Seconds = Array.from({ length: 60 }, (_, i) => ({
  value: i.toString().padStart(2, '0'),
  label: i.toString().padStart(2, '0'),
}))

const Periods = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
]

interface RideTimeSelectorProps {
  initialTime?: string
  saveHandler: (time: string) => void
  isOutOfOrder: boolean
  isDuplicate: boolean
}

const RideTimeSelector: React.FC<RideTimeSelectorProps> = ({
  initialTime,
  saveHandler,
  isOutOfOrder,
  isDuplicate,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [isEditingPending, setIsEditingPending] = useState(false)
  const ref = useOutsideClick(() => {
    setEditMode(false)
  })

  const [selectedTime, setSelectedTime] = useState<{
    hour: string
    minute: string
    second: string
    period: string
  }>(() =>
    initialTime
      ? convertTo12HourFormat(initialTime)
      : { hour: '00', minute: '00', second: '00', period: 'AM' }
  )

  const handleOpenEditMode = () => {
    if (!initialTime) {
      setSelectedTime({ hour: '08', minute: '00', second: '00', period: 'AM' })
      setIsEditingPending(true) // Flag to enable editMode once state updates
    } else {
      setSelectedTime(convertTo12HourFormat(initialTime))
      setEditMode(true)
    }
  }

  // Enable edit mode after selectedTime updates
  useEffect(() => {
    if (isEditingPending) {
      setEditMode(true)
      setIsEditingPending(false) // Reset pending state
    }
  }, [selectedTime, isEditingPending])

  const handleTimeChange = (
    type: 'hour' | 'minute' | 'second' | 'period',
    option: SingleValue<{ value: string; label: string }>
  ) => {
    if (option) {
      setSelectedTime((prev) => ({ ...prev, [type]: option.value }))
    }
  }

  const handleSave = () => {
    const formattedTime = convertTo24HourFormat(
      selectedTime.hour,
      selectedTime.minute,
      selectedTime.second,
      selectedTime.period
    )
    saveHandler(formattedTime)
    setEditMode(false)
  }

  if (!editMode) {
    return (
      <div
        className="cursor-pointer text-center min-w-[100px] text-gray-700"
        onClick={(e) => {
          e.stopPropagation() // Ensure click event doesn't get blocked
          handleOpenEditMode()
        }}
      >
        {initialTime ? (
          isDuplicate ? (
            <Tooltip
              placement="top"
              arrow
              title={<h4 className="tooltip_title">Duplicate ride time detected</h4>}
            >
              <span className="text-red-500 font-medium cursor-pointer">{`${selectedTime.hour}:${selectedTime.minute}:${selectedTime.second} ${selectedTime.period}`}</span>
            </Tooltip>
          ) : isOutOfOrder ? (
            <Tooltip
              placement="top"
              arrow
              title={<h4 className="tooltip_title">Ride time out of order</h4>}
            >
              <span className="text-[#3852FB] font-medium cursor-pointer">{`${selectedTime.hour}:${selectedTime.minute}:${selectedTime.second} ${selectedTime.period}`}</span>
            </Tooltip>
          ) : (
            <span className="text-gray-700">{`${selectedTime.hour}:${selectedTime.minute}:${selectedTime.second} ${selectedTime.period}`}</span>
          )
        ) : (
          <span className="text-gray-600">Set Time</span> // Placeholder for unset rideTime
        )}
      </div>
    )
  }

  return (
    <div ref={ref} className="flex min-w-[265px] items-center text-center">
      <div onClick={(e) => e.stopPropagation()} className="flex w-full">
        <Select
          classNamePrefix="select"
          options={Hours}
          value={Hours.find((h) => h.value === selectedTime.hour)}
          onChange={(opt) =>
            handleTimeChange('hour', opt as SingleValue<{ value: string; label: string }>)
          }
          styles={selectStyles as any}
          className="flex-1 px-[0!important] h-[40px] bg-[#f0f0f0] flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:outline-0 no-underline rounded-l-[4px]"
          isClearable={false}
          components={{ DropdownIndicator: null }}
          isSearchable={true}
        />
      </div>

      <div onClick={(e) => e.stopPropagation()} className="flex w-full">
        <Select
          classNamePrefix="select"
          options={Minutes}
          value={Minutes.find((m) => m.value === selectedTime.minute)}
          onChange={(opt) =>
            handleTimeChange('minute', opt as SingleValue<{ value: string; label: string }>)
          }
          styles={selectStyles as any}
          className="flex-1 px-[0!important] h-[40px] border-none flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-[#f0f0f0] !rounded-none"
          isClearable={false}
          components={{ DropdownIndicator: null }}
          isSearchable={true}
        />
      </div>

      <div onClick={(e) => e.stopPropagation()} className="flex w-full">
        <Select
          classNamePrefix="select"
          options={Seconds}
          value={Seconds.find((s) => s.value === selectedTime.second)}
          onChange={(opt) => handleTimeChange('second', opt)}
          styles={selectStyles as any}
          className="flex-1 h-[40px] border-none flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-[#f0f0f0] !rounded-none"
          isClearable={false}
          isSearchable
        />
      </div>

      <div onClick={(e) => e.stopPropagation()} className="flex w-full">
        <Select
          classNamePrefix="select"
          options={Periods}
          value={Periods.find((p) => p.value === selectedTime.period)}
          onChange={(opt) =>
            handleTimeChange('period', opt as SingleValue<{ value: string; label: string }>)
          }
          styles={selectStyles as any}
          className="flex-1 px-[0!important] h-[40px] outline-0 flex items-center text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-[#f0f0f0] rounded-r-[4px]"
          isClearable={false}
          components={{ DropdownIndicator: null }}
          isSearchable={true}
        />
      </div>

      <button className="ms-3 text-[12px]" onClick={() => setEditMode(false)}>
        Cancel
      </button>
      <button className="ms-3 me-5 text-SeabiscuitMainThemeColor text-[12px]" onClick={handleSave}>
        Save
      </button>
    </div>
  )
}

export default RideTimeSelector
