import { UI_REGISTER_CONST } from '../../const/ui/ui-register.const'
import ITypes from './registrationSlice.types'

const baseDataForAccordion = {
  totalPrice: 0,
  recipientIds: [],
  recipientsCount: 0,
  totalItemsCount: 0,
  uniqueRecipientCount: 0,
}

const PAY_TAB: ITypes['IRegistrationInitialState']['tabs']['payTab'] = {
  registration: {
    ...baseDataForAccordion,
    totalHorseCount: 0,
    totalMemberCount: 0,
    uniqueHorseCount: 0,
    uniqueMemberCount: 0,
    totalRegistrationCount: 0,
  },
  ticket: baseDataForAccordion,
  fees: baseDataForAccordion,
  governance: baseDataForAccordion,
  grandTotal: {
    ...baseDataForAccordion,
    totalPriceWithoutSalesTax: 0,
    feesTotal: 0,
    ticketTotal: 0,
    registrationTotal: 0,
    governanceTotal: 0,
    salesTax: 0,
    prevTotalPrice: 0,
  },
  summary: {
    ...baseDataForAccordion,
    data: [],
    remainingTotalPrice: 0,
    recipientsTotalPrice: 0,
  },
  paperwork: {
    data: [],
    signatoryCount: 0,
  },
}

const TABS: ITypes['IRegistrationInitialState']['tabs'] = {
  feesTab: [],
  ticketTab: [],
  teamTab: [],
  registerTab: [],
  paperworkTab: [],
  payTab: PAY_TAB,
  signTab: {
    eventOwnerIsMinor: false,
    eventOwnerAdded: false,
    showGuardianMessage: false,
  },
}

const DATA: ITypes['IRegistrationInitialState']['data'] = {
  registration: null,
  recipientsInDb: [],
  publishedEventInDb: null,
  userTeamMembers: [],
  ridersTeamMembers: [],
  ridersTeamMembersInDb: [],
  registrationsFees: [],
  registrationsByDay: [],
  registrationsTickets: [],
  filteredPaperworkDocuments: [],
  teamTabDataInDb: [],
  registrationsByDayInDb: [],
  answers: [],
}

const REGISTRATION_INITIAL_STATE: ITypes['IRegistrationInitialState'] = {
  activeStep: 0,
  /** @info `true` specifies that the payment is in progress */
  paying: false,
  activeTab: UI_REGISTER_CONST.TABS.REVIEWANDPAY,
  loading: false,
  tabs: TABS,
  hasErrors: {
    users: false,
    horses: false,
  },
  data: DATA,
}

const REGISTRATION_SLICE_CONSTS = {
  INITIAL_STATE: REGISTRATION_INITIAL_STATE,
}

export default REGISTRATION_SLICE_CONSTS
