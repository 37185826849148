import { PillButtonElement } from '../../../../components/elements/buttons/pill/PillButtonElement'
import tabsJSon from './tabs.json'
import ITab from './tabs.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const Tabs = (props: ITab['ITabProps']) => {
  // Hooks and vars

  // Function

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onTabButtonClick = (tabName: ITab['ITabProps']['activeTab']) => {
    props.onTabClick(tabName)
  }

  return (
    <div className="flex justify-between w-full">
      <div className="flex">
        {tabsJSon.map((currTab) => {
          return (
            <PillButtonElement
              visible={true}
              key={currTab.tabName}
              text={currTab.tabName}
              value={currTab.tabName}
              className="text-SeabiscuitDark200ThemeColor border-none"
              selected={props.activeTab === currTab.ticketUrl}
              onButtonClicked={() => onTabButtonClick(currTab.ticketUrl as any)}
            />
          )
        })}
      </div>
      <div className="flex items-center">
        <div className="px-2 flex items-center gap-2 py-1 mx-auto rounded-lg">
          <div className="text-sm text-SeabiscuitDark200ThemeColor opacity-50">Checked In: </div>
          <div className="flex justify-center text-[#00B6AA]">
            {props.checkedIn} / {props.total}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
