import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import {
  IAnswer,
  IPaperworkTab_,
  IRegisterTab,
  IRegistrationTabs,
  TEventRegisteredUsers,
} from '../../models/event-registered-users/event-registered-users.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { RootState } from '../store'
import IConsts from './registrationSlice.const'

import { IDocumentList } from '../../fakeData/fakeDocumentList'
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import ITypes from './registrationSlice.types'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import { IRegistrationTeamInterface } from '../../models/registeration-teams/registration-teams.interface'

const registrationSlice = createSlice({
  name: 'registration',
  initialState: IConsts.INITIAL_STATE,
  reducers: {
    setRegisterTabData: (state, { payload }: { payload: IRegisterTab[] }) => {
      state.tabs.registerTab = payload
    },
    setRegistrationAnswers: (state, { payload }: { payload: IAnswer[] }) => {
      state.data.answers = payload
    },
    setHorseRegistrationErrors: (state, { payload }: { payload: boolean }) => {
      state.hasErrors.horses = payload
    },
    setUserRegistrationErrors: (state, { payload }: { payload: boolean }) => {
      state.hasErrors.users = payload
    },
    setTicketTabData: (state, { payload }: { payload: IRegistrationTabs['ITicketTab'][] }) => {
      state.tabs.ticketTab = payload
    },
    setTeamTabData: (state, { payload }: { payload: IRegistrationTabs['ITeamTab'] }) => {
      state.tabs.teamTab = payload
    },
    setFeesTabDataAc: (state, { payload }: { payload: IRegistrationTabs['IFeesTab'][] }) => {
      state.tabs.feesTab = payload
    },
    setRegistrationsByDay: (state, { payload }: { payload: IRegistrationByDayInterface[] }) => {
      state.data.registrationsByDay = payload
    },
    setRegistrationsTicketsAc: (
      state,
      { payload }: { payload: IRegistrationTicketInterface[] }
    ) => {
      state.data.registrationsTickets = payload
    },
    setRegistrationsFeesAc: (state, { payload }: { payload: IRegistrationFeesInterface[] }) => {
      state.data.registrationsFees = payload
    },
    setRegistrationLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload
    },
    setRegistrationAc: (state, { payload }: { payload: TEventRegisteredUsers | null }) => {
      state.data.registration = payload
    },
    updatePayTabGrandTotalAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabGrandTotalAccordionPayload']
      }
    ) => {
      state.tabs.payTab.grandTotal = {
        ...current(state.tabs.payTab.grandTotal),
        ...(payload ?? {}),
      }
    },
    setPaperworkTabData: (state, { payload }: { payload: IPaperworkTab_[] }) => {
      state.tabs.paperworkTab = payload
    },
    setUserTeamMembers: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IRegistrationInitialState']['data']['userTeamMembers']
      }
    ) => {
      state.data.userTeamMembers = payload
    },
    setRidersTeamMembers: (state, { payload }: { payload: IRiderTeamMemberInterface[] }) => {
      state.data.ridersTeamMembers = payload
    },
    setRidersTeamMembersInDb: (state, { payload }: { payload: IRiderTeamMemberInterface[] }) => {
      state.data.ridersTeamMembersInDb = payload
    },
    setTeamTabDataInDb: (state, { payload }: { payload: IRegistrationTeamInterface[] }) => {
      state.data.teamTabDataInDb = payload
    },
    setRegistrationsByDayInDb: (state, { payload }: { payload: IRegistrationByDayInterface[] }) => {
      state.data.registrationsByDayInDb = payload
    },
    setFilteredPaperworkDocuments: (state, { payload }: { payload: IDocumentList }) => {
      state.data.filteredPaperworkDocuments = payload
    },
    setSignTab: (
      state,
      { payload }: { payload: ITypes['IRegistrationInitialState']['tabs']['signTab'] }
    ) => {
      state.tabs.signTab = payload
    },
    setRecipientsInDb: (
      state,
      { payload }: { payload: ITypes['IRegistrationInitialState']['data']['recipientsInDb'] }
    ) => {
      state.data.recipientsInDb = payload
    },
    setPublishedEventInDb: (state, { payload }: { payload: IEventReviewPublish | null }) => {
      state.data.publishedEventInDb = payload
    },
    setSendMailLoading: (
      state,
      {
        payload,
      }: {
        payload: {
          sendMailIndex: ITypes['IRegistrationInitialState']['tabs']['signTab']['sendMailIndex']
          sendMailLoading: ITypes['IRegistrationInitialState']['tabs']['signTab']['sendMailLoading']
        }
      }
    ) => {
      if (typeof payload.sendMailIndex === 'number' || payload.sendMailIndex === null)
        state.tabs.signTab.sendMailIndex = payload.sendMailIndex

      if (typeof payload.sendMailLoading === 'boolean')
        state.tabs.signTab.sendMailLoading = payload.sendMailLoading
    },
    setPaying: (state, { payload }: PayloadAction<boolean>) => {
      state.paying = payload
    },
  },
})

// Exports

export const {
  setUserRegistrationErrors,
  setHorseRegistrationErrors,
  setTicketTabData,
  setTeamTabData,
  setFeesTabDataAc,
  setRegistrationAc,
  setRegisterTabData,
  setRegistrationsByDay,
  setRegistrationLoading,
  setSignTab,
  setRecipientsInDb,
  setPublishedEventInDb,
  setPaperworkTabData,
  setRegistrationsTicketsAc,
  setSendMailLoading,
  setRegistrationsFeesAc,
  updatePayTabGrandTotalAccordion,
  setUserTeamMembers,
  setRidersTeamMembers,
  setRidersTeamMembersInDb,
  setTeamTabDataInDb,
  setRegistrationsByDayInDb,
  setFilteredPaperworkDocuments,
  setPaying,
  setRegistrationAnswers,
} = registrationSlice.actions

export const selectRegistrationErrors = (state: RootState) => state.registration.hasErrors
export const selectFeesTabDataR = (state: RootState) => state.registration.tabs.feesTab
export const selectRegistrationLoading = (state: RootState) => state.registration.loading
export const selectTicketTabData = (state: RootState) => state.registration.tabs.ticketTab
export const selectTeamTabData = (state: RootState) => state.registration.tabs.teamTab
export const selectRegistration = (state: RootState) => state.registration.data.registration
export const selectSignTabDataR = (state: RootState) => state.registration.tabs.signTab
export const selectUserTeamMembersR = (state: RootState) => state.registration.data.userTeamMembers
export const selectRegisterTabData = (state: RootState) => state.registration.tabs.registerTab
export const selectRegistertrationPaying = (state: RootState) => state.registration.paying
export const selectRegistrationFeesR = (state: RootState) =>
  state.registration.data.registrationsFees
export const selectRegistrationByDay = (state: RootState) =>
  state.registration.data.registrationsByDay
export const selectRecipientsInDb = (state: RootState) => state.registration.data.recipientsInDb
export const selectPublishedEventInDb = (state: RootState) =>
  state.registration.data.publishedEventInDb
export const selectRidersTeamMembersR = (state: RootState) =>
  state.registration.data.ridersTeamMembers
export const selectRidersTeamMembersInDb = (state: RootState) =>
  state.registration.data.ridersTeamMembersInDb
export const selectTeamTabDataInDb = (state: RootState) => state.registration.data.teamTabDataInDb
export const selectRegistrationsByDayInDb = (state: RootState) =>
  state.registration.data.registrationsByDayInDb
export const selectFilteredPaperworkDocuments = (state: RootState) =>
  state.registration.data.filteredPaperworkDocuments
export const selectPayTabGrandTotalAccordion = (state: RootState) =>
  state.registration.tabs.payTab.grandTotal
export const selectRegistrationTicketsR = (state: RootState) =>
  state.registration.data.registrationsTickets

export const selectRegistrationAnswers = (state: RootState) =>
  state.registration.data?.answers || []

export const selectPaperworkTabData = (state: RootState) => state.registration.tabs.paperworkTab
export const selectSendMailLoadingR = (state: RootState) => ({
  sendMailIndex: state.registration.tabs.signTab.sendMailIndex,
  sendMailLoading: state.registration.tabs.signTab.sendMailLoading,
})

export default registrationSlice.reducer
