import { useEffect, useState } from 'react'

// Third party
import clsx from 'clsx'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Redux
import { useAppSelector } from '../../../../../../store/hooks'
import {
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
} from '../../../../../../store/registration/registrationSlice'

import {
  IPaperworkAccordionTypeItem,
  IPayTab,
} from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'

import { CONST } from '../../../../../../const/const'

// Constants
const heading = [
  { title: 'Document', tooltipText: 'Document' },
  { title: 'Signatories', tooltipText: 'Signatories' },
]

type SelectedEventPaperworkListProps = {
  setEventTab?: any
  manageInfo: IManageInfo
  isManage?: boolean
}

export const SelectedEventPaperworkList = ({
  setEventTab,
  isManage,
  manageInfo,
}: SelectedEventPaperworkListProps) => {
  const [open, setOpen] = useState(false)

  let filteredPaperworkDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)

  const [selectedUnits, setSelectedUnits] = useState<{
    signatoryCount: number
    data: IPaperworkAccordionTypeItem[]
  }>({ signatoryCount: 0, data: [] })

  useEffect(() => {
    let signTabPaperworkAccordion: IPayTab['paperwork']['data'] = []
    let signatoryIdsList: string[] = []
    let index = -1
    let memberPreAdded = false
    const paperwork = isManage ? manageInfo.paperwork : paperworkTabData

    paperwork.forEach((paperwork) =>
      paperwork.ridersTeamMembers.forEach((ridersTeamMember) => {
        if (ridersTeamMember.teamMemberId) signatoryIdsList.push(ridersTeamMember.teamMemberId)
      })
    )

    filteredPaperworkDocuments.forEach((currPaperworkDocument) => {
      index = signTabPaperworkAccordion.findIndex((curr) => curr.key === currPaperworkDocument.key)

      if (index === -1) {
        signTabPaperworkAccordion.push({
          signatories: [],
          signatoryCount: 0,
          key: currPaperworkDocument.key,
          documentName: currPaperworkDocument.document_name,
        })
        index = signTabPaperworkAccordion.length - 1
      }
    })

    filteredPaperworkDocuments.forEach((currPaperworkDocument) => {
      index = signTabPaperworkAccordion.findIndex((curr) => curr.key === currPaperworkDocument.key)

      if (index === -1) {
        signTabPaperworkAccordion.push({
          signatories: [],
          signatoryCount: 0,
          key: currPaperworkDocument.key,
          documentName: currPaperworkDocument.document_name,
        })
        index = signTabPaperworkAccordion.length - 1
      }

      paperwork.forEach((currPaperworkTabRow) => {
        currPaperworkTabRow.ridersTeamMembers.forEach((currRiderTeamMember) => {
          currRiderTeamMember.mailLog.forEach((currMailLog) => {
            if (currMailLog.paperworkKey === currPaperworkDocument.key) {
              if (currRiderTeamMember.teamMemberId) {
                signatoryIdsList.push(currRiderTeamMember.teamMemberId)
              }

              memberPreAdded = !!(signTabPaperworkAccordion[index]?.signatories ?? []).find(
                (currSignatory) => {
                  return currSignatory.teamMemberId === currRiderTeamMember.teamMemberId
                }
              )

              signTabPaperworkAccordion[index] = {
                ...signTabPaperworkAccordion[index],
                signatories: signTabPaperworkAccordion[index]?.signatories ?? [],
              }

              if (!memberPreAdded) {
                signTabPaperworkAccordion[index].signatories.push({
                  teamMemberId: currRiderTeamMember.teamMemberId,
                  teamMemberName: currRiderTeamMember.teamMemberName,
                })
                signTabPaperworkAccordion[index].signatoryCount++
              }
            }
          })
        })
      })
    })

    setSelectedUnits({
      signatoryCount: signatoryIdsList.length > 0 ? [...new Set(signatoryIdsList)].length : 0,
      data: signTabPaperworkAccordion,
    })
  }, [isManage, manageInfo.register, paperworkTabData])

  return (
    <ConfirmationCompsWrapper
      title="Paperwork"
      setEventTab={setEventTab}
      redirectTab={CONST.UI.REGISTER.TABS.PAPERWORK}
      accordion={{ open, setOpen }}
      selected_units={`${filteredPaperworkDocuments.length} docs, ${selectedUnits.signatoryCount} signers`}
      showList={!!selectedUnits.data.length}
      cols={heading}
      colsClassName={{
        0: '!w-2/4',
      }}
    >
      <>
        {selectedUnits.data.map((row, index) => {
          return (
            <div
              key={`${JSON.stringify(row)}${index}`}
              className={`flex flex-col lg:flex-row lg:items-center justify-start w-full mb-2 lg:mt-0 ${index !== 0 ? 'mt-10 ' : ''}`}
            >
              <div className="lg:hidden w-full mb-1 font-medium text-[12px]">Document</div>
              <p className="text-SeabiscuitDark200ThemeColor w-full lg:w-1/2 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                <span>{row.documentName}</span>
              </p>

              <div className="lg:hidden w-full mb-1 font-medium text-[12px] mt-2">Signatories</div>
              <div
                className={clsx(
                  'text-SeabiscuitDark200ThemeColor w-full lg:w-1/2 p-4 rounded-lg bg-SeabiscuitGrayThemeColor text-center relative flex items-center justify-center'
                )}
              >
                <div className="whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                  {!row.signatories.length ? (
                    'No signatories added'
                  ) : (
                    <span className="mr-4">
                      {row.signatories
                        .map((currSignatory) => currSignatory.teamMemberName)
                        .join(', ')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )
        })}

        {/* Total */}
        <div className="flex mt-10 lg:mt-0 flex-col lg:flex-row items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor text-center">
          <p
            className={`flex-grow w-full  p-4 lg:text-left mr-2 lg:w-1/2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`}
          >
            {`${selectedUnits.signatoryCount} documents need to be signed`}
          </p>

          <p
            className={`w-full mt-3 lg:mt-0 lg:w-1/2 p-4 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`}
          >
            {`${selectedUnits.signatoryCount} ${selectedUnits.signatoryCount > 1 ? 'signatories' : 'signatory'}`}
          </p>
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}
