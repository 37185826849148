import { PAYMENT_STATUS_VALUES } from '../../../../types/payment'

export const DATA_FIRESTORE_V01_SPECTATOR_TICKETS = {
  NAME: 'v01_spectator_tickets',
  FIELDS: {
    ID: 'id',
    EVENT_ID: 'eventDocId',
    USER_ID: 'userId',
    IS_PLATFORM_USER: 'isPlatformUser',
    SELECTED_UNITS_COUNT: 'selectedUnitsCount',
    SHARED_TICKETS_COUNT: 'sharedTicketsCount',
    BOUGHT_TICKETS_COUNT: 'boughtTicketCount',
    TICKET_ITEM_ID: 'ticketItemId',
    TICKETS_COUNT_AGAINST_SPECTATOR_DOC: 'ticketsCountAgainstSpectatorDoc',
    TICKET_TITLE: 'ticketTitle',
    TRANSACTION_DOC_ID: 'transactionDocId',
    TICKETS_TYPE_COUNT: 'tickets_type_count',
    SPECTATOR_TICKETS: 'spectator_tickets',
    GUEST_ID: 'guest_id',
    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: PAYMENT_STATUS_VALUES,
    },
    CREATED: 'created',

    MODIFIED: 'modified',
  },
}
