import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

// Types
import { ROUTES_CONST } from '../../../../../const/routes-const'

// Hooks
import FirestoreService from '../../../../../services/firestoreService'

// Firestore
import { QueryConstraint, where } from 'firebase/firestore'

// Constants
import { CONST } from '../../../../../const/const'

// Components
import InfiniteScrollDataTable from '../../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import NoDataAvailable from './NoDataAvailable'

// Models
import clsx from 'clsx'
import customImageComponent from '../../../../../components/common/CustomImageComponent'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { IRecipientInterface } from '../../../../../models/recipients/recipients.interface'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import useGetUserEmailViaDocId from '../../../../../hooks/useGetUserEmailViaDocId'
import { AutorenewRounded } from '@mui/icons-material'
import { selectedEvent, setRevenueCustomers } from '../../../../../store/events/eventsSlice'
import { PAYMENT_STATUS } from '../../../../../types/payment'
import { RecipientModel } from '../../../../../models/recipients/recipients'
import { getConvertedData } from '../../../../../models/interface.helper'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IManageEventRegisterRevenueListProps = {
  eventId?: string
  activeTab: string
  className?: string
  searchValue: string
  isFullScreen?: boolean
}

type IGetUsersList = (args: { lastDocId: string | null }) => Promise<void>

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const ACTIVE_TABS_KEYS = {
  ALL: 'all',
  PAID: 'paid',
  UNPAID: 'unpaid',
  PENDING: 'pending',
  REFUNDED: 'refunded',
}

const LINKS = {
  MESSAGE_USER: '/messageuser/',
  REFUND_TO_EXHIBITOR: ROUTES_CONST.EXHIBITOR_REFUND.URL,
}

const COLS = {
  EXHIBITOR: 'Exhibitor',
  PAYMENT_STATUS: 'Status',
  AMOUNT: 'Amount',
  REGISTERED: 'Registered',
  RECEIPT: 'Receipt',
  REFUNDED: 'Action',
  SCRATCHED: 'Scratched',
}

const PER_PAGE = 10
const ManageEventRegisterRevenueList = (props: IManageEventRegisterRevenueListProps) => {
  // Hooks and vars
  const rowIndexToLoadRef = useRef(-1)
  const setRowIndexToLoad = useState(-1)[1]
  const currentEvent = useAppSelector(selectedEvent)
  const dispatch = useAppDispatch()
  const { eventId } = useParams<{ eventId?: any }>()
  const { getEmail } = useGetUserEmailViaDocId()
  const handleModalContext = useContext(HandleModalContext)

  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [data, setData] = useState<IRecipientInterface[]>([])

  useEffect(() => {
    getUsersList({
      lastDocId: null,
    }).then()
  }, [props.searchValue, props.activeTab])

  // Functions

  const sendEmail = async (index: number, userId: string | null) => {
    setRowIndexToLoad(index)
    rowIndexToLoadRef.current = index

    let emailId = await getEmail({ userId })
    if (emailId) {
      handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, { emailId })
    }

    setRowIndexToLoad(-1)
    rowIndexToLoadRef.current = -1
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Returns structured users registration details as per datatable
   */
  const getUsersList: IGetUsersList = async ({ lastDocId }) => {
    setLoading(true)
    try {
      let recipient: IRecipientInterface | null = null
      let paymentStatus: string | null = null

      let newData: IRecipientInterface[] = []

      const paymentStatusQuery: QueryConstraint[] = []

      if (!lastDocId) {
        setLoading(true)
      }

      switch (props.activeTab?.toLocaleLowerCase()) {
        case ACTIVE_TABS_KEYS.PAID:
          paymentStatus = PAYMENT_STATUS.PAID
          break

        case ACTIVE_TABS_KEYS.UNPAID:
          paymentStatus = PAYMENT_STATUS.DEFAULT
          break
      }

      if (paymentStatus) {
        paymentStatusQuery.push(
          where(COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.KEY, '==', paymentStatus)
        )
      }

      if (!!props.searchValue) {
        paymentStatusQuery.push(
          where(
            COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_NAME_N_GRAM.KEY,
            'array-contains',
            props.searchValue
          )
        )
      }

      paymentStatusQuery.push(where(COLLECTIONS.RECIPIENT.FIELDS.EVENT_ID.KEY, '==', eventId))

      const recipientSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.RECIPIENT.NAME,
        paymentStatusQuery,
        PER_PAGE,
        null,
        null,
        lastDocId
      )

      recipientSnapshots.forEach((currDoc) => {
        recipient = getConvertedData(RecipientModel.fromFirestoreDoc(currDoc).toObject())
        if (recipient) newData.push(recipient)
      })

      if (!newData.length) setHasMore(false)

      if (currentEvent && (!currentEvent.revenueCustomers || !currentEvent.revenueCustomers[0])) {
        dispatch(setRevenueCustomers(newData))
      }

      if (lastDocId) setData([...data, ...newData])
      else setData(newData)
    } catch (error) {
      console.log(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onViewReceiptClick = (row: IRecipientInterface) => {
    handleModalContext?.handleModal?.(true, MODAL_CONSTS.BILL_BRIEFE, {
      bill: row,
    })
  }

  // Datatable cols
  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Customers</span>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex items-center text-SeabiscuitDark200ThemeColor font-semibold capitalize">
          {!!row?.id && !!eventId && !!row?.id ? (
            <div
              // to={`${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${row?.recipientId}/${eventId}/${registration?.id}`}
              className="flex items-center"
            >
              {customImageComponent(
                row.recipientProfilePicture,
                row.recipientName,
                '!w-[45px] !h-[45px] object-cover rounded-full mr-5 shrink-0'
              )}
              {row?.recipientName}
            </div>
          ) : (
            <>
              {customImageComponent(
                row.recipientProfilePicture,
                row.recipientName,
                'w-[45px] h-[45px] rounded-full mr-5 shrink-0'
              )}
              {row?.recipientName}
            </>
          )}
        </div>
      ),
      width: '35%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.PAYMENT_STATUS}</span>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
          <img src="/assets/cp_icons/MagneticCard.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            {row.paymentStatus === PAYMENT_STATUS.DEFAULT ? 'Unpaid' : row.paymentStatus}
          </span>
        </div>
      ),
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.AMOUNT}</span>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <>
          <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
            <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              ${row.amountPaid?.toFixed(2)}
            </span>
          </div>
        </>
      ),
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.SCRATCHED}</span>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <>
          <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
            <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              ${row.amountScratched ? row.amountScratched.toFixed(2) : 0}
            </span>
          </div>
        </>
      ),
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Refunded</span>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <>
          <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
            <img src="/assets/cp_icons/Tear-Off Calendar.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              ${row.amountRefunded ? row.amountRefunded.toFixed(2) : 0}
            </span>
          </div>
        </>
      ),
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.RECEIPT}</span>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <>
          <div className="underline flex items-center w-full">
            <img src="/assets/cp_icons/Bill.svg" className="mr-2" alt="paidStatus" />
            <button onClick={() => onViewReceiptClick(row)}>View</button>
          </div>
        </>
      ),
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center pl-10">
          <span className="whitespace-nowrap">{COLS.REFUNDED}</span>
        </span>
      ),
      cell: (row: IRecipientInterface, rowIndex: number) => (
        <>
          {row.paymentStatus === ACTIVE_TABS_KEYS.PAID ? (
            <Link
              to={`${LINKS.REFUND_TO_EXHIBITOR}/${row?.recipientId}/${eventId}/${row?.registrationDocId}`}
              className="flex items-center justify-center w-full py-2"
            >
              <p className="w-full bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-center text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor">
                Refund
              </p>
            </Link>
          ) : row.paymentStatus === ACTIVE_TABS_KEYS.UNPAID ? (
            <div className="flex justify-center text-SeabiscuitDark200ThemeColor w-full py-2">
              <button className="bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-center text-SeabiscuitDark200ThemeColor">
                Remind
              </button>
            </div>
          ) : (
            <div className="w-full justify-center flex cursor-pointer py-2">
              <button
                className="bg-[#F2F3F8] py-3 px-5 font-semibold rounded-xl text-center text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor w-full"
                onClick={async () => await sendEmail(rowIndex, row.recipientId)}
              >
                {rowIndexToLoadRef.current === rowIndex ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Message'
                )}
              </button>
            </div>
          )}
        </>
      ),
      width: '140px',
    },
  ]

  return (
    <InfiniteScrollDataTable
      data={data}
      hasMore={hasMore}
      columns={columns}
      fixedHeader={true}
      fetchMore={async () => {
        await getUsersList({
          lastDocId: data.at(-1)?.id ?? null,
        })
      }}
      progressPending={loading}
      reloadOnBasedOf={props.activeTab}
      className={clsx('!rounded-none', data.length || loading ? 'h-[50vh]' : '')}
      noDataComponent={
        <NoDataAvailable
          buttonText={'Edit registrations >'}
          imgSrc="/assets/og_icons/People-4.svg"
          text="No exhibitors have registered for this event"
        />
      }
      progressComponent={
        <ViewsLoader
          className="flex items-center w-full justify-center my-10"
          size="md"
          color="#F70763"
        />
      }
    />
  )
}

export default ManageEventRegisterRevenueList
