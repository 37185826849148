import { FC } from 'react'
import clsx from 'clsx'
import customImageComponent from '../../common/CustomImageComponent'
import DataNotAvailable from '../../common/alerts/data-not-available/DataNotAvailable'
import { IconCheckBlack } from '../../icons/IconCheckBlack'
import { IconPlusCircle } from '../../icons/IconPlusCircle'
import { IProfile } from './types/switchAccountTypes'

interface SwitchAccountFirstStepProps {
  loading: boolean
  fields: IProfile[]
  selectHandler: (index: number) => void
  addAccountHandler: () => void
  notAvailableText?: string
  selectedIndex: number | null
}

export const SwitchAccountFirstStep: FC<SwitchAccountFirstStepProps> = ({
  loading,
  fields,
  addAccountHandler,
  selectHandler,
  notAvailableText,
  selectedIndex,
}) => {
  return (
    <div
      className={clsx('flex flex-col h-[250px] overflow-auto gap-5', loading && 'animate-pulse')}
    >
      {fields.length || loading ? (
        [...(loading ? new Array(3) : fields)].map((currProfile: IProfile, index) => {
          return (
            <div
              role="button"
              onClick={() => selectHandler(index)}
              className="w-full flex gap-3 min-h-[40px] cursor-pointer items-center"
              key={`profile${index}`}
            >
              <div
                className={clsx(
                  'w-10 h-10 shrik-0 rounded-full',
                  loading && 'rounded-md bg-SeabiscuitSkeletonColor'
                )}
              >
                {!loading
                  ? customImageComponent(
                      currProfile?.userProfilePicture,
                      currProfile?.name,
                      'w-full h-full object-cover rounded-full'
                    )
                  : null}
              </div>
              <div
                className={clsx(
                  'flex flex-col gap-[2px]',
                  loading ? 'w-[calc(100%-53px)]' : 'w-[calc(100%-90px)]'
                )}
              >
                <div
                  className={clsx(
                    'text-nr text-SeabiscuitDarkThemeColor capitalize min-h-[21px]',
                    loading && 'rounded-md bg-SeabiscuitSkeletonColor'
                  )}
                >
                  {currProfile?.name}
                </div>
                <div
                  className={clsx(
                    'text-sm text-SeabiscuitGray600ThemeColor capitalize min-h-[20px]',
                    loading && 'rounded-md bg-SeabiscuitSkeletonColor'
                  )}
                >
                  {currProfile?.userType}
                </div>
              </div>
              {!loading && (
                <div className={'text-black w-5 h-5 flex items-center justify-center'}>
                  <IconCheckBlack isActive={selectedIndex === index} />
                </div>
              )}
            </div>
          )
        })
      ) : (
        <DataNotAvailable mode="text" text={notAvailableText ?? 'N/A'} />
      )}

      {!loading ? (
        <button
          onClick={addAccountHandler}
          className="transition-all my-3 group text-black/50 font-normal text-[14px] flex justify-center items-center gap-2 hover:text-SeabiscuitRedThemeColor"
        >
          <IconPlusCircle />
          <span className={'underline'}>Add Account</span>
        </button>
      ) : null}
    </div>
  )
}
