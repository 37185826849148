import { where } from 'firebase/firestore'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RecipientModel } from '../../../models/recipients/recipients'
import IExhibitorSlice from '../types'

import FirestoreService from '../../../services/firestoreService'
import { getConvertedData } from '../../../models/interface.helper'

import { CONST } from '../../../const/const'

const getRecipientThunk = createAsyncThunk<
  IExhibitorSlice['IGetRecipientThunkSuccessResponse'],
  IExhibitorSlice['IGetRecipientThunkErrorResponse']
>(
  'getRecipientThunk',
  async (
    { recipientId, registrationId }: IExhibitorSlice['IGetRecipientThunkPayload'],
    thunkApi
  ) => {
    try {
      const docSnapshot = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
        [
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
            '==',
            recipientId
          ),
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ],
        1
      )

      let recipient = RecipientModel.fromFirestoreDoc(docSnapshot.docs[0]).toObject()
      return getConvertedData(recipient)
    } catch (error: any) {
      console.error(error, 'error')
      return thunkApi.rejectWithValue({
        message: error?.message,
      })
    }
  }
)

export { getRecipientThunk }
