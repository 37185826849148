import { AutorenewRounded } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useDatatable from './hooks/useDatatable'
import IUserDatatable from './hooks/useDatatable.types'
import PROFILE_DETAIL_CARD_CONST from '../../../../components/pageWise/visitedUserDetails/profile-details-card/profileDetailCard.const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Datatable = () => {
  const datatable = useDatatable()

  const columns = [
    {
      name: '',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <div className="flex items-center py-5">
            <Link
              to={
                row?.followingUserId
                  ? `${CONST.ROUTES.USER_PROFILE.URL}/${row?.followingUserId}`
                  : '#'
              }
              className="text-SeabiscuitDark200ThemeColor underline flex items-center"
            >
              <span className="w-[45px] h-[45px] block shrink-0 mr-2">
                <img
                  src={
                    !!!row?.followingUserProfilePicture
                      ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                      : row?.followingUserProfilePicture
                  }
                  className="object-cover w-full h-full rounded-full"
                  alt="icon"
                />
              </span>
            </Link>
            <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold capitalize">
              {row?.followingUserName}
            </span>
          </div>
        )
      },
      draggable: false,
      width: '38%',
    },
    {
      name: 'Last event ',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <Link
            to={
              row?.lastEvent?.id ? `${CONST.ROUTES.EVENT_DETAILS.URL}/${row?.lastEvent?.id}` : '#'
            }
            className="text-SeabiscuitDark200ThemeColor underline flex items-center"
          >
            <img src="/assets/cp_icons/calender_check.svg" className="mr-2 w-5" alt="calender" />
            {row?.lastEvent?.name ? row?.lastEvent?.name : 'N/A'}
          </Link>
        )
      },
      draggable: false,
      width: '14%',
    },
    {
      name: 'Next event ',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <Link
            to={
              row?.nextEvent?.id ? `${CONST.ROUTES.EVENT_DETAILS.URL}/${row?.nextEvent?.id}` : '#'
            }
            className="text-SeabiscuitDark200ThemeColor underline flex items-center"
          >
            <img src="/assets/og_icons/Tear-OffCalendar.svg" className="mr-2 w-6" alt="calender" />
            {row?.nextEvent?.name ? row?.nextEvent?.name : 'N/A'}
          </Link>
        )
      },
      draggable: false,
      width: '22%',
    },
    {
      name: 'Upcoming events',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <Link
            to={`${CONST.ROUTES.USER_PROFILE.URL}/${row?.followingUserId}`}
            className="text-SeabiscuitDark200ThemeColor  underline flex items-center"
          >
            <img src="/assets/cp_icons/Calendar.svg" className="mr-2 w-6" alt="calender" />
            {row.upcomingEvents.count} events
          </Link>
        )
      },
      draggable: false,
      width: '16%',
    },
    {
      name: 'Unfollow',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <button
            onClick={() => {
              datatable.onUnFollowButtonClick({
                id: row.followingDocId,
                name: row.followingUserName,
              })
            }}
            className="text-center h-[35px] flex items-center justify-center py-1 px-[15px] mr-[-14px] text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-SeabiscuitMainThemeColor focus:ring-2 hover:bg-SeabiscuitMainThemeColor/5 focus:ring-SeabiscuitMainThemeColor rounded-lg text-sm font-medium   hover:border-SeabiscuitMainThemeColor  focus:outline-none focus:ring-offset-2 whitespace-nowrap"
          >
            {datatable.unfollowingId === row.followingDocId ? (
              <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
            ) : (
              'Unfollow'
            )}
          </button>
        )
      },
      draggable: false,
      width: '10%',
    },
  ]

  if (datatable.loading)
    return (
      <div className="flex justify-center items-center min-h-[300px]">
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[30vh] mt-3"
          size="lg"
          color="red"
        />
      </div>
    )

  return (
    <InfiniteScrollDataTable
      fetchMore={datatable.fetchMore}
      columns={columns}
      hasMore={datatable.hasMore}
      fixedHeader={true}
      data={datatable.data}
      progressPending={datatable.loading}
      progressComponent={<ViewsLoader size="xl" color="red" />}
      noDataComponent={
        <DataNotAvailable
          mode="graphic"
          imageAlt="house on mountain with round balloon like tree"
          imageSource={'assets/placeholders/unfollow_organizer.svg'}
          upperText={MESSAGES_CONST.FOLLOWING_NOTFOUND_UPPER_TEXT}
          bottomText={MESSAGES_CONST.FOLLOWING_NOTFOUND_LOWER_TEXT}
        />
      }
      className="transition-all"
      noHeader={true}
    />
  )
}

export default Datatable
