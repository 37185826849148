import clsx from 'clsx'
import { IMAGE_CONSTS } from '../../../const/image-const'

export default function ProfileImage({
  src,
  className,
  lazyLoad,
}: {
  src: string | undefined | null
  className?: string
  lazyLoad?: boolean
}) {
  return lazyLoad ? (
    <img
      alt="chatProfileImage"
      loading="lazy"
      src={src ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
      onError={(e) => ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)}
      className={clsx('object-cover', className)}
    />
  ) : (
    <img
      alt="chatProfileImage"
      src={src ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
      onError={(e) => ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)}
      className={clsx(className, 'object-cover')}
    />
  )
}
