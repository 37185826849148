import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import { EventFeesRefunds } from './components/EventFeesRefunds/EventFeesRefunds'
import { EventFeesStalls } from './components/EventFeesStalls/EventFeesStalls'

import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import { IEventFees } from '../../../../models/event-fees/event-fees.interface'

import { DurationList } from '../../../../fakeData/durationList'
import { CONST } from '../../../../const/const'

import './EventFeesFormComponent.css'
import { EventFeesBedding } from './components/EventFeesBedding/EventFeesBedding'
import { EventFeesFeed } from './components/EventFeesFeed/EventFeesFeed'
import { EventFeesOthers } from './components/EventFeesOthers/EventFeesOthers'
import { EventFeesMandatory } from './components/EventFeesMandatory/EventFeesMandatory'

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
const eventFeesFormSchema = EventFeesModel.validationSchema()

interface EventFeesFormComponentProps {
  data: IEventFees | null
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  eventTab?: any
  nextEventTab?: string
}

const EventFeesFormComponent: React.FC<EventFeesFormComponentProps> = ({
  eventTab,
  nextEventTab,
  data,
  onInvalid,
  onInvalidAndExit,
  onValid,
  onValidAnExit,
}) => {
  // Hooks and vars

  const { watch, reset, trigger, control, setValue, getValues, register, handleSubmit } =
    useForm<IEventFees>({
      defaultValues: { ...eventFeesFormDefaultValues },
      resolver: yupResolver(eventFeesFormSchema),
      mode: 'onChange',
    })

  const toastFunctions = useToasterHelper()

  const [dataIncrementAttrProps, setDataIncrementAttrProps] = useState({})

  useEffect(() => {
    if (data) reset(data)
    else reset(eventFeesFormDefaultValues)

    setValue('eventTab' as any, eventTab)

    trigger().then()

    switch (data?.paymentOption) {
      case 'IncreaseAllPrices':
        setDataIncrementAttrProps({
          'data-increment-by': 5,
        })
        break

      case 'ChargeRidersAndSpectators':
        setDataIncrementAttrProps({
          'data-increment-by': 2.9,
        })
        break

      default:
        setDataIncrementAttrProps({})
    }
  }, [eventTab, data, trigger])

  const getDurationSelectedValue = (value: any) => {
    if (typeof value === 'string')
      return DurationList.find((curr) => curr?.value?.toLowerCase() === value?.toLocaleLowerCase())

    if (typeof value === 'object' && 'label' in value)
      return DurationList.find(
        (curr) => curr?.value?.toLowerCase() === value?.value?.toLocaleLowerCase()
      )
  }

  const invalidFormHandler = (errors: any) => {
    if (errors?.registrationFees?.type === 'Registration fees validation test') {
      toastFunctions.error({
        message: errors?.registrationFees?.message,
      })
    } else {
      console.error(errors)
      toastFunctions.error({
        message: 'Invalid',
      })
    }
  }

  return (
    <EventFormContainerComponent>
      <>
        <EventFormHeaderComponent
          title={
            eventTab && eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
              ? 'Entries'
              : 'Fees'
          }
          description={
            eventTab && eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
              ? 'Add fees and entry details'
              : 'Add fees to your horse show'
          }
        >
          <EventFormFooterCommonComponent
            eventTab={eventTab}
            nextEventTab={nextEventTab}
            onNext={(e, publishEvent) => {
              handleSubmit(
                (data_) =>
                  publishEvent({
                    dataToSave: data_,
                    tabName: 'EventFees',
                    validFormHandler: onValid,
                  }),
                eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
                  ? invalidFormHandler
                  : onInvalid
              )(e)
            }}
            onSaveAndExit={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    onSaveAndExit: true,
                    tabName: 'EventFees',
                    validFormHandler: onValidAnExit,
                  }),
                eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
                  ? invalidFormHandler
                  : onInvalidAndExit
              )(e)
            }}
          />
        </EventFormHeaderComponent>
        <EventFeesRefunds
          watch={watch}
          control={control}
          register={register}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
        />
        <EventFeesStalls
          watch={watch}
          control={control}
          register={register}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
          dataIncrementAttrProps={dataIncrementAttrProps}
          getDurationSelectedValue={getDurationSelectedValue}
        />
        <EventFeesBedding
          watch={watch}
          control={control}
          register={register}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
          dataIncrementAttrProps={dataIncrementAttrProps}
          getDurationSelectedValue={getDurationSelectedValue}
        />
        <EventFeesFeed
          watch={watch}
          control={control}
          register={register}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
          dataIncrementAttrProps={dataIncrementAttrProps}
          getDurationSelectedValue={getDurationSelectedValue}
        />
        <EventFeesOthers
          watch={watch}
          control={control}
          register={register}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
          dataIncrementAttrProps={dataIncrementAttrProps}
          getDurationSelectedValue={getDurationSelectedValue}
        />

        <EventFeesMandatory
          watch={watch}
          control={control}
          register={register}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
        />
      </>
    </EventFormContainerComponent>
  )
}

export default EventFeesFormComponent
