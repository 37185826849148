import React, { useContext } from 'react'

import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import customImageComponent from '../../../../components/common/CustomImageComponent'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IDataTableItem } from '../CompetitorBillDisplayTabs'
import { PAYMENT_STATUS } from '../../../../types/payment'
import { getFloatPrice } from '../../../../helpers/price'

// Constants
const imgSrc = '/assets/placeholders/NoBillsFound.svg'

const COLS = {
  EVENTS: 'Event',
  PAYMENT_STATUS: 'Status',
  AMOUNT: 'Amount',
  REGISTERED: 'Scratched',
  REFUNDED: 'Refunded',
  SCRATCHED: 'Scratched',
  FROM: 'From',
}

const CompetitorAllBills = ({
  bills,
  selectedTab,
}: {
  bills: IDataTableItem[] | null
  selectedTab?: 'all' | 'unpaid' | 'paid'
}) => {
  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const placeholderData =
    selectedTab === 'paid'
      ? 'You have not paid any bills yet'
      : selectedTab === 'all'
        ? "You don't have any bills"
        : 'No unpaid bills available yet'

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.EVENTS}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Event this bill is associated with</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IDataTableItem) => (
        <div className="py-4 flex gap-3 items-center  text-SeabiscuitDark200ThemeColor font-normal">
          <Link
            to={`/events-details/${row.eventId}`}
            className="cursor-pointer transition-all hover:opacity-70"
          >
            {customImageComponent(
              row?.eventLogo,
              row?.eventName,
              '!w-[45px] !min-w-[45px] !h-[45px] rounded-full shrink-0'
            )}
          </Link>
          {row?.eventName}
        </div>
      ),
      width: '34%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.PAYMENT_STATUS}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Bill payment status</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IDataTableItem) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MagneticCard.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal max-w-[90px] ellipsis">
            {row.paymentStatus === PAYMENT_STATUS.PAID ? 'Paid' : 'Unpaid'}
          </span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.AMOUNT}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Bill total</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IDataTableItem) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            {getFloatPrice(`${row.amountPaid}`)}
          </span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.SCRATCHED}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Total amount scratched</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IDataTableItem) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            {getFloatPrice(`${row.amountScratched}`)}
          </span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.REFUNDED}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">
                Refund status and amount refunded by the event organizer
              </h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IDataTableItem) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
          {row?.refundStatus === 'pending' ? (
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">Pending</span>
          ) : (
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              {getFloatPrice(`${row.amountRefunded}`)}
            </span>
          )}
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex ml-1">
          <span className="whitespace-nowrap">{COLS.FROM}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Who sent you this bill to pay</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: () => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/User.svg" className="mr-2" alt="paidStatus" />

          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">You</span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">View</span>
          <Tooltip
            title={<h1 className="tooltip_title">Click to view and pay bill</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IDataTableItem) => (
        <span
          className="flex w-full justify-center cursor-pointer"
          onClick={() => handleModal?.(true, MODAL_CONSTS.BILL_BRIEFE, { bill: row })}
        >
          <p className="bg-[#F2F3F8] min-w-[62px] py-3 flex justify-center w-full font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-[#fef5f6] whitespace-nowrap">
            {row.paymentStatus === PAYMENT_STATUS.PAID ? 'View' : 'Pay'}
          </p>
        </span>
      ),
      width: '6%',
    },
  ]

  return (
    <InfiniteScrollDataTable
      data={bills ?? []}
      hasMore={false}
      columns={columns}
      noTableHead={!(bills && bills.length)}
      fixedHeader={true}
      reloadOnBasedOf={selectedTab}
      className={clsx('!rounded-none !px-2', bills && bills.length ? 'max-h-[100%]' : '')}
      noDataComponent={
        <DataNotAvailable
          mode="graphic"
          imageAlt="no bills"
          upperText={placeholderData}
          imageSource={imgSrc}
        />
      }
      progressComponent={
        <ViewsLoader
          className="flex items-center w-full justify-center my-10"
          size="md"
          color="#F70763"
        />
      }
    />
  )
}

export default CompetitorAllBills
