import { IconPlusCircle } from '../../../../../../../../components/icons/IconPlusCircle'
import React, { FC } from 'react'

interface AddBreakProps {
  addBreak: () => Promise<void>
}
export const AddBreak: FC<AddBreakProps> = ({ addBreak }) => {
  return (
    <tr className={'border-t border-solid'}>
      <td className={'py-4'}>
        <button onClick={addBreak} className={'flex group gap-2 items-center text-[#122B4680]'}>
          <IconPlusCircle />{' '}
          <span className={'underline hover:no-underline hover:text-SeabiscuitMainThemeColor'}>
            Add break
          </span>
        </button>
      </td>
    </tr>
  )
}
