import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import EventFormContainerComponent from '../container/EventFormContainerComponent'

import { yupResolver } from '@hookform/resolvers/yup'
import { v4 as uuidv4 } from 'uuid'
import { CONST } from '../../../../const/const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import {
  IEventFees,
  RegistrationFeesType,
} from '../../../../models/event-fees/event-fees.interface'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import './EventEntriesFormComponent.css'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { EventEntriesFormRow } from './EventEntriesFormRow'
import { ClassSettings } from './ClassSettings'

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
const eventFeesFormSchema = EventFeesModel.validationSchema()

const initialFee = {
  order: null,
  name: '',
  category: 'registrations',
  note: '',
  cost: 0,
  actualCost: 0,
  actualCostAlias: '$0',
  costAlias: '$0',
  increment: '',
  qualifyingClass: { name: '', price: '0' },
  location: '',
  startDate: '',
  startTimeHours: '00',
  startTimeMinutes: '00',
  startTimeFormat: 'AM',
  tag: '',
}

interface EventFeesFormComponentProps {
  data: IEventFees | null
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal: (showHide: boolean, typeOfModal: string, data: { removeItem: () => void }) => void
  eventTab?: any
  nextEventTab?: string
}

const EventEntriesFormComponent: React.FC<EventFeesFormComponentProps> = ({
  eventTab,
  nextEventTab,
  data,
  onValid,
  onValidAnExit,
  onInvalid,
  onInvalidAndExit,
  handleModal,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const {
    watch,
    reset,
    trigger,
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEventFees>({
    defaultValues: { ...eventFeesFormDefaultValues },
    resolver: yupResolver(eventFeesFormSchema),
    mode: 'onChange',
  })

  const toastFunctions = useToasterHelper()

  const [showErrors, setShowErrors] = useState(false)
  const [dataIncrementAttrProps, setDataIncrementAttrProps] = useState({})

  const qualifyingClasses = useFieldArray({
    control,
    name: 'qualifyingClasses.items',
  })

  useEffect(() => {
    if (data) reset(data)
    else reset(eventFeesFormDefaultValues)

    setValue('eventTab' as any, eventTab)

    trigger().then()

    switch (data?.paymentOption) {
      case 'IncreaseAllPrices':
        setDataIncrementAttrProps({
          'data-increment-by': 5,
        })
        break

      case 'ChargeRidersAndSpectators':
        setDataIncrementAttrProps({
          'data-increment-by': 2.9,
        })
        break

      default:
        setDataIncrementAttrProps({})
    }
  }, [eventTab, data, trigger])

  const openAddNotesModal = (noteInputRef: any) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef,
      setValue,
    })
  }

  const invalidFormHandler = (errors: any) => {
    if (errors?.registrationFees?.type === 'Registration fees validation test') {
      toastFunctions.error({
        message: errors?.registrationFees?.message,
      })
    } else {
      console.error(errors, 'invalidFormHandler')
      toastFunctions.error({
        message: 'Invalid',
      })
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    const fees = watch('registrationFees')

    const parentIndex = fees?.findIndex((fee) => fee.uuid === active.id)
    const childIndex = fees?.findIndex(
      (fee) => fee.children?.findIndex((children) => children.uuid === active.id) !== -1
    )

    if (
      active.id !== over.id &&
      active.data.current.sortable.containerId === over.data.current.sortable.containerId
    ) {
      if (parentIndex !== -1) {
        const oldIndex = watch('registrationFees')!
          ?.map(({ uuid }) => uuid)
          .indexOf(active.id)
        const newIndex = watch('registrationFees')!
          ?.map(({ uuid }) => uuid)
          .indexOf(over.id)
        const newArray = arrayMove(watch('registrationFees')!, oldIndex, newIndex)
        setValue('registrationFees', newArray)
      }
      if (childIndex && childIndex !== -1) {
        const oldIndex =
          watch(`registrationFees.${childIndex}.children`)
            ?.map(({ uuid }) => uuid)
            .indexOf(active.id) ?? 0

        const newIndex =
          watch(`registrationFees.${childIndex}.children`)
            ?.map(({ uuid }) => uuid)
            .indexOf(over.id) ?? 0

        const newArray = arrayMove(
          watch(`registrationFees.${childIndex}.children`) ?? [],
          oldIndex,
          newIndex
        )
        setValue(`registrationFees.${childIndex}.children`, newArray)
      }
    }
  }

  const onAddClass = () => {
    setValue('registrationFees', [...watch(`registrationFees`)!, { ...initialFee, uuid: uuidv4() }])
  }

  const onAddDivision = () => {
    setValue('registrationFees', [
      ...watch(`registrationFees`)!,
      {
        ...initialFee,
        uuid: uuidv4(),
        children: [{ ...initialFee, uuid: uuidv4() }],
      },
    ])
  }

  return (
    <EventFormContainerComponent>
      <div className="pr-5 md:pr-0">
        <EventFormHeaderComponent
          title={
            eventTab && eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
              ? 'Entries'
              : 'Fees'
          }
          description={
            eventTab && eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
              ? 'Add classes and entry details'
              : 'Add classes to your horse show'
          }
        >
          <EventFormFooterCommonComponent
            eventTab={eventTab}
            nextEventTab={nextEventTab}
            onNext={(e, publishEvent) => {
              setShowErrors(true)
              handleSubmit(
                (data_) =>
                  publishEvent({
                    dataToSave: data_,
                    tabName: 'EventRegistrations',
                    validFormHandler: onValid,
                  }),
                eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
                  ? invalidFormHandler
                  : onInvalid
              )(e)
            }}
            onSaveAndExit={(e, publishEvent) => {
              setShowErrors(true)
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    onSaveAndExit: true,
                    tabName: 'EventRegistrations',
                    validFormHandler: onValidAnExit,
                  }),
                eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
                  ? invalidFormHandler
                  : onInvalidAndExit
              )(e)
            }}
          />
        </EventFormHeaderComponent>
        <ClassSettings
          control={control}
          setValue={setValue}
          trigger={trigger}
          errors={errors}
          watch={watch}
          register={register}
          handleModal={handleModal}
          qualifyingClasses={qualifyingClasses}
        />
        <div className="my-4 pt-4 flex flex-wrap gap-2 justify-between items-center border-t border-SeabiscuitLightThemeColorD3">
          <h4 className="font-bold">Add classes</h4>
          <div className="flex items-center gap-2 justify-end">
            <button
              onClick={() =>
                handleModalContext?.handleModal(true, MODAL_CONSTS.BULK_ADD, {
                  onSave: (fees: RegistrationFeesType[]) => {
                    setValue('registrationFees', [...watch(`registrationFees`)!, ...fees])
                  },
                })
              }
              className="text-SeabiscuitDark200ThemeColor underline hover:no-underline text-[14px]"
            >
              <span>Bulk add</span>
            </button>
          </div>
        </div>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={watch('registrationFees')!?.map(({ uuid }) => uuid)}
            strategy={verticalListSortingStrategy}
          >
            {watch('registrationFees')!.map((field, index) => {
              return (
                <EventEntriesFormRow
                  key={field.uuid}
                  field={field}
                  register={register}
                  index={index}
                  watch={watch}
                  showErrors={showErrors}
                  errors={errors?.registrationFees}
                  openAddNotesModal={openAddNotesModal}
                  handleModal={handleModal}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  selector="registrationFees"
                  dataIncrementAttrProps={dataIncrementAttrProps}
                />
              )
            })}
          </SortableContext>
        </DndContext>
        <div className="my-3 flex items-center gap-2 justify-between">
          <div className="text-[14px] text-SeabiscuitDark200ThemeColor/50 flex items-center gap-2">
            <img src={`/assets/og_icons/Cancel.svg`} className="rotate-45 w-5" alt="plus icon" />
            <button type="button" className="underline hover:no-underline" onClick={onAddClass}>
              Add class
            </button>
            I
            <button type="button" className="underline hover:no-underline" onClick={onAddDivision}>
              Add division
            </button>
          </div>

          {watch('registrationFees')!.length > 0 && (
            <button
              onClick={() => setValue('registrationFees', [])}
              className="text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline text-[14px]"
            >
              <span>Clear All</span>
            </button>
          )}
        </div>
      </div>
    </EventFormContainerComponent>
  )
}

export default EventEntriesFormComponent
