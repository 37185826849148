import { createSlice } from '@reduxjs/toolkit'
import { IEventInterface } from '../../models/events/event.interface'
import { IAssignedTicket } from '../../models/assigned-tickets/assigned-tickets.interface'
import { RootState } from '../store'
import { PayloadAction } from '@reduxjs/toolkit'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { TicketType } from '../../models/event-ticketing/event-ticketing.interface'
import { ISpectatorTickets } from '../../models/spectator-tickets/spectator-tickets.interface'

export interface ISpectator {
  data: []
  event_data: {
    selected: any[]
    ticketCount: number
  }
  event_tickets: TicketType[]
  spectator_tickets: ISpectatorTickets[]
  assign_payTab: IAssignedTicket[]
  eventMainData: IEventInterface | null
  assigned_tickets: IAssignedTicket[]
  valid_items: []
  payment_detail: {
    price: number
    type: string | null
  }
  payment_status: any
  eventTicketData: any
  nonLoggedInForm: {
    complete_status: boolean
    selected: any[]
    payed_item: number
    assigned_tickets: any[]
  }
}

interface ITicketSliceInitialState {
  spectator_ticket: ISpectator
  assigned_tickets: {
    data: IAssignedTicket[]
    selected_ticket: null | IAssignedTicket
  }
  selected: {
    registrationTicket: IRegistrationTicketInterface | null
  }
  ticketData: TicketType[]
}

export const initialSystemEventsState: ITicketSliceInitialState = {
  spectator_ticket: {
    data: [],
    event_data: {
      selected: [],
      ticketCount: 0,
    },
    event_tickets: [],
    eventMainData: null,
    spectator_tickets: [],
    assigned_tickets: [],
    assign_payTab: [],
    valid_items: [],
    payment_detail: {
      price: 0,
      type: null,
    },
    payment_status: {
      status: false,
      show_payment_done: false,
      data: {},
    },
    eventTicketData: {},
    nonLoggedInForm: {
      complete_status: false,
      selected: [],
      payed_item: 0,
      assigned_tickets: [],
    },
  },
  assigned_tickets: {
    data: [],
    selected_ticket: null,
  },
  selected: {
    registrationTicket: null,
  },
  ticketData: [],
}

const ticketSlice = createSlice({
  name: 'tickets',
  initialState: initialSystemEventsState,
  reducers: {
    setSelectedTicket(state, action: PayloadAction<IAssignedTicket | null>) {
      state.assigned_tickets.selected_ticket = action.payload
    },
    setSelectedRegistrationTicket(
      state,
      action: PayloadAction<IRegistrationTicketInterface | null>
    ) {
      state.selected.registrationTicket = action.payload
    },
    setAllTickets(state, action: PayloadAction<IAssignedTicket[]>) {
      state.assigned_tickets.data = action.payload
    },
    updateKeysTickets(state, { payload }) {
      const data_index = payload?.index
      const key_name = payload?.key_name
      const key_value = payload?.key_value

      state.assigned_tickets.data = state.assigned_tickets.data.map((item: any, index: number) => {
        if (index === data_index) {
          item[key_name] = key_value
        }
        return item
      })
    },
    setSpectatorEventData(
      state,
      {
        payload,
      }: {
        payload: {
          [K in keyof ITicketSliceInitialState['spectator_ticket']]?: ITicketSliceInitialState['spectator_ticket'][K]
        }
      }
    ) {
      if (payload?.data) {
        state.spectator_ticket.data = payload.data
      }
      if (payload?.event_data) {
        state.spectator_ticket.event_data = payload?.event_data
      }
      if (payload?.spectator_tickets) {
        state.spectator_ticket.spectator_tickets = payload.spectator_tickets
      }
    },
    setSpectatorMainData(state, { payload }: { payload: any }) {
      state.spectator_ticket.eventMainData = payload.eventMainData
        ? payload.eventMainData
        : state.spectator_ticket.eventMainData
      state.spectator_ticket.event_tickets = payload.event_tickets
        ? payload.event_tickets
        : state.spectator_ticket.event_tickets
      state.spectator_ticket.eventTicketData = payload.eventTicketData
        ? payload.eventTicketData
        : state.spectator_ticket.eventTicketData
    },
    setSpectatorAssignTickets(
      state,
      { payload }: { payload: ITicketSliceInitialState['spectator_ticket']['assigned_tickets'] }
    ) {
      state.spectator_ticket.assigned_tickets = payload
    },
    setSpectatorPayTabTickets(
      state,
      { payload }: { payload: ITicketSliceInitialState['spectator_ticket']['assigned_tickets'] }
    ) {
      state.spectator_ticket.assign_payTab = payload
    },
    setPaymentDetails(state, { payload }: { payload: any }) {
      state.spectator_ticket.payment_detail = payload.payment_detail
        ? payload.payment_detail
        : state.spectator_ticket.payment_detail
      state.spectator_ticket.payment_status = payload.payment_status
        ? payload.payment_status
        : state.spectator_ticket.payment_status
    },
    setEventTickets(state, { payload }: { payload: TicketType[] }) {
      state.spectator_ticket.event_tickets = payload
    },
    allDataReset(state) {
      state.spectator_ticket = initialSystemEventsState.spectator_ticket
    },
  },
})

const getSelectedTicketR = (state: RootState) => state.tickets.assigned_tickets.selected_ticket
const getAssignedTicketsR = (state: RootState) => state.tickets.assigned_tickets.data
const getSelectedRegistrationTicketsR = (state: RootState) =>
  state.tickets.selected.registrationTicket
const getTicketData = (state: RootState) => state.tickets.ticketData

export const ticketsReducer = {
  getAssignedTicketsR,
  getSelectedTicketR,
  getSelectedRegistrationTicketsR,
  getTicketData,
}

export const {
  setAllTickets,
  setSelectedTicket,
  updateKeysTickets,
  setSelectedRegistrationTicket,
  setSpectatorEventData,
  setSpectatorMainData,
  setSpectatorAssignTickets,
  setPaymentDetails,
  setEventTickets,
  setSpectatorPayTabTickets,
  allDataReset,
} = ticketSlice.actions

export const selectSpectatorTicketsR = (state: RootState) => state.tickets.spectator_ticket
export const selectEventTicketsR = (state: RootState) =>
  state.tickets.spectator_ticket.event_tickets
export const selectSpectatorTickets = (state: RootState) =>
  state.tickets.spectator_ticket.spectator_tickets
export const selectAssignedPayTabTickets = (state: RootState) =>
  state.tickets.spectator_ticket.assign_payTab

export default ticketSlice.reducer
