import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'

import FirestoreService from '../../../../../../../../services/firestoreService'

import { getConvertedData } from '../../../../../../../../models/interface.helper'
import { getUserFullName } from '../../../../../../../../helpers/helpers'

import { UserModel } from '../../../../../../../../models/users/user.model'
import { RegistrationByDayModel } from '../../../../../../../../models/registrations-by-day/registrationByDay.model'
import { IUserInterface } from '../../../../../../../../models/users/user.interface'
import { RegistrationFeesType } from '../../../../../../../../models/event-fees/event-fees.interface'

import { CONST } from '../../../../../../../../const/const'

import { sheets } from '../../../../data/sheets'
import {
  IRegistrationByDayEx,
  RegistrationFeesTypeEx,
} from '../../../ManageClinicNOtherOperationsTab'
import { MODAL_CONSTS } from '../../../../../../../../const/modal-const'
import { IHandleModal } from '../../../../../../../../layout/mainlayout/MainLayout'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const addSheetAndJudges = async (
  row: IRegistrationByDayInterface,
  fee: RegistrationFeesType
) => {
  if (!fee) return

  const sheet = sheets.find((current) => current.id === fee?.scoreCard)
  let judges: IUserInterface[] = []

  const userSnaps = await FirestoreService.getItemsUsingIds(
    COLLECTIONS.USERS.NAME,
    fee?.judges ?? []
  )

  userSnaps.forEach((currDoc) => {
    judges.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
  })

  const updatedRegistrationByDay = {
    ...row,
    score: {
      ...row.score,
      sheet: {
        name: sheet?.name ?? '',
        type: sheet?.type ?? '',
      },
      judges:
        judges?.map((judge) => ({
          id: judge.id,
          name: getUserFullName(judge),
          furtherRemarks: sheet?.furtherRemarks ?? '',
          sections: sheet?.sections ?? [],
          errors: sheet?.errors ?? 0,
        })) ?? null,
    },
  }

  // Update firestore collection
  const item = new RegistrationByDayModel(updatedRegistrationByDay)

  await FirestoreService.updateItem(
    COLLECTIONS.REGISTRATION_BY_DAY.NAME,
    item.id,
    item.toFirestore()
  )
}

export const onRank = async ({
  row,
  fee,
  setScoringLoading,
  getRegistrationsByDay,
  handleModalContext,
  onChangeJudge,
}: {
  row: IRegistrationByDayEx
  fee?: RegistrationFeesType | null
  getRegistrationsByDay: () => Promise<IRegistrationByDayInterface[]>
  setScoringLoading: (value: boolean) => void
  handleModalContext: IHandleModal | null
  onChangeJudge: (
    row: RegistrationFeesTypeEx,
    values: { label: string; value: string }[]
  ) => Promise<void>
}) => {
  setScoringLoading(true)

  if (fee?.scoreCard && !row.score?.sheet?.name) {
    await addSheetAndJudges(row, fee)
    const rows: IRegistrationByDayInterface[] = await getRegistrationsByDay()
    const currentRow = rows.find((current) => current.id === row.id)
    if (currentRow?.score) row.score = currentRow.score
  }

  setScoringLoading(false)
  handleModalContext?.handleModal(true, MODAL_CONSTS.SCORING, {
    rider: row,
    onSaveScoring: getRegistrationsByDay,
    onChangeJudge,
    editable: true,
  })
}
