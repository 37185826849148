export const processScratchItems = <
  T extends {
    id?: string | null
    isScratched?: boolean
    paymentStatus?: string | null
    selectedUnitsCount?: number
  },
>(
  items: T[],
  field: keyof T
) => {
  const scratchedItems: T[] = []
  let scratchTotal = 0

  items.forEach((item) => {
    if (!item.isScratched && item.id) {
      scratchTotal += ((item[field] as number) ?? 0) * (item.selectedUnitsCount || 1)
      scratchedItems.push({
        ...item,
        amountScratched: item[field],
        isScratched: true,
        scratchAmountAdded: true,
      } as T)
    }
  })

  return { scratchedItems, scratchTotal }
}
