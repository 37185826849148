// Library
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

// Component
import { RedTitle } from './global/RedTitle'
import { ScheduleByRider } from './ScheduleByRider'
import { ScheduleByClass } from '../ScheduleByClass'

// FireBase
import FirestoreService from '../../../../services/firestoreService'

// Models
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'

import { CONST } from '../../../../const/const'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface EventOrderOfGoProps {
  isTitleHide?: boolean
}

export interface IClassOption {
  value: string
  label: string
  actualCostAlias: string
  dayOfWeek: string
  fullTime: string
  increment: string
  publishOrder: boolean
  location: string
  order: string
  rideTime: string
}

export interface IClassOptionRegistered extends IClassOption {
  registered: IRegistrationByDayInterface[]
}

const EventOrderOfGo: React.FC<EventOrderOfGoProps> = ({ isTitleHide }) => {
  // Hooks and vars
  const eventId = useParams<{ eventId: string }>().eventId ?? null
  const [days, setDays] = useState<string[]>([])
  const [activeDay, setActiveDay] = useState<string>('All')
  const [scheduleMode, setScheduleMode] = useState<string>('class')

  // Fetch EventSchedule from Firestore - can use redux state in the future if we want but need to sync toggle and form watching in downstream components to redux state.
  const fetchScheduleBy = useCallback(async () => {
    try {
      const eventSchedulingSnapShot = await FirestoreService.getItem(
        COLLECTIONS.EVENT_SCHEDULING.NAME,
        eventId
      )

      const scheduleByDataPoint = eventSchedulingSnapShot?.data()?.scheduleBy ?? 'class' // Default to class for backwards compatibility

      setScheduleMode(scheduleByDataPoint)
    } catch (error) {
      console.error('Failed to fetch scheduleBy:', error)
    }
  }, [eventId])

  useEffect(() => {
    fetchScheduleBy().then()
  }, [eventId, fetchScheduleBy])

  return (
    <>
      {!isTitleHide && <RedTitle text="Classes" className="mb-4 sm:mb-7" />}

      {scheduleMode === 'rider' ? (
        <ScheduleByRider
          activeDay={activeDay}
          days={days}
          setDays={setDays}
          setActiveDay={setActiveDay}
        />
      ) : (
        <ScheduleByClass
          setDays={setDays}
          days={days}
          setActiveDay={setActiveDay}
          activeDay={activeDay}
        />
      )}
    </>
  )
}

export default EventOrderOfGo
