import { IMAGE_CONSTS } from '../../../const/image-const'

const TABS = {
  active: 0,
  tablist: ['All', 'Unread', 'Read', 'Settings'],
  tabDescriptionList: ['', '', 'Manage the notifications you wish to receive'],
  tabEmptyList: [
    'You have no notifications',
    'You have no unread notifications',
    'You have no notifications',
    '',
  ],
}

let listing = [
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'nitesh',
    message: 'hello',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'nitesh',
    message: 'hello',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
]

let listing2 = [
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
]
let settingsList = [
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Event updates',
    setting_description:
      'Weather delays, scores published, course map updated, new ride times, etc.',
    key: 'event_updates',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Messages',
    setting_description: 'Messages from team members, event organizers, etc.',
    key: 'messages',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Paperwork',
    setting_description:
      'Requests for signature, document saved, team members signed their paperwork, etc.',
    key: 'paperwork',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Billing',
    setting_description: 'New bill to pay, view receipts, payment confirmation, etc.',
    key: 'billing',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Scoring',
    setting_description: 'Scores published, scores updated, scores contested, etc.',
    key: 'scoring',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Team',
    setting_description:
      'Team member added, team member registered, team member signed paperwork, etc.',
    key: 'team',
    on: true,
  },

  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Horse status',
    setting_description: 'Governing body eligibility issues, memberships expiring, etc.',
    key: 'horse_status',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Profile status',
    setting_description:
      'Eligibility issues, memberships expiring, foreign endorsement letter issues, etc.',
    key: 'profile_status',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Club updates',
    setting_description: 'New members, leaderboard and volunteer hour updates.',
    key: 'club_update',
    on: true,
  },
]
export const PAPERWORK_DATA = {
  TABS,
  listing,
  listing2,
  settingsList,
}
