import React, { FC, useEffect, useState } from 'react'

import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { useParams } from 'react-router'

import { DaysTabs } from '../../common/DaysTabs/DaysTabs'
import Accordion from '../../../accordion/common/Accordion'
import ExhibitorOrderOfGoAccordion from './accordions/ExhibitorOrderOfGoAccordion'
import ViewsLoader from '../../../loader/ViewsLoader'

import FirestoreService from '../../../../services/firestoreService'

import { getUserFullName } from '../../../../helpers/helpers'
import { getRegisteredRiders } from '../../../../helpers/riders'
import useHorse from '../../../../pages/organizer/manage/common/table/HorsesManage/hooks/useHorse'

import { useAppSelector } from '../../../../store/hooks'
import { selectAllUsers } from '../../../../store/users/usersSlice'

import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationFeesType } from '../../../../models/event-fees/event-fees.interface'
import { IBreak } from '../../../../models/events/event.interface'

import { CONST } from '../../../../const/const'
import {getConvertedData} from "../../../../models/interface.helper";
import {EventModel} from "../../../../models/events/event.model";

const formatTimeTo12Hour = (time: string | undefined) => {
  if (!time) return 'N/A'
  const [hour, minute] = time.split(':').map(Number)
  const ampm = hour >= 12 ? 'PM' : 'AM'
  const formattedHour = hour % 12 || 12
  return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface ScheduleByRiderProps {
  activeDay: string
  setActiveDay: (value: string) => void
  days: string[]
  setDays: (value: string[]) => void
}
export const ScheduleByRider: FC<ScheduleByRiderProps> = ({
  activeDay,
  setActiveDay,
  days,
  setDays,
}) => {
  const eventId = useParams<{ eventId: string }>().eventId ?? null
  const { horses } = useHorse(eventId)
  const users = useAppSelector(selectAllUsers)

  const [loading, setLoading] = useState<boolean>(true)
  const [classes, setClasses] = useState<any | null>(null)
  const [riderGroupPublishStatus, setRiderGroupPublishStatus] = useState<
    Record<string, { publishOrder: boolean }>
  >({})

  const handleGetRiderData = async () => {
    setLoading(true)
    try {
      const riders = await getRegisteredRiders([eventId])

      const eventSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
      const eventData = getConvertedData(EventModel.fromFirestoreDoc(eventSnapShot).toObject())

      const eventBreaks: IBreak[] = eventData?.breaks ?? []

      // Fetch registration fees
      const snapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)
      const registrationFees = new EventFeesModel(snapShot.data()).toFirestore()

      const eventReviewPublishSnap = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      const publishData = eventReviewPublishSnap.data() || {}
      const riderGroupPublish = publishData?.RiderGroupPublish || {}

      // Group classes by day + facility
      const classesByDayFacility: Record<string, { classes: string[]; facility: string }> = {}

      registrationFees?.registrationFees?.forEach((fee: RegistrationFeesType) => {
        const dayKey = new Date(fee.startDate).toLocaleDateString('en-CA')
        const facility = fee.location || 'No Facility Assigned'
        const groupKey = `${dayKey}_${facility}`

        if (!classesByDayFacility[groupKey]) {
          classesByDayFacility[groupKey] = { classes: [], facility }
        }
        classesByDayFacility[groupKey].classes.push(fee.name ?? '')
      })

      //  Group registrations by day + facility
      const groupedByDayFacility: Record<string, any[]> = {}
      const uniqueDays = new Set<string>() // To store unique days for DaysTabs

      Object.entries(classesByDayFacility).forEach(([groupKey, { classes }]) => {
        const [dayKey] = groupKey.split('_') // Extract the day

        //  Get all registrations that belong to any class on this day
        let registrationsForGroup = riders.filter((registration: IRegistrationByDayInterface) =>
          classes.includes(registration.registrationByDayName ?? '')
        )

        //  Enrich each registration
        registrationsForGroup = registrationsForGroup.map(
          (registration: IRegistrationByDayInterface, index: number) => {
            // Find the fee whose name matches the registration's class name
            const matchingFee: RegistrationFeesType | undefined =
              registrationFees?.registrationFees?.find(
                (feeItem: RegistrationFeesType) =>
                  feeItem.name === registration.registrationByDayName
              )

            // Look up the rider's user object
            const riderAsUserObj = users.find((user) => user.id === registration.riderId)

            return {
              ...registration,
              riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : registration.riderName,
              order: registration.order ?? index + 1,
              fee: matchingFee,
            }
          }
        )

        // Fetch breaks for this group
        const breaksForGroup = eventBreaks.filter((brk) => brk.groupKey === groupKey)

        // Combine breaks and registrations, then sort
        groupedByDayFacility[groupKey] = [...registrationsForGroup, ...breaksForGroup].sort(
          (a, b) => (a.order ?? 0) - (b.order ?? 0)
        )
        uniqueDays.add(dayKey) // Add unique days for filtering
      })

      setClasses(groupedByDayFacility)
      setDays([...Array.from(uniqueDays)])
      setRiderGroupPublishStatus(riderGroupPublish)
    } catch (error) {
      console.error(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetRiderData().then()
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[100px]">
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[30vh] mt-3"
          size="lg"
          color="#F70763"
        />
      </div>
    )
  }

  return Object.entries(classes ?? []).length > 0 ? (
    <>
      <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} isRiderMode={true} />

      {Object.entries(classes ?? [])
        .filter(([groupKey]) => activeDay === 'All' || groupKey.startsWith(activeDay))
        .map(([groupKey, riderEntries]) => {
          const typedRiderEntries = riderEntries as any
          const [dayKey, facility] = groupKey.split('_')

          // Filter actual registrations (excluding breaks)
          const registrations = typedRiderEntries.filter(
            (entry: any) => !('isBreakDrag' in entry && entry.isBreakDrag)
          )

          // Get the earliest ride time from valid registrations

          const earliestRideTime = registrations.length
            ? formatTimeTo12Hour(
                registrations
                  .map((entry: any) => entry.rideTime)
                  .filter(
                    (time: any): time is string => typeof time === 'string' && time.trim() !== ''
                  )
                  .sort()[0]
              )
            : 'N/A'

          const isPublished = riderGroupPublishStatus[groupKey]?.publishOrder ?? false

          return (
            <Accordion
              key={groupKey}
              icon={<AddCircleOutline className="add_icon text-[red]" />}
              initialState={false}
              status={isPublished ? 'Available' : 'Pending'}
              className="mb-2 w-full"
              header={
                <div className="flex flex-col xl:flex-row gap-2 text-[14px] justify-between pr-[10px]">
                  <div className="flex gap-2 items-center">
                    <span className="text-[14px] font-semibold">
                      {`${new Date(dayKey).toLocaleDateString('en-US', { weekday: 'long' })} rides`}
                    </span>
                  </div>
                  <div className="text-[#122B4680] font-normal flex items-center gap-1">
                    <span className="customValue text-[#122B46]">{registrations.length}</span>
                    rides •
                    <span className="customValue text-[#122B46]">
                      {earliestRideTime ? earliestRideTime : 'N/A'}
                    </span>{' '}
                    {new Date(dayKey).toLocaleDateString('en-US', { weekday: 'long' })} •
                    <span className="customValue text-[#122B46]">{facility}</span>
                  </div>
                </div>
              }
              headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
              activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
            >
              <ExhibitorOrderOfGoAccordion
                data={typedRiderEntries}
                fullTime={'00:00:00'}
                increment={'00:00:00'}
                publishOrder={true}
                horses={horses}
                eventId={eventId}
                isRiderMode={true}
              />
            </Accordion>
          )
        })}
    </>
  ) : (
    <p className="text-SeabiscuitDark200ThemeColor/50">No rider schedules available</p>
  )
}
